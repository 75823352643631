import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const NewsCaseMobileItem = ({ expediente }) => {
	const Field = ({ label, value }) => {
		return (
			<>
				<h5 className='text-body-large-bold text-secondary-default'>{label}</h5>
				<h5 className='text-body-regular'>{value}</h5>
			</>
		);
	};

	Field.propTypes = {
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	};
	const history = useHistory();


  const goToCase = () => {
    history.push(`/admin/expedientes/${expediente.id}`);
  };

	return (
		<Card className='my-3 shadow-card' onClick={goToCase}>
			<CardHeader className='p-0'>
				<Col
					xs={12}
					className='p-0 bg-primary-variant-4'
				>
					<h5 className='text-caps-regular m-0 p-2 text-secondary-default'>
						{expediente.orden}. Expediente {expediente.number}/{expediente.year}
					</h5>
				</Col>
			</CardHeader>
			<CardBody>
				<Row className='m-0'>
					<Col
						xs={12}
						className='p-0'
					>
						<Field
							label='Carátula'
							value={expediente.coverPage}
						/>
					</Col>
					<Col
						xs={6}
						className='p-0'
					>
						<Field
							label='Jurisdicción'
							value={expediente.Jurisdiction?.name}
						/>
					</Col>
					<Col
						xs={6}
						className='p-0'
					>
						<Field
							label='Circunscripción'
							value={expediente.OrganismList[0].Organism.Circumscription?.name}
						/>
					</Col>
					<Col
						xs={6}
						className='p-0'
					>
						<Field
							label='Organismo'
							value={expediente.OrganismList[0].Organism?.name}
						/>
					</Col>
					<Col
						xs={6}
						className='p-0'
					>
						<Field
							label='Carpeta'
							value={expediente.folder ?? '-'}
						/>
					</Col>
					<Col
						xs={12}
						className='p-0'
					>
						<Field
							label='Responsable'
							value={
								expediente.colaborador?.name
                  ? `${expediente.colaborador?.name} ${expediente.colaborador?.lastName}`
                  : `${expediente.usuario?.name} ${expediente.usuario?.lastName}`
							}
						/>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default NewsCaseMobileItem;

NewsCaseMobileItem.propTypes = {
	expediente: PropTypes.object.isRequired,
};
