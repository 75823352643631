import React, { useContext, useEffect, useState } from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import ToggleButton from '../../components/Commons/ToggleButton/ToggleButton';
import { FaPlus } from 'react-icons/fa';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import AddNonWorkingDaysModal from './components/AddNonWorkingDaysModal/AddNonWorkingDaysModal';
import user from '../../context/user';
import { casesService } from '../../services/casesService';
import * as Yup from 'yup';

const ExpedientesConfigurationSchema = Yup.object().shape({
	caseChanges: Yup.boolean(),
	newCaseAdded: Yup.boolean(),
	expiredCaseAlert: Yup.boolean(),
	automaticNotifications: Yup.boolean(),
	automaticNotificationsForAdmin: Yup.boolean(),
	automaticNotificationsForResponsible: Yup.boolean(),
	automaticNotificationsSendHour: Yup.string(),
	expiredInterval: Yup.number(),
	computedType: Yup.string(),
	previousAlert: Yup.number(),
});

const ExpedientesConfiguration = (props) => {
	const {
		state: {
			userdata: { Profile },
		},
		actions: { mutateUserdata },
	} = useContext(user);

	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const initialValues = {
		caseChanges: false,
		newCaseAdded: false,
		expiredCaseAlert: false,
		automaticNotifications: false,
		automaticNotificationsForAdmin: false,
		automaticNotificationsForResponsible: false,
		automaticNotificationsSendHour: '00:00',
		expiredInterval: '0',
		computedType: 'WORKING_DAYS',
		previousAlert: '0',
	};

	useEffect(() => {
		if (Profile) {
			formik.setValues({
				caseChanges: Profile.notificationExpChange,
				newCaseAdded: Profile.notificationExpAdd,
				expiredCaseAlert: Profile.notificationCaducity,
				automaticNotifications: Profile.enableNotification,
				automaticNotificationsForAdmin: Profile.sendUserNotification,
				automaticNotificationsForResponsible: Profile.sendCollaboratorNotification,
				automaticNotificationsSendHour: Profile.scheduleNotification ?? '00:00',
				expiredInterval: Profile.caducityPeriod,
				computedType: Profile.calculationCaducity,
				previousAlert: Profile.alertPeriod,
			});
		}
	}, [Profile]);

	const handleSubmit = async (values) => {
		formik.setSubmitting(true);
		try {
			await casesService.updateGeneralSettings({
        //* If alertPeriod, caducityPeriod or calculationCaducity don't change, don't send them to don't run stored procedure again
        ...(() => {
          if (values.previousAlert !== Profile.alertPeriod) {
            return { alertPeriod: values.previousAlert };
          }
        })(),
        ...(() => {
          if (values.expiredInterval !== Profile.caducityPeriod) {
            return { caducityPeriod: values.expiredInterval };
          }
        })(),
        ...(() => {
          if (values.computedType !== Profile.calculationCaducity) {
            return { calculationCaducity: values.computedType };
          }
        })(),
				enableNotification: values.automaticNotifications,
				notificationCaducity: values.expiredCaseAlert,
				notificationExpAdd: values.newCaseAdded,
				notificationExpChange: values.caseChanges,
				sendCollaboratorNotification: values.automaticNotificationsForResponsible,
				sendUserNotification: values.automaticNotificationsForAdmin,
				automaticNotificationsSendHour: Profile.scheduleNotification,
			});
			formik.setSubmitting(false);
			await mutateUserdata();
			setShowError(false);
			setShowSuccess(true);
		} catch (e) {
			setShowSuccess(false);
			setShowError(true);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: ExpedientesConfigurationSchema,
		onSubmit: handleSubmit,
	});

	return (
		<>
			<HomeHeader
				{...props}
				headerText='Configuración General de Expedientes'
				canGoBack
			/>
			<Container
				className='bg-white mb-5'
				fluid
			>
				<Form onSubmit={formik.handleSubmit}>
					<Card className='shadow-card mb-4'>
						<CardBody>
							<h1 className='text-heading-2'>Notificaciones de la plataforma</h1>
							<Col>
								<Row className='py-3 align-items-center'>
									<FormGroup className='custom-control custom-control-alternative custom-checkbox px-5'>
										<Input
											className='custom-control-input'
											id='caseChanges'
											name='caseChanges'
											checked={formik.values.caseChanges}
											onChange={formik.handleChange}
											type='checkbox'
										/>
										<Label
											className='custom-control-label'
											htmlFor='caseChanges'
										>
											<span className='text-body-regular m-0'>Cambios en expediente</span>
										</Label>
									</FormGroup>
									<FormGroup className='custom-control custom-control-alternative custom-checkbox px-5'>
										<Input
											className='custom-control-input'
											id='newCaseAdded'
											name='newCaseAdded'
											checked={formik.values.newCaseAdded}
											onChange={formik.handleChange}
											type='checkbox'
										/>
										<Label
											className='custom-control-label'
											htmlFor='newCaseAdded'
										>
											<span className='text-body-regular m-0'>Nuevo expediente agregado</span>
										</Label>
									</FormGroup>
									<FormGroup className='custom-control custom-control-alternative custom-checkbox px-5'>
										<Input
											className='custom-control-input'
											id='expiredCaseAlert'
											name='expiredCaseAlert'
											checked={formik.values.expiredCaseAlert}
											onChange={formik.handleChange}
											type='checkbox'
										/>
										<Label
											className='custom-control-label'
											htmlFor='expiredCaseAlert'
										>
											<span className='text-body-regular m-0'>Alerta de caducidad</span>
										</Label>
									</FormGroup>
								</Row>
							</Col>
						</CardBody>
					</Card>
					<Card className='shadow-card mb-4'>
						<CardBody>
							<h1 className='text-heading-2'>Notificaciones de novedades por e-mail</h1>
							<Col>
								<Row className='py-3 align-items-center'>
									<span className='text-body-large-bold'>Notificaciones automáticas</span>
									<div className='ml-3'>
										<ToggleButton
											checked={formik.values.automaticNotifications}
											setChecked={(value) => {
												formik.setFieldValue('automaticNotifications', value);
											}}
										/>
									</div>
								</Row>
								<Row className='py-3 align-items-center'>
									<Row className='m-0 align-items-center'>
										<span className='text-body-large-bold text-secondary-default'>Destinatario</span>
										<FormGroup className='custom-control custom-control-alternative custom-checkbox px-5 m-0'>
											<Input
												className='custom-control-input'
												id='automaticNotificationsForAdmin'
												name='automaticNotificationsForAdmin'
												checked={formik.values.automaticNotificationsForAdmin}
												onChange={formik.handleChange}
												type='checkbox'
											/>
											<Label
												className='custom-control-label'
												htmlFor='automaticNotificationsForAdmin'
											>
												<span className='text-body-regular m-0'>Administrador</span>
											</Label>
										</FormGroup>
										<FormGroup className='custom-control custom-control-alternative custom-checkbox px-5 m-0'>
											<Input
												className='custom-control-input'
												id='automaticNotificationsForResponsible'
												name='automaticNotificationsForResponsible'
												checked={formik.values.automaticNotificationsForResponsible}
												onChange={formik.handleChange}
												type='checkbox'
											/>
											<Label
												className='custom-control-label'
												htmlFor='automaticNotificationsForResponsible'
											>
												<span className='text-body-regular m-0'>Responsable</span>
											</Label>
										</FormGroup>
									</Row>
								</Row>
							</Col>
						</CardBody>
					</Card>
					<Card className='shadow-card mb-4'>
						<CardBody>
							<h3 className='text-heading-2'>Plazo de caducidad por defecto</h3>
							<Row className='align-items-center'>
								<Col className='align-items-center'>
									<Row className='m-0 align-items-center'>
										<span className='text-body-large-bold text-secondary-default'>Plazo</span>
										<FormGroup className='px-3 align-items-center m-0'>
											<InputGroup
												className='input-group-alternative align-items-center m-0'
												size='sm'
											>
												<Input
													className='p-1'
													type='number'
													name='expiredInterval'
													value={formik.values.expiredInterval}
													onChange={formik.handleChange}
												/>
											</InputGroup>
										</FormGroup>
										<span className='text-body-regular'>días</span>
									</Row>
								</Col>
								<Col className='align-items-center px-0'>
									<Row className='m-0 align-items-center'>
										<span className='text-body-large-bold text-secondary-default'>Cómputo</span>
										<FormGroup
											className='align-content-center m-0 justify-content-center w-75'
											row
											tag='fieldset'
											onChange={formik.handleChange}
										>
											<Col>
												<FormGroup
													className='custom-radio align-items-center d-flex justify-content-center'
													check
												>
													<Input
														name='computedType'
														className='custom-control-input'
														value='WORKING_DAYS'
														defaultChecked={Profile?.calculationCaducity === 'WORKING_DAYS'}
														id='WORKING_DAYS'
														type='radio'
													/>
													<Label
														check
														for='WORKING_DAYS'
														className='custom-control-label'
													>
														<span className='text-body-regular'>Hábiles</span>
													</Label>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup
													className='custom-radio align-items-center d-flex justify-content-center'
													check
												>
													<Input
														name='computedType'
														id='NON_WORKING_DAYS'
														value='NON_WORKING_DAYS'
														className='custom-control-input'
														defaultChecked={Profile?.calculationCaducity === 'NON_WORKING_DAYS'}
														type='radio'
													/>
													<Label
														check
														for='NON_WORKING_DAYS'
														className='custom-control-label'
													>
														<span className='text-body-regular'>Inhábiles</span>
													</Label>
												</FormGroup>
											</Col>
										</FormGroup>
									</Row>
								</Col>
								<Col className='align-items-center' xs='4' >
									<Row className='m-0 align-items-center'>
										<span className='text-body-large-bold text-secondary-default'>Alerta previa</span>
										<FormGroup className='align-items-center m-0 px-3'>
											<InputGroup
												className='input-group-alternative align-items-center m-0'
												size='sm'
											>
												<Input
													className=''
													type='number'
													name='previousAlert'
													value={formik.values.previousAlert}
													onChange={formik.handleChange}
												/>
											</InputGroup>
										</FormGroup>
										<span className='text-body-regular'>días</span>
									</Row>
								</Col>
								<Col
									className='py-3 align-items-center px-0'
									xs='2'
								>
									<Button
										type='button'
										className='btn btn-outline-noti-secondary py-1 px-4'
										onClick={() => setShowModal(true)}
									>
										<Row className='m-0 align-items-center'>
											<FaPlus className='stroke-secondary-default' />
											<span className=''>Días inhábiles</span>
										</Row>
									</Button>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Row className='align-items-center'>
						<Col xs={2}>
							<Button
								className='btn btn-noti-primary py-1 px-5'
								type='submit'
								disabled={formik.isSubmitting}
							>
								<span
									className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
									role='status'
									aria-hidden='true'
								/>
								<span className='text-button'>Actualizar</span>
							</Button>
						</Col>
						<Col>
							{showSuccess && setTimeout(() => setShowSuccess(false), 2000) && (
								<CustomMessage
									icon={
										<CheckIcon
											width={20}
											height={20}
											className='stroke-success'
										/>
									}
									message={'¡Datos actualizados!'}
								/>
							)}
							{showError && setTimeout(() => setShowError(false), 2000) && (
								<CustomMessage
									icon={
										<CloseIcon
											width={20}
											height={20}
											className='stroke-error'
										/>
									}
									message={'Error al actualizar la configuración'}
								/>
							)}
						</Col>
					</Row>
				</Form>
			</Container>
			<AddNonWorkingDaysModal
				isOpen={showModal}
				toggle={() => setShowModal(!showModal)}
			/>
		</>
	);
};

ExpedientesConfiguration.propTypes = {};

export default ExpedientesConfiguration;
