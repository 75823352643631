import React, { useContext, useState } from 'react';
import { Card, CardBody, Col, Container, Row, CardFooter } from 'reactstrap';
import HomeHeader from 'components/Headers/HomeHeader';
import TableOptionsButtons from 'components/Commons/TableOptionsButtons/TableOptionsButtons';
import DynamicTable from 'components/Commons/DynamicTable';
import user from '../../context/user';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../components/Commons/Pagination';
import useCasesListTableColumns from './hooks/useCasesListTableColumns';
import { collaboratorRoleService } from '../../services/collaboratorRoleService';
import useUpdateSearchParams from './hooks/useUpdateSearchParams';
import { CHART_BAR_COLORS, LAYOUTS } from '../../helpers/consts';
import { formatToChartData } from '../../helpers/utils';
import CardResume from '../../components/Charts/CardResume';
import CardResumeFilter from '../../components/Charts/CardResumeFilter';
import CardResumeBar from '../../components/Charts/CardResumeBar';
import { ReactComponent as ConnectionsIcon } from '../../assets/img/icons/notiexpress/sync-icon.svg';
import { ReactComponent as ZapIcon } from '../../assets/img/icons/notiexpress/zap-icon.svg';

const ExpedientsForCollaboratorView = () => {
	const {
		state: { currentUser },
	} = useContext(user);
	const history = useHistory();
	const rowsPerPage = 10;
	const initialParams = useParams();
	const [params, setParams] = useState(initialParams);

	const {
		data: casesList,
		isError: isErrorCasesList,
		isLoading: isLoadingCasesList,
		mutate: mutateCasesList,
	} = collaboratorRoleService.getExpedients({ params });

	const { data: casesCount, isLoading: isLoadingCount, mutate: mutateCasesCount } = collaboratorRoleService.getExpedientsCount();

	const { currentColumns } = useCasesListTableColumns();

	const columns = currentColumns.filter(({ Header }) => Header !== 'Responsable');

	const { handleSetPage, handleResetSearch } = useUpdateSearchParams({
		rowsPerPage,
		setParams,
		history,
		params,
		layout: 'collaborator',
		currentUser,
	});

	const jurisdictionsBarData = formatToChartData({ data: casesCount?.totalByJurisdiction, colors: CHART_BAR_COLORS });

	return (
		<>
			<HomeHeader headerText='Administración de Expedientes' />
			<Container
				className='bg-gradient-white'
				fluid={true}
			>
				<Row className='mb-3'>
					<Col
						style={{
							maxWidth: '250px',
						}}
					>
						<CardResume
							isLoading={isLoadingCount}
							layout={LAYOUTS.COLLABORATOR}
							totalExp={casesCount?.totalExp ?? 0}
						/>
					</Col>
					<Col
						style={{
							maxWidth: '350px',
						}}
					>
						<CardResumeFilter
							cardTitle='Total con'
							subtitle='Novedades'
							totalExp={casesCount?.todayNewslettersExp ?? 0}
							date={new Date().toISOString().split('T')[0]}
							isLoading={isLoadingCount}
						>
							<ZapIcon
								width={20}
								height={20}
								className='stroke-primary-default'
							/>
						</CardResumeFilter>
					</Col>
					<Col
						style={{
							maxWidth: '350px',
						}}
					>
						<CardResumeBar
							data={jurisdictionsBarData}
							cardTitle='Total por'
							subtitle='Jurisdicción'
							isLoading={isLoadingCount}
						>
							<ConnectionsIcon
								width={20}
								height={20}
								className='fill-primary-default'
							/>
						</CardResumeBar>
					</Col>
				</Row>
			</Container>
			<Container
				className='bg-white'
				fluid
			>
				<Row
					xs='3'
					className='mb-3'
				>
					<Col>
						<Card className='shadow-card mb-3'>
							<CardBody className='pt-0 '>
								<Row className='m-0 justify-content-between py-5'>
									<Pagination
										page={casesList?.currentPage ?? 0}
										handleSetPage={handleSetPage}
										count={casesList?.totalCount ?? 0}
										pageCount={casesList?.totalPage ?? 0}
										rowsPerPage={rowsPerPage ?? 15}
										disabled={isErrorCasesList || isLoadingCasesList}
									/>
									<Col
										xs={12}
										className='p-0'
									>
										<TableOptionsButtons
											tableType='cases'
											data={casesList?.paginateData ?? []}
											resetSearch={handleResetSearch}
											layout={LAYOUTS.COLLABORATOR}
											canSearch
										></TableOptionsButtons>
									</Col>
								</Row>
								<DynamicTable
									data={casesList?.paginateData ?? []}
									columns={columns}
									linkTo='/collaborator/expedientes/'
									propertyId='id'
									isLoading={isLoadingCasesList}
									isError={isErrorCasesList}
								/>
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Expedientes ({casesList?.totalCount ?? '-'})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ExpedientsForCollaboratorView;
