import React, { useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';

const DragAndDropInput = ({ onChange, accept, onDrop }) => {
	// State for dragState
	const [dragActive, setDragActive] = useState(false);
	// Input ref
	const inputRef = useRef();

	//handle drag event
	const handleDrag = (event) => {
		event.preventDefault();
		event.stopPropagation();
		switch (event.type) {
			case 'dragenter':
				setDragActive(true);
				break;
			case 'dragover':
				setDragActive(true);
				break;
			case 'dragleave':
				setDragActive(false);
				break;
		}
	};

	//handle drop event
	const handleDrop = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setDragActive(false);
		onDrop(event);
	};

	// handle onChange event
	const handleChange = (event) => {
		event.preventDefault();
		onChange(event);
	};

	//handle button click
	const handleButtonClick = () => {
		inputRef.current?.click();
	};

	return (
		<div
			onDragEnter={handleDrag}
			className='w-100'
		>
			<input
				id='inputFile'
				type='file'
				ref={inputRef}
				accept={accept}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
			<label
				htmlFor='inputFile'
				className={`w-100 bg-primary-variant-4 ${
					dragActive ? 'bg-primary-variant-2' : ''
				}`}
				style={{
					borderStyle: 'dashed',
					borderColor: '#0592E0',
					borderRadius: '20px',
					height: '200px',
				}}
			>
				<Col className='p-3 px-5 d-flex flex-column align-items-center justify-content-around w-100 h-100'>
					{dragActive ? (
						<>
							<Row>
								<FaPlus size='18' />
								<div className='font-weight-bold'>Agregar</div>
							</Row>
						</>
					) : (
						<>
							<div className='text-body-large text-secondary-default'>
								Arrastrá y solta tu foto de perfil acá
							</div>
							<div className='text-body-large text-secondary-default'>o</div>
							<Button
								type='button'
								className='btn btn-noti-primary'
								onClick={handleButtonClick}
							>
								<span className='text-button'>Cargá tu foto</span>
							</Button>
						</>
					)}
				</Col>
			</label>
			{dragActive ? (
				<div
					id='drag-file-element'
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						borderRadius: '20px',
						top: '0px',
						bottom: '0px',
						right: '0px',
						left: '0px',
					}}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			) : null}
		</div>
	);
};

export default DragAndDropInput;

DragAndDropInput.propTypes = {
	onChange: PropTypes.func,
	accept: PropTypes.string,
	onDrop: PropTypes.func,
};
