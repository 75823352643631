import React from 'react';
import { Card, CardBody, Col, Row, Button, Spinner } from 'reactstrap';
import { ReactComponent as FolderIcon } from '../../assets/img/icons/notiexpress/folder.svg';
import { ReactComponent as SettingIcon } from '../../assets/img/icons/notiexpress/setting-icon.svg';
import { Link } from 'react-router-dom';
import { LAYOUTS } from '../../helpers/consts';
import TooltipElement from '../../components/Commons/TooltipElement';

const CardResume = ({ totalExp, layout = 'default', isLoading = false }) => {
	return (
		<Card className='shadow h-100'>
			<CardBody className='w-100 d-flex align-items-center justify-content-center'>
				{isLoading ? (
					<div className='d-flex justify-content-center align-items-center h-100'>
						<Spinner color='primary' />
					</div>
				) : (
					<Row>
						<Col>
							<FolderIcon
								width={24}
								height={24}
								className='stroke-primary-default'
							/>
							<p
								className='text-heading-1 mb-0 pb-0'
								style={{
									fontSize: '40px',
									lineHeight: '45px',
								}}
							>
								{totalExp ?? '-'}
							</p>
							<p className='text-exp-caducity text-primary-default m-0'>Expedientes</p>
						</Col>
						<Col className='d-flex justify-content-center align-items-center'>
							{layout === LAYOUTS.ADMIN && (
								<Button
									className='btn btn-noti-icon-primary p-2'
									id='goToConfig'
									tag={Link}
									to='expedientes/configuration'
								>
									<SettingIcon
										width={20}
										height={20}
										className='stroke-primary-default'
									/>
									<TooltipElement
										id={'goToConfig'}
										children={'Configuración'}
									/>
								</Button>
							)}
						</Col>
					</Row>
				)}
			</CardBody>
		</Card>
	);
};
export default CardResume;
