import React from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import HiddenSynchronization from '../../../components/HiddenSynchronization/HiddenSynchronization';
import { LAYOUTS } from '../../../helpers/consts';
import CopyToClipboardElement from 'components/Commons/CopyToClipboardElement';

function ProvidenceCard({ providence, mutate, layout }) {
	const {
		actuationDate,
		content,
		Expediente: { id },
	} = providence;
	const jurisdictionName = providence.Expediente.Jurisdiction.name;
	const hasFiles = providence.ProvidenceFiles.length > 0;
	return (
		<>
			<Card className='bg-white shadow-card'>
				<CardBody>
					<Col>
						<Row className='py-3 d-flex align-items-center'>
							<Col
								md='2'
								lg='2'
								xs='12'
							>
								<h5 className='text-body-large-bold text-secondary-default'>Fecha</h5>
								<CardText className='text-body-large'>{actuationDate && moment(actuationDate).format('DD/MM/YYYY')}</CardText>
							</Col>
							<Col
								lg='7'
								md='12'
								xs='12'
							>
								<h5 className='text-body-large-bold text-secondary-default'>Extracto</h5>
								<div className='d-flex'>
									<CardText className='text-body-large mb-0'>{content}</CardText>
									<CopyToClipboardElement
										id={id}
										text={content}
										tooltipMessage={'Copiar extracto'}
									/>
								</div>
							</Col>
							{/* <Col
								lg='4'
								md='12'
								xs='12'
							>
								<h5 className='text-body-large-bold text-secondary-default'>
									Firma
								</h5>
								<CardText className='text-body-large'>{organismName}</CardText>
							</Col> */}
						</Row>
					</Col>
				</CardBody>
			</Card>
			{layout !== LAYOUTS.COLLABORATOR && jurisdictionName === 'Chaco' && !hasFiles && (
				<Card className='bg-white shadow-card mt-3'>
					<CardBody>
						<Col>
							<HiddenSynchronization
								jurisdiction={jurisdictionName}
								providenceDate={actuationDate}
								mutate={mutate}
								expId={id}
							/>
						</Col>
					</CardBody>
				</Card>
			)}
		</>
	);
}

export default ProvidenceCard;

ProvidenceCard.propTypes = {
	providence: PropTypes.object,
	mutate: PropTypes.func,
	layout: PropTypes.string,
};
