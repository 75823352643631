import React, { useEffect, useState } from 'react';
import { userService } from '../../services/userService';
import NotFound from '../NotFound/NotFound';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import LockIcon from '../../components/Icons/LockIcon';
import EyeOffIcon from '../../components/Icons/EyeOffIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import CrossIcon from '../../components/Icons/CrossIcon';
import { STATUS_COLORS } from '../../helpers/consts';

const resetPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.required('Debe colocar una contraseña')
		.min(6, 'Debe tener al menos 6 caracteres.')
		.max(250, 'Demasiado larga.'),
	repeatPassword: Yup.string()
		.ensure()
		.test(
			'password-match',
			'Las contraseñas deben coincidir',
			function (repeatPassword) {
				return this.parent.password === repeatPassword;
			}
		),
});

const ChangePassword = () => {
	const history = useHistory();

	const email = new URLSearchParams(location.search).get('email') ?? '';
	const forgotToken =
		new URLSearchParams(location.search).get('forgotToken') ?? '';

	const [isValidToken, setIsValidToken] = useState(false);

	const gotoLogin = (status = true) =>
		history.push(
			{
				pathname: '/auth/login',
				search: `?changePassword=${status}`,
			},
			{ email, forgotToken }
		);

	useEffect(() => {
		userService
			.isValidToken({ email: email, forgotToken: forgotToken })
			.then(() => setIsValidToken(true))
			.catch(() => history.push('/auth/login'));
	}, []);

	const ResetPasswordForm = () => {
		const [showPassword, setShowPassword] = useState(false);
		const [showRepeatPassword, setShowRepeatPassword] = useState(false);
		const [showError, setShowError] = useState(false);
		const handleTogglePassword = () => setShowPassword(!showPassword);
		const handleToggleRepeatPassword = () =>
			setShowRepeatPassword(!showRepeatPassword);

		const formik = useFormik({
			initialValues: {
				password: '',
				repeatPassword: '',
			},
			validationSchema: resetPasswordSchema,
			onSubmit: (values) =>
				userService
					.resetPassword({ email, forgotToken, newPassword: values.password })
					.then(() => gotoLogin())
					.catch(() => {
						setShowError(true);
					}),
		});

		return (
			<Col className='bg-white pt-6'>
				<Col className='mt-3 px-5'>
					<h3 className='text-heading-2 text-lg py-3'>Cambiar contraseña</h3>
					<p className='text-body-regular text-sm py-3'>
						Recordá que tu contraseña debe tener 6 caracteres, al menos una
						minúscula, una mayúscula y un carácter especial.
					</p>
					<Form
						role='form'
						onSubmit={formik.handleSubmit}
					>
						{/* new password */}
						<FormGroup className='mb-3'>
							<InputGroup className='input-group-alternative'>
								<InputGroupAddon
									className='input_group'
									addonType='prepend'
								>
									<InputGroupText>
										<LockIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									name='password'
									onChange={formik.handleChange}
									value={formik.values.password}
									id='password'
									placeholder='Ingresá tu nueva contraseña'
									type='password'
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePassword}>
											{showPassword ? (
												<EyeOffIcon size='18' />
											) : (
												<EyeIcon size='18' />
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors?.password && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.password}
								</span>
							)}
						</FormGroup>

						{/* confirm password */}
						<FormGroup>
							<InputGroup className='input-group-alternative'>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<LockIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									name='repeatPassword'
									onChange={formik.handleChange}
									value={formik.values.repeatPassword}
									id='repeatPassword'
									placeholder='Repetí tu nueva contraseña'
									type='password'
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleToggleRepeatPassword}>
											{showRepeatPassword ? (
												<EyeOffIcon size='18' />
											) : (
												<EyeIcon size='18' />
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors?.repeatPassword && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.repeatPassword}
								</span>
							)}
						</FormGroup>
						<div className='text-center text-button'>
							<Button
								className='my-4 btn btn-noti-primary px-5 py-1'
								type='submit'
							>
								<span
									className={`spinner-border spinner-border-sm mr-3 ${
										formik.isSubmitting ? '' : 'd-none'
									}`}
									role='status'
									aria-hidden='true'
								></span>
								Confirmar
							</Button>
						</div>
						{showError && (
							<Col className='m-2'>
								<CustomMessage
									icon={
										<CrossIcon
											size={18}
											color={STATUS_COLORS.error}
										/>
									}
									message='Hubo un error al cambiar la contraseña'
								/>
							</Col>
						)}
					</Form>
				</Col>
			</Col>
		);
	};

	return isValidToken ? (
		<>
			<ResetPasswordForm />
		</>
	) : (
		<NotFound />
	);
};

export default ChangePassword;
