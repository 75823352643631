import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import AdminNavbar from '../../../components/Navbars/AdminNavbar.js';
import AdminNavbarMobile from '../../../components/Navbars/AdminNavbarMobile.js';
import Sidebar from '../../../components/Sidebar/Sidebar.js';
import routes from 'routes/routes.js';
import { paymentsService } from 'services/paymentsService';
import user from 'context/user';
import { PAYMENT_STATUS } from 'helpers/consts';
import AuthFooter from '../../../components/Footers/Footer/Footer';
import { Container as ModalContainer } from 'react-modal-promise';
import useBreakpoints from 'hooks/useBreakpoints';

const Admin = (props) => {
	const {
		state: { currentUser },
	} = useContext(user);

	const status = paymentsService.usePaymentStatus();

	const { isMobile } = useBreakpoints();

	const adminRoutes = routes.filter((route) => route.layout === '/admin');

	const getRoutes = (adminRoutes) => {
		return adminRoutes.map((prop, key) => {
			if (status !== PAYMENT_STATUS.ACTIVE) {
				return (
					prop.isExpired && (
						<Route
							exact
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					)
				);
			} else {
				return (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			}
		});
	};
	const getBrandText = () => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				return routes[i].name;
			}
		}
		return 'NotiExpress';
	};

	return (
		<>
			{!isMobile && (
				<Sidebar
					{...props}
					routes={adminRoutes}
					logo={{
						innerLink: '/admin/',
						imgAlt: 'NotiExpress',
					}}
					status={status}
					role={currentUser.rol}
				/>
			)}
			<div className={!isMobile ? 'main-content' : 'main-content-mobile'}>
				{isMobile ? (
					<AdminNavbarMobile
						status={status}
						{...props}
					/>
				) : (
					<AdminNavbar
						status={status}
						{...props}
						brandText={getBrandText(props.location.pathname)}
					/>
				)}
				<div className='container-admin'>
					<Switch>
						{getRoutes(adminRoutes)}
						<Redirect
							from='*'
							to={'/admin/novedades'}
						/>
					</Switch>
				</div>
				<AuthFooter layout='admin' />
				<ModalContainer />
			</div>
		</>
	);
};
export default Admin;

Admin.propTypes = {
	location: PropTypes.object,
};
