import React, { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { userService } from 'services/userService';
import passwordStrength from '../../helpers/password-strength';
import { Button, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Label } from 'reactstrap';
import UserIcon from '../../components/Icons/UserIcon';
import MailIcon from '../../components/Icons/MailIcon';
import LockIcon from '../../components/Icons/LockIcon';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import CrossIcon from '../../components/Icons/CrossIcon';
import { STATUS_COLORS } from '../../helpers/consts';
import MapPinIcon from '../../components/Icons/MapPinIcon';
import EyeOffIcon from '../../components/Icons/EyeOffIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import * as Yup from 'yup';
import './Register.css';
import DivideCircleIcon from '../../components/Icons/DivideCircleIcon';
import CustomModal from '../../components/Modals/CustomModal/CustomModal';
import TermsAndConditionsContent from '../Terms&Conditions/TermsAndConditionsContent';
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent';

const RegisterSchema = Yup.object().shape({
	//I need that first name and second name must be letters only not numbers or special characters but spaces are allowed
	firstName: Yup.string()
		.required('Campo obligatorio.')
		.matches(/^[a-zA-Z\s]+$/, 'El nombre debe contener solo letras.'),
	lastName: Yup.string()
		.required('Campo obligatorio.')
		.matches(/^[a-zA-Z\s]+$/, 'El apellido debe contener solo letras.'),
	email: Yup.string().email('El e-mail no es válido.').required('Campo obligatorio.'),
	password: Yup.string()
		.required('Campo obligatorio.')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
			'La contraseña debe tener al menos 6 caracteres, una mayúscula, una minúscula y un número.'
		)
		.min(6, 'La contraseña debe tener al menos 6 caracteres.'),
	passwordClone: Yup.string()
		.required('Campo obligatorio.')
		.min(6, 'La contraseña debe tener al menos 6 caracteres.')
		.ensure()
		.test('password-match', 'Las contraseñas deben coincidir', function (repeatPassword) {
			return this.parent.password === repeatPassword;
		}),
	address: Yup.string().required('Campo obligatorio.'),
	locality: Yup.string().required('Campo obligatorio.'),
	province: Yup.string().required('Campo obligatorio.'),
	hasMatricula: Yup.boolean().required('Debe tener matrícula profesional.').isTrue('Debe tener matrícula profesional.'),
	acceptTerms: Yup.boolean().required('Debe aceptar los términos y condiciones.').isTrue('Debe aceptar los términos y condiciones.'),
});

const Register = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordClone, setShowPasswordClone] = useState(false);
	const [showCheckEmail, setShowCheckEmail] = useState(false);
	//Modals
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);

	const handleToggleTermsModal = () => setShowTermsModal(!showTermsModal);
	const handleTogglePrivacyModal = () => setShowPrivacyModal(!showPrivacyModal);

	const handleTogglePassword = () => setShowPassword(!showPassword);
	const handleTogglePasswordClone = () => setShowPasswordClone(!showPasswordClone);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			passwordClone: '',
			address: '',
			locality: '',
			province: '',
			hasMatricula: false,
			acceptTerms: false,
		},
		validationSchema: RegisterSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true);
				await userService.signup({
					nombre: values.firstName,
					apellido: values.lastName,
					email: values.email,
					password: values.password,
					address: values.address,
					locality: values.locality,
					province: values.province,
				});
				setSubmitting(false);
				formik.resetForm();
				setShowCheckEmail(true);
			} catch (error) {
				setSubmitting(false);
				Swal.fire({
					icon: 'error',
					title: 'No se pudo crear la cuenta',
					text: 'Por favor, revise sus datos.',
					confirmButtonText: 'Aceptar',
				});
			}
		},
	});

	return (
		<>
			<Col className='bg-white py-5 '>
				<Col className='px-lg-5'>
					<h3 className='text-heading-2 text-lg py-3'>Crear cuenta</h3>
					<Form
						className=''
						role='form'
						onSubmit={formik.handleSubmit}
					>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<UserIcon
											className='stroke-primary-default'
											size={18}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='firstName'
									hidden
								>
									Nombre
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.firstName}
									name='firstName'
									placeholder='Ingresá tu nombre'
									type='text'
								/>
							</InputGroup>
							{formik.errors.firstName && formik.touched.firstName && (
								<span className='text-dark-link-regular text-error'>{formik.errors.firstName}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<UserIcon
											className='stroke-primary-default'
											size={18}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='lastName'
									hidden
								>
									Apellido
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.lastName}
									name='lastName'
									placeholder='Ingresá tu apellido'
									type='text'
								/>
							</InputGroup>
							{formik.errors.lastName && formik.touched.lastName && (
								<span className='text-dark-link-regular text-error'>{formik.errors.lastName}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MailIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='email'
									hidden
								>
									Email
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									name='email'
									placeholder='Ingresá tu e-mail'
									type='email'
								/>
							</InputGroup>
							{formik.errors.email && formik.touched.email && (
								<span className='text-dark-link-regular text-error'>{formik.errors.email}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative form-group'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<LockIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='password'
									hidden
								>
									Contraseña
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									name='password'
									placeholder='Ingresá tu contraseña'
									type={showPassword ? 'text' : 'password'}
									autoComplete='new-password'
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePassword}>
											{showPassword ? (
												<EyeOffIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											) : (
												<EyeIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors.password && formik.touched.password && (
								<span className='text-dark-link-regular text-error'>{formik.errors.password}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<LockIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='passwordClone'
									hidden
								>
									Repita la contraseña
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.passwordClone}
									placeholder='Repetí tu contraseña'
									name='passwordClone'
									type={showPasswordClone ? 'text' : 'password'}
									autoComplete='new-password'
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePasswordClone}>
											{showPasswordClone ? (
												<EyeOffIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											) : (
												<EyeIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							<div className='text-muted font-italic mb-1'>
								<small>
									Seguridad de la contraseña:{' '}
									<span className={`${passwordStrength(formik.values.password)?.color} font-weight-700`}>
										{passwordStrength(formik.values.password)?.text}
									</span>
								</small>
							</div>
							{formik.errors.passwordClone && formik.touched.passwordClone && (
								<span className='text-dark-link-regular text-error'>{formik.errors.passwordClone}</span>
							)}
						</FormGroup>

						<h4 className='text-body-large-bold text-secondary-dark-1 '>Domicilio legal</h4>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MapPinIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='firstName'
									hidden
								>
									Dirección
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.address}
									name='address'
									placeholder='Ingresá tu dirección'
									type='address'
								/>
							</InputGroup>
							{formik.errors.address && formik.touched.address && (
								<span className='text-dark-link-regular text-error'>{formik.errors.address}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MapPinIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='firstName'
									hidden
								>
									Localidad
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.locality}
									name='locality'
									placeholder='Ingresá tu Localidad'
									type='text'
								/>
							</InputGroup>
							{formik.errors.locality && formik.touched.locality && (
								<span className='text-dark-link-regular text-error'>{formik.errors.locality}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MapPinIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='firstName'
									hidden
								>
									Provincia
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.province}
									name='province'
									placeholder='Ingresá tu Provincia'
									type='text'
								/>
							</InputGroup>
							{formik.errors.province && formik.touched.province && (
								<span className='text-dark-link-regular text-error'>{formik.errors.province}</span>
							)}
						</FormGroup>

						<Row className='my-4'>
							<Col xs='12'>
								<div className='custom-control custom-control-alternative custom-checkbox'>
									<Input
										className='custom-control-input'
										id='customCheckRegister'
										name='hasMatricula'
										checked={formik.values.hasMatricula}
										onChange={formik.handleChange}
										type='checkbox'
									/>
									<label
										className='custom-control-label'
										htmlFor='customCheckRegister'
									>
										<span className='text-body-regular m-0'>Tengo matrícula profesional en Derecho vigente.</span>
									</label>
								</div>
							</Col>
						</Row>
						<Row className='my-4'>
							<Col xs='12'>
								<div className='custom-control custom-control-alternative custom-checkbox'>
									<Input
										className='custom-control-input'
										id='customCheckRegisterTerms'
										name='acceptTerms'
										checked={formik.values.acceptTerms}
										onChange={formik.handleChange}
										type='checkbox'
									/>
									<label
										className='custom-control-label'
										htmlFor='customCheckRegisterTerms'
									>
										<span className='text-body-regular m-0'>Al suscribir estás aceptando los </span>
										<span
											className='text-link-regular'
											onClick={(e) => {
												e.preventDefault();
												handleToggleTermsModal();
											}}
										>
											Términos y Condiciones -{' '}
										</span>
										<span
											className='text-link-regular'
											onClick={(e) => {
												e.preventDefault();
												handleTogglePrivacyModal();
											}}
										>
											Políticas de Privacidad de Notiexpress.
										</span>
									</label>
								</div>
								<CustomModal
									title={'Términos y Condiciones'}
									isOpen={showTermsModal}
									toggle={handleToggleTermsModal}
								>
									<div className='p-2 mx-5 mb-5 '>
										<TermsAndConditionsContent />
									</div>
								</CustomModal>
								<CustomModal
									title={'Políticas de Privacidad'}
									isOpen={showPrivacyModal}
									toggle={handleTogglePrivacyModal}
								>
									<div className='p-2 mx-5 mb-5 '>
										<PrivacyPolicyContent />
									</div>
								</CustomModal>
							</Col>
						</Row>
						{((formik.errors.acceptTerms && formik.touched.acceptTerms) || (formik.errors.hasMatricula && formik.touched.hasMatricula)) && (
							<Row className='my-4'>
								<Col xs='12'>
									<CustomMessage
										icon={
											<CrossIcon
												size={18}
												color={STATUS_COLORS.error}
											/>
										}
										message='Tenés que marcar ambas casillas para crear tu cuenta.'
									/>
								</Col>
							</Row>
						)}
						<div>
							{showCheckEmail && (
								<CustomMessage
									icon={
										<DivideCircleIcon
											size={18}
											color={STATUS_COLORS.warning}
										/>
									}
									message='Revisá tu e-mail para habilitar la cuenta.'
								/>
							)}
							<Button
								className='mt-4 p-1 px-5 btn btn-noti-primary'
								color='primary'
								type='submit'
								disabled={formik.isSubmitting}
							>
								<span
									className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
									role='status'
									aria-hidden='true'
								/>
								Crear cuenta
							</Button>
						</div>
					</Form>
				</Col>
			</Col>
		</>
	);
};

export default Register;
