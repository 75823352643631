import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from 'reactstrap';
import { ReactComponent as UserIcon } from '../../../../../assets/img/icons/notiexpress/user-icon.svg';
import { ReactComponent as CreditCardIcon } from '../../../../../assets/img/icons/notiexpress/credit-card-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/icons/notiexpress/close-icon.svg';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { paymentsService } from '../../../../../services/paymentsService';
import user from '../../../../../context/user';
import cartContext from '../../../../../context/cart';
import CustomMessage from '../../../../../components/CustomMessage/CustomMessage';
import PropTypes from 'prop-types';

const mp = new window.MercadoPago(
	// eslint-disable-next-line no-undef
	process.env.REACT_APP_PUBLIC_KEY_SALER
);

const paymentInputsSchema = Yup.object().shape({
	cardNumber: Yup.string().required('Campo obligatorio'),
	identificationNumber: Yup.string()
		.required('Campo obligatorio')
		.min(7, 'Demasiado corto')
		.max(9, 'Demasiado largo'),
	cardExpirationMonth: Yup.string().required('Campo obligatorio'),
	cardExpirationYear: Yup.string().required('Campo obligatorio'),
	securityCode: Yup.string().required('Campo obligatorio'),
	acceptedTerms: Yup.boolean().oneOf(
		[true],
		'Tenés que aceptar los Términos y condiciones - Políticas de privacidad de Notiexpress.'
	),
	cardholderName: Yup.string().required('Campo obligatorio'),
	installments: Yup.string().required('Campo obligatorio'),
});

const AddPaymentMethodPanel = ({ setStatus }) => {
	const { cart, validVoucher, totalAmount, setHasPayed } =
		useContext(cartContext);

	const {
		state: { currentUser },
	} = useContext(user);

	const [paymentMethodId, setPaymentMethodId] = useState(null);
	const [issuersOptions, setIssuersOptions] = useState([]);
	const [installmentsOptions, setInstallmentsOptions] = useState([]);
	const [idTypesOptions, setIdTypesOptions] = useState([]);
	const [paymentError, setPaymentError] = useState(false);

	const initialValues = {
		cardNumber: 'FREE',
		cardholderName: 'FREE',
		cardExpirationMonth: 'FREE',
		cardExpirationYear: 'FREE',
		identificationType: 'DNI',
		identificationNumber: '00000000',
		securityCode: 'FREE',
		installments: 'FREE',
		issuer: '',
		acceptedTerms: false,
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: paymentInputsSchema,
		onSubmit: onSubmit,
	});

	useEffect(() => {
		const getIdTypes = async () => {
			try {
				const idTypes = await mp.getIdentificationTypes();
				setIdTypesOptions(idTypes);
			} catch (e) {
				setIdTypesOptions([]);
				console.error('error getting Identification Types: ', e);
			}
		};
		getIdTypes();
	}, []);

  useEffect(() => {
    if (totalAmount) {
      formik.values.cardNumber = '';
      formik.values.cardholderName = '';
      formik.values.cardExpirationMonth = '';
      formik.values.cardExpirationYear = '';
      formik.values.identificationType = '';
      formik.values.identificationNumber = '';
      formik.values.securityCode = '';
      formik.values.installments = '';
      formik.values.issuer = '';
    } else {
      formik.values.cardNumber = 'FREE';
      formik.values.cardholderName = 'FREE';
      formik.values.cardExpirationMonth = 'FREE';
      formik.values.cardExpirationYear = 'FREE';
      formik.values.identificationType = 'FREE';
      formik.values.identificationNumber = '00000000';
      formik.values.securityCode = 'FREE';
      formik.values.installments = 'FREE';
      formik.values.issuer = '';
    }
  }, [totalAmount]);

	async function getIssuers(bin, paymentMethodId) {
		try {
			const issuers = await mp.getIssuers({
				paymentMethodId,
				bin,
			});
			setIssuersOptions(issuers);
			getInstallments(bin);
		} catch (e) {
			setIssuersOptions([]);
			console.error('error getting issuers: ', e);
		}
	}

	async function guessPaymentMethod(card) {
		try {
			const cardNumber = card.replace(' ', '');
			if (cardNumber.length > 5) {
				const bin = cardNumber.substring(0, 6);
				const response = await mp.getPaymentMethods({ bin });
				const { id, additional_info_needed, issuer } = response.results[0];
				setPaymentMethodId(id);
				additional_info_needed.includes('issuer_id')
					? getIssuers(bin, id)
					: (() => {
							setIssuersOptions([issuer]);
							formik.handleChange({
								target: { name: 'issuer', value: issuer.id },
							});
							getInstallments(bin);
					  })();
			} else {
				setPaymentMethodId(null);
				setIssuersOptions([]);
			}
		} catch {
			setPaymentMethodId(null);
			//console.log("error getting payment method id: ", e);
		}
	}

	async function getInstallments(bin) {
		try {
			const installments = await mp.getInstallments({
				amount: totalAmount.toString(),
				bin,
				paymentTypeId: 'credit_card',
			});
			setInstallmentsOptions(installments[0].payer_costs);
		} catch {
			//console.log("error getting installments: ", e);
			setInstallmentsOptions([]);
		}
	}

	async function createCardToken({
		cardNumber,
		cardholderName,
		identificationType,
		identificationNumber,
		securityCode,
		cardExpirationMonth,
		cardExpirationYear,
	}) {
		try {
			const token = await mp.createCardToken({
				cardNumber,
				cardholderName,
				identificationType,
				identificationNumber,
				securityCode,
				cardExpirationMonth,
				cardExpirationYear,
			});
			return token.id;
		} catch {
			//console.log("error creating token: ", e);
		}
	}

	async function onSubmit(values, { setSubmitting, resetForm }) {
		try {
			setSubmitting(true);
			setPaymentError(false);
			const cardNumber = values?.cardNumber.replace(/\s/g, '');
			const token = !totalAmount ? 'FREE' : await createCardToken({ ...values, cardNumber });
			const body = {
				user: currentUser?.id,
				services: getSelectedServices(),
				card: {
					token,
					email: currentUser?.email,
					description: getDescription(),
					installments: !totalAmount ? '1' : parseInt(values.installments),
					payment_method_id: !totalAmount ? 'FREE' : paymentMethodId,
					transaction_amount: totalAmount,
				},
			};
			if (validVoucher?.publicId) {
				body.voucher = validVoucher.publicId;
			}
			const { payment } = await paymentsService.createPayment(body);
			if (payment.status !== 'rejected') {
				setHasPayed(true);
				resetForm();
				setStatus('3');
			} else {
				setPaymentError(true);
			}
		} catch {
			//console.log("error when submitting: ", e);
			setPaymentError(true);
		}
		setSubmitting(false);
	}

	function getSelectedServices() {
		return cart.map((item) => ({
			service: item._id,
		}));
	}

	function getDescription() {
		return (
			'Suscripciones NotiExpress ' + cart.map((item) => item.nombre).join(', ')
		);
	}

	const onBlurCardNumber = (e) => {
		formik.handleBlur(e);
		guessPaymentMethod(e.target.value);
	};

	/**
	 * Add whitespace each four numbers in credit card value.
	 * @param {string} value
	 * @returns {string}
	 */
	const formatCreditCardNumber = (value) => {
		const cleanValue = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
		const matches = cleanValue.match(/\d{4,16}/g);
		const match = (matches && matches[0]) || '';
		let parts = [];
		let len = match.length;

		for (let i = 0; match.length && i < len; i += 4) {
			parts.push(match.substring(i, i + 4));
		}

		if (parts.length) {
			return parts.join(' ');
		} else {
			return value;
		}
	};

	return (
		<>
			<Col>
				<h3 className='text-heading-2 text-secondary-default py-3'>
					Datos de pago
				</h3>
				<p className='text-body-large py-3'>
					Completá los datos de pago para finalizar con el proceso de registro.
				</p>
				<Form onSubmit={formik.handleSubmit}>
					<FormGroup
						tag={Row}
					>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<UserIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									type='text'
									name='cardholderName'
									maxLength='50'
									id='input-cardholderName'
									onChange={formik.handleChange}
									value={formik.values.cardholderName !== 'FREE' ? formik.values.cardholderName : ''}
									placeholder='Ingresá tu nombre y apellido'
									invalid={
										formik.errors.cardholderName &&
										formik.touched.cardholderName
									}
                  disabled={!totalAmount}
								/>
							</InputGroup>
							{formik.errors.cardholderName &&
								formik.touched.cardholderName && (
									<span className='text-dark-link-regular text-error'>
										{formik.errors.cardholderName}
									</span>
								)}
						</Col>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<CreditCardIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									type='select'
									id='input-issuer'
									name='issuer'
									data-checkout='issuer'
									className='form-control'
									onChange={formik.handleChange}
									value={formik.values.issuer !== 'FREE' ? formik.values.issuer : ''}
									disabled={issuersOptions?.length < 2 || !totalAmount}
								>
									{issuersOptions.map(({ id, name }) => (
										<option
											key={id}
											value={id}
										>
											{name}
										</option>
									))}
								</Input>
							</InputGroup>
						</Col>
					</FormGroup>
					<FormGroup
						tag={Row}
						className='justify-content-around'
					>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<Input
									type='select'
									name='identificationType'
									id='identificationType'
									data-checkout='identificationType'
									className='form-control'
									onChange={formik.handleChange}
									placeholder={'Tipo de documento'}
									value={formik.values.identificationType !== 'FREE' ? formik.values.identificationType : ''}
									invalid={
										formik.errors.identificationType &&
										formik.touched.identificationType
									}
                  disabled={!totalAmount}
								>
									{idTypesOptions.map(({ id, name }) => (
										<option
											key={id}
											value={id}
										>
											{name}
										</option>
									))}
								</Input>
							</InputGroup>
							{formik.errors.identificationType &&
								formik.touched.identificationType && (
									<span className='text-dark-link-regular text-error'>
										{formik.errors.identificationType}
									</span>
								)}
						</Col>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<UserIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									type='text'
									id='input-identificationNumber'
									name='identificationNumber'
									maxLength='9'
									onChange={formik.handleChange}
									value={formik.values.identificationNumber !== '00000000' ? formik.values.identificationNumber : ''}
									placeholder='Ingresá tu número de documento'
									invalid={
										formik.errors.identificationNumber &&
										formik.touched.identificationNumber
									}
                  disabled={!totalAmount}
								/>
							</InputGroup>
							{formik.errors.identificationNumber &&
								formik.touched.identificationNumber && (
									<span className='text-dark-link-regular text-error'>
										{formik.errors.identificationNumber}
									</span>
								)}
						</Col>
					</FormGroup>
					<FormGroup
						tag={Row}
						className='justify-content-around'
					>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<CreditCardIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder='Ingresá tu número de tu tarjeta'
									name='cardNumber'
									id='input-cardNumber'
									maxLength='19'
									onBlur={onBlurCardNumber}
									value={formatCreditCardNumber(formik.values.cardNumber) !== 'FREE' ? formatCreditCardNumber(formik.values.cardNumber) : ''}
									onChange={formik.handleChange}
									invalid={
										formik.errors.cardNumber && formik.touched.cardNumber
									}
                  disabled={!totalAmount}
								/>
								<InputGroupAddon
									addonType='append'
									className='form-control'
									style={{ maxWidth: '30px' }}
									invalid={
										formik.errors.cardNumber && formik.touched.cardNumber
									}
								>
									{issuersOptions[0]?.thumbnail ? (
										<img
											src={issuersOptions[0]?.thumbnail ?? ''}
											alt='emisor'
										/>
									) : null}
								</InputGroupAddon>
							</InputGroup>
							{formik.errors.cardNumber && formik.touched.cardNumber && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.cardNumber}
								</span>
							)}
						</Col>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<CreditCardIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									className='text-right'
									type='text'
									id='input-cardExpirationMonth'
									name='cardExpirationMonth'
									maxLength='2'
									onChange={formik.handleChange}
									value={formik.values.cardExpirationMonth !== 'FREE' ? formik.values.cardExpirationMonth : ''}
									invalid={
										formik.errors.cardExpirationMonth &&
										formik.touched.cardExpirationMonth
									}
                  disabled={!totalAmount}
									placeholder='MM'
								/>
								<InputGroupAddon
									addonType='append'
									className='form-control'
									style={{ maxWidth: '25px', padding: 0 }}
								>
									<InputGroupText>/</InputGroupText>
								</InputGroupAddon>
								<Input
									type='text'
									id='input-cardExpirationYear'
									name='cardExpirationYear'
									maxLength='2'
									onChange={formik.handleChange}
									value={formik.values.cardExpirationYear !== 'FREE' ? formik.values.cardExpirationYear : ''}
									invalid={
										formik.errors.cardExpirationYear &&
										formik.touched.cardExpirationYear
									}
                  disabled={!totalAmount}
									placeholder='AA'
								/>
							</InputGroup>
							{((formik.errors.cardExpirationYear &&
								formik.touched.cardExpirationYear) ||
								(formik.errors.cardExpirationMonth &&
									formik.touched.cardExpirationMonth)) && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.cardExpirationYear ||
										formik.errors.cardExpirationMonth}
								</span>
							)}
						</Col>
					</FormGroup>
					<FormGroup
						tag={Row}
						className='justify-content-between'
					>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<CreditCardIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									type='text'
									name='securityCode'
									maxLength='4'
									placeholder='CVV'
									id='input-securityCode'
									onChange={formik.handleChange}
									value={formik.values.securityCode !== 'FREE' ? formik.values.securityCode : ''}
									invalid={
										formik.errors.securityCode && formik.touched.securityCode
									}
                  disabled={!totalAmount}
								/>
							</InputGroup>
							{formik.errors.securityCode && formik.touched.securityCode && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.securityCode}
								</span>
							)}
						</Col>
						<Col
							xs={12}
							lg={6}
						>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<CreditCardIcon className='stroke stroke-primary-default' />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									type='select'
									name='installments'
									id='input-installments'
									onChange={formik.handleChange}
									value={formik.values.installments !== 'FREE' ? formik.values.installments : ''}
									disabled={installmentsOptions.length === 0 || !totalAmount}
									invalid={
										formik.errors.installments && formik.touched.installments
									}
								>
									<option value=''>Cuotas</option>
									{installmentsOptions.map(
										({ installments, recommended_message }) => (
											<option
												key={installments}
												value={installments}
											>
												{recommended_message}
											</option>
										)
									)}
								</Input>
							</InputGroup>
							{formik.errors.installments && formik.touched.installments && (
								<span className='text-dark-link-regular text-error'>
									{formik.errors.installments}
								</span>
							)}
						</Col>
					</FormGroup>

					<Row className='my-4'>
						<Col xs='12'>
							<div className='custom-control custom-control-alternative custom-checkbox'>
								<Input
									className='custom-control-input'
									id='customCheckRegister'
									name='receiveNews'
									type='checkbox'
								/>
								<label
									className='custom-control-label'
									htmlFor='customCheckRegister'
								>
									<span className='text-body-regular m-0'>
										Quiero recibir novedades de servicios y promociones.
									</span>
								</label>
							</div>
						</Col>
					</Row>
					<Row className='my-4'>
						<Col xs='12'>
							<div className='custom-control custom-control-alternative custom-checkbox'>
								<Input
									className='custom-control-input'
									id='checkTerms'
									name='acceptedTerms'
									type='checkbox'
									onChange={formik.handleChange}
									checked={formik.values.acceptedTerms}
								/>
								<label
									className='custom-control-label'
									htmlFor='checkTerms'
								>
									<span className='text-body-regular m-0'>
										Al suscribir estás aceptando los Términos y Condiciones -
										Políticas de Privacidad de Notiexpress.
									</span>
								</label>
							</div>
						</Col>
					</Row>
					<Row className='m-0'>
						<Button
							className='btn btn-noti-primary py-1 px-5'
							disabled={formik.isSubmitting}
							type='submit'
						>
							<span
								className={`spinner-border spinner-border-sm mr-3 ${
									formik.isSubmitting ? '' : 'd-none'
								}`}
								role='status'
								aria-hidden='true'
							></span>
							<span className='text-button'>
								{formik.isSubmitting ? 'Procesando pago...' : 'Registrar'}
							</span>
						</Button>
					</Row>
					{paymentError && (
						<Col className='py-4'>
							<CustomMessage
								icon={
									<CloseIcon
										className='stroke stroke-error'
										width={18}
										height={18}
									/>
								}
								message='La transacción no pudo ser realizada. Verifica tus datos.'
							/>
						</Col>
					)}
					{formik.errors.acceptedTerms && formik.touched.acceptedTerms && (
						<Col className='py-4'>
							<CustomMessage
								icon={
									<CloseIcon
										className='stroke stroke-error'
										width={24}
										height={24}
									/>
								}
								message='Debes aceptar los términos y condiciones para continuar.'
							/>
						</Col>
					)}
				</Form>
			</Col>
		</>
	);
};

AddPaymentMethodPanel.propTypes = {
	setStatus: PropTypes.func,
};

export default AddPaymentMethodPanel;
