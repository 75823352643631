import React from 'react';
import { create } from 'react-modal-promise';
import CustomModal from '../../../../../components/Modals/CustomModal/CustomModal';
import {
	Button,
	Col,
	Form,
	Input,
	InputGroup,
	Label,
	ModalBody,
	Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { officesService } from '../../../../../services/officesService';
import PropTypes from 'prop-types';

const AddressSchema = Yup.object().shape({
	name: Yup.string(),
	address: Yup.string().required('Campo obligatorio'),
	locality: Yup.string().required('Campo obligatorio'),
	province: Yup.string().required('Campo obligatorio'),
	cp: Yup.string(),
	phone: Yup.string(),
});

const ModifyOfficeModal = ({ mutate, office, isOpen, onResolve, onReject }) => {
	const initialValues = {
		name: office?.name ?? '',
		address: office.address ?? '',
		locality: office.locality ?? '',
		province: office.province ?? '',
		cp: office.cp ?? '',
		phone: office.phone ?? '',
	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: AddressSchema,
		onSubmit: async (values) => {
			try {
				await officesService.updateOffice({
					...values,
					id: office.userOfficeId,
				});
				mutate();
        onResolve();
			} catch (e) {
        onReject();
				//console.log(e);
			}
		},
	});
	return (
		<CustomModal
			title={'Modificar Oficina'}
			isOpen={isOpen}
			toggle={() => onReject()}
			className='w-50'
		>
			<ModalBody>
				<Form onSubmit={formik.handleSubmit}>
					<Row className='pb-3'>
						<Col>
							<Row className='p-2'>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Oficina
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Nombre de la oficina'
											type='text'
											name='name'
											onChange={formik.handleChange}
											value={formik.values.name}
                      size='sm'
										/>
									</InputGroup>
									{formik.errors.name && formik.touched.name && (
										<span className='text-link-regular text-error'>
											{formik.errors.name}
										</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Dirección*
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Escriba su dirección'
											type='text'
											name='address'
											onChange={formik.handleChange}
											value={formik.values.address}
                      size='sm'
										/>
									</InputGroup>
									{formik.errors.address && formik.touched.address && (
										<span className='text-link-regular text-error'>
											{formik.errors.address}
										</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Localidad*
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Nombre de la localidad'
											type='text'
											name='locality'
											onChange={formik.handleChange}
                      size='sm'
											value={formik.values.locality}
										/>
									</InputGroup>
									{formik.errors.locality && formik.touched.locality && (
										<span className='text-link-regular text-error'>
											{formik.errors.locality}
										</span>
									)}
								</Col>
							</Row>
							<Row className='p-2'>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Provincia*
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Nombre de la provincia'
											type='text'
											name='province'
											onChange={formik.handleChange}
                      size='sm'
											value={formik.values.province}
										/>
									</InputGroup>
									{formik.errors.province && formik.touched.province && (
										<span className='text-link-regular text-error'>
											{formik.errors.province}
										</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Código postal
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Ingresá el código postal'
											type='text'
											name='cp'
											onChange={formik.handleChange}
                      size='sm'
											value={formik.values.cp}
										/>
									</InputGroup>
									{formik.errors.cp && formik.touched.cp && (
										<span className='text-link-regular text-error'>
											{formik.errors.cp}
										</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>
										Teléfono
									</Label>
									<InputGroup className='input-group-alternative mb-2 form-group'>
										<Input
											placeholder='Teléfono de la oficina'
											type='text'
											name='phone'
											onChange={formik.handleChange}
                      size='sm'
											value={formik.values.phone}
										/>
									</InputGroup>
									{formik.errors.phone && formik.touched.phone && (
										<span className='text-link-regular text-error'>
											{formik.errors.phone}
										</span>
									)}
								</Col>
							</Row>
							<Row className='p-2'>
								<Col>
									<Button
										className='btn btn-outline-noti-secondary py-1 px-5'
										onClick={() => onReject()}
									>
										<span className=''>Cancelar</span>
									</Button>
								</Col>
								<Col>
									<Button
										className='btn-noti-primary py-1 px-5'
										type='submit'
									>
										<span
											className={`spinner-border spinner-border-sm mr-3 ${
												formik.isSubmitting ? '' : 'd-none'
											}`}
											role='status'
											aria-hidden='true'
										/>
										<span className='text-button'>Actualizar</span>
									</Button>
								</Col>
								<Col>{}</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</ModalBody>
		</CustomModal>
	);
};

ModifyOfficeModal.propTypes = {
  mutate: PropTypes.func.isRequired,
  office: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onResolve: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export const modifyOfficeModal = create(ModifyOfficeModal);
