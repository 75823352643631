import React from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledDropdown,
	Label,
	Row,
	Spinner,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { useFormik } from 'formik';
import { ReactComponent as SearchIcon } from 'assets/img/icons/notiexpress/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import { parseObjectToQS } from '../../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/notiexpress/calendar-icon.svg';
import es from 'date-fns/locale/es';
import moment from 'moment';

const SearchForProvidence = ({ resetSearch }) => {
	const history = useHistory();
	const initialValues = {
		providenceDate: '',
		extract: '',
	};

	const handleSubmit = (values) => {
		const queryObject = {
			...(values.providenceDate && { providenceDate: values.providenceDate }),
			...(values.extract && { extract: values.extract }),
		};
		const newQueryString = parseObjectToQS(queryObject);
		history.push(`${location.pathname}?${newQueryString}`);
		formik.setSubmitting(false);
	};

	const handleReset = () => {
		resetSearch();
		history.push(`${location.pathname}`);
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		onReset: handleReset,
	});

	return (
		<Form
			onSubmit={formik.handleSubmit}
			className='py-3'
		>
			<Row>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<FormGroup>
						<Label className='text-body-large-bold text-secondary-default'>Fecha</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='form-control'
								type='text'
								onChange={(event) => {
									formik.handleChange(event);
								}}
								placeholder='DD/MM/AAAA'
								value={formik.values.providenceDate ? moment(formik.values.providenceDate).format('DD/MM/YYYY') : ''}
							/>
							<InputGroupAddon addonType='append'>
								<UncontrolledDropdown active>
									<DropdownToggle
										nav
										className='h-100 d-flex align-items-center'
									>
										<CalendarIcon
											width={20}
											height={20}
											className='stroke stroke-primary-default'
										/>
									</DropdownToggle>
									<DropdownMenu right>
										<DayPicker
											style={{ width: '100%' }}
											mode='single'
											defaultMonth={new Date()}
											selected={formik.values.providenceDate}
											onDayClick={(day) => {
												formik.setFieldValue('providenceDate', moment(day).format('YYYY-MM-DD'));
											}}
											locale={es}
											weekStartsOn={0}
											disabled={{
												after: new Date(Date.now()),
											}}
										/>
									</DropdownMenu>
								</UncontrolledDropdown>
							</InputGroupAddon>
						</InputGroup>
						{formik.errors.providenceDate && formik.touched.providenceDate && (
							<span className='text-error text-body-small'>{formik.errors.providenceDate}</span>
						)}
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-extract'
						>
							Extracto
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='form-control-alternative'
								name='extract'
								id='input-search-extract'
								placeholder='Ingrese titulo del extracto'
								type='text'
								onChange={(event) => {
									formik.handleChange(event);
								}}
								value={formik.values.extract}
							/>
						</InputGroup>
						{formik.errors.extract && formik.touched.extract && <span className='text-error text-body-small'>{formik.errors.extract}</span>}
					</FormGroup>
				</Col>
			</Row>
			<Row className='align-items-center'>
				<div className='px-3'>
					<Button
						className='btn btn-noti-primary px-5'
						type='submit'
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<Spinner
								size='sm'
								className='mr-2'
							/>
						) : (
							<SearchIcon
								width={24}
								height={24}
								className='stroke-neutro-1'
							/>
						)}
						<span className='text-button'>Buscar</span>
					</Button>
				</div>
				<Button
					onClick={() => {
						formik.resetForm();
					}}
					color='link'
					className='pl-0'
				>
					<Row className='align-items-center m-0'>
						<CloseIcon
							width={24}
							height={24}
							className='stroke-info'
						/>
						<span className='text-link-regular'>Limpiar campos</span>
					</Row>
				</Button>
			</Row>
		</Form>
	);
};

SearchForProvidence.propTypes = {
	resetSearch: PropTypes.func.isRequired,
};

export default SearchForProvidence;
