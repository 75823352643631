import React from 'react';
import svgLogoBLue from '../../assets/img/icons/logo/notiexpress-logo-blue.svg';
import svgLogoWhite from '../../assets/img/icons/logo/notiexpress-logo-white.svg';
import PropTypes from 'prop-types';

const BrandIcon = ({ theme = 'light' }) => {
	return (
		<>
			<img
				src={theme === 'light' ? svgLogoBLue : svgLogoWhite}
				alt='NotiExpress'
			/>
		</>
	);
};

BrandIcon.propTypes = {
  theme: PropTypes.string,
};

export default BrandIcon;
