import { API } from '../helpers/fetch';
import useSWR from 'swr';
import {
	ID,
	EMAIL,
	ROLE,
	AUTH,
	OWNER,
	PHOTO,
	NAME,
	LAST_NAME,
} from 'helpers/consts';

function login({ email, password, keepSession }) {
	return API.post('auth/signin', { email, password }).then((response) => {
		// login successful if there's a user in the response
		if (response && response?.user?.habilitado) {
			// store user details and basic auth credentials in local storage
			// to keep user logged in between page refreshes
			if (keepSession) {
				localStorage.setItem(ID, response.user?.id);
				localStorage.setItem(EMAIL, response.user?.email);
				localStorage.setItem(ROLE, response.user?.rol);
				localStorage.setItem(AUTH, response?.token);
				localStorage.setItem(NAME, response?.user?.name);
				localStorage.setItem(LAST_NAME, response?.user?.lastName);
			} else {
				sessionStorage.setItem(ID, response.user?.id);
				sessionStorage.setItem(EMAIL, response.user?.email);
				sessionStorage.setItem(ROLE, response.user?.rol);
				sessionStorage.setItem(AUTH, response?.token);
				sessionStorage.setItem(NAME, response?.user?.name);
				sessionStorage.setItem(LAST_NAME, response?.user?.lastName);
			}
			userService.getProfile({ userId: response.user?.id }).then((response) => {
				if (keepSession) {
					localStorage.setItem(PHOTO, response.Perfil?.fotoPerfil);
				} else {
					sessionStorage.setItem(PHOTO, response.Perfil?.fotoPerfil);
				}
			});
		}
		return response;
	});
}

function signup({
	nombre,
	apellido,
	email,
	password,
	address,
	locality,
	province,
}) {
	return API.post('auth/signup', {
		name: nombre,
		lastName: apellido,
		email,
		password,
		address,
		locality,
		province,
	});
}

function logout() {
	// remove user from local/session storage
	localStorage.removeItem(ID);
	localStorage.removeItem(EMAIL);
	localStorage.removeItem(ROLE);
	localStorage.removeItem(AUTH);
	localStorage.removeItem(OWNER);
	sessionStorage.removeItem(ID);
	sessionStorage.removeItem(EMAIL);
	sessionStorage.removeItem(ROLE);
	sessionStorage.removeItem(AUTH);
	sessionStorage.removeItem(OWNER);
}

function updateUserByUserId(userId, { name, lastName, profilePicture }) {
	return API.put(`/auth/${userId}`, {
		...(name && { name }),
		...(lastName && { lastName }),
		...(profilePicture && { profilePicture }),
	});
}

function updatePasswordByUserId(
	userId,
	{ name, lastName, profilePicture, password, currentPassword }
) {
	return API.put(`/auth/${userId}`, {
		...(name && { name }),
		...(lastName && { lastName }),
		...(profilePicture && { profilePicture }),
		password,
		currentPassword,
	});
}

async function fetcher(url) {
	return await API.get(url);
}

function useUser() {
	const { data, error, mutate } = useSWR('user/profile', fetcher);

	return {
		user: data,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
}

function updateProfile(values) {
	const {
		id,
		oficina: nombre,
		ciudad,
		provincia,
		direccion,
		cp,
		telefono,
		...rest
	} = values;

	rest.Oficina = { nombre, ciudad, provincia, direccion, cp };

	if (telefono.length) {
		rest.telefono = telefono;
	}

	return API.put(`/user/profile/${id}`, rest);
}

function updateLastSuccessfulSync() {
  return API.put('/user/lastSuccessfulSync');
}

function updateUser(userId, data) {
	return API.put(`/user/${userId}`, data);
}

function createOficina(values) {
	const { id, oficina: nombre, ciudad, provincia, direccion, cp } = values;
	const oficina = { nombre, ciudad, provincia, direccion, cp };
	return API.post(`/user/office/${id}`, oficina);
}

function isValidToken({ email, forgotToken }) {
	return API.get('/auth/isValidRecoverToken', {
		params: {
			email: email,
			forgotToken: forgotToken,
		},
	});
}
function validateNewUser({ id }) {
	return API.get('/auth/validateUser', { params: { id } });
}

function forgotPassword({ email }) {
	return API.post(`/auth/forgotPassword/${email}`);
}

function resetPassword({ email, forgotToken, newPassword }) {
	return API.post('/auth/recoverPassword', {
		email,
		forgotToken,
		password: newPassword,
	});
}

function getProfile() {
	return API.get('/user/profile');
}

export const userService = {
	login,
	signup,
	logout,
	useUser,
	updateProfile,
	updateUser,
	updateUserByUserId,
	updatePasswordByUserId,
	getProfile,
	createOficina,
	validateNewUser,
	isValidToken,
	resetPassword,
	forgotPassword,
  updateLastSuccessfulSync,
};
