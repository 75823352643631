import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch } from 'react-router-dom';

// reactstrap components
import { Container, Row, Col, Toast, ToastBody, ToastHeader } from 'reactstrap';

// core components
import AuthNavbar from '../../../components/Navbars/AuthNavbar.js';

import user from 'context/user';
import routes from '../../routes.js';
import './Auth.css';
import Welcome from '../../../components/Welcome/Welcome';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { useLocation } from 'react-router-dom';
import Landing from '../../../views/Landing/Landing.jsx';

const Auth = () => {
	const {
		state: { status },
	} = useContext(user);

	const { pathname } = useLocation();

	const { breakpoint } = useBreakpoint();

	const [showToast, setShowToast] = useState(false);

	useEffect(() => {
		document.body.classList.add('bg-default');
		if (status === 'resolved') {
			setShowToast(true);
		}
		return () => {
			document.body.classList.remove('bg-default');
			setShowToast(false);
		};
	}, []);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/auth') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			{pathname === '/' ? <Landing/> : (
				<div className='main-content'>
					<div className='bg-auth-body' />
					<AuthNavbar />
					<Container
						className='container-admin'
						fluid
					>
						<Col className='p-0'>
							<Row className='m-0 h-100'>
								{breakpoint === 'md' ||
								breakpoint === 'lg' ||
								breakpoint === 'xl' ||
								breakpoint === '2xl' ||
								breakpoint === '3xl' ? (
									<Col
										className='px-0'
										lg='8'
										md='7'
									>
										<div className='header h-100 w-100 border-left'>
											<Container
												className='bg-auth h-100 w-100'
												fluid
											/>
											<Col className='top-8'>
												<Welcome />
											</Col>
										</div>
									</Col>
								) : null}
								<Col
									className='p-0'
									xs='12'
									lg='4'
									md='5'
								>
									{/* Page content */}
									<Row className='justify-content-center h-100 border-right'>
										<Switch>
											{getRoutes(routes)}
											{/* <Redirect from="/auth/recoverpassword" to="/auth/login" /> */}
										</Switch>
									</Row>
								</Col>
							</Row>
						</Col>
					</Container>
				</div>
			)}
			<div className='position-fixed bottom-0 end-0 right-2'>
				<Toast
					isOpen={showToast}
					className='p-3 bg-gradient-lighter my-2 rounded'
				>
					<ToastHeader toggle={() => setShowToast(false)}>
						NotiExpress
					</ToastHeader>
					<ToastBody>Se cerró la sesión correctamente.</ToastBody>
				</Toast>
			</div>
		</>
	);
};

export default Auth;

Auth.propTypes = {
	location: PropTypes.object,
};
