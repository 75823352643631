import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import user from 'context/user';
import PropTypes from 'prop-types';
import ProfilePhotoIcon from '../../../components/Icons/ProfilePhotoIcon';

const ProfileInfo = ({ userInfo }) => {
	const {
		state: { userdata },
	} = useContext(user);

	return (
		<Card className='card-profile shadow-card m-0 h-100'>
			{!userInfo ? (
				<Col className='justify-content-center align-items-center d-flex'>
					<Spinner color='primary' />
				</Col>
			) : (
				<>
					<Row className='justify-content-center'>
						<Col
							className='order-lg-2'
							lg='3'
						>
							<div className='card-profile-image'>
								<a
									href='#'
									onClick={(e) => e.preventDefault()}
								>
									{userdata.Profile?.profilePicture ? (
										<img
											alt='...'
											className='rounded-circle d-block w-100%'
											src={userdata?.Profile?.profilePicture}
										/>
									) : (
										<ProfilePhotoIcon
											className='rounded-circle d-block w-100%'
											size={180}
										/>
									)}
								</a>
							</div>
						</Col>
					</Row>
					<CardHeader className='text-center border-0 mt-6'>
						<div className='d-flex justify-content-around'>
							<div></div>
							<div></div>
						</div>
					</CardHeader>
					<CardBody className='pt-0 pt-md-4'>
						<div className='text-center'>
							<h3 className='text-heading-2'>{`${userInfo?.name} ${userInfo?.lastName}`}</h3>
							<h5 className='text-body-large-bold'>{`${userInfo?.email}`}</h5>
							<hr className='my-4' />
							<h5 className='text-body-large-bold text-secondary-default'>Fecha de Alta</h5>
							<p className='text-body-large m-0'>
								{new Date(userdata?.createdAt).toLocaleDateString('es-ES', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
								})}
							</p>
						</div>
					</CardBody>
				</>
			)}
		</Card>
	);
};

export default ProfileInfo;

ProfileInfo.propTypes = {
	userInfo: PropTypes.object,
};
