import { API } from 'helpers/fetch';

export const localityService = {
	getFromJurisdiction,
	getOrganisms,
};

function getFromJurisdiction(id) {
	return API.get(`/circumscription/jurisdiction/${id}`);
}

function getOrganisms(id) {
	return API.get(`/organism/circumscription/${id}`);
}
