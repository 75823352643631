import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { create } from 'react-modal-promise';
import CustomModal from '../Modals/CustomModal/CustomModal';
import { Button, Col, FormGroup, Input, InputGroup, ModalBody, Row } from 'reactstrap';
import { scrapperService } from '../../services/scrapperService';
import useBreakpoints from '../../hooks/useBreakpoints';

const CaptchaModal = ({ isOpen, onResolve, onReject, captchaImage }) => {
	const [captcha, setCaptcha] = useState('');

	const handleCloseUserTab = async () => {
		await scrapperService.closeTabDTIOLScrapper();
		onReject();
	};
	const handleSendCaptcha = () => {
		onResolve(captcha);
	};

	const { isMobile } = useBreakpoints();

	return (
		<CustomModal
			title={'Jurisdicción de Chaco'}
			isOpen={isOpen}
			toggle={() => onReject()}
			className={isMobile ? 'w-95' : 'w-50'}
		>
			<ModalBody>
				<p className='text-body-large text-neutro-2'>Para sincronizar ingresá los caracteres que aparecen en la imagen.</p>
				<img
					src={captchaImage}
					alt='captcha'
				/>
				<Row className='m-0'>
					<FormGroup>
						<InputGroup
							className='input-group-alternative mt-3'
							size='sm'
						>
							<Input
								placeholder='Ingresá los caracteres de la imagen.'
								type='text'
								autoFocus={true}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										handleSendCaptcha();
									}
								}}
								value={captcha}
								onChange={(event) => setCaptcha(event.target.value)}
								style={{ width: '300px' }}
							/>
						</InputGroup>
					</FormGroup>
				</Row>
				<Row className='pt-3'>
					<Col>
						<Row className={isMobile ? 'align-items-center justify-content-between' : 'm-0 align-items-center'}>
							<Button
								outline
								className={isMobile ? 'btn btn-outline-noti-secondary py-1 px-3' : 'btn btn-outline-noti-secondary py-1 px-5'}
								onClick={handleCloseUserTab}
								size={isMobile ? 'sm' : 'md'}
							>
								Cancelar
							</Button>
							<Button
								className={isMobile ? 'btn btn-noti-primary py-1 px-3' : 'btn btn-noti-primary ml-3 py-1 px-5'}
								onClick={handleSendCaptcha}
								bgSize={isMobile ? 'sm' : 'md'}
							>
								<span className='text-button'>Aceptar</span>
							</Button>
						</Row>
					</Col>
				</Row>
			</ModalBody>
		</CustomModal>
	);
};

CaptchaModal.propTypes = {
	isOpen: PropTypes.bool,
	onResolve: PropTypes.func,
	onReject: PropTypes.func,
	captchaImage: PropTypes.string,
};

export const captchaModal = create(CaptchaModal);
