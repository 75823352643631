import React from 'react';
import { create } from 'react-modal-promise';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import { Button, Col, ModalBody, Row } from 'reactstrap';
import { ReactComponent as SettingIcon } from 'assets/img/icons/notiexpress/setting-icon.svg';
import PropTypes from 'prop-types';

const SuccessAddedCaseModal = ({ isOpen, onResolve, onReject }) => {
	return (
		<CustomModal
			title={'Expediente agregado'}
			isOpen={isOpen}
			toggle={() => onReject()}
			className='w-50'
		>
			<ModalBody>
				<Row className=''>
					<Col xs={12}>
						<h3 className='text-heading-2'>
							¡El expediente se agregó con éxito!
						</h3>
						<p className='text-body-large'>
							Completá la configuración del expediente para ver su despacho
							histórico
						</p>
					</Col>
				</Row>
				<Row className='pt-2'>
					<Col>
						<Row className='m-0 align-items-center'>
							<Button
								className='btn btn-outline-noti-secondary py-1 px-5'
								onClick={() => onReject()}
							>
								<span className=''>Aceptar</span>
							</Button>
							<Button
								className='btn btn-noti-primary ml-3 py-1 px-5'
								onClick={() => onResolve()}
							>
								<SettingIcon className='stroke-neutro-1'></SettingIcon>
								<span className='text-button'>Configurar</span>
							</Button>
						</Row>
					</Col>
				</Row>
			</ModalBody>
		</CustomModal>
	);
};

SuccessAddedCaseModal.propTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
};

export const successAddedCaseModal = create(SuccessAddedCaseModal);
