/* eslint-disable indent */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { scrapperService } from '../services/scrapperService';
import { SYNC_STATUS } from '../helpers/consts';
import { captchaModal } from '../components/CaptchaModal/CaptchaModal';
import { syncErrorModal } from '../components/Modals/SyncErrorModal/SyncErrorModal';
import { userService } from '../services/userService';


export const SynchronizeContext = createContext(undefined);

const reducer = (state, action) => {
	switch (action.type) {
		case 'RESET_EXPEDIENTS_TO_ADD':
			return {
				...state,
				expedientsToAdd: [],
			};
		case 'SET_EXPEDIENTS_TO_ADD':
			return {
				...state,
				expedientsToAdd: [...state.expedientsToAdd, ...action.payload],
			};
		case 'RESET_SYNC_STATUS':
			return {
				...state,
				status: SYNC_STATUS.START,
				message: 'Sincronizar',
			};
		case 'SET_SYNC_STATUS_CAPTCHA':
			return {
				...state,
				status: SYNC_STATUS.CAPTCHA,
				message: action.payload,
			};
		case 'SET_SYNC_STATUS_SYNCING':
			return {
				...state,
				status: SYNC_STATUS.SYNCING,
				message: action.payload,
			};
		case 'SET_SYNC_STATUS_SUCCESS':
			return {
				...state,
				status: SYNC_STATUS.SUCCESS,
				message: action.payload,
			};
		case 'SET_SYNC_STATUS_ERROR':
			return {
				...state,
				status: SYNC_STATUS.ERROR,
				message: action.payload.message,
				error: action.payload.error,
			};
		default:
			return state;
	}
};

const initialState = {
	status: SYNC_STATUS.START,
	message: 'Sincronizar',
	expedientsToAdd: [],
	error: null,
};
export const SynchronizeProvider = ({ children }) => {
	const [syncStatus, dispatch] = useReducer(
		reducer,
		initialState,
		() => initialState
	);

	const startSync = ({ message }) => {
		dispatch({ type: 'SET_SYNC_STATUS_SYNCING', payload: message });
	};

	const captchaSync = ({ message }) => {
		dispatch({ type: 'SET_SYNC_STATUS_CAPTCHA', payload: message });
	};

	const successSync = ({ message }) => {
		dispatch({ type: 'SET_SYNC_STATUS_SUCCESS', payload: message });
	};

	const errorSync = ({ message, error }) => {
		dispatch({
			type: 'SET_SYNC_STATUS_ERROR',
			payload: { message: message, error: error },
		});
	};

	const resetSyncStatus = () => {
		dispatch({ type: 'RESET_SYNC_STATUS' });
	};

	const setExpedientsToAdd = ({ expedients = [] }) => {
		dispatch({ type: 'SET_EXPEDIENTS_TO_ADD', payload: expedients });
	};

	const resetExpedientsToAdd = () => {
		dispatch({ type: 'RESET_EXPEDIENTS_TO_ADD' });
	};

	const syncDTIOLNews = async ({ date: date }) => {
		captchaSync({
			message: 'Resolviendo captcha...',
		});
		try {
			const { result: captchaImage } = await scrapperService.getDtiolCaptcha();
			const resolvedCaptcha = await captchaModal({ captchaImage });
			startSync({
				message: 'Sincronizando novedades...',
			});
			await scrapperService.scrapDtiolNews({
				captcha: resolvedCaptcha,
				date: date,
			});
			successSync({
				message: '¡Novedades sincronizadas correctamente!',
			});
			setTimeout(() => resetSyncStatus(), 2000);
		} catch (e) {
			try {
				await syncErrorModal({ jurisdictionName: 'Chaco' });
				resetSyncStatus();
			} catch (error) {
				errorSync({
					message: '¡Error al sincronizar noticias de las jurisdicciones!',
					error: e,
				});
			}
		}
	};

	const synchronizeAllNow = ({ jurisdictions, resolvedCaptcha, date }) =>
		Promise.allSettled(
			jurisdictions.map(({ name }) => {
				switch (name) {
					case 'Chaco':
						return scrapperService
							.scrapDtiolNews({
								date,
								captcha: resolvedCaptcha,
							})
							.catch(() => {
								throw new Error('Chaco');
							});
					case 'Federal':
						return scrapperService.scrapPJNNews({ date: date }).catch(() => {
							throw new Error('Capital Federal / Justicia Federal');
						});
					default:
						return Promise.resolve();
				}
			})
		).then((results) => {
      const rejected = results.filter(({ status }) => status === 'rejected');
      if (rejected.length) {
        throw new Error(rejected.map(({ reason }) => reason.message).join(', '));
      } else {
        userService.updateLastSuccessfulSync();
      }
    });

	const syncAllNews = async ({ activeJurisdictions, date }) => {
		try {
			let resolvedCaptcha;
			if (activeJurisdictions.some(({ name }) => name === 'Chaco')) {
				captchaSync({
					message: 'Resolviendo captcha...',
				});
				const { result: captchaImage } = await scrapperService
					.getDtiolCaptcha()
					.catch(() => {
						throw new Error('Chaco');
					});
				try {
					resolvedCaptcha = await captchaModal({ captchaImage });
				} catch (error) {
					throw new Error('Captcha no resuelto');
				}
			}
			startSync({
				message: 'Sincronizando novedades...',
			});
			await synchronizeAllNow({
				jurisdictions: activeJurisdictions,
				resolvedCaptcha,
				date,
			});
			successSync({
				message: '¡Sincronizado!',
			});
			setTimeout(() => resetSyncStatus(), 2000);
		} catch (e) {
			if (e.message === 'Captcha no resuelto') {
				errorSync({
					message: 'Sincronización interrumpida. Inténtalo nuevamente',
					error: e,
				});
			} else {
				try {
					await syncErrorModal({
						jurisdictionName: e.message,
						isNoveltyView: true,
					});
					resetSyncStatus();
				} catch (error) {
					errorSync({
						message: '¡Error al sincronizar noticias de las jurisdicciones!',
						error: e,
					});
				}
			}
		}
	};

	const syncDTIOLExpedient = async ({ expId }) => {
		captchaSync({
			message: 'Resolviendo captcha...',
		});
		try {
			const { result: captchaImage } = await scrapperService.getDtiolCaptcha();
			let resolvedCaptcha;
			try {
				resolvedCaptcha = await captchaModal({ captchaImage });
			} catch (error) {
				throw new Error('Captcha no resuelto');
			}
			startSync({
				message: 'Sincronizando expediente...',
			});
			await scrapperService.scrapDtiolExpedient({
				expId: expId,
				captcha: resolvedCaptcha,
			});
			successSync({
				message: '¡Sincronizado!',
			});
			setTimeout(() => resetSyncStatus(), 2000);
		} catch (e) {
			if (e.message === 'Captcha no resuelto') {
				errorSync({
					message: 'Sincronización interrumpida. Inténtalo nuevamente',
					error: e,
				});
			} else {
				try {
					await syncErrorModal({ jurisdictionName: 'Chaco' });
					resetSyncStatus();
				} catch (error) {
					errorSync({
						message:
							'¡Error al sincronizar expediente de la jurisdicción de Chaco!',
						error: e,
					});
				}
			}
		}
	};

	const syncPJNExpedient = async ({ expId }) => {
		startSync({
			message: 'Sincronizando expediente...',
		});
		try {
			await scrapperService.scrapPJNExpedient({ expId: expId });
			successSync({
				message: '¡Sincronizado!',
			});
			setTimeout(() => resetSyncStatus(), 2000);
		} catch (e) {
			try {
				await syncErrorModal({ jurisdictionName: 'Capital Federal / Justicia Federal' });
				resetSyncStatus();
			} catch (error) {
				errorSync({
					message:
						'¡Error al sincronizar expediente de la jurisdicción Capital Federal / Justicia Federal',
					error: e,
				});
			}
		}
	};

	const syncDTIOLProvidence = async ({ expId, providenceDate }) => {
		captchaSync({
			message: 'Resolviendo captcha...',
		});
		try {
			const { result: captchaImage } = await scrapperService.getDtiolCaptcha();
			let resolvedCaptcha;
			try {
				resolvedCaptcha = await captchaModal({ captchaImage });
			} catch (error) {
				throw new Error('Captcha no resuelto');
			}
			startSync({
				message: 'Sincronizando despacho...',
			});
			await scrapperService.scrapDtiolProvidence({
				expId: expId,
				captcha: resolvedCaptcha,
				providenceDate: providenceDate,
			});
			successSync({
				message: '¡Sincronizado!',
			});
			setTimeout(() => resetSyncStatus(), 2000);
		} catch (e) {
			if (e.message === 'Captcha no resuelto') {
				errorSync({
					message: 'Sincronización interrumpida. Inténtalo nuevamente',
					error: e,
				});
			} else {
				try {
					await syncErrorModal({ jurisdictionName: 'Chaco' });
					resetSyncStatus();
				} catch (error) {
					errorSync({
						message:
							'¡Error al sincronizar providencia de la jurisdicción de Chaco!',
						error: e,
					});
				}
			}
		}
	};

	const syncExpedient = ({ jurisdiction, expId }) => {
		switch (jurisdiction) {
			case 'Chaco':
				return syncDTIOLExpedient({ expId });
			case 'Federal':
				return syncPJNExpedient({ expId });
			default:
				return () => {};
		}
	};

	const syncProvidence = ({ jurisdiction, providenceDate, expId }) => {
		switch (jurisdiction) {
			case 'Chaco':
				return syncDTIOLProvidence({ expId, providenceDate });
			case 'Federal':
				return () => {};
			default:
				return () => {};
		}
	};

	return (
		<SynchronizeContext.Provider
			value={{
				state: syncStatus,
				actions: {
					syncAllNews,
					syncDTIOLNews,
					syncExpedient,
					syncProvidence,
					setExpedientsToAdd,
					resetExpedientsToAdd,
				},
			}}
		>
			{children}
		</SynchronizeContext.Provider>
	);
};

SynchronizeProvider.propTypes = {
	children: PropTypes.element,
};
