import HomeHeader from 'components/Headers/HomeHeader';
import React from 'react';
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	ButtonGroup,
} from 'reactstrap';
import './HelpViewStyles.css';
import data from './frequentQuestions.json';

const HelpView = () => {
	const [activeTab, setActiveTab] = React.useState(undefined);

	return (
		<>
			<HomeHeader
				className=''
				headerText='Ayuda'
			/>
			<Container
				className='bg-white mb-5'
				fluid={true}
			>
				<Row>
					<Col
						xs={5}
						xl={3}
					>
						<ButtonGroup
							vertical
							tag={'div'}
						>
							{data.map((item, index) => (
								<p
									key={index}
									onClick={() => setActiveTab(index)}
									className={
										activeTab === index
											? 'active text-heading-4 text-primary-default py-2'
											: 'text-heading-4 text-primary-default py-2'
									}
									style={{ cursor: 'pointer' }}
								>
									{item.title}
								</p>
							))}
						</ButtonGroup>
					</Col>
					<Col
						xs={7}
						xl={9}
					>
						<Card className='shadow-card'>
							<CardBody>
								{activeTab !== undefined ? (
									data.map((item, index) => (
										<div
											key={index}
											className={activeTab === index ? 'd-block' : 'd-none'}
										>
											<h1 className='text-heading-2 text-primary-default mb-3'>
												{item.title}
											</h1>
											<h2 className='text-heading-3 text-secondary-default pre'
                      >
												{item.subTitle}
											</h2>
											{item.sections.map((section, index) => (
												<div key={index}>
													<hr className='my-4' />
													<h2 className='text-heading-4 text-secondary-default'>
														{section.title}
													</h2>
													<p className='text-body-large m-0 text-secondary-default'>
														{section.content}
													</p>
													{section.subContent && (
														<p className='text-body-large m-0 text-secondary-default'>
															{section.subContent}
														</p>
													)}
												</div>
											))}
										</div>
									))
								) : (
									<>
										<h1 className='text-heading-1 text-primary-default mb-3'>¡Notiexpress te da la bienvenida!</h1>
                    <hr className='my-4' />
                    <h2 className='text-heading-2 text-secondary-default'>
											Podrás encontrar aquí todo lo que necesites saber sobre la
											plataforma
                    </h2>
									</>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default HelpView;
