import React from 'react';
import { Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import BrandIcon from '../Icons/BrandIcon';
import NotificationBell from './NotificationBell/NotificationBell';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';

const CollaboratorNavbar = () => {
	return (
		<>
			<Navbar
				className='navbar-top navbar-dark d-none d-md-block bg-primary-default'
				expand='md'
			>
				<Container
					className='h-100'
					fluid
				>
					<NavbarBrand
						to='/'
						tag={Link}
					>
						<BrandIcon theme='dark' />
					</NavbarBrand>
					<Row>
						<NotificationBell />
						<ProfileDropdown layout='collaborator' />
					</Row>
				</Container>
			</Navbar>
		</>
	);
};

export default CollaboratorNavbar;
