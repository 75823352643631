import React from 'react';
import { Card, Col } from 'reactstrap';

const NotFoundPage = () => {
	return (
		<Col>
			<Card>
				<span>404</span>
			</Card>
		</Col>
	);
};

export default NotFoundPage;
