import React, { useContext, useEffect, useState } from 'react';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import {
	Button,
	Col,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	ModalBody,
	Row,
	Spinner,
	Tooltip,
	UncontrolledDropdown,
} from 'reactstrap';
import DynamicTable from '../../../../components/Commons/DynamicTable';
import { useFormik } from 'formik';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/notiexpress/calendar-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import { ReactComponent as DefaultIcon } from 'assets/img/icons/notiexpress/divide-circle.svg';
import { ReactComponent as GridIcon } from 'assets/img/icons/notiexpress/grid-icon.svg';
import { DayPicker } from 'react-day-picker';
import global from 'context/global';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CustomMessage from '../../../../components/CustomMessage/CustomMessage';
import { FaPlus } from 'react-icons/fa';
import { casesService } from '../../../../services/casesService';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { es } from "date-fns/locale";

const AddNonWorkingDaysModal = ({ isOpen, toggle }) => {
	const [disabledDays, setDisabledDays] = useState([]);

	const inputFormSchema = Yup.object().shape({
		reason: Yup.string().required('El motivo es requerido'),
		initDate: Yup.date().required('La fecha es requerida'),
		endDate: Yup.date().when(['initDate', 'repeat.value'], (initDate, repeat, schema) => {
			let days = 0;
			let limitDate = moment(initDate).add(days, 'days');
			if (repeat === 'MONTHLY') {
				days = 29;
				limitDate = moment(initDate).add(days, 'days');
			} else if (repeat === 'YEARLY') {
				days = 364;
				limitDate = moment(initDate).add(days, 'days');
			} else if (repeat === 'WEEKLY') {
				days = 3;
				limitDate = moment(initDate).add(days, 'days');
			} else {
				return schema;
			}

			return schema.test({
				test: (value) => {
					if (!value) {
						return true;
					}
					return moment(value).isSameOrBefore(limitDate);
				},
				message: `La fecha final debe ser menor a ${moment(limitDate).format('DD/MM/YYYY')}`,
			});
		}),
		jurisdictions: Yup.array()
			.min(1, 'Debe seleccionar al menos una jurisdicción')
			.of(
				Yup.object()
					.shape({
						label: Yup.string(),
						value: Yup.string().required('La jurisdicción es requerida'),
					})
					.required('Campo requerido')
			),
		repeat: Yup.object()
			.shape({
				label: Yup.string(),
				value: Yup.string().required('La repetición es requerida').oneOf(['UNIQUE', 'WEEKLY', 'MONTHLY', 'YEARLY']),
			})
			.required('Campo requerido'),
	});

	const {
		state: { jurisdictions },
	} = useContext(global);
	const { data: nonWorkingDays, mutate, isLoading } = casesService.getNonWorkingDays();

	useEffect(() => {
		if (!isLoading) {
			const disabledDaysI = [
				(day) => day.getDay() === 0 || day.getDay() === 6,
				(day) => day.getMonth() === 0,
				...nonWorkingDays?.nonWorkingDaysUser
					?.map(({ days }) => days)
					.flat()
					.map((aDate) => new Date(aDate)),
				...nonWorkingDays?.defaultNonWorkingDays
					?.map(({ days }) => days)
					.flat()
					.map((aDate) => new Date(aDate)),
			];
			setDisabledDays(disabledDaysI);
		}
	}, [isLoading, nonWorkingDays]);

	const [showSuccess, setShowSuccess] = useState({ show: false, message: '' });
	const [showError, setShowError] = useState({ show: false, message: '' });
	const [loading, setLoading] = useState(false);

	const TooltipElement = ({ id, children }) => {
		const [tooltipOpen, setTooltipOpen] = useState(false);

		const toggleTool = () => setTooltipOpen(!tooltipOpen);

		return (
			<Tooltip
				isOpen={tooltipOpen}
				target={id}
				toggle={toggleTool}
			>
				{children}
			</Tooltip>
		);
	};

	const initialValues = {
		reason: '',
		initDate: '',
		endDate: '',
		jurisdictions: [],
		repeat: '',
	};

	const handleDeleteDays = async ({ id }) => {
		try {
			setLoading(true);
			await casesService.deleteNonWorkingDay({ id });
			setShowError({ show: false, message: '' });
			setShowSuccess({
				show: true,
				message: '!Datos actualizados!',
			});
			await mutate();
			setLoading(false);
		} catch (e) {
			setShowSuccess({ show: false, message: '' });
			setShowError({ show: true, message: 'Error al eliminar el día inhábil' });
		}
	};

	const columns = [
		{
			Header: 'Fecha/Rango',
			accessor: (row) =>
				!moment(row.initDate).isValid() ? (
					<span>{row.initDate}</span>
				) : (
					<span>
						{moment(row.initDate).format('DD/MM/YYYY')} {row.endDate ? '- ' + moment(row.endDate).format('DD/MM/YYYY') : ''}
					</span>
				),
		},
		{
			Header: 'Jurisdicción',
			accessor: ({ jurisdictionId }) => (
				<span>
					{jurisdictions?.find((jurisdiction) => jurisdiction.id === jurisdictionId)?.name ??
						`${jurisdictions.map((jurisdiction) => jurisdiction.name)}`}
				</span>
			),
		},
		{
			Header: 'Motivo',
			accessor: ({ description }) => <span>{description ?? '-'}</span>,
		},
		{
			Header: 'Repetir',
			accessor: ({ repeat }) => {
				switch (repeat) {
					case 'UNIQUE':
						return <span>Único</span>;
					case 'YEARLY':
						return <span>Anualmente</span>;
					case 'MONTHLY':
						return <span>Mensualmente</span>;
					case 'WEEKLY':
						return <span>Semanalmente</span>;
					default:
						return <span>-</span>;
				}
			},
			width: 70,
		},
		{
			Header: () => (
				<div className='text-center'>
					<GridIcon
						width={16}
						height={16}
						className='stroke-secondary-default'
					/>
				</div>
			),
			accessor: 'id',
			Cell: ({ value }) => {
				return value ? (
					loading ? (
						<div className='text-center'>
							<Spinner size='sm' />
						</div>
					) : (
						<div className='text-center'>
							<CloseIcon
								className='stroke-error'
								width={24}
								height={24}
								onClick={() => handleDeleteDays({ id: value })}
								id={`tooltip-${value}`}
							/>
							<TooltipElement id={`tooltip-${value}`}>Eliminar</TooltipElement>
						</div>
					)
				) : (
					<div className='text-center'>
						<DefaultIcon
							width={24}
							height={24}
							style={{
								cursor: 'not-allowed',
							}}
							id={`tooltip-1`}
						/>
						<TooltipElement id={`tooltip-1`}>Predeterminado - No se puede eliminar</TooltipElement>
					</div>
				);
			},
			width: 10,
		},
	];

	const jurisdictionOptions = jurisdictions?.map((jurisdiction) => ({
		label: `${jurisdiction.name}`,
		value: jurisdiction.id,
	}));
	const animatedComponents = makeAnimated();

	const CustomOption = (props) => {
		return (
			<animatedComponents.Option {...props}>
				<Row className='m-0 align-items-center justify-content-between'>
					<div className='m-0'>
						<Row className='m-0 align-items-center'>
							<span>{props.data.label}</span>
						</Row>
					</div>
					<div className='custom-control custom-control-alternative custom-checkbox m-0'>
						<Row className='m-0 align-items-center'>
							<input
								type='checkbox'
								id={props.data.value}
								defaultChecked={props.isSelected}
								className='custom-control-input m-0'
							/>
							<label
								className='custom-control-label m-0'
								htmlFor={props.data.value}
							/>
						</Row>
					</div>
				</Row>
			</animatedComponents.Option>
		);
	};

	CustomOption.propTypes = {
		data: Yup.object().shape({
			label: Yup.string(),
			value: Yup.string(),
		}),
		isSelected: Yup.bool(),
	};

	const handleSubmit = async (values) => {
		try {
			formik.setSubmitting(true);
			await casesService.addNonWorkingDays({
				description: values.reason,
				initDate: values.initDate,
				...(values?.endDate && { endDate: values.endDate }),
				jurisdictions: values.jurisdictions,
				repeat: values.repeat,
			});
			setShowSuccess({
				show: true,
				message: '¡Agregado!',
			});
			setShowError({ show: false, message: '' });
			formik.resetForm();
			await mutate();
		} catch (e) {
			setShowSuccess({ show: false, message: '' });
			setShowError({
				show: true,
				message: 'Error al agregar los días inhábiles',
			});
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: inputFormSchema,
		onSubmit: handleSubmit,
	});

	const handleResetForm = () => {
		formik.resetForm();
		setShowError(false);
		setShowSuccess(false);
	};

	const styles = {
		boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
		transition: 'box-shadow 0.15s ease',
		borderRadius: '8px',
		height: '35px',
		fontSize: '0.875rem',
		minHeight: '35px',
	};

	const repetirOptions = [
		{ value: 'UNIQUE', label: 'Único' },
		{ value: 'WEEKLY', label: 'Semanalmente' },
		{ value: 'MONTHLY', label: 'Mensualmente' },
		{ value: 'YEARLY', label: 'Anualmente' },
	];

	return (
		<CustomModal
			title={'Agregar días inhábiles'}
			isOpen={isOpen}
			toggle={toggle}
			className='m-5'
		>
			<ModalBody>
				<Form onSubmit={formik.handleSubmit}>
					<Row>
						<Col className='mx-3'>
							<Row className='mb-2'>
								<span className='text-body-large-bold text-secondary-default pr-2'>Fecha / Rango *</span>
							</Row>
							<Row className='align-items-center'>
								<FormGroup className='m-0 w-100'>
									<InputGroup
										className={`input-group-alternative ${formik.errors.initDate && formik.touched.initDate ? 'border-error' : ''} ${
											formik.errors.endDate && formik.touched.endDate ? 'border-error' : ''
										}`}
										size='sm'
									>
										<Input
											className='form-control'
											value={`${formik.values?.initDate ? moment(formik.values?.initDate).format('DD/MM/YYYY') : ''} ${
												formik.values?.endDate ? `- ${moment(formik.values?.endDate).format('DD/MM/YYYY')}` : ''
											}`}
											type='text'
										/>
										<InputGroupAddon addonType='append'>
											<UncontrolledDropdown active>
												<DropdownToggle
													nav
													className='h-100 d-flex align-items-center'
												>
													<CalendarIcon
														width={20}
														height={20}
														className='stroke stroke-primary-default'
													/>
												</DropdownToggle>
												<DropdownMenu right>
													<DayPicker
														style={{ width: '100%' }}
														mode='range'
														selected={{ from: formik.values?.initDate, to: formik.values?.endDate }}
														onSelect={(dateRange) => {
															formik.setFieldValue('initDate', dateRange?.from);
															formik.setFieldValue('endDate', dateRange?.to);
														}}
														disabled={disabledDays}
														onDayBlur={() => {
															formik.setFieldTouched('initDate', true);
															formik.setFieldTouched('endDate', true);
														}}
                            locale={es}
													/>
												</DropdownMenu>
											</UncontrolledDropdown>
										</InputGroupAddon>
									</InputGroup>
									{formik.errors?.initDate && formik.touched.initDate && (
										<Row className='ml-1 mt-2'>
											<span className='text-link-regular text-error'>{formik.errors?.initDate}</span>
										</Row>
									)}
									{formik.errors?.endDate && formik.touched.endDate && (
										<Row className='ml-1 mt-2'>
											<span className='text-link-regular text-error'>{formik.errors?.endDate}</span>
										</Row>
									)}
								</FormGroup>
							</Row>
						</Col>
						<Col className='mx-3'>
							<Row className='mb-2'>
								<span className='text-body-large-bold text-secondary-default '>Jurisdicción *</span>
							</Row>
							<Row className='align-items-center'>
								<FormGroup className='m-0 w-100'>
									<Select
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
										options={jurisdictionOptions ?? []}
										onChange={(newValue) => {
											formik.setFieldValue('jurisdictions', newValue);
											formik.setFieldTouched('jurisdictions', true);
										}}
										onBlur={() => {
											formik.setFieldTouched('jurisdictions', true);
										}}
										value={formik.values.jurisdictions}
										components={{
											...animatedComponents,
											Option: CustomOption,
										}}
										isMulti
										isDisabled={formik.isSubmitting}
										name='selectedEmails'
										placeholder='Seleccione una opción'
										styles={{
											control: (base) => ({
												...base,
												...styles,
												border: formik.errors.jurisdictions && formik.touched.jurisdictions ? '2px solid #f5365c' : '2px solid #D1E7F3',
											}),
										}}
									/>
									{formik.errors.jurisdictions && formik.touched.jurisdictions && (
										<Row className='ml-1 mt-2'>
											<span className='text-link-regular text-error'>{formik.errors.jurisdictions}</span>
										</Row>
									)}
								</FormGroup>
							</Row>
						</Col>
						<Col className='mx-3'>
							<Row className='mb-2'>
								<span className='text-body-large-bold text-secondary-default'>Motivo *</span>
							</Row>
							<Row className='align-items-center'>
								<FormGroup className='m-0 w-100'>
									<InputGroup
										className={`input-group-alternative ${formik.errors.reason && formik.touched.reason ? 'border-error' : ''}`}
										size='sm'
									>
										<Input
											className='form-control'
											value={formik.values.reason}
											type='text'
											name='reason'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											placeholder='Indique el motivo'
										/>
									</InputGroup>
									{formik.errors.reason && formik.touched.reason && (
										<Row className='ml-1 mt-2'>
											<span className='text-link-regular text-error'>{formik.errors.reason}</span>
										</Row>
									)}
								</FormGroup>
							</Row>
						</Col>
						<Col className='mx-3'>
							<Row className='mb-2'>
								<span className='text-body-large-bold text-secondary-default'>Repetir *</span>
							</Row>
							<Row className='align-items-center'>
								<FormGroup className='m-0 w-100'>
									<Select
										options={repetirOptions ?? []}
										onChange={(newValue) => {
											formik.setFieldValue('repeat', newValue);
										}}
										onBlur={() => {
											formik.setFieldTouched('repeat', true);
										}}
										value={formik.values.repeat}
										isDisabled={formik.isSubmitting}
										name='selectedRepeat'
										placeholder='Seleccione una opción'
										styles={{
											...styles,
											control: (base) => ({
												...base,
												...styles,
												border: formik.errors.repeat && formik.touched.repeat ? '2px solid #f5365c' : '2px solid #D1E7F3',
											}),
										}}
									/>
									{formik.errors.repeat && formik.touched.repeat && (
										<Row className='ml-1 mt-2'>
											<span className='text-link-regular text-error'>{formik.errors.repeat.value}</span>
										</Row>
									)}
								</FormGroup>
							</Row>
						</Col>
					</Row>
					<Row className='my-3'>
						<Col>
							<Row className='pl-3 align-items-center'>
								<Button
									className='btn btn-noti-primary px-5'
									type='submit'
									disabled={formik.isSubmitting || !formik.isValid}
								>
									<Row className='m-0 align-items-center'>
										<span
											className={`${formik.isSubmitting ? 'spinner-border spinner-border-sm mr-1' : 'd-none'}`}
											role='status'
											aria-hidden='true'
										/>
										{!formik.isSubmitting && (
											<FaPlus
												className='stroke-primary-default ml-0'
												width={20}
												height={20}
											/>
										)}
										<span className='text-button'>Agregar</span>
									</Row>
								</Button>
								<Button
									color='link'
									onClick={handleResetForm}
									disabled={formik.isSubmitting}
                  className='px-2'
								>
									<Row className='m-0 align-items-center'>
										<CloseIcon
											width={20}
											height={20}
											className='stroke-primary-default mr-2'
										/>
										<span className='text-body-regular text-primary-default'>Limpiar Campos</span>
									</Row>
								</Button>
								{showSuccess.show && setTimeout(() => setShowSuccess({ show: false }), 2000) && (
									<CustomMessage
										className='ml-3'
										icon={
											<CheckIcon
												width={20}
												height={20}
												className='stroke-success'
											/>
										}
										message={showSuccess.message}
									/>
								)}
								{showError.show &&
									setTimeout(
										() => setShowSuccess({ show: false }),
										2000
									)(
										<CustomMessage
											className='ml-3'
											icon={
												<CloseIcon
													width={20}
													height={20}
													className='stroke-error'
												/>
											}
											message={showError.message}
										/>
									)}
							</Row>
						</Col>
					</Row>
				</Form>
				<DynamicTable
					data={nonWorkingDays ? [...nonWorkingDays.defaultNonWorkingDays, ...nonWorkingDays.nonWorkingDaysUser] : []}
					columns={columns}
					noDataMessage='No hay días inhábiles agregados.'
				/>
			</ModalBody>
		</CustomModal>
	);
};

AddNonWorkingDaysModal.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	value: PropTypes.string,
};

export default AddNonWorkingDaysModal;
