import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomMessage = ({ icon, message }) => {
	return (
		<Row className='m-0'>
			<div className='d-flex align-items-center'>
				<div>{icon}</div>
				<div>
					<span className='text-body-regular ml-1'>{message}</span>
				</div>
			</div>
		</Row>
	);
};

CustomMessage.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.element,
	message: PropTypes.string,
};

export default CustomMessage;
