import useBreakpoint from '../../../hooks/useBreakpoint';
import { casesTableColumns } from '../../../variables/casesListTables';

export default function useCasesListTableColumns() {
	const { breakpoint } = useBreakpoint();

	switch (breakpoint) {
		case '2xs':
		case 'xs':
		case 'sm': // return {currentColumns: casesTableColumnsMobile}
		case 'md':
		case 'lg': // return {currentColumns: casesTableColumnsTablet}
		case 'xl': // return {currentColumns: casesTableColumnsDesktop}
		case '2xl': // return {currentColumns: casesTableColumns}
		default:
			return { currentColumns: casesTableColumns };
	}
}
