import { MP_PAYMENT_STATUS } from '../../../helpers/consts';
import React from 'react';
import toArs from '../../../helpers/toArs';
import moment from 'moment';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as PendingIcon } from 'assets/img/icons/notiexpress/divide-circle-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
export const receiptTableColumns = [
	{
		Header: 'Subscripción',
		accessor: ({ services }) => {
			let servicesString = '';
			services.map((service) => (servicesString += `${service.nombre}, `));
			servicesString = servicesString.slice(0, servicesString.length - 2);
			return servicesString;
		},
	},
	{
		Header: 'Estado del pago',
		accessor: ({ status }) => {
			return getColorAndMessageFromStatus(status);
		},
		disableSortBy: true,
	},
	{
		Header: 'Fecha de emisión',
		accessor: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY'),
	},
	{
		Header: 'Importe',
		accessor: ({ finalAmount }) => {
			return toArs(finalAmount);
		},
		disableSortBy: true,
	},
];

export const getColorAndMessageFromStatus = (status) => {
	switch (status) {
		case MP_PAYMENT_STATUS.approved:
			return <CheckIcon className='stroke-success' width={25}/>;
		case MP_PAYMENT_STATUS.in_process:
			return <PendingIcon className='stroke-warning' width={25}/>;
		case MP_PAYMENT_STATUS.rejected:
			return <CloseIcon className='stroke-error' width={25}/>;
	}
};
