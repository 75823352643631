import React from 'react';
import { Card, CardBody, Col, Row, Button, Spinner } from 'reactstrap';
import TooltipElement from 'components/Commons/TooltipElement';

const CardResumeFilter = ({
	cardTitle = 'default',
	subtitle = 'default',
	totalExp,
	children,
	date,
	isLoading = false,
	handleFilter = () => {},
}) => {
	return (
		<Card className='shadow h-100 d-flex align-items-center'>
			<CardBody
				style={{
					minWidth: '250px',
				}}
			>
				{isLoading ? (
					<div className='d-flex justify-content-center align-items-center h-100'>
						<Spinner color='primary' />
					</div>
				) : (
					<Row className='justify-content-between px-3 h-100'>
						<Col className='d-flex flex-column justify-content-between pr-0'>
							<Row>{children}</Row>
							<Row
								style={{
									width: '100px',
								}}
							>
								<span
									style={{
										fontSize: '12px',
										fontFamily: 'Roboto',
										fontWeight: '400',
										lineHeight: '20px',
										color: '#9BA7AF',
									}}
								>
									{cardTitle}
								</span>
								<span
									style={{
										fontSize: '17px',
									}}
									className='text-body-large-bold text-secondary-default'
								>
									{subtitle}
								</span>
							</Row>
						</Col>
						<Col
							className='d-flex justify-content-end pr-0 pb-1'
							style={{
								maxWidth: '120px',
							}}
						>
							<div className='d-flex justify-content-end flex-column'>
								<div className='d-flex'>
									<span
										className='text-heading-1 mb-0 pb-0 d-flex flex-column'
										style={{
											fontSize: '40px',
											lineHeight: '40px',
										}}
									>
										{totalExp ?? '-'}
										<hr className='my-1 w-100' />
									</span>
								</div>
								<div className='mt-2'>
									<Button
										color='link'
										onClick={() => handleFilter(date)}
										className='p-0'
										id='filterNewslettersCases'
									>
										Ver
									</Button>
									<TooltipElement
										id={'filterNewslettersCases'}
										children={'Ver novedades del día'}
									/>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</CardBody>
		</Card>
	);
};

export default CardResumeFilter;
