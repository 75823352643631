import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipElement = ({ id, children }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggleTool = () => setTooltipOpen(!tooltipOpen);

	return (
		<Tooltip
			isOpen={tooltipOpen}
			target={id}
			toggle={toggleTool}
		>
			{children}
		</Tooltip>
	);
};

export default TooltipElement;