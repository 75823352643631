import React from 'react';
import { Button, CardBody, Col, Form, FormGroup, Input } from 'reactstrap';

class Password extends React.Component {
	render() {
		return (
			<>
				<CardBody>
					<Form>
						<Col lg='6'>
							<FormGroup>
								<label
									className='form-control-label'
									htmlFor='input-current-password'
								>
									Contraseña actual
								</label>
								<Input
									className='form-control'
									id='input-current-password'
									type='password'
									placeholder='**********'
								/>
							</FormGroup>
						</Col>
						<Col lg='6'>
							<FormGroup>
								<label
									className='form-control-label'
									htmlFor='input-new-password'
								>
									Nueva contraseña
								</label>
								<Input
									className='form-control'
									id='input-new-password'
									type='password'
								/>
							</FormGroup>
						</Col>
						<Col lg='6'>
							<FormGroup>
								<label
									className='form-control-label'
									htmlFor='input-retry-password'
								>
									Repita la contraseña
								</label>
								<Input
									className='form-control'
									id='input-retry-password'
									type='password'
								/>
							</FormGroup>
						</Col>
						<Col lg='6'>
							<Button color='primary'>Aceptar</Button>
						</Col>
					</Form>
				</CardBody>
			</>
		);
	}
}

export default Password;
