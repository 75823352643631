import React, { useContext } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import global from 'context/global';
import LegalDisclaimerContent from './LegalDisclaimerContent';

const LegalDisclaimerView = () => {
  const {
    state: { jurisdictions },
  } = useContext(global);
  
	return (
		<>
			<HomeHeader headerText='Aviso Legal' />
			<Container
				className='bg-white mb-5'
				fluid={true}
			>
				<Card className='shadow-card'>
          <LegalDisclaimerContent />
				</Card>
			</Container>
		</>
	);
};

export default LegalDisclaimerView;
