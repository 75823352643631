import React, { useState, useContext } from 'react';
import { Card, CardBody, Col, Container, Row, CardFooter, Button } from 'reactstrap';
import HomeHeader from 'components/Headers/HomeHeader';
import 'moment/locale/es';
import TableOptionsButtons from 'components/Commons/TableOptionsButtons/TableOptionsButtons';
import DynamicTable from 'components/Commons/DynamicTable';
import user from '../../context/user';
import Pagination from '../../components/Commons/Pagination';
import { useHistory } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakpoints';
import useExpirationTableColumns from './hooks/useExpirationTableColumns';
import useUpdateSearchParams from './hooks/useUpdateSearchParams';
import { collaboratorRoleService } from '../../services/collaboratorRoleService';
import { LAYOUTS } from '../../helpers/consts';
import CardResumeDonut from '../../components/Charts/CardResumeDonut';

const CaducateListForCollaboratorView = () => {
	const history = useHistory();
	const [params, setParams] = useState({});

	const {
		state: { currentUser },
	} = useContext(user);

	const { isMobile } = useBreakpoints();
	const rowsPerPage = isMobile ? 10 : 10;
	const { data: casesExpiredList, isError, isLoading } = collaboratorRoleService.getExpedientsExpiration({ params });

	const { data: casesExpirationStatusCount, isLoading: isLoadingCount } = collaboratorRoleService.getExpedientsExpirationCount({ params });

	const { currentColumns } = useExpirationTableColumns();

	const columns = currentColumns.filter(({ Header }) => Header !== 'Responsable');

	const { handleSetPage, handleResetSearch } = useUpdateSearchParams({
		rowsPerPage,
		setParams,
		history,
		params,
		layout: LAYOUTS.COLLABORATOR,
		currentUser,
	});

	return (
		<>
			<HomeHeader headerText='Control de Caducidad' />
			<Container
				className='bg-gradient-white h-100'
				fluid={true}
			>
				<Row className='mb-3'>
					<Col
						style={{
							maxWidth: '350px',
						}}
					>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.COLLABORATOR}
							cardTitle='controlled'
							isLoading={isLoadingCount}
						/>
					</Col>
					<Col
						style={{
							maxWidth: '350px',
						}}
					>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.COLLABORATOR}
							cardTitle='alert'
							isLoading={isLoadingCount}
						/>
					</Col>
					<Col
						style={{
							maxWidth: '350px',
						}}
					>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.COLLABORATOR}
							cardTitle='expired'
							isLoading={isLoadingCount}
						/>
					</Col>
				</Row>
			</Container>
			<Container
				className='bg-white'
				fluid
			>
				<Row
					xs='3'
					className='mb-3'
				>
					<Col>
						<Card className='shadow mb-3'>
							<CardBody className='pt-0 '>
								<Row className='m-0 justify-content-between py-5'>
									<Col xs={12}>
										<Pagination
											rowsPerPage={rowsPerPage ?? 15}
											page={casesExpiredList?.currentPage ?? 0}
											pageCount={casesExpiredList?.totalPage ?? 0}
											count={casesExpiredList?.totalCount ?? 0}
											handleSetPage={handleSetPage}
											disabled={isLoading || isError}
										/>
										<TableOptionsButtons
											tableType='expiration'
											data={casesExpiredList?.paginateData ?? []}
											canSearch
											resetSearch={handleResetSearch}
											layout={LAYOUTS.COLLABORATOR}
										></TableOptionsButtons>
									</Col>
								</Row>
								<DynamicTable
									data={casesExpiredList?.paginateData ?? []}
									columns={columns}
									linkTo='/collaborator/expedientes/'
									propertyId='id'
									isLoading={isLoading}
									isError={isError}
									noDataMessage='No se encontraron expedientes.'
								/>
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Expedientes ({casesExpiredList?.totalCount ?? '-'})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default CaducateListForCollaboratorView;
