import React from 'react';
import PropTypes from 'prop-types';

const MaximizeIcon = ({
	className,
	size,
	fill = 'transparent',
	color = '',
}) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15 3H21V9'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9 21H3V15'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M21 3L14 10'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3 21L10 14'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

MaximizeIcon.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	className: PropTypes.string,
	fill: PropTypes.string,
};

export default MaximizeIcon;
