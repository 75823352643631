import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Donut from './Donut';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as PendingIcon } from 'assets/img/icons/notiexpress/divide-circle-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import TooltipElement from 'components/Commons/TooltipElement';
import { Spinner } from 'reactstrap';

const CardResumeDonut = ({ cardTitle = 'default', casesExpirationStatusCount, layout, isLoading = false }) => {
	const FILTER_EXPIRATION_STATUS = {
		default: 'Todos',
		controlled: 'Controlados',
		alert: 'Verificar',
		expired: 'Críticos',
	};

	const COLORS = {
		default: '#2196F3',
		controlled: '#92CB38',
		alert: '#FFC107',
		expired: '#FF784D',
	};

	const ICONS = {
		default: (
			<CheckIcon
				width={20}
				height={20}
				className='stroke-pending'
			/>
		),
		controlled: (
			<CheckIcon
				width={20}
				height={20}
				className='stroke-success'
			/>
		),
		alert: (
			<PendingIcon
				width={20}
				height={20}
				className='stroke-warning'
			/>
		),
		expired: (
			<CloseIcon
				width={20}
				height={20}
				className='stroke-danger'
			/>
		),
	};

	const history = useHistory();

	return (
		<Card className='shadow h-100  d-flex align-items-center'>
			<CardBody style={{
        minWidth: '255px'
      }} >
				{isLoading ? (
					<div className='d-flex justify-content-center align-items-center h-100'>
						<Spinner color='primary' />
					</div>
				) : (
					<Row className='h-100 justify-content-between'>
						<Col className='d-flex flex-column justify-content-between pr-0'>
							{ICONS[cardTitle]}
							{casesExpirationStatusCount && (
								<div className='d-flex'>
									<span className='text-body-large-bold text-secondary-default'>
										{FILTER_EXPIRATION_STATUS[cardTitle]} ({casesExpirationStatusCount[cardTitle]})
										<hr className='my-1 w-100' />
									</span>
								</div>
							)}
							<div>
								<Button
									color='link'
									onClick={() =>
										layout === 'admin'
											? history.push(`/admin/caducidad?expirationStatus=${cardTitle}`)
											: history.push(`/collaborator/caducidad?expirationStatus=${cardTitle}`)
									}
									className='p-0'
									id={'filterStatusCases' + cardTitle}
								>
									Ver
								</Button>
								<TooltipElement
									id={'filterStatusCases' + cardTitle}
									children={'Ver expedientes ' + FILTER_EXPIRATION_STATUS[cardTitle].toLowerCase().replace('verificar', 'a verificar')}
								/>
							</div>
						</Col>
						<Col
							className='d-flex justify-content-end px-0'
              style={{
								maxWidth: '120px',
							}}
						>
							{casesExpirationStatusCount?.total ? (
								<Donut
									backgroundColor={COLORS[cardTitle]}
									expedienteCountByStatus={casesExpirationStatusCount[cardTitle]}
									casesExpirationStatusCount={casesExpirationStatusCount}
									labels={[FILTER_EXPIRATION_STATUS[cardTitle]]}
								/>
							) : null}
						</Col>
					</Row>
				)}
			</CardBody>
		</Card>
	);
};

export default CardResumeDonut;
