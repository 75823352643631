import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import CrossIcon from '../Icons/CrossIcon';
import UserIcon from 'components/Icons/UserIcon';

const ServiceExpiredSoonMessage = ({
	title,
	message,
	to,
	buttonIcon,
	buttonLabel,
	icon,
}) => {
	const [show, setShow] = useState(
    window.sessionStorage.getItem('serviceExpiredSoonMessage') !== 'false'
  );

  const handleOnClose = () => {
    window.sessionStorage.setItem('serviceExpiredSoonMessage', 'false');
    setShow(false);
  };

	return show ? (
		<Card className='shadow-card mb-3 border-info my-2'>
			<Row className='justify-content-between p-5 px-3 px-lg-7'>
				<Col>
					<Row className='align-items-center gap-10'>
						{icon ? icon : null}
						<Col>
							<h3 className='text-body-large-bold text-secondary-default pl-lg-5'>
								{title}
							</h3>
							{message ? (
								<h5 className='text-body-regular  pl-lg-5'>{message}</h5>
							) : null}
						</Col>
						{to ? (
							<Col className='p-1'>
								<Row className='justify-content-center'>
                <Button
										className='btn btn-noti-sync px-5'
										tag={Link}
										to={to}
									>
										<span>
											<UserIcon
												size={20}
												className='fill-secondary-default mr-2'
											/>
										</span>
										<span>{buttonLabel ? buttonLabel : 'Ir a la página'}</span>
									</Button>
								</Row>
							</Col>
						) : null}
					</Row>
				</Col>
				<span
					onClick={handleOnClose}
					style={{ cursor: 'pointer' }}
				>
					<CrossIcon
						size={24}
						className='stroke stroke-primary-default'
					/>
				</span>
			</Row>
		</Card>
	) : null;
};

ServiceExpiredSoonMessage.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	to: PropTypes.string,
	buttonIcon: PropTypes.element,
	buttonLabel: PropTypes.string,
	icon: PropTypes.element,
};

export default ServiceExpiredSoonMessage;
