import React, { useContext, useState } from 'react';
import {
	Button,
	CardBody,
	Col,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	ModalBody,
	ModalFooter,
	Row,
} from 'reactstrap';
import { BiCamera } from 'react-icons/bi';
import CardAccordion from '../../../../components/CardAccordion/CardAccordion';
import user from '../../../../context/user';
import { useFormik } from 'formik';
import EyeOffIcon from '../../../../components/Icons/EyeOffIcon';
import EyeIcon from '../../../../components/Icons/EyeIcon';
import * as Yup from 'yup';
import CustomMessage from '../../../../components/CustomMessage/CustomMessage';
import CrossIcon from '../../../../components/Icons/CrossIcon';
import { STATUS_COLORS } from '../../../../helpers/consts';
import ProfilePhotoIcon from '../../../../components/Icons/ProfilePhotoIcon';
import ProfilePictureLoader from '../ProfilePictureLoader/ProfilePictureLoader';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import { userService } from '../../../../services/userService';
import CheckIcon from '../../../../components/Icons/CheckIcon';
import { currentPasswordModal } from '../../../../components/Forms/CurrentPasswordModal';
import { useHistory } from 'react-router-dom';

const UserDataSchema = Yup.object().shape({
	nombre: Yup.string(),
	apellido: Yup.string(),
	password: Yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres.'),
	passwordRepeat: Yup.string()
		.min(6, 'La contraseña debe tener al menos 6 caracteres.')
		.test('password-match', 'Las contraseñas deben coincidir', function (repeatPassword) {
			return this.parent.password === repeatPassword;
		}),
	profilePicture: Yup.string(),
});
const UserDataForm = () => {
	const history = useHistory();
	const {
		state: { currentUser, userdata },
		actions: { mutateUserdata },
	} = useContext(user);

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordClone, setShowPasswordClone] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const handleTogglePassword = () => setShowPassword(!showPassword);
	const handleTogglePasswordClone = () => setShowPasswordClone(!showPasswordClone);

	function handleLogOut() {
		userService.logout();
		history.push('/auth/login?reactivateAccount=true');
	}

	const formik = useFormik({
		initialValues: {
			name: userdata?.name ?? '',
			lastName: userdata?.lastName ?? '',
			password: '',
			passwordRepeat: '',
			profilePicture: '',
		},
		validationSchema: UserDataSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				if (!values.password) {
					await userService.updateUserByUserId(currentUser.id, {
						name: values.name,
						lastName: values.lastName,
						profilePicture: values.profilePicture,
					});
				} else {
					await currentPasswordModal().then(async (currentPassword) => {
						await userService.updatePasswordByUserId(currentUser.id, {
							name: values.name,
							lastName: values.lastName,
							password: values.password,
							profilePicture: values.profilePicture,
							currentPassword: currentPassword,
						});
						handleLogOut();
					});
				}
				mutateUserdata();
				setShowError(false);
				setShowSuccess(true);
			} catch (e) {
				setShowSuccess(false);
				setShowError(true);
			} finally {
				setSubmitting(false);
			}
		},
	});

	const ModalContainer = () => {
		const [avatar, setAvatar] = useState(null);

		return (
			<div className='w-75%'>
				<ModalBody>
					<ProfilePictureLoader onAvatarLoaded={setAvatar} />
				</ModalBody>
				<ModalFooter>
					<div>
						<Button
							type='button'
							className='btn btn-noti-primary py-1 px-5'
							onClick={() => {
								formik.setFieldValue('profilePicture', avatar);
								setIsOpen(false);
							}}
						>
							Aceptar
						</Button>
					</div>
				</ModalFooter>
			</div>
		);
	};

	return (
		<CardAccordion title='Mis datos'>
			<CardBody>
				<Form onSubmit={formik.handleSubmit}>
					<Row className='p-2'>
						<Col>
							<Label className='text-body-large-bold text-secondary-default'>Nombre</Label>
							<InputGroup
								className='input-group-alternative mb-2 form-group'
								size='sm'
							>
								<Input
									placeholder='Nombre del usuario'
									type='text'
									name='name'
									id='name'
									onChange={formik.handleChange}
									value={formik.values.name}
								/>
							</InputGroup>
							{formik.errors.name && formik.touched.name && <span className='text-link-regular text-error'>{formik.errors.name}</span>}
						</Col>
						<Col>
							<Label className='text-body-large-bold text-secondary-default'>Apellido</Label>
							<InputGroup
								className='input-group-alternative mb-2 form-group'
								size='sm'
							>
								<Input
									placeholder='Apellido del usuario'
									type='text'
									id='lastName'
									name='lastName'
									onChange={formik.handleChange}
									value={formik.values.lastName}
								/>
							</InputGroup>
							{formik.errors.lastName && formik.touched.lastName && (
								<span className='text-link-regular text-error'>{formik.errors.lastName}</span>
							)}
						</Col>
						<Col>
							<Label className='text-body-large-bold text-secondary-default'>Foto de perfil</Label>
							<Row className='m-0 align-items-center'>
								<Col className='pl-0'>
									<Button
										outline
										className='btn-outline-noti-secondary w-100'
										onClick={() => setIsOpen(!isOpen)}
										size='sm'
									>
										<BiCamera size={24} />
										Cambiar
									</Button>
								</Col>
								{userdata?.Profile?.profilePicture ? (
									<img
										src={userdata?.Profile?.profilePicture}
										width={36}
										height={36}
										alt='profile'
										className='rounded-circle'
									/>
								) : (
									<ProfilePhotoIcon size={36} />
								)}
							</Row>
						</Col>
					</Row>
					<Row className='p-2'>
						<Col>
							<Label className='text-body-large-bold text-secondary-default'>Contraseña</Label>
							<InputGroup
								className='input-group-alternative mb-2 form-group'
								size='sm'
							>
								<Input
									placeholder='xxxxxxxxxxxxx'
									type={showPassword ? 'text' : 'password'}
									name='password'
									onChange={formik.handleChange}
									value={formik.values.password}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePassword}>
											{showPassword ? (
												<EyeOffIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											) : (
												<EyeIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors.password && formik.touched.password && (
								<span className='text-link-regular text-error'>{formik.errors.password}</span>
							)}
						</Col>
						<Col>
							<Label className='text-body-large-bold text-secondary-default'>Repetir contraseña</Label>
							<InputGroup
								className='input-group-alternative mb-2 form-group'
								size='sm'
							>
								<Input
									placeholder='xxxxxxxxxxxxx'
									type={showPasswordClone ? 'text' : 'password'}
									name='passwordRepeat'
									onChange={formik.handleChange}
									value={formik.values.passwordRepeat}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePasswordClone}>
											{showPasswordClone ? (
												<EyeOffIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											) : (
												<EyeIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors.passwordRepeat && formik.touched.passwordRepeat && (
								<span className='text-link-regular text-error'>{formik.errors.passwordRepeat}</span>
							)}
						</Col>
					</Row>
					<Row className='p-2 m-0 align-items-center'>
						<Button
							className='btn btn-noti-primary py-1 px-5'
							type='submit'
							disabled={formik.isSubmitting}
						>
							<span
								className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
								role='status'
								aria-hidden='true'
							/>
							<span className='text-button'>Actualizar</span>
						</Button>
						<Col>
							{showSuccess && (
								<CustomMessage
									className='m-0'
									icon={
										<CheckIcon
											size={18}
											color={STATUS_COLORS.success}
										/>
									}
									message={'¡Datos actualizados correctamente!'}
								/>
							)}
							{showError && (
								<CustomMessage
									icon={
										<CrossIcon
											size={18}
											color={STATUS_COLORS.error}
										/>
									}
									message={'¡Ha ocurrido un error al actualizar los datos!'}
								/>
							)}
						</Col>
					</Row>
				</Form>
				<CustomModal
					title={'Cambiar foto de perfil'}
					isOpen={isOpen}
					toggle={() => setIsOpen(!isOpen)}
					className='w-50'
				>
					<ModalContainer setIsOpen={setIsOpen} />
				</CustomModal>
			</CardBody>
		</CardAccordion>
	);
};

UserDataForm.propTypes = {};

export default UserDataForm;
