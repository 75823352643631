import React, { useContext, useMemo, useState } from 'react';
import global from '../../../../../context/global';
import { SynchronizeContext } from 'context/synchronize';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, InputGroup, InputGroupText, Label, Row, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import DynamicTable from '../../../DynamicTable';
import PropTypes from 'prop-types';
import { captchaModal } from '../../../../CaptchaModal/CaptchaModal';
import { scrapperService } from '../../../../../services/scrapperService';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import { SYNC_STATUS } from '../../../../../helpers/consts';
import { noResultCaseModal } from 'views/ExpedienteDetails/components/NoResultCaseModal/NoResultCaseModal';

const AddCaseFlow = () => {
	const {
		state: { jurisdictions },
	} = useContext(global);

	const addCaseSchema = Yup.object().shape({
		jurisdiction: Yup.string().required('Campo requerido.'),
		circumscription: Yup.string().required('Debe seleccionar una circunscripción'),
		organism: Yup.string().required('Requerido'),
		number: Yup.number().required('N° de expediente requerido'),
		year: Yup.number().required('Año requerido'),
	});

	const initialValues = {
		jurisdiction: '',
		circumscription: '',
		organism: '',
		number: '',
		year: '',
	};

	const formik = useFormik({
		initialValues,
		validationSchema: addCaseSchema,
		onSubmit: async (values, { resetForm }) => {
			await handleSearchExpedient(values, resetForm);
		},
	});

	const {
		state: { status, expedientsToAdd },
		actions: { setExpedientsToAdd },
	} = useContext(SynchronizeContext);

	const [circumscriptions, setCircumscriptions] = useState([]);
	const [isLoadingCircumscriptions, setIsLoadingCircumscriptions] = useState(false);
	const [organisms, setOrganisms] = useState([]);
	const [isLoadingOrganisms, setIsLoadingOrganisms] = useState(false);
	const [isResolvingCaptcha, setIsResolvingCaptcha] = useState('');
	const [showSearchResults, setShowSearchResults] = useState(false);

	const resetFormulario = (resetFormikForm) => {
		setIsResolvingCaptcha('');
		setShowSearchResults(false);
		setExpedientsToAdd([]);
		setCircumscriptions([]);
		setIsLoadingCircumscriptions(false);
		setIsLoadingOrganisms(false);
		setOrganisms([]);
		resetFormikForm ? resetFormikForm() : null;
	};

	const StepJurisdiction = () => {
		const handleStartAddCase = async (jurisdiction) => {
			//AddCase only works for DTIOL.
			if (status === SYNC_STATUS.SYNCING) {
				await formik.setFieldTouched('jurisdiction', true);
				formik.setFieldError('jurisdiction', 'Acción interrumpida. Sincronización en proceso');
				return;
			}
			if (jurisdiction === 'ckao0m7jb0002qr2d01dfqdsg') {
				try {
					const { result: captchaImage } = await scrapperService.getDtiolCaptcha();
					const resolvedCaptcha = await captchaModal({ captchaImage });
					if (resolvedCaptcha) {
						setIsLoadingCircumscriptions(true);
						const { data: circumscription } = await scrapperService.getDTIOLCircumscription({
							captcha: resolvedCaptcha,
						});
						setCircumscriptions(circumscription);
						setIsLoadingCircumscriptions(false);
						setIsResolvingCaptcha('Resolved');
					}
				} catch {
					resetFormulario(formik.resetForm);
					//console.log(e)
				}
			} else {
				formik.setFieldError('jurisdiction', 'La jurisdicción seleccionada no admite la carga de expedientes.');
			}
		};

		return (
			<>
				<Col
					xs='3'
					style={{ maxWidth: 300, width: '100%' }}
					className='mt-3'
				>
					<Label className='text-body-large-bold text-secondary-default'>Jurisdicción</Label>
					<InputGroup className='input-group-alternative'>
						<Input
							name='jurisdiction'
							type='select'
							value={formik.values.jurisdiction}
							onChange={async (e) => {
								formik.handleChange(e);
								await handleStartAddCase(e.target.value);
							}}
							disabled={!(isResolvingCaptcha === '')}
							bsSize={'sm'}
						>
							<>
								<option
									key={-1}
									value=''
								>
									Seleccioná una jurisdicción
								</option>
								{jurisdictions.map(({ id, name }, index) => {
									if (id === 'ckao0m7jb0002qr2d01dfqdsg') {
										return (
											<option
												key={index}
												value={id}
											>
												{name}
											</option>
										);
									}
								})}
							</>
						</Input>
					</InputGroup>
				</Col>
			</>
		);
	};

	const StepCircumscription = () => {
		const handleCircumscriptionChange = async (value) => {
			setIsLoadingOrganisms(true);
			if (value !== '') {
				try {
					const response = await scrapperService.getDTIOLOrganism({
						option: value,
					});
					setIsLoadingOrganisms(false);
					setOrganisms(response);
				} catch (e) {
					resetFormulario(formik.resetForm);
				}
			}
		};

		return (
			<>
				<Col
					xs='3'
					style={{ maxWidth: 300, width: '100%' }}
					className='mt-3'
				>
					<Label className='text-body-large-bold text-secondary-default'>Circunscripción</Label>
					<InputGroup className='input-group-alternative'>
						<Input
							type='select'
							name='circumscription'
							id='circumscription'
							value={formik.values.circumscription}
							disabled={isResolvingCaptcha !== 'Resolved' || showSearchResults || isLoadingCircumscriptions || circumscriptions.length < 1}
							onChange={(event) => {
								setIsLoadingOrganisms(true);
								formik.handleChange(event);
								handleCircumscriptionChange(event.target.value)
									.then(() => setIsLoadingOrganisms(false))
									.catch(() => {
										setIsLoadingOrganisms(false);
										setIsLoadingCircumscriptions(false);
									});
							}}
							bsSize='sm'
						>
							{
								<>
									<option
										key='-1'
										value=''
									>
										{isLoadingCircumscriptions ? 'Cargando...' : 'Seleccioná una circunscripción'}
									</option>
									{circumscriptions.map(({ value, option }, index) => (
										<option
											key={index}
											value={value}
										>
											{option}
										</option>
									))}
								</>
							}
						</Input>
					</InputGroup>
					{formik.errors.circumscription && formik.touched.circumscription && (
						<div className='text-danger text-sm'>{formik.errors.circumscription}</div>
					)}
				</Col>
			</>
		);
	};

	const StepOrganismo = () => {
		return (
			<Col
				xs='3'
				style={{ maxWidth: 300, width: '100%' }}
				className='mt-3'
			>
				<Label
					className='text-body-large-bold text-secondary-default'
					htmlFor='organismo'
				>
					Organismo
				</Label>
				<InputGroup className='input-group-alternative'>
					<Input
						type='select'
						name='organism'
						id='organism'
						value={formik.values.organism}
						onChange={formik.handleChange}
						disabled={isResolvingCaptcha !== 'Resolved' || showSearchResults || isLoadingOrganisms || organisms.length < 1}
						bsSize='sm'
					>
						<>
							<option
								key='-1'
								value=''
							>
								{isLoadingOrganisms ? 'Cargando...' : 'Seleccioná un organismo'}
							</option>
							{organisms.map(({ option, value }, index) => (
								<option
									key={index}
									value={value}
								>
									{option}
								</option>
							))}
						</>
					</Input>
				</InputGroup>
				{formik.errors.organism && formik.touched.organism && <div className='text-danger text-sm'>{formik.errors.organism}</div>}
			</Col>
		);
	};

	const PreviewSearchResult = () => {
		const searchResultsAddCase = useMemo(
			() => [
				{
					Header: 'Jurisdicción',
					accessor: ({ Jurisdiction }) => <span>{Jurisdiction.name}</span>,
					maxWidth: 200,
				},
				{
					Header: 'Circunscripción',
					accessor: ({ OrganismList }) => <span>{OrganismList[0]?.Organism?.Circumscription?.name}</span>,
				},
				{
					Header: 'Organismo',
					accessor: ({ OrganismList }) => <span>{OrganismList[0]?.Organism?.name}</span>,
				},
				{
					Header: 'Expediente',
					maxWidth: 100,
					accessor: ({ number, year }) => (
						<span className='text-truncate'>
							{number} / {year}
						</span>
					),
				},
				{
					Header: 'Carátula',
					accessor: ({ coverPage }) => <span className='text-truncate'>{coverPage}</span>,
					maxWidth: 300,
				},
			],
			[]
		);

		return (
			<Card
				xs='12'
				className='w-100 mt-1'
			>
				<CardHeader>Resultados de la búsqueda:</CardHeader>
				<CardBody>
					<DynamicTable
						data={expedientsToAdd}
						columns={searchResultsAddCase}
						propertyId='number'
						maxColumnWidth='600'
						linkTo='expedientes/preview/'
					/>
				</CardBody>
			</Card>
		);
	};

	const handleSearchExpedient = async (values, resetForm) => {
		try {
			const response = await scrapperService.getDTIOLSearchForAddExpediente({
				option: values.organism,
				expNumber: values.number,
				expYear: values.year,
			});
			const jurisdiccionName = jurisdictions.find((jur) => jur.id === values.jurisdiction)?.name ?? '';
			const circumscriptionName = circumscriptions.find((circ) => circ.value === values.circumscription)?.option ?? '';
			const organismName = organisms.find((org) => org.value === values.organism)?.option ?? '';

			setExpedientsToAdd({
				expedients: [
					{
						year: response[0]?.anio,
						number: response[0]?.numero,
						coverPage: response[0]?.caratula,
						Jurisdiction: { name: jurisdiccionName },
						OrganismList: [
							{
								Organism: {
									Circumscription: { name: circumscriptionName },
									name: organismName,
								},
							},
						],
					},
				],
			});
			setShowSearchResults(true);
		} catch (error) {
			if (error.message === 'NoResults: No results found for the given search') await noResultCaseModal();
			resetFormulario(resetForm);
		}
	};

	return (
		<Col className='pt-2'>
			<Row>
				<Form
					className='w-100'
					onSubmit={formik.handleSubmit}
				>
					<Row xs={8}>
						<StepJurisdiction />
						<StepCircumscription />
						<StepOrganismo />
						<Col
							xs='3'
							style={{ maxWidth: 300, width: '100%' }}
							className='mt-3'
						>
							<Label
								className='text-body-large-bold text-secondary-default'
								htmlFor='nro'
							>
								Expediente
							</Label>
							<InputGroup
								className='input-group-alternative'
								size='sm'
								style={isResolvingCaptcha !== 'Resolved' ? { backgroundColor: '#e9ecef' } : {}}
							>
								<Input
									className='text-right'
									name='number'
									id='number'
									placeholder='Número'
									type='text'
									onChange={formik.handleChange}
									value={formik.values.number}
									disabled={isResolvingCaptcha !== 'Resolved' || showSearchResults}
								/>

								<InputGroupText
									style={isResolvingCaptcha !== 'Resolved' ? { backgroundColor: '#e9ecef' } : {}}
									className='py-0'
								>
									/
								</InputGroupText>

								<Input
									className='text-left'
									name='year'
									placeholder='Año'
									type='text'
									onChange={formik.handleChange}
									value={formik.values.year}
									disabled={isResolvingCaptcha !== 'Resolved' || showSearchResults}
								/>
							</InputGroup>
							{((formik.errors.number && formik.touched.number) || (formik.errors.year && formik.touched.year)) && (
								<div className='text-danger text-sm'>{formik.errors.number || formik.errors.year}</div>
							)}
						</Col>
						<Col
							xs='12'
							className='mt-3'
							style={{ width: '100%' }}
						>
							<Row className='w-100% align-items-center pl-3'>
								<Button
									type='submit'
									size='md'
									className='btn btn-noti-primary py-2 px-5'
									disabled={!formik.isValid || isResolvingCaptcha !== 'Resolved' || formik.isSubmitting || showSearchResults}
								>
									<Row className='align-items-center m-0'>
										{formik.isSubmitting ? (
											<Spinner
												size='sm'
												color='secondary'
												className='mr-2'
											/>
										) : (
											<FaPlus />
										)}
										<span className='text-button'>Agregar</span>
									</Row>
								</Button>
								<Button
									onClick={() => resetFormulario(formik.resetForm)}
									color='link'
									style={{
										paddingLeft: 0,
									}}
								>
									<Row className='align-items-center m-0'>
										<CloseIcon
											width={24}
											height={24}
											className='stroke-info'
										/>
										<span className='text-link-regular'>Limpiar campos</span>
									</Row>
								</Button>
								{formik.errors.jurisdiction && formik.touched.jurisdiction && (
									<span className='text-link-regular text-error'>{formik.errors.jurisdiction}</span>
								)}
							</Row>
						</Col>
					</Row>
				</Form>
			</Row>
			{showSearchResults ? (
				<Row className='justify-content-center'>
					<PreviewSearchResult />
				</Row>
			) : null}
		</Col>
	);
};

export default AddCaseFlow;

AddCaseFlow.propTypes = {
	closeTab: PropTypes.func,
};
