function passwordStrength(pwd) {
	const mediumRegex = new RegExp(
		'^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
		'g'
	);
	const strongRegex = new RegExp(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
	);
	const enoughRegex = new RegExp('(?=.{6,}).*', 'g');

	if (pwd.length === 0) {
		return;
	} else if (false === enoughRegex.test(pwd)) {
		return { color: 'text-danger', text: 'Débil' };
	} else if (mediumRegex.test(pwd)) {
		return { color: 'text-info', text: 'Media' };
	} else if (strongRegex.test(pwd)) {
		return { color: 'text-success', text: 'Fuerte' };
	} else {
		return { color: 'text-info', text: 'Media' };
	}
}

export default passwordStrength;
