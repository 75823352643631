import React from 'react';
import { useFormik } from 'formik';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { collaboratorService } from 'services/collaboratorService';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/Modals/CustomModal/CustomModal';

function DeleteCollaboratorModal({
	collaborator,
	deleteModalIsOpen,
	setDeleteModalIsOpen,
	mutate,
  setCollaboratorErrorMessage,
  setCollaboratorSuccessMessage,
}) {
	const formik = useFormik({
		enableReinitialize: true,
		validate: () => {}, // Add validator
	});

	function handleDeleteCollaborator(e) {
		e.preventDefault();
		collaboratorService
			.remove(collaborator.id)
			.then(() => {
				mutate();
				setDeleteModalIsOpen(false);
        setCollaboratorSuccessMessage('¡Colaborador eliminado!');
			})
			.catch(() => {
        setCollaboratorErrorMessage('No se pudo eliminar el colaborador');
      });
	}

	return (
		<CustomModal
			title='Eliminar colaborador'
			isOpen={deleteModalIsOpen}
			toggle={() => setDeleteModalIsOpen(false)}
			className='w-50'
		>
			<Form
				role='form'
				onSubmit={handleDeleteCollaborator}
			>
				<ModalBody>
					<p className='text-heading-2'>
						¿Estás seguro que querés eliminar al colaborador?
					</p>
					<p className='text-body-large'>
						Si eliminás al colaborador, perderás su configuración y no podrás
						acceder a su cuenta.
					</p>
				</ModalBody>
				<ModalFooter className='justify-content-start'>
					<Button
						className='btn btn-outline-noti-secondary py-1 px-5'
						data-dismiss='modal'
						type='button'
						onClick={() => setDeleteModalIsOpen(false)}
					>
						Cancelar
					</Button>
					<Button
						className='btn btn-noti-primary py-1 px-5'
						type='submit'
						disabled={formik.isSubmitting}
					>
						<span
							className={`spinner-border spinner-border-sm mr-3 ${
								formik.isSubmitting ? '' : 'd-none'
							}`}
							role='status'
							aria-hidden='true'
						></span>
						<span className='text-button'>Aceptar</span>
					</Button>
				</ModalFooter>
			</Form>
		</CustomModal>
	);
}

export default DeleteCollaboratorModal;

DeleteCollaboratorModal.propTypes = {
	collaborator: PropTypes.object,
	deleteModalIsOpen: PropTypes.bool,
	setDeleteModalIsOpen: PropTypes.func,
	mutate: PropTypes.func,
  setCollaboratorErrorMessage: PropTypes.func,
  setCollaboratorSuccessMessage: PropTypes.func,
};
