import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { paymentsService } from '../services/paymentsService';
import { connectionsService } from '../services/connectionsService';
import { subscriptionStatus } from '../helpers/services';
import { SERVICES_STATUS } from '../helpers/consts';

const initialState = {
	activeJurisdictions: [],
	activeServices: [],
};

const AdminContext = createContext(initialState);

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_ACTIVE_JURISDICTIONS':
			return {
				...state,
				activeJurisdictions: action.payload,
			};
		case 'SET_ACTIVE_SERVICES':
			return {
				...state,
				activeServices: action.payload,
			};
		default:
			return state;
	}
};

const AdminProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, undefined);

	const { data: userServices } = paymentsService.useUserServicesTable();
	const { data: activeConnections } = connectionsService.useConnectionsTable();

	function setActiveJurisdictions(activeJurisdictions) {
		dispatch({
			type: 'SET_ACTIVE_JURISDICTIONS',
			payload: activeJurisdictions,
		});
	}
	const setActiveServices = (userServices) => {
		dispatch({ type: 'SET_ACTIVE_SERVICES', payload: userServices });
	};

	useEffect(() => {
		const activeService = userServices?.services?.filter(
			(userService) =>
				subscriptionStatus(userService) === SERVICES_STATUS.ACTIVE
		);
		setActiveServices(activeService);
	}, [userServices]);

	useEffect(() => {
		setActiveJurisdictions([
			...(activeConnections?.map((connection) => connection.jurisdiction) ??
				[]),
		]);
	}, [activeConnections]);

	return (
		<AdminContext.Provider value={{ state }}>{children}</AdminContext.Provider>
	);
};
export { AdminProvider as Provider, AdminContext as default };

AdminProvider.propTypes = {
	children: PropTypes.object,
};
