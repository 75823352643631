import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

import VTlist from './VTlist';
import VTcontent from './VTcontent';

import PropTypes from 'prop-types';

function VerticalTab({ data }) {
	const [activeTabId, setActiveTabId] = useState(0);

	function btnClick(id) {
		setActiveTabId(id);
	}

	return (
		<div className='landing-card-body-features p-4'>
				<div className='justify-content-between d-flex flex-column list'>
					{data.map((job, index) => (
						<VTlist
							key={index}
							onClick={btnClick}
							data={job}
							index={index}
							activeTabId={activeTabId}
						/>
					))}
				</div>
				<div className='content-info'>
					{data.map((job, index) => (
						<VTcontent
							data={job}
							key={index}
							index={index}
							activeTabId={activeTabId}
						/>
					))}
				</div>
			<span
				className={
					activeTabId === 0
						? 'index1-chosen'
						: activeTabId === 1
						? 'index2-chosen'
						: 'index3-chosen'
				}
			>
				&nbsp;
			</span>
		</div>
	);
}

VerticalTab.propTypes = {
	data: PropTypes.array,
};

export default VerticalTab;
