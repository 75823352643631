import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {};

const CollaboratorContext = createContext(initialState);

const reducer = (state, action) => {
	switch (action.type) {
		case 'COLLABORATOR_LOGGEDIN':
			return {
				...state,
				admin: action.payload,
			};
		case 'COLLABORATOR_LOGGEDOUT':
			return {
				...state,
				admin: {},
			};
		default:
			return state;
	}
};

const CollaboratorProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, undefined);

	const setAdmin = (data) => {
		dispatch({ type: 'COLLABORATOR_LOGGEDIN', payload: data });
	};

	const logout = () => {
		dispatch({ type: 'COLLABORATOR_LOGGEDOUT' });
	};

	return (
		<CollaboratorContext.Provider value={{ state }}>
			{children}
		</CollaboratorContext.Provider>
	);
};

export { CollaboratorProvider as Provider, CollaboratorContext as default };

CollaboratorProvider.propTypes = {
	children: PropTypes.object,
};
