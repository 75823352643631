import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import CrossIcon from '../Icons/CrossIcon';
import UserIcon from 'components/Icons/UserIcon';

const ServiceExpiredMessage = ({ title, message, to, buttonLabel }) => {
	const [show, setShow] = useState(
		window.sessionStorage.getItem('serviceExpiredMessage') !== 'false'
	);

	const handleOnClose = () => {
		window.sessionStorage.setItem('serviceExpiredMessage', 'false');
		setShow(false);
	};

	return show ? (
		<Card className='shadow-card mb-3 border-info my-2'>
			<Row className='justify-content-between p-5 px-3 px-lg-7'>
				<Col>
					<Row className='m-0'>
						<Col>
							<h3 className='text-body-large-bold text-error'>{title}</h3>
							{message ? (
								<h5 className='text-body-regular'>{message}</h5>
							) : null}
						</Col>
						{to ? (
							<Col className='p-1'>
								<Row className='justify-content-center'>
									<Button
										className='btn btn-noti-sync px-5'
										tag={Link}
										to={to}
									>
										<span>
											<UserIcon
												size={20}
												className='fill-secondary-default mr-2'
											/>
										</span>
										<span>{buttonLabel ? buttonLabel : 'Ir a la página'}</span>
									</Button>
								</Row>
							</Col>
						) : null}
					</Row>
				</Col>
				<span
					onClick={handleOnClose}
					style={{ cursor: 'pointer' }}
				>
					<CrossIcon
						size={24}
						className='stroke stroke-primary-default'
					/>
				</span>
			</Row>
		</Card>
	) : null;
};

ServiceExpiredMessage.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	to: PropTypes.string,
	buttonIcon: PropTypes.element,
	buttonLabel: PropTypes.string,
};

export default ServiceExpiredMessage;
