import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import 'moment/locale/es';
import { Container, Row, Col, Spinner } from 'reactstrap';
import HomeHeader from '../../components/Headers/HomeHeader';
import { casesService } from '../../services/casesService';
import CaseFileTabs from './components/CaseFileTabs';
import CaseFileHistoric from './components/CaseFileHistoric';
import user from 'context/user';
import CaseFileConfiguration from './components/CaseFileConfiguration';
import { LAYOUTS } from '../../helpers/consts';
import useBreakpoints from '../../hooks/useBreakpoints';

const CaseFileHistory = (props) => {
	let { id } = useParams();
	const {
		state: { currentUser },
	} = useContext(user);

	const { isMobile } = useBreakpoints();

	const { data: caseInfo, error, isLoading, mutate } = casesService.useCaseDetails(id, currentUser?.owner);

	return (
		<>
			<HomeHeader
				{...props}
				headerText='Detalle de Expediente'
				canGoBack
			/>
			<Container
				className='bg-gradient-white'
				fluid={true}
			>
				<Row>
					<Col xl='12'>
						{isLoading ? (
							<Row className='justify-content-center d-flex'>
								<Spinner
									color='default'
									className='m-3'
								></Spinner>
							</Row>
						) : (
							<CaseFileTabs
								caseInfo={{
									...caseInfo?.Expediente,
									collaborator: caseInfo?.Colaborador,
									fechaCaducidad: caseInfo?.caducityDate,
									fechaAlerta: caseInfo?.alertDate,
									deleted: caseInfo?.deleted,
								}}
								mutate={mutate}
								isLoading={isLoading}
								isError={!!error}
								autoSync
								{...props}
								isMobile={isMobile}
							/>
						)}
					</Col>
				</Row>
				<Row>
					<Col xl='12'>
						<CaseFileConfiguration
							caseInfo={caseInfo}
							mutate={mutate}
							canEdit
							isMobile={isMobile}
						/>
					</Col>
				</Row>
				{isLoading ? (
					<></>
				) : (
					<Row className='mb-3'>
						<Col xl='12'>
							<CaseFileHistoric
								caseInfo={caseInfo}
								layout={LAYOUTS.ADMIN}
                mutate={mutate}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</>
	);
};

export default CaseFileHistory;

CaseFileHistory.propTypes = {
	history: PropTypes.object,
};
