import React, { useContext } from 'react';
import { CardBody } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import global from 'context/global';

function TermsAndConditionsContent() {
/* 	const {
		state: { jurisdictions },
	} = useContext(global); */

	return (
		<CardBody>
			<h1 className='text-heading-3 text-secondary-default'>
				ÚLTIMA ACTUALIZACIÓN: 23/06/2023
			</h1>
			<ol className='text-heading-3 text-primary-default'>
				<li>Concepto de Notiexpress</li>
				<p className='text-body-regular'>
					Notiexpress es un servicio integrado en una plataforma web dirigida
					actualmente a personas con matrícula profesional en Derecho
					vigente en: 1) Provincia de Chaco en materia Civil y Comercial; 2) Capital Federal; y 3) Justicia Federal.
					{/* {jurisdictions?.filter(
						(jurisdiction) => jurisdiction?.name === 'Federal'
					).length > 0
						? ': 1) Provincia de Chaco en materia Civil y Comercial; 2) Capital Federal; y 3) Justicia Federal.'
						: ' la Provincia de Chaco en materia Civil y Comercial'} */}
					{' '}Tiene como principal finalidad, facilitarte el seguimiento,
					administración y procuración de tus expedientes judiciales y brindarte
					herramientas que permitan generar un mayor control sobre los mismos,
					ahorrándote en el proceso, valioso tiempo.
				</p>
				<li>Relación contractual</li>
				<p className='text-body-regular'>
					Los usuarios aceptan las cláusulas contenidas en estos Términos y
					Condiciones desde el momento en que se registran a través del
					formulario de subscripción de la plataforma o al utilizar alguno de
					los servicios que se ofrecen. Cuando debamos hacer cambios importantes
					en nuestros servicios, publicaremos las modificaciones con al menos
					veinte cuatro (24) horas de anticipación para que puedas aceptarlas y
					seguir utilizando el servicio de Notiexpress o rechazarlas y darte de
					baja.
				</p>
				<li>Registro y Cuenta</li>
				<p className='text-body-regular'>
					Si quieres usar nuestros servicios, deberás completar el formulario de
					registro con los datos básicos y necesarios que te sean requeridos. Al
					completarlo, te comprometés a hacerlo de manera exacta, precisa y
					verdadera, debiendo mantener los mismos siempre actualizados para
					otorgarte la mejor experiencia de servicio posible. Podremos
					solicitarte y/o consultarte información adicional para corroborar tu
					identidad en cualquier momento para mantener la calidad y brindar la
					seguridad que se requiere en la plataforma.
				</p>
				<p className='text-body-regular'>
					El usuario y contraseña que crees tiene carácter personal, único,
					intransferible y confidencial, es decir que bajo ningún concepto
					podrás vender, ceder o compartir a otra persona las credenciales que
					permiten el acceso a tu cuenta.
				</p>
				<p className='text-body-regular'>
					Podremos rechazar una solicitud de registro o bien cancelar un
					registro ya aceptado, sin que esto genere derecho a un resarcimiento,
					cuando se haya detectado el incumplimiento de las cláusulas contenidas
					en estos Términos y Condiciones y/o en la{' '}
					<Link to='politica-de-privacidad'>Política de Privacidad</Link> de la
					plataforma.
				</p>
				<p className='text-body-regular'>
					No podrán registrarse nuevamente los usuarios que hayan sido
					inhabilitados previamente.
				</p>
				<li>Período de prueba</li>
				<p className='text-body-regular'>
					Al registrarte por primera vez como usuario de Notiexpress podrás
					disfrutar de todos los servicios con los cuales cuenta la plataforma
					de manera gratuita y por un plazo de prueba de treinta (30) días
					corridos. Vencido que sea dicho plazo, deberás abonar el costo de la
					suscripción de tu preferencia para continuar disfrutando de nuestros
					servicios.
				</p>
				<p className='text-body-regular'>
					Toda carga de información previa que hayas hecho y/o configuración que
					hayas establecido durante el período de prueba en la plataforma,
					seguirá vigente así que no te preocupes.
				</p>
				<li>Costos</li>
				<p className='text-body-regular'>
					Notiexpress te cobrará un precio determinado por el uso de sus
					servicios. Cada suscripción tendrá una vigencia de treinta (30) días.
					Vencido que se encuentre dicho plazo, deberás renovarla para continuar
					utilizando la misma.
				</p>
				<p className='text-body-regular'>
					Podremos modificar los costos de los servicios que brindemos en
					cualquier momento, notificándote sobre tal cambio veinticuatro (24)
					horas antes de llevarlo a cabo.
				</p>
				<p className='text-body-regular'>
					Los costos de los servicios se actualizarán conforme el Índice de
					Precios al Consumidor (IPC) que emite el INDEC de manera trimestral
					durante el año en curso.
				</p>
				<p className='text-body-regular'>
					De la misma manera, podremos modificar dichos costos por promociones
					y/o actualizaciones y/o aumento de los costos operativos de la
					plataforma, notificándote sobre tal cambio al menos veinticuatro (24)
					horas antes de llevarlo a cabo.
				</p>
				<p className='text-body-regular'>
					Notiexpress únicamente autoriza a realizar los pagos mediante los
					medios bancarios habilitados en la plataforma. No se aceptará ningún
					tipo de pago que no sea por los medios establecidos y/o autorizados.
				</p>
				<p className='text-body-regular'>
					Como usuario, eres el único responsable por los pagos que se realicen
					en tu cuenta o a tu nombre. En caso de detectar un uso no autorizado
					de la misma, debés notificar de forma inmediata y fehaciente a
					Notiexpress a través de la sección de “Contacto” o a través de nuestro
					e-mail{' '}
					<a href='mailto:contacto@notiexpress.net'>contacto@notiexpress.net</a>{' '}
					para que evaluemos el hecho y darte una respuesta a la mayor brevedad
					posible.
				</p>
				<li>Responsabilidad</li>
				<p className='text-body-regular'>
					Notiexpress no será responsable por los problemas externos que
					comprometan el normal desarrollo de la prestación de los servicios
					ofrecidos en la plataforma salvo que estos le sean imputables conforme
					el alcance previsto en las leyes vigentes.
				</p>
				<p className='text-body-regular'>
					Tampoco se será responsable si detectas algún inconveniente y/o mal
					funcionamiento en los servicios que te ofrecemos y no nos comunicas de
					ello a través de la sección de “Contacto” o a través del e-mail{' '}
					<a href='mailto:contacto@notiexpress.net'>contacto@notiexpress.net</a>{' '}
					.
				</p>
				<p className='text-body-regular'>
					Todo lo relacionado a la protección de tu información personal y los
					datos que nos aportes se regirá por lo establecido expresamente en
					nuestra{' '}
					<Link to='politica-de-privacidad'>Política de Privacidad</Link>{' '}
				</p>
				<li>Requisitos para el uso correcto de la plataforma</li>
				<p className='text-body-regular'>
					Para poder disfrutar de todos los servicios de Notiexpress, debés
					cumplir con los siguientes requisitos mínimos recomendados:
				</p>
				<ol>
					<li className='text-body-regular'>
						Computadora y/o Tablet y/o Teléfono Celular con capacidad web e
						Internet.
					</li>
					<li className='text-body-regular'>
						Última versión disponible del navegador Chrome y/o Safari y/o
						Firefox y/o explorador compatible.
					</li>
				</ol>
				<br />
				<li>Derechos y obligaciones del usuario</li>
				<ol
					type='A'
					className='text-body-large-bold'
				>
					<li>Derechos:</li>
					<ol className='text-body-regular'>
						<li>Acceder a la plataforma con usuario y contraseña</li>
						<li>
							Visualizar los expedientes judiciales que el usuario seleccione y
							que se encuentren habilitados a tal efecto.
						</li>
						<li>
							Realizar todos los reclamos y/o recomendar mejoras mediante la
							sección de “Contacto” y/o a través del e-mail{' '}
							<a href='mailto:contacto@notiexpress.net'>
								contacto@notiexpress.net
							</a>{' '}
						</li>
						<li>
							Hacer los reclamos pertinentes en las autoridades que
							correspondan.
						</li>
					</ol>
					<li>Obligaciones:</li>
					<ol className='text-body-regular'>
						<li>
							Introducir los datos correctos y verídicos en todos los
							formularios de la plataforma.
						</li>
						<li>
							Realizar los pagos de las suscripciones seleccionadas en debido
							tiempo y forma, cuando se aplique.
						</li>
						<li>
							Utilizar el usuario que le corresponda por el perfil que
							contraten, evitando suplantaciones de identidad.
						</li>
						<li>
							Utilizar la plataforma con la mayor diligencia y confidencialidad
							que se puede esperar de un ciudadano promedio.
						</li>
						<li>
							Cargar los días inhábiles a través de la sección de Configuración
							General de Expedientes de la plataforma a los fines de poder
							brindar la mayor exactitud posible a la función de control de
							caducidad de los expedientes judiciales cargados por el usuario.{' '}
							<br />
							Se hace saber que el cómputo de los días hábiles como de los días
							inhábiles es aproximado y orientativo. <br />
							Será responsabilidad exclusiva del usuario la confirmación del
							cómputo final correspondiente.
						</li>
						<li>
							Evitar la utilización de computadoras, tablets o soportes donde se
							pueda visualizar la plataforma que se encuentre dañada o con
							brechas de seguridad que puedan llegar a perjudicar a la misma.
						</li>
					</ol>
				</ol>
				<br />
				<li>Derechos y obligaciones de la plataforma</li>
				<ol
					type='A'
					className='text-body-large-bold'
				>
					<li>Derechos:</li>
					<ol className='text-body-regular'>
						<li>
							Reclamar los pagos a los usuarios, en los casos que se aplique.
						</li>
						<li>
							Eliminar, suspender o privar de acceso a todo usuario que no
							cumpla con los Términos y Condiciones descritos.
						</li>
						<li>
							Exigir a todos los proveedores, terceros y/o usuarios que respeten
							todos los derechos reconocidos por Notiexpress.
						</li>
					</ol>
					<li>Obligaciones:</li>
					<ol className='text-body-regular'>
						<li>
							Proporcionar el correcto funcionamiento de los servicios ofrecidos
							por Notiexpress conforme lo debidamente pactado en sus Términos y
							Condiciones.
						</li>
						<li>
							Realizar tareas de mantenimiento cuando sea necesario, debiendo
							notificar previamente a los usuarios de las mismas.
						</li>
						<li>
							Actualizar la plataforma y los textos legales según corresponda.
						</li>
						<li>
							Evitar y supervisar la eventual existencia de brechas de seguridad
							en la plataforma.
						</li>
						<li>
							Contar con los documentos necesarios con los proveedores y
							terceros que trabajan con Notiexpress.
						</li>
					</ol>
				</ol>
				<br />
				<li>Propiedad de la plataforma</li>
				<p className='text-body-regular'>
					Notiexpress es propiedad de Carlos Alberto Díaz, CUIT 20-30791958-4,
					con todos los derechos de propiedad intelectual y/o industrial
					existentes sobre la plataforma, sitios, contenido, servicios, marcas,
					nombres comerciales, logos, diseños, imágenes, frases publicitarias,
					derechos de autor, dominios, programas, códigos, desarrollos,
					software, información, tecnología, patentes y modelos de utilidad,
					diseños y modelos industriales, secretos comerciales, entre otros, los
					cuales se encuentran protegidos por leyes nacionales e
					internacionales.
				</p>
				<p className='text-body-regular'>
					La plataforma te otorga permiso para usar sus servicios conforme lo
					previsto en sus Términos y Condiciones, esto no implica una
					autorización para otros tipos de usos o modificaciones sobre toda o
					parte de su propiedad, excepto consentimiento previo y expreso de su
					propietario. En cualquier caso, todo usuario que utilice o tenga
					acceso de alguna manera a nuestros servicios no podrán utilizar la
					propiedad de Notiexpress de una manera que cause confusión en el
					público, debiendo llevar a cabo su actividad comercial bajo una marca
					o nombre comercial propio y distintivo, que no resulte confundible con
					la marca Notiexpress.
				</p>
				<p className='text-body-regular'>
					Está prohibido usar nuestros productos o servicios para fines
					ilegales, realizar cualquier tipo de ingeniería inversa u obras
					derivadas, utilizar herramientas de búsqueda o de extracción de datos
					para su reutilización y/o creación de bases de datos propias que
					incluyan en todo o en parte el contenido que ofrecemos sin nuestra
					expresa autorización. Está también prohibido el uso indebido, sin
					autorización y/o contrario a la normativa vigente y/o que genere
					confusión o implique uso denigratorio y/o que le cause perjuicio,
					daños o pérdidas a Notiexpress. La utilización de los servicios de la
					plataforma tampoco implica la autorización para usar propiedad de
					terceros que pueda estar involucrada, cuyo uso quedará bajo exclusiva
					responsabilidad del usuario.
				</p>
				<p className='text-body-regular'>
					En caso que un usuario infrinja la propiedad de Notiexpress o de
					terceros, podremos sancionar al usuario conforme a lo previsto en
					estos Términos y Condiciones sin perjuicio de interponer las acciones
					extrajudiciales y/o judiciales correspondientes.
				</p>
				<li>Sanciones</li>
				<p className='text-body-regular'>
					En el supuesto de que incumplas con los Términos y Condiciones de
					Notiexpress, podremos suspender, restringir o inhabilitar temporal o
					definitivamente tu cuenta.
				</p>
				<p className='text-body-regular'>
					En caso de que incurras en algún incumplimiento en la plataforma con
					tu usuario o los usuarios colaboradores que tengas habilitados a tu
					cargo, Notiexpress te informará de ello a través del e-mail que hayas
					registrado.
				</p>
				<li>Confidencialidad</li>
				<p className='text-body-regular'>
					Notiexpress será utilizada única y exclusivamente para los fines
					previstos en sus Términos y Condiciones.
				</p>
				<p className='text-body-regular'>
					Es responsabilidad exclusiva del usuario mantener la confidencialidad
					de la información que se visualice y/o logre el debido acceso en la
					plataforma aun con posterioridad a la finalización de la relación
					comercial.
				</p>
				<li>Seguridad. Medidas implementadas</li>
				<p className='text-body-regular'>
					Notiexpress cumple con la normativa aplicable y ha adoptado las
					medidas de seguridad recomendadas según los estándares internacionales
					de la industria para proteger tu información personal y la que nos
					brindes.
				</p>
				<oh className='text-body-regular'>
					La plataforma cumple con las siguientes medidas de seguridad:
				</oh>
				<ol className='text-body-regular'>
					<li>Hasheo de las credenciales de conexión.</li>
					<li>
						Encriptado de datos de los expedientes judiciales cargados por el
						usuario.
					</li>
					<li>Doble autenticación de identidad del usuario mediante e-mail.</li>
					<li>Encriptado de contraseñas contenidas en la plataforma.</li>
					<li>Certificación de sitio web seguro SSL.</li>
					<li>Implementación de navegación HTTPS.</li>
					<li>Protección web por Clickjacking.</li>
					<li>Política de Seguridad de Contenido (CSP).</li>
					<li>
						Sistema de protección de ataques de denegación de servicios y
						sobrecarga.
					</li>
				</ol>
				<p className='text-body-regular'>
					La presente cláusula se rige y complementa debidamente con la
					contenida en la{' '}
					<Link to='politica-de-privacidad'>Política de Privacidad</Link> de la
					plataforma.
				</p>
				<li>Indemnidad</li>
				<p className='text-body-regular'>
					El usuario mantendrá indemne a Notiexpress, así como a quienes
					dirigen, suceden, administran, representan y/o trabajan en la
					plataforma, por cualquier reclamo administrativo o judicial iniciado
					por otros usuarios, terceros o por cualquier Organismo y/o
					Institución, relacionado con sus actividades.
				</p>
				<p className='text-body-regular'>
					En virtud de esa responsabilidad, podrán realizar compensaciones,
					retenciones u otras medidas necesarias para la reparación de pérdidas,
					daños y perjuicios, cualquiera sea su naturaleza.
				</p>
				<li>Ley Aplicable y Jurisdicción</li>
				<p className='text-body-regular'>
					Estos Términos y Condiciones se rigen por la ley argentina. Toda
					controversia derivada de su aplicación, interpretación, ejecución o
					validez será resuelta por los tribunales ordinarios competentes, con
					asiento en la Ciudad de Resistencia, Provincia del Chaco, salvo
					disposición específica de normas de Orden Público, como por ejemplo,
					legislación relativa al Consumidor. Para todos los efectos
					relacionados con estos Términos y Condiciones y con el uso de la
					plataforma, Notiexpress establece su domicilio legal en Hipólito
					Irigoyen 352, Ciudad de Resistencia, Provincia del Chaco, Argentina.
				</p>
				<li>Relación con los demás textos legales</li>
				<p className='text-body-regular'>
					Todos los textos legales de Notiexpress se encuentran integrados en la
					misma plataforma. Podés visualizarlos en. cualquier momento ingresando
					en <Link to='aviso-legal'>Aviso legal</Link> y{' '}
					<Link to='politica-de-privacidad'>Política de Privacidad</Link>.
				</p>
			</ol>
		</CardBody>
	);
}

export default TermsAndConditionsContent;
