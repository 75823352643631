/* eslint-disable indent */
import React, { useState } from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';
import { useHistory } from 'react-router-dom';
import { collaboratorRoleService } from '../../services/collaboratorRoleService';
import useNewsTableColumns from './hooks/useNewsTableColumns';
import moment from 'moment/moment';
import queryString from 'query-string';
import useUpdateSearchParams from './hooks/useUpdateSearchParams';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';
import HomeHeader from '../../components/Headers/HomeHeader';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import TableOptionsButtons from '../../components/Commons/TableOptionsButtons/TableOptionsButtons';
import Pagination from '../../components/Commons/Pagination';
import NewsCaseMobileItem from './components/NewsCaseMobileItem';
import NewsTable from '../../components/Commons/NewsTable/NewsTable';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/notiexpress/calendar-icon.svg';
import { ReactComponent as ZapIcon } from 'assets/img/icons/notiexpress/zap-icon.svg';
import { LAYOUTS } from '../../helpers/consts';
import FolderIcon from '../../components/Icons/FolderIcon';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
import TooltipElement from '../../components/Commons/TooltipElement';
import CardResumeFilter from '../../components/Charts/CardResumeFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import useQueryParams from 'hooks/useQueryParams';

const NewsForCollaboratorView = () => {
	const { windowsSize } = useBreakpoint();
	const { isMobile } = useBreakpoints();

	const history = useLocation();
	const currentParams = queryString.parse(history.search);

	const [selectedDay, setSelectedDay] = useState(() => moment(currentParams?.fechaActuacion ?? moment().format('YYYY-MM-DD')).toDate());

	const { handleResetSearch, handleSetPage, params, handleSetFilter } = useQueryParams({
		page: currentParams?.page ?? 1,
		take: 10,
		fechaActuacion: currentParams?.fechaActuacion ?? moment().format('YYYY-MM-DD'),
	});

	const { currentColumns } = useNewsTableColumns();

	const columns = currentColumns
		.filter(({ Header }) => Header !== 'Responsable')
		.map((column) => {
			if (column.Header === 'Expediente') {
				return {
					...column,
					accessor: ({ number, year, id }) => (
						<div className='d-flex justify-content-between'>
							<span className='text-body-small mb-0'>{number + '/' + year}</span>
							<Link
								to={`/collaborator/expedientes/${id}`}
								className='text-body-small mb-0'
							>
								<div id={`tooltip-${id}`}>
									<FolderIcon
										size={20}
										className='stroke-primary-default'
									/>
								</div>
							</Link>
							<TooltipElement id={`tooltip-${id}`}>Ver expediente</TooltipElement>
						</div>
					),
				};
			} else {
				return column;
			}
		});

	const { data: newsList, isLoadingNewsList, isErrorNewsList, mutate } = collaboratorRoleService.getNovelties({ params: params });

	const {
		data: newsListCount,
		isLoading: isLoadingNewsListCount,
		mutate: mutateNewsListCount,
	} = collaboratorRoleService.getNoveltiesCount();

	/* 	const { handleDayClick, handleSetPage, handleResetSearch } = useUpdateSearchParams({
		selectedDay,
		setSelectedDay,
		rowsPerPage,
		setParams,
		history,
		params,
		layout: 'collaborator',
	}); */

	function handleDayClick(day) {
		handleSetFilter({ fechaActuacion: moment(day).format('YYYY-MM-DD') });
		setSelectedDay(moment(day).toDate());
	}

	return (
		<>
			{/* Header container */}
			<HomeHeader
				headerText='Novedades del día'
				className=''
			/>
			<Container
				className='bg-gradient-white'
				fluid
			>
				<Row className='m-0 mb-3'>
					<Col
						xs='12'
						className='p-0'
					>
						{!isMobile ? (
							<Row className={`m-0 ${windowsSize.width < 768 ? 'justify-content-center' : ''}`}>
								<Col
									className='pl-0 pr-1 d-flex flex-column justify-content-between'
									style={{
										maxWidth: '200px',
									}}
								>
									<FormGroup className={`${windowsSize.width < 768 ? 'w-100 py-3' : ''}`}>
										<InputGroup className='input-group-alternative'>
											<Input
												className='form-control'
												value={moment(selectedDay).format('DD/MM/YYYY')}
												type='text'
											/>
											<InputGroupAddon addonType='append'>
												<UncontrolledDropdown active>
													<DropdownToggle
														nav
														className='h-100 d-flex align-items-center'
													>
														<CalendarIcon
															width={20}
															height={20}
															className='stroke stroke-primary-default'
														/>
													</DropdownToggle>
													<DropdownMenu right>
														<DayPicker
															style={{ width: '100%' }}
															mode='single'
															selected={selectedDay}
															onSelect={handleDayClick}
															locale={es}
															weekStartsOn={0}
															disabled={{
																after: new Date(Date.now()),
															}}
															captionLayout='dropdown-buttons'
															fromYear={2010}
															toYear={2024}
														/>
													</DropdownMenu>
												</UncontrolledDropdown>
											</InputGroupAddon>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col
									style={{
										maxWidth: '300px',
									}}
								>
									<CardResumeFilter
										cardTitle='Total con'
										subtitle='Novedades'
										totalExp={newsListCount?.todayNewslettersExp ?? 0}
										date={new Date().toISOString().split('T')[0]}
										isLoading={isLoadingNewsListCount}
										handleFilter={handleDayClick}
									>
										<ZapIcon
											width={20}
											height={20}
											className='stroke-primary-default'
										/>
									</CardResumeFilter>
								</Col>
								<Col
									style={{
										maxWidth: '300px',
									}}
								>
									<CardResumeFilter
										totalExp={newsListCount?.totalYesterdayNovelties ?? 0}
										cardTitle='Total en fecha'
										subtitle={moment(selectedDay).subtract(1, 'days').format('DD/MM/YYYY') ?? '-'}
										date={moment(selectedDay).subtract(1, 'days').format('YYYY-MM-DD') ?? '-'}
										isLoading={isLoadingNewsListCount}
										handleFilter={handleDayClick}
									>
										<CalendarIcon
											width={20}
											height={20}
											className='stroke-primary-default'
										/>
									</CardResumeFilter>
								</Col>
							</Row>
						) : (
							<Row className='align-items-center  m-0 justify-content-center'>
								<FormGroup className='w-100 py-3'>
									<Label className='text-body-large-bold text-secondary-default'>Fecha</Label>
									<InputGroup className='input-group-alternative'>
										<Input
											className='form-control'
											value={moment(selectedDay).format('DD/MM/YYYY')}
											type='text'
											onChange={(e) => {
												setSelectedDay(e.target.value);
											}}
										/>
										<InputGroupAddon addonType='append'>
											<UncontrolledDropdown active>
												<DropdownToggle
													nav
													className='h-100 d-flex align-items-center'
												>
													<CalendarIcon
														width={20}
														height={20}
														className='stroke stroke-primary-default'
													/>
												</DropdownToggle>
												<DropdownMenu right>
													<DayPicker
														style={{ width: '100%' }}
														mode='single'
														defaultMonth={new Date(selectedDay)}
														selected={selectedDay}
														onSelect={handleDayClick}
														locale={es}
														weekStartsOn={0}
														disabled={{
															after: new Date(Date.now()),
														}}
													/>
												</DropdownMenu>
											</UncontrolledDropdown>
										</InputGroupAddon>
									</InputGroup>
									<span className='text-body-large'>Buscá tus expedientes por fecha</span>
								</FormGroup>
							</Row>
						)}
					</Col>
				</Row>
				<Row xs='3'>
					<Col>
						<Card className='shadow-card mb-3'>
							<CardHeader>
								<Row className='justify-content-between mb-5'>
									{!isMobile ? (
										<Pagination
											count={newsList?.totalCount ?? 0}
											page={newsList?.currentPage ?? 0}
											rowsPerPage={params?.take ?? 15}
											pageCount={newsList?.totalPage ?? 0}
											handleSetPage={handleSetPage}
											disabled={isErrorNewsList || isLoadingNewsList}
										/>
									) : (
										<div className='mt-5'>
											<Pagination
												count={newsList?.totalCount ?? 0}
												page={newsList?.currentPage ?? 0}
												rowsPerPage={params?.take ?? 15}
												pageCount={newsList?.totalPage ?? 0}
												handleSetPage={handleSetPage}
												disabled={isErrorNewsList || isLoadingNewsList}
											/>
										</div>
									)}
									<Col xs={12}>
										<TableOptionsButtons
											tableType='news'
											data={newsList?.paginateData ?? []}
											canAdd={false}
											resetSearch={handleResetSearch}
											layout={LAYOUTS.COLLABORATOR}
										></TableOptionsButtons>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								{windowsSize.width < 768 ? (
									!newsList?.paginateData?.length ? (
										<Row className='m-0 justify-content-center'>
											<span className='text-body-large-bold text-secondary-default'>No tenés ninguna novedad agregada.</span>
										</Row>
									) : (
										newsList?.paginateData?.map((news) => (
											<NewsCaseMobileItem
												expediente={news}
												key={news?.id}
											/>
										))
									)
								) : (
									<NewsTable
										data={newsList?.paginateData ?? []}
										columns={columns}
										propertyId='id'
										isLoading={isLoadingNewsList}
										isError={isErrorNewsList}
										noDataMessage='No tenés ninguna novedad agregada.'
										mutate={mutate}
									/>
								)}
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Novedades ({newsList?.totalCount ?? ''})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default NewsForCollaboratorView;
