import React from 'react';
import PropTypes from 'prop-types';

function VTcontent(props) {
	let data = props.data.expData;

	return (
		<div
			key={props.index}
			style={
				props.activeTabId === props.index
					? { display: 'block' }
					: { display: 'none' }
			}
		>
			<div className='d-flex align-items-around w-100'>
				<section className='info-section'>
					<h4 className='text-heading-1-secondary p-0 m-0'>{data.position}</h4>
					<h5 className='text-heading-3-blue-sm py-1 m-0'>{data.period}</h5>
          {data.details}
					{data.footer}
				</section>
				<section 
          className='hero-section mt-4' 
        >
          {data.hero}
        </section>
			</div>
		</div>
	);
}

VTcontent.propTypes = {
	data: PropTypes.object,
	index: PropTypes.number,
	activeTabId: PropTypes.number,
};

export default VTcontent;
