import React from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Col, Container, Row } from 'reactstrap';
import LegalAddressList from './components/LegalAddressList/LegalAddressList';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import UserDataForm from './components/UserDataForm/UserDataForm';

const ProfileConfiguration = () => {
	return (
		<>
			<HomeHeader
				headerText='Configuración de Perfil de Usuario'
				canGoBack
			/>
			<Container className='bg-gradient-white' fluid={true}>
				<Row>
					<Col>
						<UserDataForm />
						<InfoMessage
							title='El primer domicilio que agregues será tu domicilio legal principal.'
							message='Podés agregar y administrar todos los domicilios secundarios que necesites.'
							messageKey='legalAddressInfo'
						/>
						<LegalAddressList />
					</Col>
				</Row>
			</Container>
		</>
	);
};

ProfileConfiguration.propTypes = {};

export default ProfileConfiguration;
