import { API } from '../helpers/fetch';
import useSWR from 'swr';

function fetcher(url, params) {
	return API.get(url, params ? { params: params } : {});
}

const useOfficesByUserId = () => {
	const { data, error, mutate } = useSWR('/offices/user', fetcher);
	return {
		offices: data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
};

const createOffice = ({ name, address, locality, province, cp, phone }) => {
	return API.post('/offices/user', {
		...(name && { name }),
		address,
		locality,
		province,
		...(cp && { cp }),
		...(phone && { phone }),
	});
};

const updateOffice = ({
	id,
	name,
	address,
	locality,
	province,
	cp,
	phone,
	isPrimary,
}) => {
	return API.put(`/offices/user/${id}`, {
		isPrimary: isPrimary,
		Office: {
			...(name && { name }),
			address,
			locality,
			province,
			...(cp && { cp }),
			...(phone && { phone }),
		},
	});
};

const deleteOffice = ({ id }) => {
	return API.delete(`/offices/user/${id}`);
};

export const officesService = {
	useOfficesByUserId,
	createOffice,
	updateOffice,
	deleteOffice,
};
