import React, { useState, useContext } from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Row,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailService } from '../../../services/emailService';
import user from '../../../context/user';
import CustomMessage from 'components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';

const sendHelpEmail = Yup.object().shape({
	title: Yup.string().required(),
	body: Yup.string().required(),
});

const SendHelpEmail = () => {
	const initialValues = {
		title: '',
		body: '',
	};

	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const {
		state: { userdata },
	} = useContext(user);

	const handleSubmit = async (values) => {
		formik.setSubmitting(true);
		try {
			await emailService.sendHelpEmailService({
				subject: values.title,
				body: values.body,
				userEmail: userdata.email,
				userName: userdata.name,
				userLastName: userdata.lastName,
			});
			setShowSuccess(true);
			formik.resetForm();
			setShowError(false);
		} catch (error) {
			setShowError(true);
			setShowSuccess(false);
		} finally {
			formik.setSubmitting(false);
			// await mutate(['/noticenter/newsletter']);
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: sendHelpEmail,
		onSubmit: handleSubmit,
	});

	return (
		<>
			<p className='text-body-large-bold text-secondary-default'>
				Ante cualquier consulta, no dudes en comunicarte con nosotros.
			</p>
			<Card className='shadow-card'>
				<CardBody>
					<Form onSubmit={formik.handleSubmit}>
						<Col
							style={{
								maxHeight: 500,
							}}
						>
							<Row>
								<Col>
									<FormGroup>
										<Label
											for='title'
											className='text-body-large-bold text-secondary-default'
										>
											{'Motivo'}
										</Label>
										<InputGroup className='input-group-alternative'>
											<Input
												type='text'
												value={formik.values.title}
												onChange={formik.handleChange}
												name='title'
												id='title'
												placeholder={'Indique el motivo'}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label
											for='body'
											className='text-body-large-bold text-secondary-default'
										>
											{'Detallá aquí tu consulta'}
										</Label>
										<InputGroup className='input-group-alternative'>
											<Input
												style={{ height: 300 }}
												type='textarea'
												value={formik.values.body}
												onChange={formik.handleChange}
												name='body'
												id='body'
												placeholder={'Indique el mensaje'}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className='align-items-center'>
										<Button
											className='btn btn-noti-primary ml-3 py-1 px-5'
											type='submit'
											disabled={formik.isSubmitting}
										>
											<span className=''>Enviar</span>
										</Button>
										{showSuccess &&
											setTimeout(() => setShowSuccess(!showSuccess), 2000) && (
												<CustomMessage
													className='m-0'
													icon={
														<CheckIcon
															width='18'
															height='18'
															className='stroke stroke-success'
														/>
													}
													message={'¡Email enviado!'}
												/>
											)}
										{showError &&
											setTimeout(() => setShowError(!setShowError), 2000) && (
												<CustomMessage
													className='m-0'
													icon={
														<CloseIcon
															width='18'
															height='18'
															className='stroke stroke-error'
														/>
													}
													message={
														'¡Ha ocurrido un error al enviar el email de ayuda!'
													}
												/>
											)}
									</Row>
								</Col>
							</Row>
						</Col>
					</Form>
				</CardBody>
			</Card>
		</>
	);
};

SendHelpEmail.propTypes = {};

export default SendHelpEmail;
