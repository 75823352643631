import React from 'react';
import { Route } from 'react-router-dom';
import { Provider as CollaboratorProvider } from '../context/collaborator';
import AuthenticatedProvider from './AuthenticatedProvider';
import PropTypes from 'prop-types';

const CollaboratorRoute = ({ component: Component, ...rest }) => {
	return (
		<>
			<Route
				{...rest}
				render={(props) => (
					<AuthenticatedProvider>
						<CollaboratorProvider>
							<Component {...props} />
						</CollaboratorProvider>
					</AuthenticatedProvider>
				)}
			/>
		</>
	);
};

CollaboratorRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default CollaboratorRoute;
