/* eslint-disable indent */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import 'moment/locale/es';
import moment from 'moment';
import {
	InputGroup,
	Card,
	CardBody,
	Container,
	Col,
	Row,
	CardHeader,
	InputGroupAddon,
	FormGroup,
	CardFooter,
	Input,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Label,
} from 'reactstrap';
import TableOptionsButtons from '../../components/Commons/TableOptionsButtons/TableOptionsButtons';
import SynchronizeButton from '../../components/SynchronizeButton/SynchronizeButton';
import user from '../../context/user';
import global from '../../context/global';
import { CHART_BAR_COLORS, LAYOUTS, SERVICES_STATUS, SYNC_STATUS } from '../../helpers/consts';
import { formatToChartData } from '../../helpers/utils';
import { useLocation } from 'react-router-dom';
import useNewsTableColumns from './hooks/useNewsTableColumns';
import Pagination from '../../components/Commons/Pagination';
import { newsService } from '../../services/newsService';
import NewsTable from '../../components/Commons/NewsTable/NewsTable';
import HomeHeader from '../../components/Headers/HomeHeader';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/notiexpress/calendar-icon.svg';
import { ReactComponent as SyncIcon } from 'assets/img/icons/notiexpress/sync-icon.svg';
import { ReactComponent as UserIcon } from 'assets/img/icons/notiexpress/user-icon.svg';
import useBreakpoint from '../../hooks/useBreakpoint';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import { paymentsService } from '../../services/paymentsService';
import { subscriptionStatus } from '../../helpers/services';
import ServiceExpiredMessage from '../../components/ServiceExpiredMessage/ServiceExpiredMessage';
import ServiceExpiredSoonMessage from '../../components/ServiceExpiredMessage/ServiceExpiredSoonMessage';
import NewsCaseMobileItem from './components/NewsCaseMobileItem';
import { es } from 'date-fns/locale';
import useBreakpoints from '../../hooks/useBreakpoints';
import CardResumeBar from '../../components/Charts/CardResumeBar';
import { ReactComponent as UsersIcon } from '../../assets/img/icons/notiexpress/users.svg';
import { ReactComponent as LockIcon } from '../../assets/img/icons/notiexpress/lock-time-outline.svg';
import CardResumeFilter from '../../components/Charts/CardResumeFilter';
import { SynchronizeContext } from '../../context/synchronize';
import CardResumeInfo from '../../components/Charts/CardResumeInfo';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/notiexpress/close-icon.svg';
import useQueryParams from '../../hooks/useQueryParams';
import queryString from 'query-string';

const Novedades = () => {
	const { windowsSize } = useBreakpoint();
	const { isMobile } = useBreakpoints();
	const history = useLocation();
	const currentParams = queryString.parse(history.search);

	const [selectedDay, setSelectedDay] = useState(() => moment(currentParams?.fechaActuacion ?? moment().format('YYYY-MM-DD')).toDate());

	const {
		state: { status },
	} = useContext(SynchronizeContext);

	const { handleResetSearch, handleSetPage, handleSetFilter, params } = useQueryParams({
		page: currentParams?.page ?? 1,
		take: 10,
		fechaActuacion: currentParams?.fechaActuacion ?? moment().format('YYYY-MM-DD'),
	});

	const { data } = paymentsService.useUserServicesTable();
	const premiumServices = data?.services?.filter(({ service }) => service.idJuris[0] === 'All');

	const {
		state: { currentUser },
	} = useContext(user);

	const {
		state: {
			notifications: { data: notifications },
		},
	} = useContext(global);

	const { currentColumns } = useNewsTableColumns();

	const {
		data: newsList,
		isError: isErrorNewsList,
		isLoading: isLoadingNewsList,
		mutate,
	} = newsService.useNewsTable({ role: currentUser.rol, params: params });

	const { data: newsListCount, isLoading: isLoadingNewsListCount, mutate: mutateNewsListCount } = newsService.useNewsTableCount(params);

	useEffect(() => {
		mutate();
		mutateNewsListCount();
	}, [notifications]);

	function handleDayClick(day) {
		handleSetFilter({ fechaActuacion: moment(day).format('YYYY-MM-DD') });
		setSelectedDay(moment(day).toDate());
	}

	const collaboratorBarData = formatToChartData({ data: newsListCount?.totalByCollaborator, colors: CHART_BAR_COLORS });

	const getMessage = (status) => {
		// TODO move to helpers
		switch (status) {
			case SYNC_STATUS.START:
				return '';
			case SYNC_STATUS.ERROR:
				return 'Inténtalo nuevamente';
			case SYNC_STATUS.SUCCESS:
				return '¡Sincronizado!';
		}
	};

	return (
		<>
			{!isMobile && (
				<Container
					className='bg-gradient-white'
					fluid={true}
				>
					<InfoMessage
						title={'Para sincronizar las novedades de tus expedientes, completá los datos de conexión.'}
						to={'/admin/connections'}
						icon={
							<SyncIcon
								width={20}
								height={20}
								className='fill-secondary-default'
							/>
						}
						textButton='Mis Conexiones'
						messageKey='JurisdictionInfoMessage'
						isHomePage={true}
					/>
					{premiumServices?.length ? (
						subscriptionStatus(premiumServices[0]) === SERVICES_STATUS.EXPIRED ? (
							<ServiceExpiredMessage
								title={`Tu suscripción para la jurisdicción de ${premiumServices[0]?.service?.nombre ?? '-'} está vencida.`}
								message='Renovala para seguir recibiendo tus novedades'
								to={'/admin/user-profile'}
								buttonIcon={
									<UserIcon
										width={20}
										height={20}
										className='stroke-secondary-default fill-secondary-default'
									/>
								}
								buttonLabel='Mi Perfil'
							/>
						) : (
							subscriptionStatus(premiumServices[0]) === SERVICES_STATUS.EXPIRED_SOON && (
								<ServiceExpiredSoonMessage
									title={`La suscripción de ${premiumServices[0]?.service?.nombre ?? '-'} está próxima a vencerse.`}
									message='Renovala para no perder tus novedades.'
									to={'/admin/user-profile/subscriptions'}
									buttonIcon={
										<UserIcon
											width={20}
											height={20}
											className='stroke-secondary-default fill-secondary-default'
										/>
									}
									buttonLabel='Mi Perfil'
									icon={
										<SyncIcon
											width={48}
											height={40}
											className='fill-primary-default'
										/>
									}
								/>
							)
						)
					) : (
						data?.services?.map((service, key) => {
							if (subscriptionStatus(service) === SERVICES_STATUS.EXPIRED) {
								return (
									<ServiceExpiredMessage
										key={key}
										title={`Tu suscripción para la jurisdicción de ${service?.service?.nombre ?? '-'} está vencida.`}
										message='Renovala para seguir recibiendo tus novedades'
										to={'/admin/user-profile'}
										buttonIcon={
											<UserIcon
												width={20}
												height={20}
												className='stroke-secondary-default fill-secondary-default'
											/>
										}
										buttonLabel='Mi Perfil'
									/>
								);
							} else if (subscriptionStatus(service) === SERVICES_STATUS.EXPIRED_SOON) {
								return (
									<ServiceExpiredSoonMessage
										key={service?.id}
										title={`La suscripción de ${service?.service?.nombre ?? '-'} está próxima a vencerse.`}
										message='Renovala para no perder tus novedades.'
										to={'/admin/user-profile/subscriptions'}
										buttonIcon={
											<UserIcon
												width={20}
												height={20}
												className='stroke-secondary-default fill-secondary-default'
											/>
										}
										buttonLabel='Mi Perfil'
										icon={
											<SyncIcon
												width={48}
												height={40}
												className='fill-primary-default'
											/>
										}
									/>
								);
							}
						})
					)}
				</Container>
			)}
			<HomeHeader headerText='Novedades del día' />
			<Container
				className='bg-gradient-white'
				fluid={true}
			>
				<Row className='m-0 mb-3'>
					<Col
						xs='12'
						className='p-0'
					>
						{!isMobile ? (
							<Row
								className='m-0'
								style={{
									maxHeight: '120px',
									gap: '1rem',
								}}
							>
								<Col
									className='pl-0 pr-1 d-flex flex-column justify-content-between'
									style={{
										maxWidth: '230px',
										minWidth: '200px',
									}}
								>
									<SynchronizeButton
										date={params.fechaActuacion}
										mutate={mutateNewsListCount}
									/>
									<hr className='w-100 bg-primary-default my-1' />
									<FormGroup className='mb-0'>
										<InputGroup className='input-group-alternative'>
											<Col
												className='w-100 pr-0'
												xs='10'
											>
												<Input
													// center label and input value into the input
													className='form-control text-center pr-0 pl-3'
													value={moment(selectedDay).format('DD/MM/YYYY')}
													type='text'
												/>
											</Col>
											<Col
												xs='2'
												className='pl-0'
											>
												<UncontrolledDropdown
													active
													className='h-100'
												>
													<DropdownToggle
														nav
														className='pl-0  d-flex align-items-center h-100'
													>
														<CalendarIcon
															width={20}
															height={20}
															className='stroke stroke-primary-default'
														/>
													</DropdownToggle>
													<DropdownMenu right>
														<DayPicker
															style={{ width: '100%' }}
															mode='single'
															selected={selectedDay}
															onSelect={handleDayClick}
															locale={es}
															weekStartsOn={0}
															disabled={{
																after: new Date(Date.now()),
															}}
															defaultMonth={new Date(selectedDay)}
															captionLayout='dropdown-buttons'
															fromYear={2010}
															toYear={2024}
														/>
													</DropdownMenu>
												</UncontrolledDropdown>
											</Col>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col
									className='px-0'
									style={{
										maxWidth: `${windowsSize.width > 1440 ? '20%' : ''}`,
									}}
								>
									<CardResumeInfo
										isLoading={isLoadingNewsListCount}
										cardTitle='Última sincronización'
										subtitle={
											status !== SYNC_STATUS.START ? (
												<CustomMessage
													icon={
														status === SYNC_STATUS.ERROR ? (
															<CloseIcon
																width={18}
																height={18}
																className='stroke-error'
															/>
														) : status === SYNC_STATUS.SUCCESS ? (
															<CheckIcon
																width={18}
																height={18}
																className='stroke-success'
															/>
														) : (
															<></>
														)
													}
													message={getMessage(status)}
												/>
											) : (
												<span
													style={{
														fontSize: '17px',
													}}
													className='text-body-large-bold text-secondary-default'
												>
													{newsListCount?.lastSyncDate ?? 'Sin sincronizar'}
												</span>
											)
										}
									>
										<LockIcon
											width={24}
											height={24}
											className='fill-primary-default'
										/>
									</CardResumeInfo>
								</Col>
								<Col className='px-0'>
									<CardResumeBar
										isLoading={isLoadingNewsListCount}
										data={collaboratorBarData}
										cardTitle='Total por'
										subtitle='Responsable'
										extraInfo={{
											title: 'Total de',
											subtitle: 'Novedades',
											value: newsListCount?.totalTodayNovelties ?? 0,
										}}
									>
										<UsersIcon
											width={24}
											height={24}
											className='stroke-primary-default'
										/>
									</CardResumeBar>
								</Col>
								<Col
									className='px-0'
									style={{
										maxWidth: `${windowsSize.width > 1440 ? '20%' : ''}`,
									}}
								>
									<CardResumeFilter
										totalExp={newsListCount?.totalYesterdayNovelties ?? 0}
										cardTitle='Total en fecha'
										subtitle={moment(params.fechaActuacion).subtract(1, 'days').format('DD/MM/YYYY') ?? '-'}
										date={moment(params.fechaActuacion).subtract(1, 'days').format('YYYY-MM-DD') ?? '-'}
										isLoading={isLoadingNewsListCount}
										handleFilter={handleDayClick}
									>
										<CalendarIcon
											width={24}
											height={24}
											className='stroke-primary-default'
										/>
									</CardResumeFilter>
								</Col>
							</Row>
						) : (
							<Row className={`align-items-center  m-0 ${windowsSize.width < 768 ? 'justify-content-center' : ''}`}>
								<FormGroup className={`${windowsSize.width < 768 ? 'w-100 py-3' : ''}`}>
									<Label className='text-body-large-bold text-secondary-default'>Fecha</Label>
									<InputGroup className='input-group-alternative'>
										<Input
											className='form-control'
											value={moment(selectedDay).format('DD/MM/YYYY')}
											type='text'
											onChange={(e) => {
												setSelectedDay(e.target.value);
											}}
										/>
										<InputGroupAddon addonType='append'>
											<UncontrolledDropdown active>
												<DropdownToggle
													nav
													className='h-100 d-flex align-items-center'
												>
													<CalendarIcon
														width={20}
														height={20}
														className='stroke stroke-primary-default'
													/>
												</DropdownToggle>
												<DropdownMenu right>
													<DayPicker
														style={{ width: '100%' }}
														mode='single'
														defaultMonth={new Date(selectedDay)}
														selected={selectedDay}
														onSelect={handleDayClick}
														locale={es}
														weekStartsOn={0}
														disabled={{
															after: new Date(Date.now()),
														}}
													/>
												</DropdownMenu>
											</UncontrolledDropdown>
										</InputGroupAddon>
									</InputGroup>
									<span className='text-body-large'>Buscá tus expedientes por fecha</span>
								</FormGroup>
								<Col className='justify-content-center align-items-center mb-2'>
									<SynchronizeButton date={params.fechaActuacion} />
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				<Row xs='3'>
					<Col>
						<Card className='shadow-card mb-3'>
							<CardHeader>
								<Row className='m-0 justify-content-between'>
									{!isMobile && (
										<Pagination
											count={newsList?.totalCount ?? 0}
											page={newsList?.currentPage ?? 0}
											rowsPerPage={Number(params?.take) ?? 15}
											pageCount={newsList?.totalPage ?? 0}
											handleSetPage={handleSetPage}
											disabled={isErrorNewsList || isLoadingNewsList}
										/>
									)}
									<Col
										xs={12}
										className='p-0'
									>
										<TableOptionsButtons
											tableType='news'
											data={newsList?.paginateData ?? []}
											canAdd={false}
											resetSearch={() =>
												handleResetSearch(Object.keys(params).filter((key) => key !== 'page' && key !== 'take' && key !== 'fechaActuacion'))
											}
											layout={LAYOUTS.ADMIN}
										/>
									</Col>
									{isMobile && <hr className='w-100' />}
									<div className='mt-4 pt-2'>
										{isMobile && (
											<Pagination
												count={newsList?.totalCount ?? 0}
												page={newsList?.currentPage ?? 0}
												rowsPerPage={params?.take ?? 15}
												pageCount={newsList?.totalPage ?? 0}
												handleSetPage={handleSetPage}
												disabled={isErrorNewsList || isLoadingNewsList}
											/>
										)}
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{windowsSize.width < 768 ? (
									!newsList?.paginateData?.length ? (
										<Row className='m-0 justify-content-center'>
											<span className='text-body-regular-bold text-secondary-default'>No tenés ninguna novedad agregada.</span>
										</Row>
									) : (
										newsList?.paginateData?.map((news) => (
											<NewsCaseMobileItem
												expediente={news}
												key={news?.id}
											/>
										))
									)
								) : (
									<NewsTable
										data={newsList?.paginateData ?? []}
										columns={currentColumns}
										propertyId='id'
										isLoading={isLoadingNewsList}
										isError={isErrorNewsList}
										noDataMessage='No tenés ninguna novedad agregada.'
										mutate={mutate}
									/>
								)}
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Novedades ({newsList?.totalCount ?? ''})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Novedades;

Novedades.propTypes = {
	tableTitle: PropTypes.string,
};
