import React from 'react';
import { Col, Row } from 'reactstrap';
import CheckIcon from '../Icons/CheckIcon';
import { STATUS_COLORS } from '../../helpers/consts';

const Welcome = () => {
	return (
		<Col>
			<h1 className='text-heading-1 text-primary-default py-2'>
				¡Bienvenido a Notiexpress!
			</h1>
			<p className='font-weight-700 text-secondary-dark-1 py-2'>
				Descubrí todas las herramientas que tenemos para vos.
			</p>
			<Col className='py-2'>
				<Row className='p-2'>
					<CheckIcon
						size={24}
						color={STATUS_COLORS.success}
					/>
					<p className='font-weight-700 text-secondary-dark-1 ml-3'>
						Novedades del día.
					</p>
				</Row>
				<Row className='p-2'>
					<CheckIcon
						size={24}
						color={STATUS_COLORS.success}
					/>
					<p className='font-weight-700 text-secondary-dark-1 ml-3'>
						Administración de expedientes.
					</p>
				</Row>
				<Row className='p-2'>
					<CheckIcon
						size={24}
						color={STATUS_COLORS.success}
					/>
					<p className='font-weight-700 text-secondary-dark-1 ml-3'>
						Control de caducidad.{' '}
					</p>
				</Row>
				<Row className='p-2'>
					<CheckIcon
						size={24}
						color={STATUS_COLORS.success}
					/>
					<p className='font-weight-700 text-secondary-dark-1 ml-3'>
						Hasta 5 colaboradores por cuenta.
					</p>
				</Row>
			</Col>
		</Col>
	);
};

export default Welcome;
