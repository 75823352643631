import React, { useContext, useState } from 'react';
import CustomModal from '../Modals/CustomModal/CustomModal';
import {
	Button,
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	ModalBody,
	Row,
} from 'reactstrap';
import EyeOffIcon from '../Icons/EyeOffIcon';
import EyeIcon from '../Icons/EyeIcon';
import { create } from 'react-modal-promise';
import user from 'context/user';
import { userService } from '../../services/userService';

const CurrentPasswordModal = ({ isOpen, onResolve, onReject }) => {
	const {
		state: { currentUser },
	} = useContext(user);
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [showError, setShowError] = useState(false);
	const handleToggleCurrentPassword = () =>
		setShowCurrentPassword(!showCurrentPassword);

	const handleValidateCurrentPassword = async () => {
		try {
			await userService.login({
				email: currentUser?.email,
				password: currentPassword,
			});
			setShowError(false);
			onResolve(currentPassword);
		} catch (e) {
			setShowError(true);
		}
	};

	return (
		<CustomModal
			title='Ingresar contraseña actual'
			isOpen={isOpen}
			toggle={() => onReject()}
			className={'w-50'}
		>
			<ModalBody>
				<p className='text-body-large text-neutro-2'>
					Para ver éste campo, debés introducir la contraseña de Administrador.
				</p>
				<Row className='py-3'>
					<Col>
						<InputGroup className='input-group-alternative mb-2 form-group'>
							<Input
								placeholder='Contraseña actual'
								type={showCurrentPassword ? 'text' : 'password'}
								name='password'
								onChange={(e) => setCurrentPassword(e.target.value)}
								value={currentPassword}
							/>
							<InputGroupAddon addonType='append'>
								<InputGroupText>
									<span onClick={handleToggleCurrentPassword}>
										{showCurrentPassword ? (
											<EyeOffIcon
												className='stroke stroke-primary-default'
												size='18'
											/>
										) : (
											<EyeIcon
												className='stroke stroke-primary-default'
												size='18'
											/>
										)}
									</span>
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
						{showError && (
							<span className='text-dark-link-regular text-error'>
								La contraseña ingresada es incorrecta
							</span>
						)}
					</Col>
				</Row>
				<Row className='px-3 pt-3'>
					<Col>
						<Row className='m-0 align-items-center'>
							<Button
								outline
								className='btn btn-outline-noti-secondary py-1 px-5'
								onClick={() => {
									onReject();
								}}
							>
								Cancelar
							</Button>
							<Button
								className='btn btn-noti-primary ml-3 py-1 px-5'
								onClick={handleValidateCurrentPassword}
							>
								<span className='text-button'>Aceptar</span>
							</Button>
						</Row>
					</Col>
				</Row>
			</ModalBody>
		</CustomModal>
	);
};

CurrentPasswordModal.propTypes = {};

export const currentPasswordModal = create(CurrentPasswordModal);
