import React, { useState } from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Col, Container, Row } from 'reactstrap';
import CardAccordion from '../../components/CardAccordion/CardAccordion';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import ConnectionsForm from './components/ConnectionsForm';
import { connectionsService } from '../../services/connectionsService';
import DynamicTable from '../../components/Commons/DynamicTable';
import ConnectionsDropdown from './components/ConnectionsDropdown';
import CollaboratorsForm from './components/CollaboratorsForm';
import CollaborationDropdown from './components/ColaborationDropdown';
import { collaboratorService } from '../../services/collaboratorService';
import { ReactComponent as GridIcon } from '../../assets/img/icons/notiexpress/grid-icon.svg';
import PropTypes from 'prop-types';

const ConnectionsPage = () => {
	const [connectionSuccessMessage, setConnectionSuccessMessage] = useState('');
	const [connectionErrorMessage, setConnectionErrorMessage] = useState('');
	const [collaboratorSuccessMessage, setCollaboratorSuccessMessage] = useState('');
	const [collaboratorErrorMessage, setCollaboratorErrorMessage] = useState('');

	const {
		data: connections,
		isLoading: isLoadingConnections,
		isError: isErrorConnections,
		mutate: mutateConnections,
	} = connectionsService.useConnectionsTable();

	const {
		data: collaborators,
		isError: isErrorCollaborators,
		mutate: mutateCollaborators,
		isLoading: isLoadingCollaborators,
	} = collaboratorService.useCollaboratorsTable();

	const sistema = {
		Chaco: 'Poder Judicial del Chaco',
		Corrientes: 'Iurix',
		Federal: 'Poder Judicial Nacional',
	};

	/*const PasswordField = ({value}) => {
        const [showPassword, setShowPassword] = useState(false);
        const handleShowPassword = async () => {
            try {
                await currentPasswordModal();
                setShowPassword(true);
            } catch (e) {
                setShowPassword(false)
            }
        }
        return <Row className="m-0">
            <Col xs={12} className="p-0">
                <input
                    className="w-100 border-0 bg-transparent"
                    type={showPassword ? "text" : "password"}
                    value={value}
                    disabled
                />
            </Col>
        </Row>
    }*/

	const connectionsColumns = [
		{
			Header: 'Jurisdicción',
			accessor: ({ jurisdiction }) => (jurisdiction.name === 'Federal' ? <span >Capital Federal / Justicia Federal</span> : <span>{jurisdiction.name}</span>),
			width: 200,
			minWidth: 150,
		},
		{
			Header: 'Sistema',
			accessor: ({ jurisdiction }) => <span>{sistema[jurisdiction.name]}</span>,
		},
		{
			Header: 'Matrícula',
			accessor: ({ licensePlate }) => <span>{licensePlate ?? '-'}</span>,
			width: 200,
			minWidth: 150,
		},
		{
			Header: () => (
				<div className='text-center'>
					<GridIcon
						width={16}
						height={16}
						className='stroke-secondary-default'
					/>
				</div>
			),
			accessor: 'id',
			Cell: ({ row }) => (
				<ConnectionsDropdown
					ConexionOrigenDeDatos={row.original}
					jurisdiction={row?.original?.jurisdiction}
					mutate={mutateConnections}
					setConnectionErrorMessage={setConnectionErrorMessage}
					setConnectionSuccessMessage={setConnectionSuccessMessage}
				/>
			),
			width: 50,
		},
	];

	const collaborationsColumns = [
		{
			Header: 'Nombre',
			accessor: ({ User }) => <span>{User?.name ?? '-'}</span>,
		},
		{
			Header: 'Apellido',
			accessor: ({ User }) => <span>{User?.lastName ?? '-'}</span>,
		},
		{
			Header: 'Matrícula',
			accessor: ({ User }) => <span>{User?.licensePlate ?? '-'}</span>,
		},
		{
			Header: 'Correo',
			accessor: ({ User }) => <span>{User?.email ?? '-'}</span>,
		},
		{
			Header: () => (
				<div className='text-center'>
					<GridIcon
						width={16}
						height={16}
						className='stroke-secondary-default'
					/>
				</div>
			),
			accessor: 'user',
			Cell: ({ row }) => {
				return (
					<CollaborationDropdown
						collaborator={row.original.User}
						mutate={mutateCollaborators}
						setCollaboratorErrorMessage={setCollaboratorErrorMessage}
						setCollaboratorSuccessMessage={setCollaboratorSuccessMessage}
					/>
				);
			},
			width: 10,
		},
	];

	return (
		<div>
			<HomeHeader headerText='Gestión de Conexiones' />
			<Container
				className='pb-2 bg-gradient-white'
				fluid={true}
			>
				<Row className=''>
					<Col
						xs='12'
						className='px-2'
					>
						<InfoMessage
							title='Conéctate a las jurisdicciones disponibles para recibir las novedades de tus expedientes.'
							message='Podés conectarte a varias jurisdicciones simultáneamente.'
							messageKey='connectionsInfo'
						/>
						<CardAccordion title='Mis conexiones'>
							<Row className='m-0 pb-3'>
								<Col
									xs='12'
									className='p-2'
								>
									<ConnectionsForm
										mutate={mutateConnections}
										connectionErrorMessage={connectionErrorMessage}
										connectionSuccessMessage={connectionSuccessMessage}
										setConnectionErrorMessage={setConnectionErrorMessage}
										setConnectionSuccessMessage={setConnectionSuccessMessage}
									/>
								</Col>
							</Row>
							<Row className='m-0 py-3'>
								<Col
									xs='12'
									className='p-2'
								>
									<DynamicTable
										data={connections ?? []}
										columns={connectionsColumns}
										isLoading={isLoadingConnections}
										isError={isErrorConnections}
										noDataMessage='No tenés ninguna conexión agregada.'
									/>
								</Col>
							</Row>
						</CardAccordion>
					</Col>
					<Col
						xs='12'
						className='p-2'
					>
						<InfoMessage
							title='Agregá colaboradores para tenerlos disponibles en la configuración de cada expediente.'
							message='Podés administrar sus datos y asignarles una cuenta de acceso.'
							messageKey='colaborationsInfo'
						/>
						<CardAccordion title='Mis colaboradores'>
							<Row className='m-0 py-3'>
								<Col
									xs='12'
									className='p-2'
								>
									<CollaboratorsForm
										mutate={mutateCollaborators}
										collaboratorErrorMessage={collaboratorErrorMessage}
										collaboratorSuccessMessage={collaboratorSuccessMessage}
										setCollaboratorErrorMessage={setCollaboratorErrorMessage}
										setCollaboratorSuccessMessage={setCollaboratorSuccessMessage}
									/>
								</Col>
							</Row>
							<Row className='m-0 py-3'>
								<Col
									xs='12'
									className='p-2'
								>
									<DynamicTable
										data={collaborators ?? []}
										columns={collaborationsColumns}
										isLoading={isLoadingCollaborators}
										isError={isErrorCollaborators}
										noDataMessage='No tenés ningún colaborador agregado.'
									/>
								</Col>
							</Row>
						</CardAccordion>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

ConnectionsPage.propTypes = {
	row: PropTypes.object,
};

export default ConnectionsPage;
