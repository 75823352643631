import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DeleteConnectionModal from './DeleteConnectionModal';
import ModifyConnectionModal from './ModifyConnectionModal';
import PropTypes from 'prop-types';

function ConnectionsDropdown({ ConexionOrigenDeDatos, jurisdiction, mutate, setConnectionErrorMessage, setConnectionSuccessMessage }) {
	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

	function openDeleteModal() {
		setDeleteModalIsOpen(true);
	}

	function openUpdateModal() {
		setUpdateModalIsOpen(true);
	}

	return (
		<div className='text-center'>
			<UncontrolledDropdown>
				<DropdownToggle
					className='btn-icon-only btn btn-secondary text-default m-0'
					role='button'
					size='sm'
					color=''
					onClick={(event) => event.preventDefault()}
				>
					<i
						aria-label='Acciones'
						className='fas fa-ellipsis-v'
					/>
				</DropdownToggle>
				<DropdownMenu
					className='dropdown-menu-arrow'
					right
				>
					<DropdownItem onClick={() => openUpdateModal()}>Modificar</DropdownItem>
					<DropdownItem onClick={() => openDeleteModal()}>Quitar</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			<DeleteConnectionModal
				deleteModalIsOpen={deleteModalIsOpen}
				setDeleteModalIsOpen={setDeleteModalIsOpen}
				connection={ConexionOrigenDeDatos}
				jurisdiccion={jurisdiction}
				mutate={mutate}
				setConnectionErrorMessage={setConnectionErrorMessage}
				setConnectionSuccessMessage={setConnectionSuccessMessage}
			/>
			<ModifyConnectionModal
				updateModalIsOpen={updateModalIsOpen}
				setUpdateModalIsOpen={setUpdateModalIsOpen}
				connection={ConexionOrigenDeDatos}
				mutate={mutate}
				setConnectionErrorMessage={setConnectionErrorMessage}
				setConnectionSuccessMessage={setConnectionSuccessMessage}
			/>
		</div>
	);
}

export default ConnectionsDropdown;

ConnectionsDropdown.propTypes = {
	ConexionOrigenDeDatos: PropTypes.object,
	jurisdiction: PropTypes.object,
	mutate: PropTypes.func,
	setConnectionErrorMessage: PropTypes.func,
	setConnectionSuccessMessage: PropTypes.func,
};
