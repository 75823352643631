import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { ReactComponent as TrashIcon } from '../../../../../assets/img/icons/notiexpress/trash-icon.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/icons/notiexpress/close-icon.svg';
import cartContext from '../../../../../context/cart';
import { useFormik } from 'formik';
import toArs from '../../../../../helpers/toArs';
import { paymentsService } from '../../../../../services/paymentsService';
import CustomMessage from '../../../../../components/CustomMessage/CustomMessage';
import PropTypes from 'prop-types';

const AddJurisdictionPanel = ({ services, setStatus }) => {
	const { cart, setCart, totalAmount, addToCart, discountAmount, validVoucher, setValidVoucher } = useContext(cartContext);
	const [isLoadingVoucher, setIsLoadingVoucher] = useState(false);
	const [invalidVoucher, setInvalidVoucher] = useState(false);
	const [noServiceSelected, setNoServiceSelected] = useState(false);
	const initialValues = {
		service: '',
		voucher: '',
	};
	const formik = useFormik({
		initialValues,
		onSubmit: () => {},
	});
	const handleRemoveFromCart = (_id) => {
		const newCart = cart.filter((service) => service._id !== _id);
		setCart(newCart);
	};
	const handleAddToCart = (_id) => {
		const service = services.find((service) => service._id === _id);
		if (!service) return;
		addToCart(service);
	};

	const handleAddDiscount = async (e) => {
		e.preventDefault();
		if (cart?.length === 0) {
			setNoServiceSelected(true);
			return;
		}
		setIsLoadingVoucher(true);
		try {
			const response = await paymentsService.getFinalAmount(cart, formik.values.voucher);
			setValidVoucher(response?.voucher);
			setInvalidVoucher(false);
			setNoServiceSelected(false);
		} catch (e) {
			setInvalidVoucher(true);
		} finally {
			setIsLoadingVoucher(false);
		}
	};

	const handleGoNextTab = (e) => {
		e.preventDefault();
		setStatus('2');
	};

	return (
		<Form
			tag={Row}
			className='align-items-center'
			onSubmit={formik.handleSubmit}
		>
			<Col>
				<div className='mb-2'>
					<span className='text-secondary-default'>Suscripciones</span>
				</div>
				<FormGroup>
					<InputGroup
						className='input-group-alternative mb-2'
						size='sm'
					>
						<Input
							type='select'
							className='w-100'
							name='service'
							value={formik.values.service}
							onChange={formik.handleChange}
						>
							<option value=''>Seleccioná una suscripción</option>
							{services?.map(
								(service, index) =>
									service.active && (
										<option
											key={index}
											value={service?._id}
										>
											{service.nombre}
										</option>
									)
							)}
						</Input>
					</InputGroup>
					<Button
						className='btn btn-noti-primary py-1 px-5'
						onClick={() => handleAddToCart(formik.values.service)}
						disabled={formik.values.service === ''}
					>
						<Row className='align-items-center'>
							<FaPlus
								size={18}
								className='stroke stroke-neutro-1'
							/>
							<span className='text-button'>Agregar</span>
						</Row>
					</Button>
				</FormGroup>
				<Col className='p-2'>
					<Col>
						{cart?.map((service, index) => (
							<Row
								className='justify-content-between m-0 py-2'
								key={index}
							>
								<span className='text-body-regular'>{service?.nombre}</span>
								<Row className='align-items-center'>
									<span className='text-body-regular mr-2'>
										<strong>{toArs(service?.precio)}</strong>/mes
									</span>
									<TrashIcon
										className='stroke-primary-default cursor-pointer'
										width={18}
										height={18}
										onClick={() => handleRemoveFromCart(service?._id)}
									/>
								</Row>
							</Row>
						))}
					</Col>
					<Col className='p-3'>
						{cart?.length ? (
							<>
								{discountAmount > 0 ? (
									<Row className='justify-content-between m-0'>
										<span className='text-link-regular text-info'>
											<strong>Descuento</strong>
										</span>
										<span className='text-link-regular text-info'>
											<strong>{toArs(-discountAmount)}</strong>
										</span>
									</Row>
								) : null}
								<Row className='justify-content-between m-0'>
									<span className='text-body-regular'>
										<strong>TOTAL</strong>
									</span>
									<span className='text-body-regular'>
										<strong>{toArs(totalAmount)}</strong>
									</span>
								</Row>
							</>
						) : null}
					</Col>
				</Col>
				<Col>
					<Row>
						<Button
							className='btn btn-noti-primary py-1 px-5'
							onClick={handleGoNextTab}
							disabled={cart?.length === 0}
						>
							<span className='text-button'>Siguiente</span>
						</Button>
					</Row>
				</Col>
			</Col>
			<Col>
				<Card className='shadow-card'>
					<CardBody>
						<p className='m-0 text-heading-2 text-secondary-default py-3'>Cupón de descuento</p>
						<FormGroup>
							<InputGroup
								className='input-group-alternative mb-2'
								size='sm'
							>
								<Input
									type='text'
									placeholder='Código de descuento'
									name='voucher'
									value={formik.values.voucher}
									onChange={formik.handleChange}
									disabled={isLoadingVoucher || !!validVoucher}
								/>
							</InputGroup>
						</FormGroup>
						<Row className='m-0 align-items-center'>
							<Button
								className='btn btn-outline-noti-secondary py-1 px-5'
								onClick={handleAddDiscount}
								disabled={isLoadingVoucher || !!validVoucher}
								size='sm'
							>
								<Row className='align-items-center m-0'>
									<span
										className={`spinner-border spinner-border-sm mr-3 ${isLoadingVoucher ? '' : 'd-none'}`}
										role='status'
										aria-hidden='true'
									></span>
									<span>Validar</span>
								</Row>
							</Button>
							{validVoucher ? (
								<CustomMessage
									className='m-0'
									message='¡Cupón válido!'
									icon={
										<CheckIcon
											className='stroke-success mr-2'
											width={18}
											height={18}
										/>
									}
								/>
							) : null}
							{invalidVoucher ? (
								<CustomMessage
									className='m-0'
									message='Cupón inválido'
									icon={
										<CloseIcon
											className='stroke-danger mr-2'
											width={18}
											height={18}
										/>
									}
								/>
							) : null}
							{noServiceSelected ? (
								<CustomMessage
									className='m-0'
									message='Debe seleccionar al menos un servicio'
									icon={
										<CloseIcon
											className='stroke-danger mr-2'
											width={18}
											height={18}
										/>
									}
								/>
							) : null}
						</Row>
					</CardBody>
				</Card>
			</Col>
		</Form>
	);
};

AddJurisdictionPanel.propTypes = {
	setStatus: PropTypes.func.isRequired,
};

export default AddJurisdictionPanel;
