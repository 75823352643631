import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const Donut = ({ expedienteCountByStatus, backgroundColor, casesExpirationStatusCount, labels = [] }) => {
	const data = {
		labels: labels,
		datasets: [
			{
				data: [expedienteCountByStatus, casesExpirationStatusCount?.total - expedienteCountByStatus],
				backgroundColor: [backgroundColor, '#E5E5E5'],
				cutout: '85%',
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	return (
		<div
			style={{
				width: '90px',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
			}}
		>
			<Doughnut
				data={data}
				options={options}
				width={90}
				height={90}
			/>
			<span
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					fontSize: '16px',
					fontFamily: 'Roboto',
					fontWeight: '700',
					lineHeight: '20px',
				}}
			>
				{Math.round((expedienteCountByStatus / casesExpirationStatusCount?.total) * 100)}%
			</span>
		</div>
	);
};

export default Donut;
