import React from 'react';
import { useParams } from 'react-router-dom';
import 'moment/locale/es';
import { Container, Row, Col, Spinner } from 'reactstrap';
import HomeHeader from 'components/Headers/HomeHeader';
import CaseFileTabs from './components/CaseFileTabs';
import CaseFileHistoric from './components/CaseFileHistoric';
import CaseFileConfiguration from './components/CaseFileConfiguration';
import { collaboratorRoleService } from '../../services/collaboratorRoleService';
import { LAYOUTS } from '../../helpers/consts';

const CaseFileHistoryForCollaboratorView = (props) => {
	let { id } = useParams();

	const {
		data: caseInfo,
		error,
		isLoading,
		mutate,
	} = collaboratorRoleService.getExpedientById(id);

	return (
		<>
			<HomeHeader
				{...props}
				headerText='Detalle de Expediente'
				canGoBack
			/>
			<Container className='bg-gradient-white'  fluid={true}>
				<Row className=''>
					<Col xl='12'>
						{isLoading ? (
							<Spinner color='default'></Spinner>
						) : (
							<CaseFileTabs
								caseInfo={{
									...caseInfo?.Expediente,
									collaborator: caseInfo?.Colaborador,
									fechaCaducidad: caseInfo?.caducityDate,
									fechaAlerta: caseInfo?.alertDate,
									deleted: caseInfo?.deleted,
								}}
								mutate={mutate}
								isLoading={isLoading}
								isError={!!error}
								autoSync={false}
								{...props}
							/>
						)}
					</Col>
				</Row>
				<Row>
					<Col xl='12'>
						<CaseFileConfiguration
							caseInfo={caseInfo}
							mutate={mutate}
							canEdit={false}
						/>
					</Col>
				</Row>
				{isLoading ? (
					<></>
				) : (
					<Row className='mb-3'>
						<Col xl='12'>
							<CaseFileHistoric
								caseInfo={caseInfo}
								layout={LAYOUTS.COLLABORATOR}
                mutate={mutate}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</>
	);
};

export default CaseFileHistoryForCollaboratorView;
