import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardAccordion from '../../../../components/CardAccordion/CardAccordion';
import { Button, CardBody, CardFooter, Col, Row } from 'reactstrap';
import DynamicTable from '../../../../components/Commons/DynamicTable';
import { receiptTableColumns } from '../../assets/receiptTableColumns';
import { paymentsService } from '../../../../services/paymentsService';
import cart from '../../../../context/cart';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom';

const ReceiptsInfo = () => {
	const history = useHistory();
	const {
		data: receiptPayments,
		mutate,
		isError,
		isLoading,
	} = paymentsService.getUserPayments(moment().subtract(1, 'year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
	const { hasPayed } = useContext(cart);

	useEffect(() => {
		mutate();
	}, [hasPayed]);

	const goToReceipt = () => {
		history.push('/admin/user-profile/receipts');
	};

	return (
		<CardAccordion
			title='Mis comprobantes'
			defaultValue={false}
		>
			<CardBody>
				<DynamicTable
					data={receiptPayments?.slice(0, 5) ?? []}
					columns={receiptTableColumns}
					isLoading={isLoading}
					isError={isError}
					noDataMessage='No tenés ningún comprobante agregado.'
				/>
			</CardBody>
			<CardFooter>
				<Row>
					<Col className=''>
						<Button
							className='btn btn-noti-primary px-4 py-2'
							onClick={goToReceipt}
						>
							<span className='text-button'>Consultar</span>
						</Button>
					</Col>
				</Row>
			</CardFooter>
		</CardAccordion>
	);
};

ReceiptsInfo.propTypes = {
	limit: PropTypes.number,
};

export default ReceiptsInfo;
