import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TooltipElement from './TooltipElement';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/notiexpress/copy-icon.svg';
import Swal from 'sweetalert2';

const CopyToClipboardElement = ({ id, text, tooltipMessage }) => {
	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className='px-3 btn btn-link py-0'
		>
			<CopyToClipboard
				text={text}
				onCopy={() =>
					Swal.fire({
						icon: 'success',
						timer: 1000,
						position: 'bottom-end',
						toast: true,
						showConfirmButton: false,
						title: 'Copiado al portapapeles',
					})
				}
			>
				<CopyIcon
					width={20}
					height={20}
					className='stroke-primary-default'
					id={`tooltip-${id}`}
				/>
			</CopyToClipboard>
			<TooltipElement id={`tooltip-${id}`}>{tooltipMessage}</TooltipElement>
		</div>
	);
};

export default CopyToClipboardElement;
