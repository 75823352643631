import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { connectionsService } from 'services/connectionsService';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/Modals/CustomModal/CustomModal';
import * as Yup from 'yup';
import global from '../../../context/global';

const validationSchema = Yup.object().shape({
	jurisdiction: Yup.string().required('Campo obligatorio.'),
	matricula: Yup.string().required('Campo obligatorio.'),
	userLogin: Yup.string().required('Campo obligatorio.'),
	passwordLogin: Yup.string().required('Campo obligatorio.'),
});

function ModifyConnectionModal({
	updateModalIsOpen,
	setUpdateModalIsOpen,
	connection,
	mutate,
	setConnectionErrorMessage,
	setConnectionSuccessMessage,
}) {
	const {
		state: { jurisdictions },
	} = useContext(global);
	const formik = useFormik({
		initialValues: {
			jurisdiction: connection?.jurisdiction.id ?? '',
			matricula: connection?.licensePlate ?? '',
			userLogin: '',
			passwordLogin: '',
			id: connection?.id,
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true);
			connectionsService
				.update(values)
				.then(() => {
					mutate();
					setUpdateModalIsOpen(false);
					setConnectionSuccessMessage('¡Datos actualizados!');
				})
				.catch(() => {
					setConnectionErrorMessage('No se pudo modificar la conexión');
				})
				.finally(() => {
					setSubmitting(false);
				});
		},
	});

	return (
		<CustomModal
			title='Modificar conexión'
			className='w-75'
			isOpen={updateModalIsOpen}
			toggle={() => setUpdateModalIsOpen(false)}
		>
			<Form
				role='form'
				onSubmit={formik.handleSubmit}
			>
				<ModalBody className=''>
					<Row className='align-items-center  mt-2'>
						<Col>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									for='connection-jurisdiction-modify-input'
								>
									Jurisdicción *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										type='select'
										name='jurisdiction'
										id='jurisdiction'
										value={formik.values.jurisdiction}
										onChange={formik.handleChange}
										size='sm'
										placeholder='Seleccione una opción'
									>
										<>
											<option value=''>Seleccione una opción</option>
											{jurisdictions?.map((jurisdiction, index) => (
												<option
													key={index}
													value={jurisdiction.id}
												>
													{jurisdiction.name}
												</option>
											))}
										</>
									</Input>
								</InputGroup>
								{formik.errors.jurisdiction && formik.touched.jurisdiction ? (
									<span className='text-error text-link-regular'>{formik.errors.jurisdiction}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									for='connection-matricula-modify-input'
								>
									Matrícula *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='connection-matricula-modify-input'
										name='matricula'
										onChange={formik.handleChange}
										value={formik.values.matricula}
										size='sm'
										autoComplete='off'
									/>
								</InputGroup>
								{formik.errors.matricula && formik.touched.matricula ? (
									<span className='text-error text-link-regular'>{formik.errors.matricula}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									for='connection-username-modify-input'
								>
									Usuario / Id *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='connection-username-modify-input'
										name='userLogin'
										onChange={formik.handleChange}
										size='sm'
										value={formik.values.userLogin}
									/>
								</InputGroup>
								{formik.errors.userLogin && formik.touched.userLogin ? (
									<span className='text-error text-link-regular'>{formik.errors.userLogin}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='connection-password-modify-input'
								>
									Contraseña *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='password'
										id='connection-password-modify-input'
										name='passwordLogin'
										onChange={formik.handleChange}
										value={formik.values.passwordLogin}
										size='sm'
										autoComplete={'new-password'}
									/>
								</InputGroup>
								{formik.errors.passwordLogin && formik.touched.passwordLogin ? (
									<span className='text-error text-link-regular'>{formik.errors.passwordLogin}</span>
								) : null}
							</FormGroup>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter className='justify-content-start'>
					<Button
						className='btn btn-outline-noti-secondary py-1 px-5'
						data-dismiss='modal'
						type='button'
						onClick={() => setUpdateModalIsOpen(false)}
					>
						Cancelar
					</Button>
					<Button
						className='btn btn-noti-primary py-1 px-5 ml-3'
						type='submit'
						disabled={formik.isSubmitting || !formik.dirty}
					>
						<span
							className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
							role='status'
							aria-hidden='true'
						></span>
						<span className='text-button'>Actualizar</span>
					</Button>
				</ModalFooter>
			</Form>
		</CustomModal>
	);
}

export default ModifyConnectionModal;

ModifyConnectionModal.propTypes = {
	updateModalIsOpen: PropTypes.bool,
	setUpdateModalIsOpen: PropTypes.func,
	connection: PropTypes.object,
	mutate: PropTypes.func,
	setConnectionErrorMessage: PropTypes.func,
	setConnectionSuccessMessage: PropTypes.func,
};
