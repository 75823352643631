import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ barData = [{ name: 'default', value: 15, color: '#9BA7AF' }] }) => {
	const data = {
		labels: barData.map(({ name }) => name),
		datasets: [
			{
				data: barData.map(({ value }) => value),
				backgroundColor: barData.map(({ color }) => color),
				borderRadius: 4,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
        ticks: {
          callback: function(value, index, values) {
            return barData[index].value
          }
        },
        
			},
			y: {
				max: barData.reduce((acc, { value }) => (value > acc ? value : acc), 0),
				grid: {
					display: false,
				},
				ticks: {
					display: false,
				},
				display: false,
			},
		},
	};

	return (
		<Bar
			data={data}
			options={{
				...options,
				font: {
					size: 7,
				},
				scales: {
					...options.scales,
				},
			}}
			width={80}
			height={60}
		/>
	);
};

export default BarChart;
