import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Col, Card, Row, Alert, Spinner } from 'reactstrap';
import HomeHeader from 'components/Headers/HomeHeader';
import ProvidenceDetails from './components/ProvidenceDetails';
import 'moment/locale/es';
import ProvidenceCard from './components/ProvidenceCard';
import { collaboratorRoleService } from '../../services/collaboratorRoleService';
import { LAYOUTS } from '../../helpers/consts';
import PropTypes from 'prop-types';

const ProvidenceForCollaboratorView = (props) => {
	let { id } = useParams();

	const {
		data: providence,
		isLoading,
		isError,
		mutate,
	} = collaboratorRoleService.getProvidenceById({
		providenceId: id,
		caseId: props.match.params.caseId,
	});

	return (
		<>
			<HomeHeader
				headerText='Detalle de Despacho'
				canGoBack
			/>
			<Container className='mb-3' fluid>
				<Row className='mb-3'>
					<Col xl='12'>
						{isLoading && <Spinner color='default'></Spinner>}
						{providence && (
							<ProvidenceCard
								providence={providence}
								mutate={mutate}
                layout={LAYOUTS.COLLABORATOR}
							/>
						)}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xl='12'>
						{isLoading && <Spinner color='default'></Spinner>}
						{isError && (
							<Card className='shadow-card mb-3'>
								<Alert color='warning'>La providencia deseada no existe.</Alert>
							</Card>
						)}
						{providence && (
							<ProvidenceDetails
								layout={LAYOUTS.COLLABORATOR}
								providence={providence}
								{...props}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ProvidenceForCollaboratorView;

ProvidenceForCollaboratorView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ 
      caseId: PropTypes.string.isRequired,
    }),
  }),
};
