import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function usePDF(pdfSettings) {
	const { head, details, body, document, title } = pdfSettings;

	const doc = new jsPDF();
	const totalPagesExp = '{total_pages_count_string}';

	function downloadPDF(filename) {
		doc.save(filename);
	}

	// Providencia
	if (document) {
		doc.setFontSize(12);
		const pageSize = doc.internal.pageSize;
		const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
		const text = doc.splitTextToSize(
			String(document).replace(/\uFFFD/g, ''),
			pageWidth - 35,
			{}
		);

		// const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeigth();
		// doc.text(text, 14, pageHeight - 10);
		doc.text(text, 14, 30);
	}

	let initialTableOffset = 30;
	if (details) {
		initialTableOffset = 60;
		doc.autoTable({
			head: details.head,
			body: details.body,
			startY: 30,
			theme: 'plain',
		});
	}

	// Tablas
	if (body) {
		body.map((subbody, index) => {
			const offsetY =
				index === 0 ? initialTableOffset : doc.lastAutoTable.finalY + 10;

			return doc.autoTable({
				head: head,
				body: subbody,
				startY: offsetY,
				didDrawPage: function (data) {
					// Header
					doc.setFontSize(20);
					doc.setTextColor(40);
					index === 0 && doc.text(title, 20, 22);

					// Footer
					let str = 'Página ' + doc.internal.getNumberOfPages();
					if (typeof doc.putTotalPages === 'function') {
						str += ' de ' + totalPagesExp;
					}
					doc.setFontSize(10);

					const pageSize = doc.internal.pageSize;
					const pageHeight = pageSize.height
						? pageSize.height
						: pageSize.getHeight();
					doc.text(str, data.settings.margin.left, pageHeight - 10);
				},
				margin: { top: index === 0 ? 30 : 20 },
			});
		});
	}

	doc.putTotalPages(totalPagesExp);

	return [doc.output('bloburi'), doc, downloadPDF];
}
