import React from 'react';

const ZapIcon = ({
	className,
	size,
	fill = 'transparent',
	color = 'primary-default',
}) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3 14L13 2L12 10H21L11 22L12 14H3Z'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ZapIcon;
