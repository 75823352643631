import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Row } from 'reactstrap';
import SettingIcon from '../../../../components/Icons/SettingIcon';
import { useHistory } from 'react-router-dom';
import user from '../../../../context/user';
import { officesService } from '../../../../services/officesService';

const OfficeInfo = () => {
	const {
		state: { currentUser },
	} = useContext(user);
	const { offices } = officesService.useOfficesByUserId(currentUser.id);

	const [selectedOffice, setSelectedOffice] = useState('');

	const handleOfficeChange = (e) => {
		setSelectedOffice(offices?.offices?.find((office) => office.id === e.target.value));
	};

	useEffect(() => {
		if (offices?.offices?.length) {
			setSelectedOffice(offices?.offices[0]);
		}
	}, [offices]);

	const history = useHistory();

	const goToConfig = () => history.push('user-profile/configuration');

	return (
		<Card className='shadow-card'>
			<CardHeader>
				<Col>
					<Row>
						<Col
							xs='12'
							lg='4'
						>
							<label>
								<h5 className='text-body-large-bold text-secondary-default'>Información de la oficina:</h5>
							</label>
						</Col>
						<Col>
							<Input
								className='input-group'
								type='select'
								name='office'
								id='office'
								value={selectedOffice.id}
								onChange={handleOfficeChange}
								bsSize='sm'
							>
								{offices?.offices?.map((office) => (
									<option
										key={office.id}
										value={office.id}
									>
										{office.name}
									</option>
								))}
							</Input>
						</Col>
					</Row>
				</Col>
			</CardHeader>
			<CardBody>
				<Row className='p-2'>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Oficina</p>
						<p className='text-body-large m-0'>{selectedOffice?.name ?? '-'}</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Dirección</p>
						<p className='text-body-large m-0'>{selectedOffice?.address}</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Localidad</p>
						<p className='text-body-large m-0'>{selectedOffice?.locality}</p>
					</Col>
				</Row>
				<Row className='p-2'>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Provincia</p>
						<p className='text-body-large m-0'>{selectedOffice?.province}</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Código Postal</p>
						<p className='text-body-large m-0'>{selectedOffice?.cp ?? '-'}</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default m-0'>Teléfono</p>
						<p className='text-body-large m-0'>{selectedOffice?.phone ?? '-'}</p>
					</Col>
				</Row>
			</CardBody>
			<CardFooter>
				<Row>
					<Col>
						<Button
							className='btn-noti-primary py-2 px-5'
							onClick={goToConfig}
						>
							<Row className='align-items-center'>
								<SettingIcon
									className='stroke stroke-neutro-1'
									size={24}
								/>
								<span className='text-button'>Configurar</span>
							</Row>
						</Button>
					</Col>
				</Row>
			</CardFooter>
		</Card>
	);
};

OfficeInfo.propTypes = {};

export default OfficeInfo;
