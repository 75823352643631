import tableHeading from 'components/Tables/TablesHeadings';
import moment from 'moment';
import toArs from './toArs';
import { EXPIRATION_STATUS, MP_PAYMENT_STATUS } from './consts';

export const getColorAndMessageFromStatus = (status) => {
	switch (status) {
		case MP_PAYMENT_STATUS.approved:
			return { color: 'success', msg: 'Aprobado' };
		case MP_PAYMENT_STATUS.in_process:
			return { color: 'info', msg: 'Pendiente' };
		case MP_PAYMENT_STATUS.rejected:
			return { color: 'danger', msg: 'Rechazado' };
	}
};

export const getExpirationPdfSettings = (data) => {
	const pdfSettings = {
		head: [tableHeading.caducidad],
		body: [],
		title: 'Control de Caducidad',
		fileName: 'caducidad',
	};
	const getExpirationStatus = (status) => {
		switch (status) {
			case EXPIRATION_STATUS.EXPIRED:
				return 'Caducado';
			case EXPIRATION_STATUS.CRITIC:
				return 'Por Caducar';
			case EXPIRATION_STATUS.CONTROLLED:
				return 'Controlado';
			default:
				'Sin datos';
		}
	};

	try {
		pdfSettings.body = data
			? [
					data?.map?.(
						(
							{ colaborador, User, Expediente, caducityDate, expirationStatus },
							index
						) => [
							index + 1,
							[Expediente.number, Expediente.year].join('/'),
							Expediente.coverPage,
							colaborador
								? [colaborador.name, colaborador.lastName].join(' ')
								: [User.name, User.lastName],
							Expediente.lastActuationDate
								? moment.utc(Expediente.lastActuationDate).format('DD/MM/YYYY')
								: '',
							caducityDate ? moment.utc(caducityDate).format('DD/MM/YYYY') : '',
							getExpirationStatus(expirationStatus),
						]
					),
			  ]
			: [];
	} catch {
		// console.log(error);
	}
	return pdfSettings;
};

export const getCasesPdfSettings = (data) => {
	const pdfSettings = {
		head: [tableHeading.expedientes],
		body: [],
		title: 'Expedientes',
		fileName: 'expedientes',
	};
	try {
		pdfSettings.body = data
			? [
					data.map((item) => [
						item.number,
						item.year,
						item.coverPage,
						item.Jurisdiction?.name,
						item.OrganismList[0]?.Organism?.Circumscription?.name,
						item.OrganismList[0]?.Organism?.name,
						item.colaborador
							? [item.colaborador?.name, item.colaborador?.lastName].join(' ')
							: ['Sin asignar'],
					]),
			  ]
			: [];
	} catch {
		//console.log(error);
	}
	return pdfSettings;
};

export const getNewsPdfSettings = (data, selectedDay) => {
	const pdfSettings = {
		head: [tableHeading.novedades],
		body: [],
		title: `Novedades del día: ${moment(selectedDay).format('DD/MM/YYYY')}`,
		fileName: `novedades-${moment(selectedDay).format('DD-MM-YYYY')}`,
	};
	try {
		pdfSettings.body = data
			? [
					data?.map?.((item) => {
						return [
							item.number,
							item.year,
							item.coverPage,
							item.Jurisdiction?.name,
							item.OrganismList[0]?.Organism?.Circumscription?.name,
							item.OrganismList[0]?.Organism?.name,
							item.colaborador
								? [item.colaborador?.name, item.colaborador?.lastName].join(' ')
								: ['Sin asignar'],
						];
					}),
			  ]
			: [];
	} catch {
		//console.log(error);
	}
	return pdfSettings;
};

export const getProvidencePdfSettings = (data, details) => {
	const pdfSettings = {
		head: [['Fecha', 'Extracto', 'Firma']],
		details: {
			head: [
				[
					'Expediente',
					'Carátula',
					'Jurisdicción',
					'Organismo',
					'Última novedad',
				],
			],
			body: [],
		},
		body: [],
		title: 'Detalle de expediente',
	};
	try {
		pdfSettings.details.body = details?.Expediente.id
			? [
					[
						`${details?.Expediente.number}/${details?.Expediente.year}`,
						details?.Expediente.coverPage,
						details?.Expediente.OrganismList[0]?.Organism?.Circumscription
							?.name,
						details?.Expediente.OrganismList[0]?.Organism?.name,
						moment
							.utc(details.Expediente.lastActuationDate)
							.format('DD/MM/YYYY'),
					],
			  ]
			: [];
		pdfSettings.body = [
			data?.map((item) => [
				item.actuationDate
					? moment(item.actuationDate).format('DD/MM/YYYY')
					: '',
				item.extract,
				details?.Expediente.OrganismList[0]?.Organism?.name,
			]),
		];
	} catch {
		// console.log(error);
	}
	return pdfSettings;
};

export const getExtractPdfSettings = (extract, filename) => {
	return {
		document: extract,
		title: 'Detalle de expediente',
		fileName: filename,
	};
};

export const getReceiptPdfSettings = (receipt, userdata) => {
	let listServices = '';
	receipt.services?.map(
		(service) => (listServices += `${service.nombre}, ` ?? '-')
	);
	listServices = listServices.slice(0, listServices.length - 2);
	return {
		head: [['Datos del pago', 'Descripción']],
		body: [
			[
				['Referencia de pago:', receipt.referenceMP ?? '-'],
				['Importe abonado:', toArs(receipt.finalAmount) ?? '-'],
				['Servicio/s abonado/s:', listServices],
				[
					'Voucher aplicado:',
					receipt.voucher
						? `- $${receipt.voucher?.amount} (${receipt.voucher?.publicId})`
						: '-',
				],
				['Estado:', getColorAndMessageFromStatus(receipt.status).msg ?? '-'],
				[
					'Fecha de creación:',
					moment(receipt.createdAt).format('DD/MM/YYYY - hh:mm') ?? '-',
				],
				[
					'Fecha de última actualización',
					moment(receipt.updatedAt).format('DD/MM/YYYY - hh:mm') ?? '-',
				],
			],
		],
		details: {
			head: [['Nombre y apellido:', 'Email', 'Telefono']],
			body: [
				[
					`${userdata?.nombre}, ${userdata?.apellido}`,
					`${userdata?.email}`,
					`${userdata?.Perfil?.telefono ?? '---'}`,
				],
			],
			title: 'Datos del cliente:',
		},
		title: `Recibo N° ${receipt.referenceMP ?? 'xxxxxxx'}`,
	};
};
