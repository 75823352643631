import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import user from 'context/user';
import { userService } from 'services/userService';
import { Button, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Label } from 'reactstrap';
import { getOwnerId } from '../../helpers/user';
import MailIcon from '../../components/Icons/MailIcon';
import LockIcon from '../../components/Icons/LockIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import EyeOffIcon from '../../components/Icons/EyeOffIcon';
import { useLocation } from 'react-router-dom';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import CheckIcon from '../../components/Icons/CheckIcon';
import { STATUS_COLORS } from '../../helpers/consts';
import CrossIcon from '../../components/Icons/CrossIcon';
import * as Yup from 'yup';
import DivideCircleIcon from '../../components/Icons/DivideCircleIcon';

const loginInputSchema = Yup.object().shape({
	email: Yup.string().email('El email no es válido.').required('Campo requerido.'),
	password: Yup.string().required('Campo requerido.'),
	keepSession: Yup.string(),
});

/**
 * Login page
 * @component
 * @returns {JSX.Element}
 * @constructor
 */
const Login = () => {
	const history = useHistory();
	const location = useLocation();

	const [showPassword, setShowPassword] = useState(false);
	const [showIsConfirmed, setShowIsConfirmed] = useState(false);
	const [showIsValidated, setShowIsValidated] = useState(false);
	const [showErrorLogin, setShowErrorLogin] = useState(false);
	const [showErrorValidated, setShowErrorValidated] = useState(false);
	const [showIsInactive, setShowIsInactive] = useState(false);

	const changePassword = location.search ? new URLSearchParams(location.search).get('changePassword') : '';
	const id = location.search ? new URLSearchParams(location.search).get('id') : '';
	const reactivateAccount = location.search ? new URLSearchParams(location.search).get('reactivateAccount') : '';

	const {
		actions: { setCurrentUser, addUserdata },
	} = useContext(user);

	useEffect(() => {
		id ? userService.validateNewUser({ id }).then(() => setShowIsValidated(true)) : null;
		changePassword ? setShowIsConfirmed(true) : null;
		reactivateAccount ? setShowIsInactive(true) : null;
	}, []);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			keepSession: false,
		},
		validationSchema: loginInputSchema,
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true);
			userService
				.login(values)
				.then((data) => {
					setSubmitting(false);
					setCurrentUser({
						...data.user,
						owner: getOwnerId(),
						admin: data.user.Admin,
					});
					//.catch((e)=> console.log(e))
					history.push('/admin/');
				})
				.catch((e) => {
					if (e.message === 'User not enabled') {
						setShowErrorValidated(true);
					} else {
						setShowErrorLogin(true);
					}
				})
				.finally(() => setSubmitting(false));
		},
	});

	const handleTogglePassword = () => setShowPassword(!showPassword);

	return (
		<>
			<Col className='bg-white h-100 pt-6'>
				<Col className='mt-3 px-5'>
					<h3 className='text-heading-2 text-lg py-3'>Ingresá a tu cuenta</h3>
					<Form
						role='form'
						onSubmit={formik.handleSubmit}
					>
						<FormGroup className='mb-3'>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MailIcon size='18' />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='email'
									hidden
								>
									Email
								</Label>
								<Input
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									name='email'
									placeholder='Email'
									type='email'
									autoComplete='new-email'
									required
								/>
							</InputGroup>
							{formik.errors.email && formik.touched.email && (
								<span className='text-dark-link-regular text-error'>{formik.errors.email}</span>
							)}
						</FormGroup>
						<FormGroup>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<LockIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Label
									for='password'
									hidden
								>
									Contraseña
								</Label>
								<Input
									onChange={formik.handleChange}
									value={formik.values.password}
									onBlur={formik.handleBlur}
									name='password'
									placeholder='Contraseña'
									type={showPassword ? 'text' : 'password'}
									autoComplete='new-password'
									minLength='6'
									required
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText>
										<span onClick={handleTogglePassword}>
											{showPassword ? (
												<EyeOffIcon size='18' />
											) : (
												<EyeIcon
													className='stroke stroke-primary-default'
													size='18'
												/>
											)}
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							{formik.errors.password && formik.touched.password && (
								<span className='text-dark-link-regular text-error'>{formik.errors.password}</span>
							)}
						</FormGroup>
						<Row className='m-0 justify-content-end '>
							<Link
								className='text-dark-link-regular text-info'
								to='/auth/recoverpassword'
							>
								Olvidé mi contraseña
							</Link>
						</Row>
						<div className='custom-control custom-control-alternative custom-checkbox mt-5'>
							<Input
								onChange={formik.handleChange}
								checked={formik.values.keepSession}
								name='keepSession'
								className='custom-control-input'
								id='customCheckLogin'
								type='checkbox'
							/>
							<label
								className='custom-control-label'
								htmlFor='customCheckLogin'
							>
								<span className='text-body-regular'>Mantener sesión iniciada</span>
							</label>
						</div>
						<div className='my-3'>
							<Button
								className='my-4 p-2 px-5 btn btn-noti-primary'
								type='submit'
								disabled={formik.isSubmitting}
							>
								<span
									className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
									role='status'
									aria-hidden='true'
								></span>
								<span className='text-button '>Iniciar sesión</span>
							</Button>
						</div>
						<div className='my-3'>
							{showErrorLogin && (
								<CustomMessage
									icon={
										<CrossIcon
											size={18}
											color={STATUS_COLORS.error}
										/>
									}
									message='Los datos ingresados son incorrectos. '
								/>
							)}
							{showErrorValidated && (
								<CustomMessage
									icon={
										<CrossIcon
											size={18}
											color={STATUS_COLORS.error}
										/>
									}
									message='Tu cuenta no está habilitada. '
								/>
							)}
							{showIsConfirmed && (
								<CustomMessage
									icon={
										<CheckIcon
											size={18}
											color={STATUS_COLORS.success}
										/>
									}
									message='¡Tu contraseña se cambió con éxito!'
								/>
							)}
							{showIsValidated && (
								<CustomMessage
									icon={
										<CheckIcon
											size={18}
											color={STATUS_COLORS.success}
										/>
									}
									message='¡Tu cuenta se habilitó con éxito!'
								/>
							)}
							{showIsInactive && (
								<CustomMessage
									icon={
										<DivideCircleIcon
											size={18}
											color={STATUS_COLORS.warning}
										/>
									}
									message='Revisá tu e-mail para habilitar la cuenta.'
								/>
							)}
						</div>
					</Form>
				</Col>
			</Col>
		</>
	);
};

export default Login;
