import React, { useState, useContext } from 'react';
import { Button, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import user from '../../../context/user';
import CardAccordion from '../../../components/CardAccordion/CardAccordion';
import { updateCaseConfigurationModal } from './UpdateCaseConfigurationModal/UpdateCaseConfigurationModal';
import CustomMessage from '../../../components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import moment from 'moment';

const CaseFileConfiguration = ({ caseInfo, mutate, canEdit, isMobile = false }) => {
	const {
		state: { userdata },
	} = useContext(user);

	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const handleUpdateConfiguration = async () => {
		try {
			await updateCaseConfigurationModal({ expediente: caseInfo });
			await mutate();
			setShowError(false);
			setShowSuccess(true);
		} catch (error) {
			if (error !== 'closed') {
				setShowSuccess(false);
				setShowError(true);
			}
		}
	};
	const DetailField = ({ field, value }) => (
		<>
			<h3 className='text-body-large-bold text-secondary-default'>{field}</h3>
			<p className='m-0 text-body-large'>{value}</p>
		</>
	);

	DetailField.propTypes = {
		field: PropTypes.string,
		value: PropTypes.string,
	};

	return (
		<>
			<CardAccordion
				title='Configuración'
				defaultValue={false}
			>
				<CardBody>
					<Row className='align-items-center py-2'>
						<Col xl='4'>
							<DetailField
								field='Carpeta'
								value={`${caseInfo?.folder ?? '-'}`}
							/>
						</Col>
						<Col xl='4'>
							<DetailField
								field='Responsable'
								value={
									caseInfo?.Collaborator
										? `${caseInfo?.Collaborator.name} ${caseInfo?.Collaborator.lastName}`
										: `${userdata?.name} ${userdata?.lastName}`
								}
							/>
						</Col>
						<Col xl='4'>
							<DetailField
								field='Domicilio legal'
								value={caseInfo?.Office ? `${caseInfo?.Office.name}` : '-'}
							/>
						</Col>
					</Row>
					<Row className='align-items-center py-2'>
						<Col xl='4'>
							<DetailField
								field='Representación'
								value={caseInfo?.representation?.replaceAll('_', ' ') ?? '-'}
							/>
						</Col>
						<Col xl='4'>
							<DetailField
								field='Fecha de caducidad'
								value={moment(caseInfo?.caducityDate ?? '').format('DD/MM/YYYY') ?? '-'}
							/>
						</Col>
						<Col xl='4'>
							<DetailField
								field='Fecha de último control'
								value={caseInfo?.ControlReviews[0]?.date ? moment(caseInfo?.ControlReviews[0]?.date).format('DD/MM/YYYY') : '-'}
							/>
						</Col>
					</Row>
					{canEdit && !isMobile && (
						<Row className='align-items-center py-2'>
							<Col xl='12'>
								<Row className='align-items-center m-0'>
									<Button
										className='btn btn-noti-primary py-1 px-5'
										onClick={handleUpdateConfiguration}
									>
										<span className='text-button'>Modificar</span>
									</Button>
									{showSuccess && setTimeout(() => setShowSuccess(false), 2000) && (
										<CustomMessage
											className='m-0'
											icon={
												<CheckIcon
													width='18'
													height='18'
													className='stroke stroke-success'
												/>
											}
											message={'¡Datos actualizados!'}
										/>
									)}
									{showError && (
										<CustomMessage
											className='m-0'
											icon={
												<CloseIcon
													width='18'
													height='18'
													className='stroke stroke-error'
												/>
											}
											message={'¡Ha ocurrido un error al actualizar los datos!'}
										/>
									)}
								</Row>
							</Col>
						</Row>
					)}
				</CardBody>
			</CardAccordion>
		</>
	);
};

export default CaseFileConfiguration;

CaseFileConfiguration.propTypes = {
	caseInfo: PropTypes.object,
	mutate: PropTypes.func,
	canEdit: PropTypes.bool,
	isMobile: PropTypes.bool,
};
