import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../CustomModal/CustomModal';
import { Button, Col, ModalBody, Row } from 'reactstrap';
import { create } from 'react-modal-promise';
import { Link } from 'react-router-dom';
import { ReactComponent as SyncIcon } from 'assets/img/icons/notiexpress/sync-icon.svg';
import { ReactComponent as UserIcon } from 'assets/img/icons/notiexpress/user-icon.svg';
import useBreakpoints from '../../../hooks/useBreakpoints';

const SyncErrorModal = ({
	isOpen,
	onReject,
	onResolve,
	jurisdictionName,
	isNoveltyView = false,
}) => {
	const { isMobile } = useBreakpoints();

	return (
		<CustomModal
			title='No se pudo sincronizar la jurisdicción'
			isOpen={isOpen}
			toggle={() => onReject()}
			className={isMobile ? 'w-95' : 'w-75'}
			isMobile={isMobile}
		>
			<ModalBody className={isMobile ? 'p-0 py-2' : ''}>
				<Row className='m-0'>
					<Col xs={12}>
						<p className='text-body-large text-neutro-2'>
							No se pudo sincronizar la jurisdicción de {jurisdictionName}. Las
							razones pueden ser:
						</p>
					</Col>
					<Col xs={12}>
						<ul className='text-body-large text-neutro-2'>
							<li className='py-3'>
								La conexión se encuentra momentáneamente fuera de servicio.
							</li>
							<li className='py-3'>
								Las credenciales para la conexión son incorrectas
								{!isMobile && (
									<Button
										className='btn btn-noti-sync ml-3 py-2 px-5'
										tag={Link}
										to='/admin/connections'
										onClick={() => onResolve()}
									>
										<Row className='m-0 align-items-center'>
											<SyncIcon
												className='fill-secondary-default'
												width={24}
												height={24}
											/>
											<span className=''>Mis conexiones</span>
										</Row>
									</Button>
								)}
							</li>
							<li className='py-3'>
								La suscripción se encuentra inactiva.
								{!isMobile && (
									<Button
										className='btn btn-noti-sync ml-3 py-2 px-5'
										tag={Link}
										to='/admin/user-profile'
										onClick={() => onResolve()}
									>
										<Row className='m-0 align-items-center'>
											<UserIcon
												className='fill-secondary-default'
												width={24}
												height={24}
											/>
											<span className=''>Mi perfil</span>
										</Row>
									</Button>
								)}
							</li>
						</ul>
					</Col>
				</Row>
				<Row className='px-3 pt-3'>
					<Col>
						<Row
							className={
								isMobile
									? 'justify-content-center m-0 align-items-center'
									: 'm-0 align-items-center'
							}
						>
							<Button
								className='btn btn-noti-primary py-1 px-5'
								onClick={() => onReject()}
							>
								<span className='text-button'>Aceptar</span>
							</Button>
						</Row>
					</Col>
				</Row>
			</ModalBody>
		</CustomModal>
	);
};

SyncErrorModal.propTypes = {
	jurisdictionName: PropTypes.string,
	onReject: PropTypes.func,
	onResolve: PropTypes.func,
	isOpen: PropTypes.bool,
	isNoveltyView: PropTypes.bool,
};

export const syncErrorModal = create(SyncErrorModal);
