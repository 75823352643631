import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink, Pagination as PaginationReactstrap } from 'reactstrap';

/**
 * Pagination component
 * @component
 * @param {Function} handleSetPage
 * @param {number} count
 * @param {number} pageCount
 * @param {number} page
 * @param {number} rowsPerPage
 * @param {boolean} disabled
 * @returns JSXElement
 */
const Pagination = ({ handleSetPage, count, pageCount, page, rowsPerPage, disabled }) => {
	const to = page ? (page * rowsPerPage > count ? count : page * rowsPerPage) : 0;
	const isNextDisabled = to === count;
	const isPreviousDisabled = page === 1;

	function _handleNextPage(e) {
		e.preventDefault();
		e.stopPropagation();
		if (typeof handleSetPage === 'function') {
			return handleSetPage(page + 1);
		}
	}

	function _handleGoToPage(newPage) {
		if (typeof handleSetPage === 'function') {
			return handleSetPage(newPage);
		}
	}

	function _handlePreviousPage(e) {
		e.preventDefault();
		e.stopPropagation();
		if (typeof handleSetPage === 'function') {
			return handleSetPage(page - 1);
		}
	}

	function _handleSetFirstPage(e) {
		e.preventDefault();
		e.stopPropagation();
		if (typeof handleSetPage === 'function') {
			return handleSetPage(1);
		}
	}

	function _handleSetLastPage(e) {
		e.preventDefault();
		e.stopPropagation();
		if (typeof handleSetPage === 'function') {
			return handleSetPage(pageCount);
		}
	}

	return (
		<div
			className='position-absolute pr-5 pt-2'
			style={{
				zIndex: 1,
				width: '180px',
				right: '0',
			}}
		>
			{/*<Col>
      <p className="m-0 mx-1 text-right">
        Mostrando {from} - {to} de {count} elementos
      </p>
    </Col>*/}
			<PaginationReactstrap
				className='pagination justify-content-end'
				listClassName='mb-0'
				size='md'
			>
				{page - 1 >= 1 && (
					<>
						<PaginationItem disabled={disabled || isPreviousDisabled}>
							<PaginationLink
								first
								onClick={_handleSetFirstPage}
							/>
						</PaginationItem>
						<PaginationItem disabled={disabled || page <= 1}>
							<PaginationLink onClick={() => _handleGoToPage(page - 1)}>
								<span>{page && page - 1 >= 1 ? page - 1 : '-'}</span>
							</PaginationLink>
						</PaginationItem>
					</>
				)}
				<PaginationItem
					disabled={disabled}
					active
				>
					<PaginationLink>
						<span>{page ? page : '-'}</span>
					</PaginationLink>
				</PaginationItem>
				{page + 1 <= pageCount && (
					<>
						<PaginationItem disabled={disabled || page >= pageCount}>
							<PaginationLink onClick={() => _handleGoToPage(page + 1)}>
								<span>{page && page + 1 <= pageCount ? page + 1 : '-'}</span>
							</PaginationLink>
						</PaginationItem>
						<PaginationItem disabled={disabled || isNextDisabled}>
							<PaginationLink
								last
								onClick={_handleSetLastPage}
							/>
						</PaginationItem>
					</>
				)}
			</PaginationReactstrap>
		</div>
	);
};

Pagination.propTypes = {
	count: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	handleSetPage: PropTypes.func.isRequired,
	pageCount: PropTypes.number,
	disabled: PropTypes.bool,
};

export default Pagination;
