import React from 'react';
import PropTypes from 'prop-types';

const RefreshIcon = ({
	size,
}) => {
	return (
		<svg
			className={'fill-primary-default fill'}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.2124 2.24899C12.5648 1.8762 10.8497 1.92686 9.22695 2.39623C7.6094 2.86411 6.13627 3.73285 4.9441 4.92178L2 7.68822V4C2 3.44772 1.55228 3 1 3C0.447715 3 0 3.44772 0 4V10C0 10.0008 4.31955e-07 10.0017 2.44213e-06 10.0025C0.000282392 10.1177 0.0200456 10.2283 0.0561704 10.3313C0.100939 10.4591 0.172542 10.5798 0.27125 10.6849C0.438494 10.8628 0.655541 10.966 0.880443 10.9929C0.924363 10.9982 0.968582 11.0006 1.01279 11H7C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9H3.52463L6.32478 6.36883C6.3324 6.36167 6.3399 6.3544 6.34729 6.34701C7.30262 5.3912 8.48451 4.69297 9.78267 4.31747C11.0808 3.94198 12.453 3.90145 13.771 4.19968C15.0891 4.49791 16.3101 5.12517 17.3202 6.02293C18.3303 6.9207 19.0965 8.0597 19.5473 9.33367C19.7315 9.85432 20.303 10.127 20.8236 9.94279C21.3442 9.75855 21.617 9.18713 21.4327 8.66648C20.8692 7.07402 19.9115 5.65026 18.6489 4.52806C17.3863 3.40585 15.86 2.62177 14.2124 2.24899ZM23.6616 13.2501C23.4853 13.0945 23.2537 13 23 13H17C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15H20.4755L17.6752 17.6313L17.6662 17.6399L17.6527 17.6531C16.6974 18.609 15.5155 19.3072 14.2173 19.6827C12.9192 20.0582 11.547 20.0987 10.229 19.8005C8.91091 19.5022 7.68988 18.875 6.6798 17.9772C5.66973 17.0795 4.90353 15.9404 4.45272 14.6665C4.26848 14.1458 3.69705 13.8731 3.17641 14.0574C2.65576 14.2416 2.38305 14.813 2.56729 15.3337C3.13081 16.9261 4.08855 18.3499 5.35114 19.4721C6.61373 20.5943 8.14003 21.3784 9.78761 21.7512C11.4352 22.1239 13.1503 22.0733 14.7731 21.6039C16.3906 21.136 17.8637 20.2673 19.0559 19.0784L22 16.3119V20C22 20.5523 22.4477 21 23 21C23.5523 21 24 20.5523 24 20V14.0036V14C24 13.8515 23.9677 13.7106 23.9096 13.584C23.8836 13.5271 23.8523 13.473 23.8165 13.4225C23.7716 13.3592 23.7196 13.3013 23.6616 13.2501Z'
			/>
		</svg>
	);
};

RefreshIcon.propTypes = {
	size: PropTypes.number,
	fill: PropTypes.string,
	color: PropTypes.string,
  className: PropTypes.string,
};

export default RefreshIcon;
