import React from 'react';
import { Card, Container } from 'reactstrap';
import HomeHeader from '../../components/Headers/HomeHeader';
import TermsAndConditionsContent from './TermsAndConditionsContent';

const TermsAndConditionsView = () => {
	return (
		<>
			<HomeHeader headerText='Términos y condiciones' />
			<Container
				className='bg-white mb-5'
				fluid={true}
			>
				<Card className='shadow-card'>
          <TermsAndConditionsContent />
				</Card>
			</Container>
		</>
	);
};

export default TermsAndConditionsView;
