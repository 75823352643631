import React from 'react';
import { Provider as GlobalProvider } from '../context/global';
import { SynchronizeProvider } from '../context/synchronize';

const AuthenticatedProvider = ({ children }) => {
	return (
		<>
			<GlobalProvider>
				<SynchronizeProvider>{children}</SynchronizeProvider>
			</GlobalProvider>
		</>
	);
};

export default AuthenticatedProvider;
