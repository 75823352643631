import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import HomeHeader from 'components/Headers/HomeHeader';
import queryString from 'query-string';
import 'moment/locale/es';
import { casesService } from '../../services/casesService';
import { useLocation } from 'react-router-dom';
import TableOptionsButtons from 'components/Commons/TableOptionsButtons/TableOptionsButtons';
import DynamicTable from '../../components/Commons/DynamicTable';
import user from '../../context/user';
import { LAYOUTS, ROLES, CHART_BAR_EXPIRATION_COLORS } from '../../helpers/consts';
import { createNewObjectWithoutProperties, parseObjectToQS } from '../../helpers/utils';
import Pagination from '../../components/Commons/Pagination';
import { useHistory } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakpoints';
import useExpirationTableColumns from './hooks/useExpirationTableColumns';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import { ReactComponent as SettingsIcon } from 'assets/img/icons/notiexpress/setting-icon.svg';
import CardFooter from 'reactstrap/lib/CardFooter';
import CardResumeDonut from '../../components/Charts/CardResumeDonut.jsx';
import CardResumeBar from '../../components/Charts/CardResumeBar';
import { ReactComponent as UsersIcon } from '../../assets/img/icons/notiexpress/users.svg';

const Caducidad = (props) => {
	const location = useLocation();
	const history = useHistory();
	const [params, setParams] = useState({});

	const {
		state: { currentUser },
	} = useContext(user);

	const { isMobile } = useBreakpoints();
	const rowsPerPage = isMobile ? 10 : 10;
	const {
		data: casesExpiredList,
		isError,
		isLoading,
	} = casesService.usePaginatedExpirationCases({
		params: params,
	});

	const { data: casesExpirationStatusCount, isLoading: isLoadingCount } = casesService.useCaseExpirationStatusCount();

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		setParams({
			page: 1,
			take: rowsPerPage,
			...params,
			...currentParams,
			...(currentUser.rol === ROLES.COLLABORATOR ? { collaborator: currentUser.id } : {}),
		});
	}, [location.search]);

	function handleSetPage(page) {
		setParams({ ...params, page: page });
	}

	useEffect(() => {
		history.push(`/admin/caducidad?${parseObjectToQS(params)}`);
	}, [params]);

	const { currentColumns } = useExpirationTableColumns();

	const handleResetSearch = (resetParams) => {
		const newParams = createNewObjectWithoutProperties({
			keys: resetParams,
			object: params,
		});
		setParams(newParams ?? {});
	};

	const barData = casesExpirationStatusCount?.expiredByCollaborator?.map((collaborator, index) => {
		return {
			name: collaborator.name + ' ' + collaborator.lastName,
			value: collaborator.value,
			color: CHART_BAR_EXPIRATION_COLORS[index],
		};
	});

	return (
		<>
			<HomeHeader
				{...props}
				headerText='Control de Caducidad'
			/>
			{currentUser?.rol === ROLES.USER && (
				<Container
					className='bg-gradient-white'
					fluid={true}
				>
					<InfoMessage
						messageKey={'non_working_days_advice'}
						highlight={'RECORDÁ: '}
						title={
							'Los días sábados, domingos y el mes de enero no serán tomados en cuenta para el cálculo de días hábiles. También podés cargar días inhábiles adicionales.'
						}
						icon={
							<SettingsIcon
								width={20}
								height={20}
								className='stroke-primary-default'
							/>
						}
						to='expedientes/configuration'
						iconButton={true}
					/>
				</Container>
			)}
			<Container
				className='bg-gradient-white'
				fluid={true}
        style={{
          minHeight: '120px',
        }}
			>
				<Row
					className='mb-3 px-3'
					style={{
						gap: '1rem',
					}}
				>
					<Col className='px-0'>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.ADMIN}
							cardTitle='controlled'
							isLoading={isLoadingCount}
						/>
					</Col>
					<Col className='px-0'>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.ADMIN}
							cardTitle='alert'
							isLoading={isLoadingCount}
						/>
					</Col>
					<Col className='px-0'>
						<CardResumeDonut
							casesExpirationStatusCount={casesExpirationStatusCount}
							layout={LAYOUTS.ADMIN}
							cardTitle='expired'
							isLoading={isLoadingCount}
						/>
					</Col>
					<Col className='px-0'>
						<CardResumeBar
							data={barData ?? []}
							subtitle='Responsable'
							cardTitle='Críticos por'
							isLoading={isLoadingCount}
						>
							<UsersIcon
								width={20}
								height={20}
								className='stroke-danger'
							/>
						</CardResumeBar>
					</Col>
				</Row>
			</Container>
			<Container
				className='bg-white'
				fluid={true}
			>
				<Row
					xs='3'
					className='mb-3'
				>
					<Col>
						<Card className='shadow mb-3'>
							<CardBody className='pt-0 '>
								<Row className='m-0 justify-content-between py-5'>
									<Pagination
										rowsPerPage={rowsPerPage ?? 15}
										page={casesExpiredList?.currentPage ?? 0}
										pageCount={casesExpiredList?.totalPage ?? 0}
										count={casesExpiredList?.totalCount ?? 0}
										handleSetPage={handleSetPage}
										disabled={isLoading || isError}
									/>
									<Col xs={12}>
										<TableOptionsButtons
											tableType='expiration'
											data={casesExpiredList?.paginateData ?? []}
											canSearch
											canAdd={false}
											resetSearch={handleResetSearch}
											layout={LAYOUTS.ADMIN}
										/>
									</Col>
								</Row>
								<DynamicTable
									data={casesExpiredList?.paginateData ?? []}
									columns={currentColumns}
									linkTo='/admin/expedientes/'
									propertyId='id'
									isLoading={isLoading}
									isError={isError}
									noDataMessage='No se encontraron expedientes.'
									maxColumnWidth='600px'
								/>
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Expedientes ({casesExpiredList?.totalCount ?? '-'})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Caducidad;

Caducidad.propTypes = {};
