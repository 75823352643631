import { useEffect } from 'react';
import queryString from 'query-string';
import {
	createNewObjectWithoutProperties,
	parseObjectToQS,
} from '../../../helpers/utils';

export default function useUpdateSearchParams({
	history,
	params,
	setParams,
	rowsPerPage,
	layout,
}) {
	useEffect(() => {
		const currentParams = queryString.parse(history.location.search);
		setParams({
			page: 1,
			take: rowsPerPage,
			...params,
			...currentParams,
		});
	}, [history.location.search]);

	function handleSetPage(page) {
		setParams({ ...params, page: page });
	}

	useEffect(() => {
		history.push(`/${layout}/caducidad?${parseObjectToQS(params)}`);
	}, [params]);

	const handleResetSearch = (resetParams) => {
		const newParams = createNewObjectWithoutProperties({
			keys: resetParams,
			object: params,
		});
		setParams(newParams ?? {});
	};

	return { handleSetPage, handleResetSearch };
}
