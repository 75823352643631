import { API } from 'helpers/fetch';
import { getCurrentId } from 'helpers/user';
import useSWR from 'swr';

export const collaboratorService = {
	useCollaboratorsTable,
	create,
	update,
	remove,
	getFromUser,
};

async function fetcher(url) {
	return await API.get(url);
}

function useCollaboratorsTable() {
	const { data, error, mutate } = useSWR(
		`/user/collaborators`,
		fetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
}

function create({ name, lastName, email, password, licensePlate }) {
	return API.post(`/user/${getCurrentId()}/collaborator`, {
		name,
		lastName,
		email,
		password,
		licensePlate,
	});
}

function update({ id, name, lastName, email, password, licensePlate }) {
	const params = { name, lastName, email, licensePlate };
	return API.put(
		`/user/collaborator/${id}`,
		password
			? {
					...params,
					password,
			  }
			: params
	);
}

function remove(id) {
	return API.delete(`/user/${getCurrentId()}/collaborator/${id}`);
}

function getFromUser() {
	return API.get(`/user/collaborators`);
}
