import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader, Row, Spinner } from 'reactstrap';
import CaseFileDetail from './CaseFileDetail';
import ToggleButton from '../../../components/Commons/ToggleButton/ToggleButton';
import { casesService } from '../../../services/casesService';
import { ReactComponent as EyeIcon } from 'assets/img/icons/notiexpress/eye-icon.svg';
import { successAddedCaseModal } from './SuccessAddedCaseModal/SuccessAddedCaseModal';
import { deleteCaseModal } from './DeleteCaseModal/DeleteCaseModal';
import { useHistory } from 'react-router-dom';

const CaseFileTabs = ({
	caseInfo,
	mutate: mutateCase,
	isLoading,
	isError,
	preview,
	handleGetPreview,
	handleAddExpedient,
	autoSync,
  isMobile = false,
}) => {
	const history = useHistory();

	const [isAddedExpedient, setIsAddedExpedient] = useState(!preview);
	const [showGetPreview, setShowGetPreview] = useState({ status: 'start' });
	const [showAddExpedient, setShowAddExpedient] = useState({ status: 'start' });
	const [showDeleteExpedient, setShowDeleteExpedient] = useState({
		status: 'start',
	});

	const handleOnClick = async () => {
		setShowGetPreview({ status: 'loading' });
		await handleGetPreview();
		setShowGetPreview({ status: 'success' });
	};

	const handleToggleAdd = async (checked) => {
		try {
			if (caseInfo.id) {
				if (caseInfo?.deleted) {
					setIsAddedExpedient(checked);
					setShowGetPreview({ status: 'success' });
					setShowAddExpedient({ status: 'loading' });
					await casesService.addDeletedCase(caseInfo?.id);
					mutateCase();
					setShowAddExpedient({ status: 'success' });
				} else {
          setShowDeleteExpedient({ status: 'loading' });
					await deleteCaseModal();
					await casesService.deleteCase(caseInfo?.id);
					mutateCase();
					setShowDeleteExpedient({ status: 'success' });
					setIsAddedExpedient(checked);
				}
			} else {
				setIsAddedExpedient(checked);
				setShowGetPreview({ status: 'success' });
				setShowAddExpedient({ status: 'loading' });
				const response = await handleAddExpedient();
				setShowAddExpedient({ status: 'success' });
        try {
          await successAddedCaseModal();
          history.push(`/admin/expedientes/${response?.expId}`);
        } catch (error) {
          history.push('/admin/expedientes/');
        }
			}
		} catch (error) {
			setIsAddedExpedient(!checked);
			setShowAddExpedient({ status: 'start' });
			setShowDeleteExpedient({ status: 'start' });
		}
	};

	return (
		<Card className='shadow-card mb-3'>
			{autoSync && !isMobile && (
				<CardHeader className=''>
					<Row className='align-items-center m-0 justify-content-between'>
						<Row className='m-0'>
							<h1 className='m-0 text-heading-2'>
								{!caseInfo?.deleted && caseInfo?.id
									? 'Quitar expediente'
									: 'Agregar expediente'}
							</h1>
							<div className='ml-3 d-flex align-items-center'>
								<ToggleButton
									checked={!caseInfo?.deleted && isAddedExpedient}
									setChecked={handleToggleAdd}
								/>
							</div>
						</Row>
						{preview && showGetPreview.status !== 'success' && (
							<Row className='align-items-center m-0'>
								<span className='text-heading-2 mr-2'>
									{showGetPreview.status === 'start'
										? 'Vista previa'
										: showGetPreview.status === 'loading'
										? 'Obteniendo vista previa...'
										: ''}
								</span>
								<Button
									className='btn btn-noti-icon-primary px-2'
									onClick={handleOnClick}
								>
									{showGetPreview.status === 'loading' && <Spinner />}
									{showGetPreview.status === 'start' && (
										<EyeIcon
											className='stroke-primary-default '
											width='24'
											height='24'
										/>
									)}
								</Button>
							</Row>
						)}
						{preview && showAddExpedient.status !== 'start' && (
							<Row className='align-items-center m-0'>
								<Spinner />
								<span className='text-heading-2 mr-2'>
									{showAddExpedient.status === 'loading'
										? 'Agregando expediente...'
										: showAddExpedient.status === 'success'
										? '¡Expediente agregado!'
										: ''}
								</span>
							</Row>
						)}
						{preview && showDeleteExpedient.status !== 'start' && (
							<Row className='align-items-center m-0'>
								<Spinner />
								<span className='text-heading-2 mr-2'>
									{showAddExpedient.status === 'loading'
										? 'Quitando expediente...'
										: showAddExpedient.status === 'success'
										? '¡Expediente quitado!'
										: ''}
								</span>
							</Row>
						)}
					</Row>
				</CardHeader>
			)}
			<CardBody>
				<CaseFileDetail
					caseInfo={caseInfo}
					isLoading={isLoading}
					isError={isError}
				/>
			</CardBody>
		</Card>
	);
};

export default CaseFileTabs;

CaseFileTabs.propTypes = {
	caseInfo: PropTypes.object,
	mutate: PropTypes.func,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	history: PropTypes.object,
	redirectExpediente: PropTypes.string,
	updateCaseFile: PropTypes.func,
	preview: PropTypes.bool,
	handleGetPreview: PropTypes.func,
	handleAddExpedient: PropTypes.func,
	autoSync: PropTypes.bool,
  isMobile: PropTypes.bool,
};
