import React from 'react';
import PropTypes from 'prop-types';

const ProfilePhotoIcon = ({ className, size, fill = 'transparent' }) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 36 36'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g filter='url(#filter0_i_167_3751)'>
				<g filter='url(#filter1_i_167_3751)'>
					<circle
						cx='18'
						cy='18'
						r='18'
						fill='#0592E0'
					/>
				</g>
				<path
					d='M20.1949 16.7601C19.5868 16.756 18.9783 16.7601 18.3703 16.7601H18.2445C18.2589 16.7085 18.2687 16.6745 18.2795 16.6394C18.5546 15.7748 18.8296 14.9099 19.1047 14.0449C19.1258 13.9784 19.1325 13.916 19.0557 13.8784C18.9788 13.8407 18.9175 13.8784 18.8674 13.9377C17.6985 15.3012 16.5305 16.6651 15.3633 18.0293C15.3302 18.0679 15.3055 18.1432 15.3256 18.1793C15.3457 18.2154 15.4287 18.2417 15.4803 18.2422C16.0847 18.2464 16.6896 18.2422 17.294 18.2422H17.4297C17.4209 18.2783 17.4168 18.301 17.4101 18.3232C17.1326 19.1978 16.8545 20.0725 16.5757 20.9471C16.5535 21.0167 16.538 21.0832 16.6226 21.1214C16.7072 21.1596 16.7587 21.1214 16.8108 21.0611C17.9763 19.7003 19.1421 18.3394 20.3083 16.9783C20.3271 16.9567 20.3405 16.9311 20.3476 16.9034C20.3547 16.8757 20.3552 16.8468 20.349 16.8189C20.3377 16.7854 20.25 16.7601 20.1949 16.7601Z'
					fill='white'
				/>
			</g>
			<defs>
				<filter
					id='filter0_i_167_3751'
					x='0'
					y='0'
					width='40'
					height='40'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood
						floodOpacity='0'
						result='BackgroundImageFix'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='BackgroundImageFix'
						result='shape'
					/>
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feMorphology
						radius='15'
						operator='erode'
						in='SourceAlpha'
						result='effect1_innerShadow_167_3751'
					/>
					<feOffset
						dx='4'
						dy='4'
					/>
					<feGaussianBlur stdDeviation='8' />
					<feComposite
						in2='hardAlpha'
						operator='arithmetic'
						k2='-1'
						k3='1'
					/>
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0'
					/>
					<feBlend
						mode='normal'
						in2='shape'
						result='effect1_innerShadow_167_3751'
					/>
				</filter>
				<filter
					id='filter1_i_167_3751'
					x='0'
					y='-4'
					width='40'
					height='40'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood
						floodOpacity='0'
						result='BackgroundImageFix'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='BackgroundImageFix'
						result='shape'
					/>
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feMorphology
						radius='3'
						operator='erode'
						in='SourceAlpha'
						result='effect1_innerShadow_167_3751'
					/>
					<feOffset
						dx='4'
						dy='-4'
					/>
					<feGaussianBlur stdDeviation='4' />
					<feComposite
						in2='hardAlpha'
						operator='arithmetic'
						k2='-1'
						k3='1'
					/>
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.2 0 0 0 0 0.360784 0 0 0 0 0.505882 0 0 0 0.2 0'
					/>
					<feBlend
						mode='normal'
						in2='shape'
						result='effect1_innerShadow_167_3751'
					/>
				</filter>
			</defs>
		</svg>
	);
};

ProfilePhotoIcon.propTypes = {
	size: PropTypes.number,
	fill: PropTypes.string,
};

export default ProfilePhotoIcon;
