import React, { useContext, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Row,
	Spinner,
	UncontrolledDropdown,
	Container,
} from 'reactstrap';
import { FiPlus } from 'react-icons/fi';
import { FaCircle } from 'react-icons/fa';
import global from 'context/global';
import NotificationItem from './components/NotificationItem';
import { notifService } from '../../../services/notificationsService';
import { getCurrentId } from '../../../helpers/user';
import Swal from 'sweetalert2';
import BellIcon from '../../Icons/BellIcon';
import MinimizeIcon from '../../Icons/MinimizeIcon';
import MaximizeIcon from '../../Icons/MaximizeIcon';
import SettingsIcon from '../../Icons/SettingIcon';
import EyeIcon from '../../Icons/EyeIcon';
import user from 'context/user';
import { ROLES } from '../../../helpers/consts';
import { Link } from 'react-router-dom';

const NotificationBell = () => {
	const {
		state: { currentUser },
	} = useContext(user);
	const {
		state: {
			notifications: { data: notifications, isLoading, isError, mutate, hasMoreNotifications },
		},
		actions: { handleNewNotification, getMoreNotifications },
	} = useContext(global);

	const [expanded, setExpanded] = useState(false);

	const MarkAllAsReadButton = () => {
		const [isSubmitting, setIsSubmitting] = useState(false);

		const handleMarkAsReadAllNotifications = () => {
			setIsSubmitting(true);
			notifService
				.markAllNotificationsAsReadByUserId({ userId: getCurrentId() })
				.then(() =>
					Swal.fire({
						icon: 'success',
						timer: 2500,
						position: 'bottom-end',
						toast: true,
						showConfirmButton: false,
						title: 'Notificaciones marcadas como leídas.',
					}).then(() => mutate())
				)
				.catch(() =>
					Swal.fire({
						icon: 'error',
						toast: true,
						timer: 2500,
						position: 'bottom-end',
						title: 'No se pudo realizar la operación.',
						showConfirmButton: false,
					})
				);
		};

		return (
			<Button
				type='button'
				className='btn btn-link border-0 shadow-none p-0 m-0'
				outline
				disabled={!notifications?.some(({ isRead }) => !isRead) || isSubmitting}
				size='sm'
				onClick={(e) => {
					e.preventDefault();
					handleMarkAsReadAllNotifications();
				}}
			>
				<Row className='align-items-center m-0 d-flex justify-content-center'>
					<span className='text-body-small text-primary-default'>Marcar todas como leídas</span>
					<div className='pl-3'>
						<EyeIcon
							size={18}
							className='stroke stroke-primary-default'
						/>
					</div>
				</Row>
			</Button>
		);
	};

	return (
		<Nav navbar>
			<UncontrolledDropdown nav>
				<DropdownToggle
					className='nav-link position-relative d-flex align-items-center h-100'
					nav
					onClick={() => {
						handleNewNotification(false);
					}}
				>
					<span className='position-relative'>
						<BellIcon
							size={24}
							color='white'
						/>
					</span>
					{notifications?.some((notification) => !notification.isRead) ? (
						<FaCircle
							className='position-absolute'
							style={{ marginLeft: '', right: '16', top: '7' }}
							size='11'
							color='red'
						/>
					) : null}
				</DropdownToggle>
				<DropdownMenu
					style={
						expanded
							? { height: '90vh', overflowY: 'auto', overflowX: 'hidden', minWidth: '500px' }
							: { height: 'auto', overflowY: 'auto', overflowX: 'hidden', minWidth: '500px' }
					}
					right
				>
					<DropdownItem
						className='m-1 p-0 w-100'
						key='-1'
						text
						tag='div'
					>
						<Row className='mb-3'>
							<Col
								xs='2'
								className='d-flex justify-content-center justify-content-start m-0 p-0'
							>
								<Button
									type='button'
									disabled={!notifications?.length}
									outline
									onClick={() => setExpanded(!expanded)}
									className='shadow-none border-0 p-0'
								>
									{expanded ? (
										<MinimizeIcon
											size={18}
											className='stroke stroke-primary-default'
										/>
									) : (
										<MaximizeIcon
											size={18}
											className='stroke stroke-primary-default'
										/>
									)}
								</Button>
							</Col>
							<Col xs='8'>
								<p className='text-heading-3 text-center m-0'>Mis Notificaciones</p>
							</Col>
							<Col
								xs='2'
								className='text-center d-flex align-items-center justify-content-center justify-content-end'
							>
								{currentUser?.rol === ROLES.USER && (
									<DropdownItem
										key={-3}
										tag={Link}
										to='/admin/expedientes/configuration'
										style={{
											backgroundColor: 'transparent',
										}}
										className='shadow-none border-0 p-0 m-0'
									>
										<SettingsIcon
											className='stroke stroke-primary-default'
											size={18}
										/>
									</DropdownItem>
								)}
							</Col>
						</Row>
						<Row className='justify-content-end w-100 px-3'>
							<MarkAllAsReadButton />
						</Row>
					</DropdownItem>
					<DropdownItem
						key='-2'
						divider
						className='py-1'
					/>
					{notifications?.length && !isError ? (
						<>
							{notifications
								?.map(({ _doc: notification }) => {
									return (
										<Container key={notification._id}>
											<NotificationItem
												notification={notification}
												mutate={mutate}
											/>
											<DropdownItem
												key='-2'
												divider
												className='py-1'
											/>
										</Container>
									);
								})
								.slice(0, expanded ? notifications?.length : 5)}
							{notifications?.length > 0 && hasMoreNotifications && expanded ? (
								<Row className='justify-content-center py-2'>
									<Button
										className='btn-outline-noti-secondary py-1 px-5'
										onClick={() => getMoreNotifications()}
									>
										<Row className='align-items-center m-0'>
											<span className=''>Ver más</span>
											<FiPlus
												size={18}
												className=''
											/>
										</Row>
									</Button>
								</Row>
							) : null}
						</>
					) : isLoading ? (
						<Row
							style={{ width: 500 }}
							className='justify-content-center'
						>
							<Spinner
								size='md'
								color='primary'
							></Spinner>
						</Row>
					) : (
						<>
							<Row
								style={{ width: 500 }}
								className='justify-content-center'
							>
								<Row className='align-items-center'>
									<Alert
										color='secondary'
										className='mt-2'
									>
										<span className='font-italic'>No hay notificaciones.</span>
									</Alert>
								</Row>
							</Row>
						</>
					)}
				</DropdownMenu>
			</UncontrolledDropdown>
		</Nav>
	);
};

export default NotificationBell;
