import { API } from 'helpers/fetch';
import { getCurrentId } from 'helpers/user';
import useSWR from 'swr';

export const connectionsService = {
	useConnectionsTable,
	create,
	getJurisdiccionId: getJurisdictionId,
	update,
	remove,
};

function fetcher(url) {
	return API.get(url);
}

function useConnectionsTable() {
	const { data, error, mutate } = useSWR(
		`/userConnection/user/${getCurrentId()}`,
		fetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
}

async function create({
	userLogin,
	passwordLogin,
	jurisdictionId,
	licensePlate,
}) {
	return API.post('/userConnection', {
		userLogin,
		passwordLogin,
		jurisdictionId,
		licensePlate,
	});
}

function getJurisdictionId(name) {
	return API.get(`/jurisdiction?name=${name}`).then((res) => res.id);
}

function update({ id, userLogin, passwordLogin, jurisdiction, matricula }) {
	return API.put(`/userConnection/${id}`, {
		jurisdictionId: jurisdiction,
		userLogin,
		passwordLogin,
		licensePlate: matricula,
	});
}

function remove(id) {
	return API.delete(`/userConnection/${id}`);
}
