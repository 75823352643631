import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';

function VTlist(props) {
  const Clicked = () => {
    props.onClick(props.index);
  };

  return (
    <Row key={props.index}>
      <Button
        onClick={Clicked}
        className={props.activeTabId === props.index ? 'landing-button active' : 'landing-button '}
        tag={'a'}
      >
        {<props.data.expData.icon/>}
      </Button>
    </Row>
  );
}

VTlist.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  index: PropTypes.number,
  activeTabId: PropTypes.number
};

export default VTlist;
