import React from 'react';
import { CardBody } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LegalDisclaimerContent = () => {
	return (
		<CardBody>
			<h1 className='text-heading-3 text-secondary-default'>
				ÚLTIMA ACTUALIZACIÓN: 23/06/2023
			</h1>
			<p>
				<strong className='text-heading-3 text-secondary-default'>
					Lineamientos generales
				</strong>
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					¿Quién se encuentra detrás de la plataforma?
				</strong>
			</p>
			<p className='text-body-regular'>
				Notiexpress (en adelante, la “plataforma” y colectivamente el
				“propietario” o “nosotros” o &ldquo;nos&rdquo;) es una creación de
				Carlos Alberto Díaz, CUIT 20-30791958-4, con domicilio legal en Hipólito
				Irigoyen 352, Resistencia, Chaco, Código Postal 3.500.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					¿Qué se pretende con este Aviso legal?
				</strong>
			</p>
			<p className='text-body-regular'>
				Con este Aviso legal se te quiere informar, en tu carácter de usuario,
				quién se encuentra detrás de la plataforma y qué tipo de información
				recogemos y para qué la necesitamos. Junto con este texto, se han
				dispuesto otros textos legales, como ser los{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link> y
				la{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link> ,
				los cuales pueden ser visualizados haciendo click en ellos de manera
				gratuita.
			</p>
			<p className='text-body-regular'>
				El uso de la plataforma, así como de cualquier servicio o contenido
				facilitado en la misma supondrá tu consentimiento voluntario, expreso e
				informado de los{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link> y{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link> ,
				quedándote vinculado legalmente a los mismos como firmante de estos. En
				caso de que no estés dispuesto a aceptarlos, te rogamos que no accedas
				ni utilices esta plataforma y/o que nos informes de tu desistimiento
				como cancelación de los servicios contratados, según corresponda.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					¿A qué público se encuentra dirigida la plataforma de Notiexpress?
				</strong>
			</p>
			<p className='text-body-regular'>
				Notiexpress está dirigida actualmente a personas con matrícula
				profesional en Derecho vigente en: 1) Provincia de Chaco en materia Civil y Comercial;2) Capital Federal; y 3) Justicia Federal.
				{/* {jurisdictions.filter((jurisdiction) => jurisdiction.name === 'Federal')
					.length > 0
					? ': 1) Provincia de Chaco en materia Civil y Comercial;2) Capital Federal; y 3) Justicia Federal.'
					: 'la Provincia de Chaco en materia Civil y Comercial.'} */}
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Derechos que detenta la plataforma
				</strong>
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Propiedad Intelectual/Industrial de la plataforma
				</strong>
			</p>
			<p className='text-body-regular'>
				Tenés que entender que la plataforma está disponible para uso personal y
				no comercial. Asimismo, aceptás que esta pertenece Carlos Alberto Díaz,
				incluyendo todos los derechos de propiedad intelectual y/o industrial
				que se deriven o se incluyan en la misma, sin que tengas ningún derecho
				a utilizarlos, explotarlos y/o modificarlos, salvo en los casos
				establecidos expresamente en sus{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link>.
			</p>
			<p className='text-body-regular'>
				Notiexpress no será responsable de ningún tipo de daño o perjuicio que
				sufras en relación a cualquier uso no autorizado de la plataforma, ni
				del mal uso que se haga de la misma.
			</p>
			<p className='text-body-regular'>
				Podrás hacer uso de esta plataforma siguiendo únicamente los
				lineamientos establecidos en sus{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link>.
			</p>
			<p className='text-body-regular'>
				No podrás copiar, modificar, alterar, duplicar o reutilizar esta
				plataforma, sus actualizaciones o parte de las mismas ni reproducir su
				tecnología o los métodos utilizados por esta para el tratamiento de la
				información que ingresa a la misma. Toda la información suministrada o
				visible en la plataforma, derivan de bases de datos oficiales.
				Notiexpress solo se limita a ponerla a tu disposición y/o de brindarte
				datos adicionales basándose en esta. Cualquier inconveniente que surja
				como consecuencia de la misma, no es responsabilidad de la plataforma.
			</p>
			<p className='text-body-regular'>
				No podrás copiar, reimprimir, modificar, exhibir, desarrollar, traducir,
				distribuir, adaptar, transmitir, comunicar al público en general a
				través de medios de telecomunicación, divulgar o vender el contenido
				procedente de la plataforma de ninguna manera, para ningún uso comercial
				o divulgarla a terceros que tengan fines comerciales, incluyendo otras
				páginas web y/o plataformas, respetando siempre lo establecido en las
				cláusulas mencionadas en{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link>{' '}
				de Notiexpress.
			</p>
			<p className='text-body-regular'>
				Adicionalmente, te comprometés a no 1) utilizar esta plataforma de
				ninguna forma que pudiera dañar, deshabilitar, sobrecargar o perjudicar
				la misma, o interferir con cualquier otro uso de esta incluyendo la
				capacidad de cualquier usuario para conectarse en actividades a tiempo
				real a través de la web; 2) usar ningún robot, bot, “spider” u otro
				dispositivo automático, proceso o medio para acceder a esta plataforma
				para cualquier propósito, obviando de esta manera, el registro de
				usuario previo y obligatorio de la plataforma; 3) utilizar cualquier
				otro dispositivo, software o rutina que interfiera en el correcto
				funcionamiento de la plataforma.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Autorizaciones y/o permisos
				</strong>
			</p>
			<p className='text-body-regular'>
				Como usuario podrás visualizar los elementos de la plataforma e incluso
				imprimirlos, copiarlos y/o almacenarlos en el disco duro de tu
				dispositivo o en cualquier otro soporte físico, siempre y cuando sea,
				única y exclusivamente, para uso personal y privado, quedando, por
				tanto, prohibida su utilización con fines comerciales, su distribución,
				así como su modificación, alteración o descompilación.
			</p>
			<p className='text-body-regular'>
				No obstante, como excepción a lo indicado en el punto anterior, está
				permitido que compartas aquello relativo a la promoción de la
				plataforma, no respecto a los datos que se encuentran en ella, mediante
				redes sociales, con las acciones &ldquo;tuit, retuit, compartir,
				bloguear&rdquo; o similares, siempre y cuando se respete su titularidad
				y/o la mención a las personas correspondientes.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Marcas registrada
				</strong>
			</p>
			<p className='text-body-regular'>
				Las marcas, nombres comerciales y logotipos que aparecen en la
				plataforma son propiedad de Carlos Alberto Díaz. No podrás utilizar o
				exhibir ninguna marca comercial y/o de servicio propiedad del
				propietario de la plataforma sin su consentimiento previo y por escrito.
				Asimismo, no podrás usar ni exhibir ninguna otra marca registrada y/o de
				servicio que aparezca en la plataforma sin el permiso expreso de su
				respectivo propietario.
			</p>
			<p className='text-body-regular'>
				La presente cláusula se rige y complementa con lo establecido en los{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link>{' '}
				de la plataforma.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Protección de los datos
				</strong>
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Usuarios
				</strong>
			</p>
			<p className='text-body-regular'>
				La plataforma cuenta con diferentes tipos de usuarios para garantizar la
				seguridad y confidencialidad de la información que se visualiza y
				conserva en la plataforma. Estos son:
			</p>
			<ol className='text-body-large-bold'>
				<li>Abogado o letrado administrador:</li>
				<ol
					type='A'
					className='text-body-regular-bold'
				>
					<li>Derechos:</li>
					<p className='text-body-regular'>
						- Ingresar a Notiexpress con el usuario y contraseña creado.
					</p>
					<p className='text-body-regular'>
						- Utilizar y navegar en la plataforma sujetándose a las reglas
						establecidas en los textos legales de la plataforma.
					</p>
					<p className='text-body-regular'>
						- Controlar expedientes judiciales propios y hacer el seguimiento de
						los mismos a través de las herramientas proporcionadas por
						Notiexpress.
					</p>
					<p className='text-body-regular'>
						- Acceder a todas las secciones habilitadas de la plataforma como
						así también a todos los servicios oportunamente contratados.
					</p>
					<li>Prohibiciones:</li>
					<p className='text-body-regular'>
						- Aprovechar su usuario para acceder a información que no le
						corresponde o servicios no contratados, ni efectuar un uso no
						autorizado o en contra de la normativa vigente.
					</p>
					<p className='text-body-regular'>
						- Incluir enlaces a otras páginas, plataformas y/o realizar
						publicidad de su despacho y/u organización y/o estudio jurídico.
					</p>
				</ol>
				<li>Colaboradores:</li>
				<ol type='A'>
					<li>Derechos:</li>
					<p className='text-body-regular'>
						- Ingresar a Notiexpress con el usuario y contraseña creado por el
						Abogado o letrado administrador.
					</p>
					<p className='text-body-regular'>
						- Controlar los expedientes asignados por el Abogado o letrado
						administrador.
					</p>
					<p className='text-body-regular'>
						- Hacer uso de las herramientas proporcionadas por Notiexpress para
						este tipo de usuario.
					</p>
					<li>Prohibiciones:</li>
					<p className='text-body-regular'>
						- Acceder a la plataforma con un usuario y contraseña distinta a la
						asignada.
					</p>
				</ol>
			</ol>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Contraseñas
				</strong>
			</p>
			<p className='text-body-regular'>
				La primera vez que te registres en Notiexpress, se te solicitará que
				crees un usuario y contraseña, en el cual, deberás sincronizar con tu
				e-mail personal o profesional. Ambas credenciales deben mantenerse en
				secreto y no debés dársela a nadie ni permitir a nadie usar tu cuenta
				sin tu consentimiento. Queda prohibido el uso de la cuenta de otra
				persona, aun cuando esta pertenezca a la misma empresa o estudio
				jurídico.
			</p>
			<p className='text-body-regular'>
				En el caso de que sospeches de cualquier uso no autorizado o acceso a su
				contraseña o cuenta, debés informarnos de forma inmediata a través de la
				sección de “Contacto” o través de nuestro e-mail{' '}
				<a href='mailto:contacto@notiexpress.net'>contacto@notiexpress.net</a>.
			</p>
			<p className='text-body-regular'>
				No seremos responsables si sufres cualquier daño o pérdida debido a no
				mantener en secreto tu usuario y/o contraseña, si permitís que otra
				persona utilice tu cuenta, si utilizás la cuenta de otra persona, o si
				te das cuenta o sospechás que puede haber un uso no autorizado de tu
				cuenta, y no nos informes inmediatamente a través de los canales de
				comunicación antes mencionados.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Uso de información personal
				</strong>
			</p>
			<p className='text-body-regular'>
				La información que se obtenga a través de la utilización de la
				plataforma, ya sea a través del proceso de registro o de la que se
				obtenga mediante su uso, estará sujeta a la{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link>{' '}
				de Notiexpress.
			</p>
			<p className='text-body-regular'>
				La{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link>{' '}
				trata sobre el uso de los datos que nos proporcionás, incluyendo los
				derechos que te pertenecen en relación a esa información.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Comunicaciones electrónicas
				</strong>
			</p>
			<p className='text-body-regular'>
				La información comunicada a través de Notiexpress constituye una
				comunicación electrónica. Cuando te comunicás a través de la plataforma
				o a través de otros medios electrónicos, tales como el e-mail, te estás
				comunicando con Notiexpress electrónicamente.
			</p>
			<p className='text-body-regular'>
				Al hacer uso de Notiexpress, estás aceptando que la plataforma se
				comunique contigo a través del e-mail proporcionado al momento de tu
				registro con sujeción a las leyes de privacidad vigentes.
			</p>
			<p className='text-body-regular'>
				Este tipo de comunicaciones, tales como avisos, actualizaciones,
				acuerdos, entre otras, tendrán la fuerza y efecto equivalente a las
				formuladas por escrito tanto en relación a su envió como a su
				correspondiente recepción.
			</p>
			<p className='text-body-regular'>
				La plataforma a su vez, podrá enviarte comunicaciones comerciales
				relacionados con los servicios contratados por Notiexpress, podrás
				desuscribirte en cualquier momento a través del envío de un e-mail a{' '}
				<a href='mailto:contacto@notiexpress.net'>contacto@notiexpress.net</a>{' '}
				indicando en el apartado de título la palabra “Desubscribir”.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Modificación o suspensión de la plataforma
				</strong>
			</p>
			<p className='text-body-regular'>
				Si bien Notiexpress tomará medidas razonables para mantener la
				plataforma funcionando correctamente, su acceso puede verse modificado,
				restringido o suspendido de forma temporal, en forma total o solo en
				alguna de sus secciones, para tareas de mantenimiento o actualización.
			</p>
			<p className='text-body-regular'>
				Toda tarea de mantenimiento o actualización será informada previamente a
				los usuarios mediante e-mail con la mayor anticipación posible a los
				fines de garantizarles la mejor experiencia en el uso de plataforma.
			</p>
			<p className='text-body-regular'>
				Notiexpress no se hace responsable de cualquier perjuicio o pérdida que
				pudieran ocasionarte tales acciones.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Responsabilidad
				</strong>
			</p>
			<p className='text-body-regular'>
				El usuario mantendrá indemne a Notiexpress y/o sus variantes y/o sus
				sociedades relacionadas, así como a quienes la dirigen, suceden,
				administran, representan y/o trabajan en ellas, por cualquier reclamo
				administrativo o judicial iniciado por otros usuarios, terceros o por
				cualquier Organismo y/o Institución, relacionado con sus actividades en
				la plataforma.
			</p>
			<p className='text-body-regular'>
				En virtud de esa responsabilidad, podrán realizar compensaciones,
				retenciones u otras medidas necesarias para la reparación de pérdidas,
				daños y perjuicios, cualquiera sea su naturaleza.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Terminación de uso y/o acceso a la plataforma
				</strong>
			</p>
			<p className='text-body-regular'>
				Notiexpress se reserva el derecho de concluir, sin previo aviso, el uso
				y/o acceso del usuario a la plataforma, así como de eliminar todo o
				parte de su cuenta, si se hallan motivos suficientes de incumplimiento
				de las presentes cláusulas, de las contenidas en los{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link>{' '}
				y/o de las vigentes en la{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link>.
				En tales circunstancias, Notiexpress te remitirá con posterioridad un
				e-mail con los motivos que originaron tal terminación, no haciéndose
				responsable de cualquier pérdida o perjuicio que pudiera ocasionar la
				misma, dejando indemne a la plataforma de cualquier reclamo posterior
				administrativo y/o judicial.
			</p>
			<p className='text-body-regular'>
				En caso de que se lleve a cabo cualquier terminación, estás aceptando
				dejar de usar la plataforma y de respetar las cláusulas relativas a
				Propiedad de la plataforma, Marcas registradas, Indemnidad y Ley
				Aplicable y Jurisdicción.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Cambios en el Aviso legal, Política de Privacidad y Términos y
					Condiciones
				</strong>
			</p>
			<p className='text-body-regular'>
				Notiexpress realizará actualizaciones periódicas de las cláusulas
				contenidas en sus textos legales para reflejar los cambios constantes en
				los servicios que se ofrecen, es por ello que te recomendamos que
				visites el apartado correspondiente a estos de manera periódica.
			</p>
			<p className='text-body-regular'>
				Te notificaremos de estos cambios por el e-mail que nos has suministrado
				al momento de tu registro, cuando se produzca un cambio sustancial o de
				relevancia en los mismos.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Independencia de las cláusulas
				</strong>
			</p>
			<p className='text-body-regular'>
				En caso de que alguna parte de estas cláusulas sea declarada ilegal,
				nula, sin validez o inaplicable, esa parte deberá entenderse eliminada
				del presente Aviso legal, pero no afectará la validez o aplicabilidad
				del resto de las cláusulas contenidas en este u otros textos legales de
				la plataforma.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Vigencia
				</strong>
			</p>
			<p className='text-body-regular'>
				Todas las cláusulas contenidas en el presente Aviso legal seguirán
				estando vigentes tras la terminación del derecho de uso de la
				plataforma.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Ley Aplicable y Jurisdicción
				</strong>
			</p>
			<p className='text-body-regular'>
				La presente Política de Privacidad se rige por la ley argentina. Toda
				controversia derivada de su aplicación, interpretación, ejecución o
				validez será resuelta por los tribunales ordinarios competentes, con
				asiento en la Ciudad de Resistencia, Provincia del Chaco, salvo
				disposición específica de normas de Orden Público, como, por ejemplo,
				legislación relativa al Consumidor.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					Relación con los demás textos legales
				</strong>
			</p>
			<p className='text-body-regular'>
				La plataforma pone a tu disposición los{' '}
				<Link to='/admin/terminos-y-condiciones'>Términos y Condiciones</Link>{' '}
				y/o de las vigentes en la y{' '}
				<Link to='/admin/politica-de-privacidad'>Política de Privacidad</Link>{' '}
				vigentes y cualquiera de sus posteriores modificaciones o adiciones que
				podrán ser publicadas periódicamente de manera gratuita, siendo estos
				textos legales, de obligado cumplimiento para todos los usuarios que
				hagan uso de la plataforma.
			</p>
			<p>
				<strong className='text-heading-3 text-primary-default'>
					¿Cómo podés ponerte en contacto con Notiexpress?
				</strong>
			</p>
			<p className='text-body-regular'>
				Para ponerse en contacto con Notiexpress respecto a preguntas o dudas en
				relación con estas cláusulas, podés dirigirte a la sección de “Contacto”
				o remitir un e-mail a{' '}
				<a href='mailto:contacto@notiexpress.net'>contacto@notiexpress.net</a> ,
				indicándonos de forma clara y sencilla tu petición.
			</p>
		</CardBody>
	);
};

export default LegalDisclaimerContent;