import React from 'react';
import PropTypes from 'prop-types';
import { userService } from 'services/userService';
import { Container, Row, Col } from 'reactstrap';

import HomeHeader from 'components/Headers/HomeHeader';
import ProfileInfo from './components/ProfileInfo';
import OfficeInfo from './components/OfficeInfo/OfiiceInfo';
import SubscriptionsInfo from './components/SubscriptionsInfo/SubscriptionsInfo';
import ReceiptsInfo from './components/ReceiptsInfo/ReceiptsInfo';

const Profile = (props) => {
	const { user: userInfo } = userService.useUser();

	return (
		<>
			<HomeHeader
				{...props}
				headerText='Perfil de Usuario'
				className=''
			/>
			<Container className='bg-gradient-white pt-5' fluid={true}>
				<Row className='mb-4'>
					<Col
						className='mb-xl-0'
						xl='4'
					>
						<ProfileInfo userInfo={userInfo} />
					</Col>
					<Col>
						<OfficeInfo userInfo={userInfo} />
					</Col>
				</Row>
				<Row>
					<Col>
						<SubscriptionsInfo showFooter />
					</Col>
				</Row>
				<Row>
					<Col>
						<ReceiptsInfo limit={5} />
					</Col>
				</Row>
				{/*<Row>
                        <Col>
                            <ProfileTabs
                                userInfo={userInfo}
                                mutate={mutate}
                                {...props}
                            />
                        </Col>
                    </Row>*/}
			</Container>
		</>
	);
};

export default Profile;

Profile.propTypes = {
	userInfo: PropTypes.object,
};
