/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Profile from 'views/UserProfile/Profile.js';
import Register from 'views/Auth/Register.js';
import Login from 'views/Auth/Login.js';
import Novedades from 'views/Novedades/Novedades.js';
import Expedientes from 'views/Expedientes/Expedientes.js';
import CaseFileHistory from 'views/ExpedienteDetails/CaseFileHistory.js';
import Providence from 'views/Providencia/Providence';
import Caducidad from 'views/Caducidad/Caducidad.js';
import HelpView from 'views/Help/HelpView.js';
import RecoverPassword from 'views/Auth/RecoverPassword';
// import ComponentsTest from "views/examples/ComponentsTest";

// Payments status
import ChangePassword from '../views/Auth/ChangePassword';
import ZapIcon from '../components/Icons/ZapIcon';
import FolderIcon from '../components/Icons/FolderIcon';
import ClockIcon from '../components/Icons/ClockIcon';
import RefreshIcon from '../components/Icons/RefreshIcon';
import UserIcon from '../components/Icons/UserIcon';
import ConnectionsPage from '../views/Connections/Connections';
import ProfileConfiguration from '../views/UserProfile/ProfileConfiguration';
import ReceiptListView from '../views/UserProfile/ReceiptListView';
import SubscriptionView from '../views/UserProfile/SubscriptionView';
import ExpedientesConfiguration from '../views/Expedientes/ExpedientesConfiguration';
import LegalDisclaimerView from '../views/LegalDisclaimer/LegalDisclaimer';
import PrivacyPolicyView from '../views/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditionsView from '../views/Terms&Conditions/TermsAndConditions';
import ContactView from '../views/Contact/Contact';
import CaseFilePreview from '../views/ExpedienteDetails/CaseFilePreview';
import NewsForCollaboratorView from '../views/Novedades/Novedades.collaborator';
import ExpedientsForCollaboratorView from '../views/Expedientes/Expedientes.collaborator';
import CaducateListForCollaboratorView from '../views/Caducidad/Caducidad.collaborator';
import CaseFileHistoryForCollaboratorView from '../views/ExpedienteDetails/CaseFileHistory.collaborator';
import ProvidenceForCollaboratorView from '../views/Providencia/Providence.collaborator';
import Landing from '../views/Landing/Landing.jsx';

const routes = [
  {
    path: '',
    name: 'Landing',
    icon: 'ni ni-planet',
    component: Landing,
    layout: '/landing',
    listable: false,
    listInMobile: false,
  },
	{
		path: '/novedades',
		name: 'Novedades',
		icon: ZapIcon,
		component: Novedades,
		layout: '/admin',
		listable: true,
		listInMobile: true,
    isExpired: true,
	},
	{
		path: '/novedades',
		name: 'Novedades',
		icon: ZapIcon,
		component: NewsForCollaboratorView,
		layout: '/collaborator',
		listable: true,
		listInMobile: true,
	},
	{
		path: '/expedientes',
		name: 'Expedientes',
		icon: FolderIcon,
		component: Expedientes,
		layout: '/admin',
		listable: true,
		listInMobile: true,
	},
	{
		path: '/expedientes',
		name: 'Expedientes',
		icon: FolderIcon,
		component: ExpedientsForCollaboratorView,
		layout: '/collaborator',
		listable: true,
		listInMobile: true,
	},
	{
		path: '/expedientes/configuration',
		name: 'Configuración',
		icon: FolderIcon,
		component: ExpedientesConfiguration,
		layout: '/admin',
		listable: false,
		listInMobile: false,
	},
	{
		path: '/caducidad',
		name: 'Caducidad',
		icon: ClockIcon,
		component: Caducidad,
		layout: '/admin',
		listable: true,
		listInMobile: false,
	},
	{
		path: '/caducidad',
		name: 'Caducidad',
		icon: ClockIcon,
		component: CaducateListForCollaboratorView,
		layout: '/collaborator',
		listable: true,
		listInMobile: false,
	},
	{
		path: '/expedientes/:id',
		name: 'Detalle Expediente',
		icon: FolderIcon,
		component: CaseFileHistory,
		layout: '/admin',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/expedientes/:id',
		name: 'Detalle Expediente',
		icon: FolderIcon,
		component: CaseFileHistoryForCollaboratorView,
		layout: '/collaborator',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/expedientes/preview/:number',
		name: 'Vista Previa',
		icon: FolderIcon,
		component: CaseFilePreview,
		layout: '/admin',
		listable: false,
		listInMobile: false,
	},
	{
		path: '/providencia/:id',
		name: 'Providencia',
		icon: FolderIcon,
		component: Providence,
		layout: '/admin',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/providencia/:id',
		name: 'Providencia',
		icon: FolderIcon,
		component: ProvidenceForCollaboratorView,
		layout: '/collaborator',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/connections',
		name: 'Conexiones',
		icon: RefreshIcon,
		component: ConnectionsPage,
		layout: '/admin',
		listable: true,
		listInMobile: false,
		onlyAdmin: true,
	},
	{
		path: '/user-profile',
		name: 'Perfil',
		icon: UserIcon,
		component: Profile,
		layout: '/admin',
		listable: true,
		listInMobile: false,
		onlyAdmin: true,
    isExpired: true,
	},
	{
		path: '/user-profile/configuration',
		name: 'Configuración de Perfil',
		icon: UserIcon,
		component: ProfileConfiguration,
		layout: '/admin',
		listable: false,
		listInMobile: false,
		onlyAdmin: true,
	},
	{
		path: '/user-profile/subscriptions',
		name: 'Registro y renovación de suscripciones',
		icon: UserIcon,
		component: SubscriptionView,
		layout: '/admin',
		listable: false,
		listInMobile: false,
		isExpired: true,
		onlyAdmin: true,
	},
	{
		path: '/user-profile/receipts',
		name: 'Comprobantes de Pago',
		icon: UserIcon,
		component: ReceiptListView,
		layout: '/admin',
		listable: false,
		listInMobile: false,
		isExpired: true,
		onlyAdmin: true,
	},
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25',
		component: Login,
		layout: '/auth',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08',
		component: Register,
		layout: '/auth',
		listable: false,
		listInMobile: true,
	},
	{
		path: '/resetPassword',
		name: 'Reset Password',
		component: ChangePassword,
		layout: '/auth',
		listable: false,
		listInMobile: false,
	},
	{
		path: '/recoverpassword',
		name: 'recoverpassword',
		component: RecoverPassword,
		layout: '/auth',
	},
	{
		path: '/contacto',
		name: 'Contact',
		icon: 'ni ni-planet',
		component: ContactView,
		layout: '/admin',
		listable: false,
    isExpired: true,
	},
	{
		path: '/ayuda',
		name: 'Help',
		icon: 'ni ni-planet',
		component: HelpView,
		layout: '/admin',
		listable: false,
    isExpired: true,
	},
	{
		path: '/aviso-legal',
		name: 'Legal Notice',
		icon: 'ni ni-planet',
		component: LegalDisclaimerView,
		layout: '/admin',
		listable: false,
    isExpired: true,
	},
	{
		path: '/terminos-y-condiciones',
		name: 'Terms and Conditions',
		icon: 'ni ni-planet',
		component: TermsAndConditionsView,
		layout: '/admin',
		listable: false,
    isExpired: true,
	},
	{
		path: '/politica-de-privacidad',
		name: 'Privacy Policy',
		icon: 'ni ni-planet',
		component: PrivacyPolicyView,
		layout: '/admin',
		listable: false,
    isExpired: true,
	},

	{
		path: '/contacto',
		name: 'Contact',
		icon: 'ni ni-planet',
		component: ContactView,
		layout: '/collaborator',
		listable: false,
	},
	{
		path: '/ayuda',
		name: 'Help',
		icon: 'ni ni-planet',
		component: HelpView,
		layout: '/collaborator',
		listable: false,
	},
	{
		path: '/aviso-legal',
		name: 'Legal Notice',
		icon: 'ni ni-planet',
		component: LegalDisclaimerView,
		layout: '/collaborator',
		listable: false,
	},
	{
		path: '/terminos-y-condiciones',
		name: 'Terms and Conditions',
		icon: 'ni ni-planet',
		component: TermsAndConditionsView,
		layout: '/collaborator',
		listable: false,
	},
	{
		path: '/politica-de-privacidad',
		name: 'Privacy Policy',
		icon: 'ni ni-planet',
		component: PrivacyPolicyView,
		layout: '/collaborator',
		listable: false,
	},
];

export default routes;
