import React from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Col, Container, Row } from 'reactstrap';
import PaymentFlow from './components/PaymentFlow/PaymentFlow';
import { Provider } from 'context/cart';

const SubscriptionView = () => {
	return (
		<>
			<HomeHeader
				headerText='Renovación de suscripciones'
				canGoBack
			/>
			<Container className='bg-gradient-white' fluid={true}>
				<Provider>
					<Row>
						<Col>
							<PaymentFlow />
						</Col>
					</Row>
				</Provider>
			</Container>
		</>
	);
};

SubscriptionView.propTypes = {};

export default SubscriptionView;
