import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CopyToClipboardElement from '../../../components/Commons/CopyToClipboardElement';

function CaseFileDetail({ caseInfo }) {
	const nombreJurisdiction = caseInfo?.Jurisdiction?.name;
	const localityName = caseInfo?.OrganismList ? caseInfo.OrganismList[0]?.Organism?.Circumscription.name ?? 'Sin Datos' : 'Sin Datos';
	const organismoName = caseInfo?.OrganismList ? caseInfo.OrganismList[0]?.Organism.name ?? 'Sin Datos' : 'Sin Datos';

	const DetailField = ({ field, value }) => (
		<>
			<h3 className='text-body-large-bold text-secondary-default'>{field}</h3>
			<div className='m-0 text-body-large'>{value}</div>
		</>
	);

	DetailField.propTypes = {
		field: PropTypes.string,
		value: PropTypes.node,
	};

	return (
		<CardBody>
			<Row className='align-items-center py-2'>
				<Col xl='12'>
					<DetailField
						field='Expediente'
						value={
							<div className='d-flex'>
								<span>{`${caseInfo?.number ?? '-'}/${caseInfo?.year ?? '-'}`}</span>
								<CopyToClipboardElement
									id={caseInfo?.id}
									text={`${caseInfo?.number ?? '-'}/${caseInfo?.year ?? '-'}`}
									tooltipMessage={'Copiar nº de expediente'}
								/>
							</div>
						}
					/>
				</Col>
			</Row>
			<Row className='align-items-center py-2'>
				<Col xl='12'>
					<DetailField
						field='Carátula'
						value={
							<div className='d-flex'>
								<span>{caseInfo?.coverPage ?? '-'}</span>
								<CopyToClipboardElement
									id={`${caseInfo?.id}-coverPage`}
									text={caseInfo?.coverPage ?? '-'}
									tooltipMessage={'Copiar carátula'}
								/>
							</div>
						}
					/>
				</Col>
			</Row>
			<Row className='align-items-center py-2'>
				<Col xl='4'>
					<DetailField
						field='Jurisdicción'
						value={nombreJurisdiction ?? 'Sin jurisdicción'}
					/>
				</Col>
				<Col xl='4'>
					<DetailField
						field='Circunscripción'
						value={localityName ?? 'Sin datos'}
					/>
				</Col>
				<Col xl='4'>
					<DetailField
						field='Organismo'
						value={organismoName ?? 'Sin datos'}
					/>
				</Col>
			</Row>
		</CardBody>
	);
}

export default CaseFileDetail;

CaseFileDetail.propTypes = {
	caseInfo: PropTypes.object,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
};
