import React from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import BarChart from './Bar';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CardResumeBar = ({
	cardTitle = 'default',
	subtitle = 'default',
	data = [{ name: 'default', value: 0, color: '#9BA7AF' }],
	extraInfo = null,
	children,
	isLoading = false,
}) => {
	return (
		<Card className='shadow h-100 d-flex align-items-center'>
			<CardBody
				style={{
					minWidth: `${extraInfo ? '450px' : '310px'}`,
				}}
			>
				{isLoading ? (
					<div className='d-flex justify-content-center align-items-center h-100'>
						<Spinner color='primary' />
					</div>
				) : (
					<Row className='px-3 h-100 d-flex justify-content-between'>
						<Col
							className='px-0 d-flex flex-column justify-content-between'
							style={{
								maxWidth: '120px',
							}}
						>
							{children}
							<Row
								className='pl-3'
								style={{
									width: '120px',
								}}
							>
								<span
									style={{
										fontSize: '12px',
										fontFamily: 'Roboto',
										fontWeight: '400',
										lineHeight: '20px',
										color: '#9BA7AF',
									}}
								>
									{cardTitle}
								</span>
								<span
									style={{
										fontSize: '17px',
									}}
									className='text-body-large-bold text-secondary-default'
								>
									{subtitle}
								</span>
							</Row>
						</Col>
						<Col
							className='d-flex justify-content-center px-0 align-items-center'
							style={{
								maxWidth: '120px',
							}}
						>
							<BarChart barData={data} />
						</Col>
						{extraInfo && (
							<Col
								className='px-0 d-flex justify-content-end'
								style={{
									maxWidth: '120px',
								}}
							>
								<div className='d-flex flex-column justify-content-end'>
									<span
										className='text-heading-1 mb-0 pb-0 d-flex flex-column'
										style={{
											fontSize: '40px',
											lineHeight: '40px',
										}}
									>
										{extraInfo.value ?? '-'}
									</span>
									<span
										style={{
											fontSize: '12px',
											fontFamily: 'Roboto',
											fontWeight: '400',
											lineHeight: '20px',
											color: '#9BA7AF',
										}}
									>
										{extraInfo.title}
									</span>
									<span
										style={{
											fontSize: '17px',
										}}
										className='text-body-large-bold text-secondary-default'
									>
										{extraInfo.subtitle}
									</span>
								</div>
							</Col>
						)}
					</Row>
				)}
			</CardBody>
		</Card>
	);
};

export default CardResumeBar;
