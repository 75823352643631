import React, { createContext, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { jurisdictionService } from 'services/jurisdictionService';
import { notifService } from '../services/notificationsService';
import user from 'context/user';

import { io } from 'socket.io-client';
import { getCurrentId } from '../helpers/user';

const initialState = {
	jurisdictions: [],
	notifications: [],
};

let socket;
const initiateSocketConnection = () => {
	socket = io(process.env.REACT_APP_SOCKET_NOTIF, {
		query: {
			userId: getCurrentId(),
		},
		secure: true,
		transports: ['websocket'],
	});
};

const disconnectSocket = () => {
	socket ? socket.disconnect() : null;
};

const GlobalContext = createContext(initialState);

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_JURISDICTIONS':
			return {
				...state,
				jurisdictions: action.payload,
			};
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: action.payload,
			};
		case 'NEW_NOTIFICATIONS':
			return {
				...state,
				notifications: {
					...state.notifications,
					hasNewNotification: action.payload,
				},
			};
		default:
			return state;
	}
};

const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, undefined);
	const {
		state: { currentUser },
	} = useContext(user);

	const {
		data: notifications,
		isError: isErrorNotifications,
		isLoading: isLoadingNotifications,
		mutate: mutateNotifications,
		size: page,
		setSize: setPage,
	} = notifService.useNotifications({ userId: currentUser.id });

	const disabledGetMore = notifications?.[page - 1]?.notifications?.length < 10;
	function setJurisdictions(data) {
		dispatch({ type: 'SET_JURISDICTIONS', payload: data });
	}

	const setNotifications = (notifications) => {
		dispatch({ type: 'SET_NOTIFICATIONS', payload: notifications });
	};

	const handleNewNotification = (flag) => {
		dispatch({
			type: 'NEW_NOTIFICATIONS',
			payload: flag,
		});
	};

	const getMoreNotifications = async () => {
		await setPage(page + 1);
	};

	useEffect(() => {
		const getJurisdictions = async () => {
			try {
				const jurisdictions = await jurisdictionService.getJurisdictions();
				if (Array.isArray(jurisdictions)) {
					setJurisdictions(jurisdictions);
				}
			} catch {
				//console.log(error);
				setJurisdictions([]);
			}
		};
		getJurisdictions();
	}, []);

	useEffect(() => {
		initiateSocketConnection();

		socket.on('socketNotification', () => {
			handleNewNotification(true);
			mutateNotifications();
		});

		return () => {
			disconnectSocket();
		};
	}, []);

	useEffect(() => {
		setNotifications({
			...state.notifications,
			data:
				notifications?.map((pages) => pages?.notifications ?? []).flat() ?? [],
			isError: isErrorNotifications,
			isLoading: isLoadingNotifications,
			mutate: mutateNotifications,
			hasMoreNotifications: !disabledGetMore,
		});
	}, [notifications, currentUser.id]);

	return (
		<GlobalContext.Provider
			value={{
				state,
				actions: {
					setJurisdictions,
					handleNewNotification,
					getMoreNotifications,
				},
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export { GlobalProvider as Provider, GlobalContext as default };

GlobalProvider.propTypes = {
	children: PropTypes.object,
};
