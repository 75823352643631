import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as RayoIcon } from '../../assets/img/icons/logo/notiexpress-logo-mobile.svg';
import { ReactComponent as LogoutIcon } from '../../assets/img/icons/notiexpress/log-out.svg';
import { userService } from '../../services/userService';
import user from '../../context/user';
import { useHistory } from 'react-router-dom';

/**
 * Navbar for authenticated users
 * @param {string} status Payment status for user.
 * @returns {JSX.Element}
 * @constructor
 */
const AdminNavbarMobile = ({ status }) => {
	const history = useHistory();

  const {
		actions: { logout },
	} = React.useContext(user);

	function handleLogOut() {
		logout();
		userService.logout();
		history.push('/auth/login');
	}

	return (
		<>
			<Navbar
				className='p-0 mobile-navbar'
				expand={false}
        id='navbar-main'
			>
				<NavbarBrand
					to='/'
					tag={Link}
          className='h-100 p-4'
				>
					<RayoIcon />
				</NavbarBrand>
        <div
          className='cursor-pointer h-100 p-4'
          onClick={handleLogOut}
        >
          <LogoutIcon/>
        </div>
			</Navbar>
		</>
	);
};

export default AdminNavbarMobile;

AdminNavbarMobile.propTypes = {
	status: PropTypes.string,
};
