/* eslint-disable indent */
import React, { useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import IndeterminateStatusBar from '../IndeterminateStatusBar/IndeterminateStatusBar';
import { SynchronizeContext } from '../../context/synchronize';
import admin from 'context/admin';
import { ReactComponent as SyncIcon } from '../../assets/img/icons/notiexpress/sync-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import { SYNC_STATUS } from '../../helpers/consts';
import CustomMessage from '../CustomMessage/CustomMessage';
import PropTypes from 'prop-types';
import useBreakpoints from '../../hooks/useBreakpoints';

/**
 *
 * @component
 * @param {string} date
 * @returns {JSX.Element}
 * @constructor
 */
const SynchronizeButton = ({ date, mutate }) => {
	const {
		state: { status, message },
		actions: { syncAllNews },
	} = useContext(SynchronizeContext);

	const {
		state: { activeJurisdictions },
	} = useContext(admin);

	const handleOnClick = async (event) => {
		event.preventDefault();
		await syncAllNews({ activeJurisdictions, date });
    await mutate();
	};

	const { isMobile } = useBreakpoints();

	const getMessage = (status) => {
		switch (status) {
			case SYNC_STATUS.START:
				return '';
			case SYNC_STATUS.ERROR:
				return 'Sincronización interrumpida. Inténtalo nuevamente';
			case SYNC_STATUS.SUCCESS:
				return '¡Sincronizado!';
		}
	};

	return (
		<>
			<Row
				className={
					isMobile
						? 'm-0 align-items-center justify-content-center'
						: 'm-0 align-items-center'
				}
			>
				{status === SYNC_STATUS.START ||
				status === SYNC_STATUS.ERROR ||
				status === SYNC_STATUS.SUCCESS ? (
					<>
						<Button
							className='btn-noti-sync py-2 px-4 w-100 m-0'
							disabled={!activeJurisdictions?.length}
							onClick={handleOnClick}
						>
							<Row className='align-items-center justify-content-center m-0'>
								<span>
									<SyncIcon className='fill-secondary-default stroke' />
								</span>
								<span className='ml-2'>Sincronizar</span>
							</Row>
						</Button>
					</>
				) : (
					<>
						<Col className='px-0 py-2'>
							<span className='text-body-large'>{message}</span>
							<IndeterminateStatusBar />
						</Col>
					</>
				)}
			</Row>
      {isMobile && (
        <CustomMessage
        className='py-3 justify-content-center'
        icon={
          status === SYNC_STATUS.ERROR ? (
            <CloseIcon
              width={18}
              height={18}
              className='stroke-error'
            />
          ) : status === SYNC_STATUS.SUCCESS ? (
            <CheckIcon
              width={18}
              height={18}
              className='stroke-success'
            />
          ) : (
            <></>
          )
        }
        message={getMessage(status)}
      />
      )}
		</>
	);
};

export default SynchronizeButton;

SynchronizeButton.propTypes = {
	date: PropTypes.string,
};
