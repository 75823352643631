/* eslint-disable indent */
import useBreakpoint from '../../../hooks/useBreakpoint';
import { newsCasesTableColumns } from '../../../variables/casesListTables';

export default function useNewsTableColumns() {
	const { breakpoint } = useBreakpoint();

	switch (breakpoint) {
		case '2xs':
		case 'xs':
		case 'sm': // return {currentColumns: newsCasesTableColumnsMobile}
		case 'md':
		case 'lg': // return {currentColumns: newsCasesTableColumnsTablet}
		case 'xl': // return {currentColumns: newsCasesTableColumnsDesktop}
		case '2xl': // return {currentColumns: newsCasesTableColumns}
		default:
			return { currentColumns: newsCasesTableColumns };
	}
}
