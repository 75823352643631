import React from 'react';
import { Button, CardBody, CardFooter, Col, Row } from 'reactstrap';
import CardAccordion from '../../../../components/CardAccordion/CardAccordion';
import UserServiceCard from './UserServiceCard';
import { paymentsService } from '../../../../services/paymentsService';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const SubscriptionInfo = ({ showFooter }) => {
	const history = useHistory();

	const { data: userServices } = paymentsService.useUserServicesTable();

	const goToSubscriptionPage = () => {
		history.push('/admin/user-profile/subscriptions');
	};

	return (
		<CardAccordion
			title='Mis suscripciones'
			defaultValue={false}
		>
			<CardBody>
				{userServices?.services?.length > 0 ? (
					userServices?.services?.map((userService, i) => (
						<UserServiceCard
							userService={userService}
							key={i}
						/>
					))
				) : (
					<p>No hay suscripciones</p>
				)}
			</CardBody>
			{showFooter && (
				<CardFooter>
					<Row>
						<Col>
							<Button
								className='btn-noti-primary py-2 px-5'
								onClick={goToSubscriptionPage}
							>
								<Row className='align-items-center'>
									<span className='text-button'>Renovar</span>
								</Row>
							</Button>
						</Col>
					</Row>
				</CardFooter>
			)}
		</CardAccordion>
	);
};

SubscriptionInfo.propTypes = {
	showFooter: PropTypes.bool,
};

export default SubscriptionInfo;
