/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { userService } from 'services/userService';
import { Collapse, Navbar, NavItem, NavLink, Col } from 'reactstrap';
import { ROLES } from '../../helpers/consts';

const Sidebar = ({ role, routes, logo }) => {
	const [collapseOpen, setCollapseOpen] = useState(false);

	useEffect(() => {
		document.body.classList.add('g-sidenav-hidden');
		document.body.classList.add('g-sidenav-open');
	}, []);

	function toggleCollapse() {
		document.body.classList.contains('g-sidenav-pinned')
			? (document.body.classList.remove('g-sidenav-pinned'),
			  document.body.classList.add('g-sidenav-hidden'))
			: (document.body.classList.add('g-sidenav-pinned'),
			  document.body.classList.remove('g-sidenav-hidden'));
	}
	// closes the collapse
	function toggleCollapseMobile() {
		setCollapseOpen((prev) => !prev);
	}

	const history = useHistory();

	function handleLogOut() {
		userService.logout();
		history.push('/auth/login');
	}

	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes) => {
		return routes.map((route, key) => {
			if (role !== ROLES.USER && route.onlyAdmin) {
				return null;
			}
			if (route.listable) {
				return (
					<NavItem key={key}>
						<NavLink
							to={route.layout + route.path}
							tag={NavLinkRRD}
							onClick={toggleCollapseMobile}
							activeClassName='active'
						>
							<i>
								<route.icon
									className='stroke stroke-primary-default'
									size={20}
								/>
							</i>
							<span className='nav-link-text p-1 pt-2'>{route.name}</span>
						</NavLink>
					</NavItem>
				);
			}
		});
	};

	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: '_blank',
		};
	}
	return (
		<Navbar
			className='sidenav navbar-vertical fixed-left navbar-light bg-white'
			expand='md'
			id='sidenav-main'
      onBlur={() => {
        document.body.classList.remove('g-sidenav-pinned'),
        document.body.classList.add('g-sidenav-hidden');
      }}
		>
			<div className=''>
				{/* Toggler */}
				<div className='toggle-button'>
					<button
						className='sidenav-toggler d-xl-block active'
						onClick={toggleCollapse}
					>
						<div className='sidenav-toggler-inner'>
							{/* Toggler */}
							<i className='sidenav-toggler-line'></i>
							<i className='sidenav-toggler-line'></i>
							<i className='sidenav-toggler-line'></i>
						</div>
					</button>
				</div>
			</div>
			{/* Navigation */}
			<div className='navbar-inner'>
				{/* Collapse */}
				<Collapse
					className='navbar-collapse'
					isOpen={collapseOpen}
				>
					<div className='navbar-collapse-header'>
						<Col
							className='collapse-close'
							xs='12'
						>
							<button
								className='navbar-toggler'
								type='button'
								onClick={toggleCollapseMobile}
							>
								<span />
								<span />
							</button>
						</Col>
						<ul className='navbar-nav'>
							{createLinks(routes.filter((route) => route.listable))}
							<NavItem>
								<NavLink
									to='/auth/login'
									className='d-block d-md-none'
									tag={NavLinkRRD}
									onClick={(e) => {
										e.preventDefault();
										handleLogOut();
									}}
									activeClassName='active'
								>
									<i className='ni ni-user-run' />
									<span className='nav-link-text'>Cerrar sesión</span>
								</NavLink>
							</NavItem>
						</ul>
					</div>
				</Collapse>
			</div>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo

		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;
