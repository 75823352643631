import React from 'react';

const DivideCircleIcon = ({ size, fill = 'transparent' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_960_39794)'>
				<path
					d='M8 12H16'
					stroke='#F1DD73'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
					stroke='#F1DD73'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_960_39794'>
					<rect
						width='24'
						height='24'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DivideCircleIcon;
