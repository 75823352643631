import React from 'react';
import './styles.css';

const IndeterminateStatusBar = () => {
	return (
		<div className='progress-bar w-100'>
			<div className='progress-bar-value'></div>
		</div>
	);
};

export default IndeterminateStatusBar;
