import { authHeader } from './auth-header';
import _axios from 'axios';
import { userService } from '../services/userService';

const axios = _axios.create({});
const API = _axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: authHeader(),
});
const API_NOTIF = _axios.create({
	baseURL: process.env.REACT_APP_NOTIF,
	headers: authHeader(),
});

const API_EMAIL = _axios.create({
	baseURL: process.env.REACT_APP_EMAIL,
	headers: authHeader(),
});

const API_PAY = _axios.create({
	baseURL: process.env.REACT_APP_APIPAGOS,
	headers: authHeader(),
});

const DTIOL = _axios.create({
	baseURL: process.env.REACT_APP_DTIOL,
});

const FEDERAL = _axios.create({
	baseURL: process.env.REACT_APP_FEDERAL,
});

axios.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => Promise.reject(error)
);

axios.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);

API.interceptors.request.use(
	(config) => {
		config.headers = authHeader();
		return config;
	},
	(error) => Promise.reject(error)
);

API.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.response.status === 401) {
			// auto logout if 401 response returned from API
			userService.logout();
		}
		if (error.response) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);

API_NOTIF.interceptors.request.use(
	(config) => {
		config.headers = authHeader();
		return config;
	},
	(error) => Promise.reject(error)
);

API_NOTIF.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.response) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);

API_EMAIL.interceptors.request.use(
	(config) => {
		config.headers = authHeader();
		return config;
	},
	(error) => Promise.reject(error)
);

API_EMAIL.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);

API_PAY.interceptors.request.use(
	(config) => {
		config.headers = authHeader();
		return config;
	},
	(error) => Promise.reject(error)
);

API_PAY.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.response) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error);
	}
);

DTIOL.interceptors.response.use(
	(response) => response.data,
	(err) => {
		if (err.response) {
			return Promise.reject(err.response.data);
		}
		return Promise.reject(err);
	}
);

export { API, API_NOTIF, API_EMAIL, API_PAY, DTIOL, FEDERAL, axios };
