import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/icons/notiexpress/arrow-left-icon.svg';

const HomeHeader = ({ headerText, canGoBack, isHome = false }) => {
	const history = useHistory();

	const goBack = () => {
		history.goBack();
	};

	return (
		<>
			<Container 
        className={ isHome ? 'pb-3' : 'py-3'}
        fluid={true}
      >
				<Row className='align-items-center'>
					<Col xs={10} sm={11}>
						<h1 className='text-heading-1'>{headerText}</h1>
					</Col>
					{canGoBack && (
						<Col xs={2} sm={1} className='p-0'>
							<Button
								color='link'
								onClick={goBack}
                className='p-3'
							>
								<ArrowLeftIcon
									width={20}
									height={20}
									className='stroke-primary-default'
								/>
							</Button>
						</Col>
					)}
				</Row>
			</Container>
		</>
	);
};

HomeHeader.propTypes = {
	headerText: PropTypes.string.isRequired,
  canGoBack: PropTypes.bool,
  isHome: PropTypes.bool,
};

export default HomeHeader;
