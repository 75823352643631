import React from 'react';
import CardAccordion from '../../../../components/CardAccordion/CardAccordion';
import { Button, CardBody, Col, Form, Input, InputGroup, Label, Row } from 'reactstrap';
import DynamicTable from '../../../../components/Commons/DynamicTable';
import { officesService } from '../../../../services/officesService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as GridIcon } from 'assets/img/icons/notiexpress/grid-icon.svg';
import LegalAddressDropDown from './LegalAddressDropdown';

const AddressSchema = Yup.object().shape({
	name: Yup.string(),
	address: Yup.string().required('Campo obligatorio'),
	locality: Yup.string().required('Campo obligatorio'),
	province: Yup.string().required('Campo obligatorio'),
	cp: Yup.string(),
	phone: Yup.string(),
});

const LegalAddressList = () => {
	const { offices, isLoading, isError, mutate } = officesService.useOfficesByUserId();

	const formik = useFormik({
		initialValues: {
			name: '',
			address: '',
			locality: '',
			province: '',
			cp: '',
			phone: '',
		},
		validationSchema: AddressSchema,
		onSubmit: async (values) => {
			try {
				await officesService.createOffice(values);
				await mutate();
			} catch (e) {
				//console.log(e);
			}
		},
	});

	const columns = [
		{
			Header: 'Oficina',
			accessor: 'name',
		},
		{
			Header: 'Dirección',
			accessor: 'address',
		},
		{
			Header: 'Localidad',
			accessor: 'locality',
		},
		{
			Header: 'Provincia',
			accessor: 'province',
		},
		{
			Header: 'Teléfono',
			accessor: (row) => row.phone || '-',
		},
		{
			Header: () => (
				<GridIcon
					width={16}
					height={16}
					className='stroke-secondary-default'
				/>
			),
			accessor: 'userOfficeId',
			Cell: (row) => (
				<LegalAddressDropDown
					mutate={mutate}
					office={row.row.original}
				/>
			),
		},
	];

	return (
		<CardAccordion title='Mis domicilios legales'>
			<CardBody>
				<Form onSubmit={formik.handleSubmit}>
					<Row className='pb-3'>
						<Col>
							<Row className='p-2'>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Oficina</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Nombre de la oficina'
											type='text'
											name='name'
											onChange={formik.handleChange}
											value={formik.values.name}
										/>
									</InputGroup>
									{formik.errors.name && formik.touched.name && <span className='text-link-regular text-danger'>{formik.errors.name}</span>}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Dirección*</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Escribí su dirección'
											type='text'
											name='address'
											onChange={formik.handleChange}
											value={formik.values.address}
										/>
									</InputGroup>
									{formik.errors.address && formik.touched.address && (
										<span className='text-link-regular text-danger'>{formik.errors.address}</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Localidad*</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Nombre de la localidad'
											type='text'
											name='locality'
											onChange={formik.handleChange}
											value={formik.values.locality}
										/>
									</InputGroup>
									{formik.errors.locality && formik.touched.locality && (
										<span className='text-link-regular text-danger'>{formik.errors.locality}</span>
									)}
								</Col>
							</Row>
							<Row className='p-2'>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Provincia*</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Nombre de la provincia'
											type='text'
											name='province'
											onChange={formik.handleChange}
											value={formik.values.province}
										/>
									</InputGroup>
									{formik.errors.province && formik.touched.province && (
										<span className='text-link-regular text-danger'>{formik.errors.province}</span>
									)}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Código postal</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Ingresá el código postal'
											type='text'
											name='cp'
											onChange={formik.handleChange}
											value={formik.values.cp}
										/>
									</InputGroup>
									{formik.errors.cp && formik.touched.cp && <span className='text-link-regular text-danger'>{formik.errors.cp}</span>}
								</Col>
								<Col>
									<Label className='text-body-large-bold text-secondary-default'>Teléfono</Label>
									<InputGroup
										className='input-group-alternative mb-2 form-group'
										size='sm'
									>
										<Input
											placeholder='Teléfono de la oficina'
											type='text'
											name='phone'
											onChange={formik.handleChange}
											value={formik.values.phone}
										/>
									</InputGroup>
									{formik.errors.phone && formik.touched.phone && (
										<span className='text-link-regular text-danger'>{formik.errors.phone}</span>
									)}
								</Col>
							</Row>
							<Row className='p-2'>
								<Col>
									<Button
										className='btn-noti-primary py-1 px-5'
										type='submit'
									>
										<span
											className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
											role='status'
											aria-hidden='true'
										/>
										<span className='text-button'>Agregar</span>
									</Button>
								</Col>
								<Col>{}</Col>
							</Row>
						</Col>
					</Row>
				</Form>
				<Row>
					<Col>
						<DynamicTable
							data={offices?.offices ?? []}
							isLoading={isLoading}
							isError={isError}
							columns={columns}
							noDataMessage='No tenés ningún domicilio agregado'
						/>
					</Col>
				</Row>
			</CardBody>
		</CardAccordion>
	);
};

LegalAddressList.propTypes = {};

export default LegalAddressList;
