import React from 'react';
import PropTypes from 'prop-types';

const MinimizeIcon = ({
	className,
	size,
	fill = 'transparent',
	color = '',
}) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4 14H10V20'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20 10H14V4'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14 10L21 3'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3 21L10 14'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

MinimizeIcon.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	className: PropTypes.string,
	fill: PropTypes.string,
};

export default MinimizeIcon;
