import React, { useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	ModalBody,
	ModalFooter,
	Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaPlus } from 'react-icons/fa';
import { collaboratorService } from '../../../services/collaboratorService';
import CustomMessage from '../../../components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/notiexpress/close-icon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/img/icons/notiexpress/eye-icon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/img/icons/notiexpress/eye-off-icon.svg';
import PropTypes from 'prop-types';
import { COLLABORATOR_LIMIT_MESSAGE } from 'helpers/consts';

const collaboratorsFormSchema = Yup.object().shape({
	name: Yup.string().required('Campo requerido'),
	lastName: Yup.string().required('Campo requerido'),
	matricula: Yup.string(),
	email: Yup.string().required('Campo requerido'),
	password: Yup.string().required('Campo requerido'),
});

const CollaboratorsForm = ({
	mutate,
	collaboratorSuccessMessage,
	collaboratorErrorMessage,
	setCollaboratorSuccessMessage,
	setCollaboratorErrorMessage,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const initialValues = {
		name: '',
		lastName: '',
		matricula: '',
		email: '',
		password: '',
	};
	const handleSubmit = async (values) => {
		formik.setSubmitting(true);
		try {
			await collaboratorService.create({
				name: values.name,
				lastName: values.lastName,
				email: values.email,
				password: values.password,
				licensePlate: values.matricula,
			});
			setCollaboratorSuccessMessage('¡Colaborador creado!');
			formik.resetForm();
			mutate();
		} catch (error) {
			if (error.name === 'BadRequestException' && error.message === COLLABORATOR_LIMIT_MESSAGE) {
				setCollaboratorErrorMessage('¡Llegaste al límite de colaboradores por cuenta!');
			} else {
				setCollaboratorErrorMessage('¡Error al crear el colaborador!');
			}
		} finally {
			formik.setSubmitting(false);
		}
	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: collaboratorsFormSchema,
		onSubmit: handleSubmit,
	});

	return (
		<>
			<Form
				onSubmit={formik.handleSubmit}
				autoComplete='off'
			>
				<FormGroup
					tag={Row}
					className='m-0'
				>
					<Col xs={4}>
						<Label
							className='text-body-large-bold text-secondary-default'
							for='name'
						>
							Nombre *
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='text'
								name='name'
								id='name'
								value={formik.values.name}
								onChange={formik.handleChange}
								placeholder='Ingresá el nombre del colaborador'
							/>
						</InputGroup>
						{formik.errors.name && formik.touched.name ? (
							<span className='text-dark-link-regular text-error'>{formik.errors.name}</span>
						) : null}
					</Col>
					<Col xs={4}>
						<Label
							className='text-body-large-bold text-secondary-default'
							for='lastName'
						>
							Apellido *
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='text'
								name='lastName'
								id='lastName'
								value={formik.values.lastName}
								onChange={formik.handleChange}
								placeholder='Ingresá el nombre del colaborador'
							/>
						</InputGroup>
						{formik.errors.lastName && formik.touched.lastName ? (
							<span className='text-dark-link-regular text-error'>{formik.errors.lastName}</span>
						) : null}
					</Col>
					<Col xs={4}>
						<Label
							className='text-body-large-bold text-secondary-default'
							for='matricula'
						>
							Matrícula
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='text'
								name='matricula'
								id='matricula'
								value={formik.values.matricula}
								onChange={formik.handleChange}
								placeholder='Ingresá su nº de matrícula'
							/>
						</InputGroup>
						{formik.errors.matricula && formik.touched.matricula ? (
							<span className='text-dark-link-regular text-error'>{formik.errors.matricula}</span>
						) : null}
					</Col>
					<Col
						xs={6}
						className='mt-1'
					>
						<Label
							className='text-body-large-bold text-secondary-default'
							for='email'
						>
							Email *
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='email'
								name='email'
								id='email'
								value={formik.values.email}
								onChange={formik.handleChange}
								placeholder='Ingresá su email'
							/>
						</InputGroup>
						{formik.errors.email && formik.touched.email ? (
							<span className='text-dark-link-regular text-error'>{formik.errors.email}</span>
						) : null}
					</Col>
					<Col
						xs={6}
						className='mt-1'
					>
						<Label
							className='text-body-large-bold text-secondary-default'
							for='password'
						>
							Contraseña *
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type={showPassword ? 'text' : 'password'}
								name='password'
								id='password'
								value={formik.values.password}
								onChange={formik.handleChange}
								placeholder='xxxxxxxxxx'
								autoComplete={'new-password'}
							/>
							<InputGroupAddon addonType='append'>
								<InputGroupText>
									{showPassword ? (
										<EyeOffIcon
											className='stroke-primary-default'
											onClick={() => setShowPassword(!showPassword)}
										/>
									) : (
										<EyeIcon
											className='stroke-primary-default'
											onClick={() => setShowPassword(!showPassword)}
										/>
									)}
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
						{formik.errors.password && formik.touched.password ? (
							<span className='text-dark-link-regular text-error'>{formik.errors.password}</span>
						) : null}
					</Col>
				</FormGroup>
				<FormGroup
					tag={Row}
					className='m-0 mt-3'
				>
					<Col>
						<Row className='m-0 align-items-center'>
							<Button
								className='btn btn-noti-primary py-1 px-5'
								type='submit'
							>
								<Row className='m-0 align-items-center'>
									<FaPlus
										size={15}
										className='mr-2'
									/>
									<span className='text-button'>Agregar</span>
								</Row>
							</Button>
							{collaboratorSuccessMessage &&
								setTimeout(() => {
									setCollaboratorSuccessMessage('');
								}, 2000) && (
									<CustomMessage
										className='m-0'
										icon={
											<CheckIcon
												className='stroke stroke-success'
												width={24}
												height={24}
											/>
										}
										message={collaboratorSuccessMessage}
									/>
								)}
							{collaboratorErrorMessage &&
								setTimeout(() => {
									setCollaboratorErrorMessage('');
								}, 2000) && (
									<CustomMessage
										className='m-0'
										icon={
											<CloseIcon
												className='stroke stroke-error'
												width={24}
												height={24}
											/>
										}
										message={collaboratorErrorMessage}
									/>
								)}
						</Row>
					</Col>
				</FormGroup>
			</Form>
		</>
	);
};

CollaboratorsForm.propTypes = {
	mutate: PropTypes.func,
	collaboratorSuccessMessage: PropTypes.string,
	collaboratorErrorMessage: PropTypes.string,
	setCollaboratorSuccessMessage: PropTypes.func,
	setCollaboratorErrorMessage: PropTypes.func,
};

export default CollaboratorsForm;
