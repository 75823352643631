import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { localityService } from 'services/localityService';
import { collaboratorService } from 'services/collaboratorService';
import global from 'context/global';
import user from 'context/user';
import { useHistory } from 'react-router-dom';
import { ROLES } from '../../../../helpers/consts';
import { parseObjectToQS } from '../../../../helpers/utils';
import { ReactComponent as SearchIcon } from 'assets/img/icons/notiexpress/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';

const Search = ({ resetSearch, isMobile = false }) => {
	const {
		state: { jurisdictions },
	} = useContext(global);

	const {
		state: { currentUser },
	} = useContext(user);

	const [params, setParams] = useState({});
	const [organismos, setOrganismos] = useState([]);
	const [colaboradores, setColaboradores] = useState([]);
	const [localidades, setLocalidades] = useState([]);
	const history = useHistory();
	const location = history.location;

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		setParams(currentParams);
		// load localities
		const loadLocalidades = async () => {
			try {
				const { circumscriptions: localidades } = await localityService.getFromJurisdiction(currentParams.jurisdiccion);
				localidades.unshift({
					id: '',
					nombre: 'Todas las localidades',
				});
				setLocalidades(localidades);
			} catch (error) {
				setLocalidades([{ id: '', nombre: 'Todas las localidades' }]);
			}
		};

		if (currentParams.jurisdiccion) {
			loadLocalidades();
		}

		// load organisms
		const loadOrganismos = async () => {
			try {
				const organismos = await localityService.getOrganismos(currentParams.localidad);
				organismos.unshift({ id: '', nombre: 'Todos los organismos' });
				setOrganismos(organismos);
			} catch (error) {
				setOrganismos([{ id: '', nombre: 'Todos los organismos' }]);
			}
		};

		if (currentParams.localidad) {
			loadOrganismos();
		}

		// load collaborators
		const fetchColabs = async () => {
			try {
				const result = await collaboratorService.getFromUser();
				setColaboradores(result);
			} catch {
				setColaboradores([]);
				//console.log(error);
			}
		};

		fetchColabs();
	}, []);

	const formik = useFormik({
		initialValues: {
			numero: params.numero || '',
			anio: params.anio || '',
			caratula: params.caratula || '',
			jurisdiccion: params.jurisdiccion || '',
			organismo: params.organismo || '',
			localidad: params.localidad || '',
			collaborator: params.colaborator || '',
			folder: params.folder || '',
		},
		enableReinitialize: true,
		validate: () => {
			return {};
		},
		onSubmit: (values) => {
			const newQueryString = parseObjectToQS({ ...values, page: 1 });
			history.push(`${location.pathname}?${newQueryString}`);
		},
		onReset: () => {
			setLocalidades([]);
			setOrganismos([]);
			resetSearch();
			history.push(`${location.pathname}`);
			setParams({});
		},
	});

	const onChangeJurisdiction = async ({ target }) => {
		try {
			const { value } = target;
			formik.handleChange({
				target: { name: 'jurisdiccion', value },
			});
			formik.handleChange({
				target: { name: 'localidad', value: '' },
			});
			formik.handleChange({
				target: { name: 'organismo', value: '' },
			});
			const { circumscriptions: localidades } = await localityService.getFromJurisdiction(value);
			localidades.unshift({ id: '', name: 'Todas las localidades' });
			setLocalidades(localidades);
		} catch (error) {
			setLocalidades([{ id: '', name: 'Todas las localidades' }]);
		}
	};

	const onChangeLocalidad = async ({ target }) => {
		try {
			const { value } = target;
			formik.handleChange({
				target: { name: 'localidad', value },
			});
			formik.handleChange({
				target: { name: 'organismo', value: '' },
			});
			const { organism: organismos } = await localityService.getOrganisms(value);
			organismos.unshift({ id: '', name: 'Todos los organismos' });
			setOrganismos(organismos);
		} catch (error) {
			setOrganismos([{ id: '', name: 'Todos los organismos' }]);
		}
	};

	return (
		<Form
			role='form'
			onSubmit={formik.handleSubmit}
			className={isMobile ? 'w-100' : 'w-100 pt-3'}
		>
			<Row
				className='align-items-center mt-2'
				lg='8'
			>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-jurisdiccion'
						>
							Jurisdicción
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='select'
								name='jurisdiccion'
								placeholder='Seleccione una jurisdicción'
								id='input-search-jurisdiccion'
								onChange={(e) => {
									onChangeJurisdiction(e);
									!e.target.value ? resetSearch(['jurisdiccion']) : null;
								}}
								value={formik.values.jurisdiccion}
							>
								<option value=''>Todas las jurisdicciones</option>
								{jurisdictions.map((item) => (
									<option
										key={item.id}
										value={item.id}
									>
										{item.name === 'Federal' ? 'Capital Federal / Justicia Federal' : item.name}
									</option>
								))}
							</Input>
						</InputGroup>
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-locality'
						>
							Circunscripción
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='select'
								name='localidad'
								placeholder='Seleccione una localidad'
								id='input-search-locality'
								onChange={(e) => {
									onChangeLocalidad(e);
									!e.target.value ? resetSearch(['localidad']) : null;
								}}
								value={formik.values.localidad}
								disabled={!localidades.length}
							>
								{localidades.map((item) => (
									<option
										key={item.id}
										value={item.id}
									>
										{item.name}
									</option>
								))}
							</Input>
						</InputGroup>
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-organismo'
						>
							Organismo
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								type='select'
								name='organismo'
								placeholder='Seleccione un organismo'
								id='input-search-organismo'
								onChange={(e) => {
									formik.handleChange(e);
									!e.target.value ? resetSearch(['organismo']) : null;
								}}
								value={formik.values.organismo}
								disabled={!organismos.length}
							>
								{organismos.map((item) => (
									<option
										key={item.id}
										value={item.id}
									>
										{item.name}
									</option>
								))}
							</Input>
						</InputGroup>
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-number'
						>
							Expediente
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='w-25 text-right'
								name='numero'
								id='input-search-number'
								placeholder='Número'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
									!e.target.value ? resetSearch(['numero']) : null;
								}}
								value={formik.values.numero}
							/>
							<InputGroupAddon
								addonType='append'
							>
								<InputGroupText>/</InputGroupText>
							</InputGroupAddon>
							<Input
								className='w-25'
								name='anio'
								id='input-search-year'
								placeholder='año'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
									!e.target.value ? resetSearch(['anio']) : null;
								}}
								value={formik.values.anio}
							/>
						</InputGroup>
					</FormGroup>
				</Col>
			</Row>
			<Row
				className='align-items-center  mt-2'
				lg='8'
				xs={12}
			>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 521, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-caratula'
						>
							Carátula
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								id='input-search-caratula'
								name='caratula'
								placeholder=''
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
									!e.target.value ? resetSearch(['caratula']) : null;
								}}
								value={formik.values.caratula}
							/>
						</InputGroup>
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-carpeta'
						>
							Carpeta
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								id='input-search-carpeta'
								name='folder'
								placeholder=''
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
									!e.target.value ? resetSearch(['folder']) : null;
								}}
								value={formik.values.folder}
							/>
						</InputGroup>
					</FormGroup>
				</Col>
				{currentUser?.rol === ROLES.USER ? (
					<Col
						xs='12'
						style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
					>
						<FormGroup>
							<Label
								className='text-body-large-bold text-secondary-default'
								htmlFor='input-search-colaborator'
							>
								Responsable
							</Label>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<Input
									type='select'
									name='collaborator'
									placeholder='Seleccione un colaborador'
									id='input-search-colaborator'
									onChange={(e) => {
										formik.handleChange(e);
										!e.target.value ? resetSearch(['collaborator']) : null;
									}}
									value={formik.values.collaborator}
									disabled={!colaboradores.length}
								>
									<option value=''>Todos los responsables</option>
                  <option value={currentUser.id}>{`${currentUser.name} ${currentUser.lastName}`}</option>
									{colaboradores.map(({ User }) => (
										<option
											key={User.id}
											value={User.id}
										>
											{`${User.name} ${User.lastName}`}
										</option>
									))}
								</Input>
							</InputGroup>
						</FormGroup>
					</Col>
				) : null}
			</Row>
			<Row
				className='align-items-center justify-content-start mt-2 '
				lg='8'
				xs={12}
			>
				{!isMobile ? (
					<>
						<Col
							xs='12'
							style={{ maxWidth: 260, width: '100%' }}
						>
							<Button
								type='submit'
								size='md'
								className='btn btn-noti-primary py-2 px-5'
							>
								<Row className='align-items-center m-0'>
									<SearchIcon
										width={24}
										height={24}
										className='stroke-neutro-1'
									/>
									<span className='text-button'>Buscar</span>
								</Row>
							</Button>
						</Col>
						<Col
							xs='12'
							style={{ maxWidth: 260, width: '100%' }}
						>
							<Button
								onClick={() => {
									formik.resetForm();
								}}
								color='link'
								style={{
									paddingLeft: 0,
								}}
							>
								<Row className='align-items-center m-0'>
									<CloseIcon
										width={24}
										height={24}
										className='stroke-info'
									/>
									<span className='text-link-regular'>Limpiar campos</span>
								</Row>
							</Button>
						</Col>
					</>
				) : (
					<>
						<Col
							xs='12'
							style={{ maxWidth: 260, width: '100%' }}
						>
							<Button
								onClick={() => {
									formik.resetForm();
								}}
								color='link'
								style={{
									paddingLeft: 0,
								}}
							>
								<Row className='align-items-center m-0'>
									<CloseIcon
										width={24}
										height={24}
										className='stroke-info'
									/>
									<span className='text-link-regular'>Limpiar campos</span>
								</Row>
							</Button>
						</Col>
						<div
							style={!isMobile ? { maxWidth: 260, width: '100%' } : { width: '100%' }}
							className='align-items-center justify-content-center d-flex'
						>
							<Button
								type='submit'
								size='md'
								className='btn btn-noti-primary py-2 px-5'
							>
								<Row className='align-items-center m-0'>
									<SearchIcon
										width={24}
										height={24}
										className='stroke-neutro-1'
									/>
									<span className='text-button'>Buscar</span>
								</Row>
							</Button>
						</div>
					</>
				)}
			</Row>
		</Form>
	);
};

export default Search;

Search.propTypes = {
	location: PropTypes.object,
	resetSearch: PropTypes.func,
	history: PropTypes.object,
	tableType: PropTypes.string,
	isMobile: PropTypes.bool,
};
