import { API } from 'helpers/fetch';
import { AxiosResponse } from 'axios';

//DTIOL

/**
 * Get the captcha image from dtiol
 * @returns {Promise<AxiosResponse<{ result : Blob}>>}
 */
const getDtiolCaptcha = () => {
	return API.get('scrap/dtiol/captcha');
};

/**
 * Get the circumscription from dtiol
 * @param captcha
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDTIOLCircumscription = ({ captcha }) => {
	return API.get('/scrap/dtiol/circumscription', {
		params: {
			cs: captcha,
		},
	});
};

const getDTIOLOrganism = ({ option }) => {
	return API.get('/scrap/dtiol/organism', {
		params: {
			option,
		},
	});
};

const getDTIOLSearchForAddExpediente = ({ option, expYear, expNumber }) => {
	return API.get('/scrap/dtiol/resultSearch', {
		params: {
			option,
			expNumber,
			expYear,
		},
	});
};

const getDTIOLProvidencesPreview = () => {
	return API.get('/scrap/dtiol/preview');
};

const addDTIOLExpedient = ({ expNumber, expYear }) => {
	return API.post(
		'/scrap/dtiol/addExpediente',
		{},
		{
			params: {
				expNumber,
				expYear,
			},
		}
	);
};

const scrapDtiolProvidence = ({ expId, captcha, providenceDate }) => {
	return API.put(
		`scrap/dtiol/providenceContent/${expId}`,
		{},
		{
			params: {
				cs: captcha,
				providenceDate,
			},
		}
	);
};

const scrapDtiolExpedient = ({ expId, captcha }) => {
	return API.put(
		`scrap/dtiol/${expId}`,
		{},
		{
			params: {
				cs: captcha,
			},
		}
	);
};

const scrapDtiolNews = ({ captcha, date }) => {
	return API.put(
		'scrap/dtiol/novelty',
		{},
		{
			params: {
				cs: captcha,
				date,
			},
		}
	);
};

const scrapPJNNews = ({ date }) => {
	return API.put(
		'scrap/pjn/novelty',
		{},
		{
			params: {
				date,
			},
		}
	);
};

const scrapPJNExpedient = ({ expId }) => {
	return API.put(`scrap/pjn/exp/${expId}`, {}, {});
};

const closeTabDTIOLScrapper = () => {
	return API.get('scrap/dtiol/closeUserPage');
};
export const scrapperService = {
	scrapDtiolProvidence,
	getDtiolCaptcha,
	scrapDtiolExpedient,
	scrapDtiolNews,
	getDTIOLCircumscription,
	getDTIOLOrganism,
	getDTIOLSearchForAddExpediente,
	getDTIOLProvidencesPreview,
	addDTIOLExpedient,
	scrapPJNNews,
	scrapPJNExpedient,
	closeTabDTIOLScrapper,
};
