import React from 'react';
import { Route } from 'react-router-dom';
import { Provider as AdminProvider } from '../context/admin';
import AuthenticatedProvider from './AuthenticatedProvider';
import propTypes from 'prop-types';

const AdminRoute = ({ component: Component, ...rest }) => {
	return (
		<>
			<Route
				{...rest}
				render={(props) => (
					<AuthenticatedProvider>
						<AdminProvider>
							<Component {...props} />
						</AdminProvider>
					</AuthenticatedProvider>
				)}
			/>
		</>
	);
};

AdminRoute.propTypes = {
  component: propTypes.func.isRequired,
};

export default AdminRoute;
