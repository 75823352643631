import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DeleteCollaboratorModal from './DeleteColaboratorModal';
import ModifyCollaboratorModal from './ModifyColaboratorModal';
import PropTypes from 'prop-types';

function CollaborationDropdown({ collaborator, mutate, setCollaboratorErrorMessage, setCollaboratorSuccessMessage }) {
	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

	function openDeleteModal() {
		setDeleteModalIsOpen(true);
	}

	function openUpdateModal() {
		setUpdateModalIsOpen(true);
	}

	return (
		<div className='text-center'>
			<UncontrolledDropdown>
				<DropdownToggle
					className='btn-icon-only text-default m-0'
					role='button'
					size='sm'
					onClick={(e) => e.preventDefault()}
				>
					<i className='fas fa-ellipsis-v' />
				</DropdownToggle>
				<DropdownMenu
					className='dropdown-menu-arrow'
					right
				>
					<DropdownItem onClick={() => openUpdateModal()}>Modificar</DropdownItem>
					<DropdownItem onClick={() => openDeleteModal()}>Quitar</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			<DeleteCollaboratorModal
				collaborator={collaborator}
				deleteModalIsOpen={deleteModalIsOpen}
				setDeleteModalIsOpen={setDeleteModalIsOpen}
				mutate={mutate}
				setCollaboratorErrorMessage={setCollaboratorErrorMessage}
				setCollaboratorSuccessMessage={setCollaboratorSuccessMessage}
			/>
			<ModifyCollaboratorModal
				collaborator={collaborator}
				updateModalIsOpen={updateModalIsOpen}
				setUpdateModalIsOpen={setUpdateModalIsOpen}
				mutate={mutate}
				setCollaboratorErrorMessage={setCollaboratorErrorMessage}
				setCollaboratorSuccessMessage={setCollaboratorSuccessMessage}
			/>
		</div>
	);
}

export default CollaborationDropdown;

CollaborationDropdown.propTypes = {
	collaborator: PropTypes.object,
	mutate: PropTypes.func,
	setCollaboratorErrorMessage: PropTypes.func,
	setCollaboratorSuccessMessage: PropTypes.func,
};
