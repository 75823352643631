import React from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Container } from 'reactstrap';
import SendHelpEmail from './SendHelpEmail';

const ContactView = () => {
	return (
		<>
			<HomeHeader headerText={'Contacto'} />
			<Container className='bg-white mb-5' fluid>
        <SendHelpEmail />
      </Container>
		</>
	);
};

export default ContactView;
