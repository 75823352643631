import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { modifyOfficeModal } from './components/ModifyOfficeModal';
import { deleteOfficeModal } from './components/DeleteOfficeModal';
import PropTypes from 'prop-types';

const LegalAddressDropDown = ({ office, mutate }) => {
	const handleModifyOffice = async (office) => {
		try {
			await modifyOfficeModal({ office, mutate });
		} catch (error) {
			// console.log(error);
		}
	};
	const handleDeleteOffice = async (office) => {
		try {
			await deleteOfficeModal({ office, mutate });
		} catch (error) {
			// console.log(error);
		}
	};
	return (
		<UncontrolledDropdown>
			<DropdownToggle
				className='btn-icon-only text-default'
				role='button'
				size='sm'
				color=''
				onClick={(e) => e.preventDefault()}
			>
				<i className='fas fa-ellipsis-v' />
			</DropdownToggle>
			<DropdownMenu
				className='dropdown-menu-arrow'
				right
			>
				<DropdownItem onClick={() => handleModifyOffice(office)}>Modificar</DropdownItem>
				<DropdownItem onClick={() => handleDeleteOffice(office)}>Eliminar</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

LegalAddressDropDown.propTypes = {
	office: PropTypes.object.isRequired,
	mutate: PropTypes.func.isRequired,
};

export default LegalAddressDropDown;
