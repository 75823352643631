import React from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';

const CardResumeInfo = ({ cardTitle = 'default', subtitle = 'default', children, isLoading = false }) => {
	return (
		<Card className='shadow h-100 d-flex align-items-center'>
			<CardBody
				style={{
					maxWidth: '270px',
				}}
			>
				{isLoading ? (
					<div className='d-flex justify-content-center align-items-center h-100'>
						<Spinner color='primary' />
					</div>
				) : (
					<Row
            className='h-100 d-flex justify-content-between'
						style={{
							width: '150px',
						}}
					>
						<Col className='d-flex flex-column justify-content-between pr-0'>
							<Row>{children}</Row>
							<Row>
								<span
									style={{
										fontSize: '12px',
										fontFamily: 'Roboto',
										fontWeight: '400',
										lineHeight: '20px',
										color: '#9BA7AF',
									}}
								>
									{cardTitle}
								</span>
								{subtitle}
							</Row>
						</Col>
					</Row>
				)}
			</CardBody>
		</Card>
	);
};

export default CardResumeInfo;
