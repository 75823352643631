import React, { useContext, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AddJurisdictionPanel from './components/AddJurisdictionPanel';
import AddPaymentMethodPanel from './components/AddPaymentMethodPanel';
import SuccessPaymentPanel from './components/SuccessPaymentPanel';
import { paymentsService } from '../../../../services/paymentsService';
import SubscriptionsInfo from '../SubscriptionsInfo/SubscriptionsInfo';
import CartStatusCard from './components/CartStatusCard';
import cartContext from '../../../../context/cart';

const PaymentFlow = () => {
	const { data: services } = paymentsService.useServicesTable();
	const { cart, hasPayed } = useContext(cartContext);
	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<>
			{activeTab === '1' ? <SubscriptionsInfo showFooter={false} /> : activeTab === '2' ? <CartStatusCard /> : null}
			<Card className='shadow-card mb-7'>
				<CardHeader>
					<Nav
						tabs
						className=''
					>
						<NavItem
							tag={Col}
							xs={12}
							md={4}
						>
							<NavLink
								className={classnames({
									active: activeTab === '1',
								})}
								onClick={() => {
									toggle('1');
								}}
								disabled={hasPayed}
							>
								<p className='text-center m-0 text-heading-3'>1. Agregá tu suscripción</p>
							</NavLink>
						</NavItem>
						<NavItem
							tag={Col}
							xs={12}
							md={4}
						>
							<NavLink
								className={classnames({
									active: activeTab === '2',
								})}
								onClick={() => {
									toggle('2');
								}}
								disabled={cart.length === 0 || hasPayed}
							>
								<p className='text-center m-0 text-heading-3'>2. Completá los datos de tu tarjeta</p>
							</NavLink>
						</NavItem>
						<NavItem
							tag={Col}
							xs={12}
							md={4}
						>
							<NavLink
								className={classnames({
									active: activeTab === '3',
								})}
								onClick={() => {
									toggle('3');
								}}
								disabled={!hasPayed}
							>
								<p className='text-center m-0 text-heading-3'>3. ¡Suscripción registrada!</p>
							</NavLink>
						</NavItem>
					</Nav>
				</CardHeader>
				<CardBody>
					<TabContent activeTab={activeTab}>
						<TabPane tabId='1'>
							<AddJurisdictionPanel
								setStatus={setActiveTab}
								status={activeTab}
								services={services}
							/>
						</TabPane>
						<TabPane tabId='2'>
							<AddPaymentMethodPanel
								setStatus={setActiveTab}
								status={activeTab}
							/>
						</TabPane>
						<TabPane tabId='3'>
							<SuccessPaymentPanel
								setStatus={setActiveTab}
								status={activeTab}
							/>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</>
	);
};

export default PaymentFlow;
