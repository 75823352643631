import React, { useContext, useEffect, useState } from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Col, Container, Row } from 'reactstrap';
import CaseFileTabs from './components/CaseFileTabs';
import CaseFileHistoric from './components/CaseFileHistoric';
import { SynchronizeContext } from '../../context/synchronize';
import { scrapperService } from '../../services/scrapperService';
import { useHistory } from 'react-router-dom';

const CaseFilePreview = () => {
  const history = useHistory();

	const {
		state: {
			expedientsToAdd: [caseInfo],
		},
    actions: { resetExpedientsToAdd },
	} = useContext(SynchronizeContext);

	const [providencesPreview, setProvidencesPreview] = useState([]);
	const handleGetPreview = async () => {
		try {
			const providencesPreview =
				await scrapperService.getDTIOLProvidencesPreview();
			setProvidencesPreview(providencesPreview);
		} catch (e) {
			//console.log(e);
		}
	};

	const handleAddExpedient = () => {
		return scrapperService.addDTIOLExpedient({
			expNumber: caseInfo.number,
			expYear: caseInfo.year,
		});
	};

  useEffect(() => {
    if (!caseInfo) {
      history.push('/admin/expedientes');
    }
    return () => {
      resetExpedientsToAdd();
    };
  }, []);

	return (
		<>
			<HomeHeader
				headerText='Detalle de Expediente'
				canGoBack
			/>
			<Container className='bg-gradient-white' fluid={true}>
				<Row>
					<Col xs={12}>
						<CaseFileTabs
							caseInfo={caseInfo}
							preview
							autoSync
							handleGetPreview={handleGetPreview}
							handleAddExpedient={handleAddExpedient}
							mutate={() => {}}
							isLoading={false}
							isError={!caseInfo}
						/>
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xl='12'>
						{providencesPreview.length > 0 && (
							<CaseFileHistoric
								caseInfo={caseInfo}
								preview
								providencesPreview={providencesPreview}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default CaseFilePreview;
