import React, { useContext, useState } from 'react';
import user from '../../../../context/user';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, ModalBody, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { collaboratorService } from '../../../../services/collaboratorService';
import { officesService } from '../../../../services/officesService';
import { create } from 'react-modal-promise';
import { casesService } from '../../../../services/casesService';
import PropTypes from 'prop-types';

const CaseConfigurationSchema = yup.object().shape({
	folder: yup.string(),
	collaborator: yup.string(),
	caducatePeriod: yup.string(),
	alertPeriod: yup
		.string()
		.ensure()
		.test('alert-limit', 'Debe ser menor que el plazo de caducidad.', function (alertPeriod) {
			return parseInt(this.parent.caducatePeriod) >= parseInt(alertPeriod);
		}),
	officeId: yup.string(),
	representationType: yup.string(),
	otherInput: yup.string().nullable(),
	calculationType: yup.string(),
});

const UpdateCaseConfigurationModal = ({ expediente, isOpen, onReject, onResolve }) => {
	const [enableOtherInput, setEnableOtherInput] = useState(!['Actora', 'Demandada', 'Derecho_Propio'].includes(expediente?.representation));

	const {
		state: { userdata },
	} = useContext(user);

	const { data: collaborators } = collaboratorService.useCollaboratorsTable();

	const { offices } = officesService.useOfficesByUserId();

	const initialValues = {
		folder: expediente?.folder ?? '',
		collaborator: expediente?.collaborator?.id ?? '',
		legalAddress: expediente?.office?.id ?? '',
		representationType: expediente?.representation ?? 'Actora',
		otherInput: enableOtherInput ? (expediente.representation ? expediente.representation : '') : '',
		caducatePeriod: expediente?.caducityPeriod ?? '',
		calculationType: expediente?.calculationCaducity ?? 'WORKING_DAYS',
		alertPeriod: expediente?.alertPeriod ?? '',
	};

	const handleSubmit = async (values) => {
		try {
			const response = await casesService.updateCaseSettings({
				caseId: expediente?.Expediente?.id,
				...values,
				officeId: values.legalAddress,
				representationType: values.representationType === 'other' ? values.otherInput : values.representationType,
			});
			onResolve(response);
		} catch (e) {
			onReject(e);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: CaseConfigurationSchema,
		onSubmit: (values) => handleSubmit(values),
	});

	return (
		<>
			<CustomModal
				title='Modificar configuración'
				isOpen={isOpen}
				toggle={() => onReject('closed')}
				className={'w-75'}
			>
				<ModalBody className=''>
					<Form onSubmit={formik.handleSubmit}>
						<Row className='align-items-center py-2'>
							<Col xs={4}>
								<FormGroup>
									<Label
										for='folder'
										className='text-body-large-bold text-secondary-default'
									>
										Carpeta
									</Label>
									<InputGroup
										className='input-group-alternative'
										size='sm'
									>
										<Input
											type='text'
											name='folder'
											id='folder'
											placeholder='Carpeta'
											value={formik.values.folder}
											onChange={formik.handleChange}
										/>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col xs={4}>
								<FormGroup>
									<Label
										for='collaborator'
										className='text-body-large-bold text-secondary-default'
									>
										Responsable *
									</Label>
									<InputGroup
										className='input-group-alternative'
										size='sm'
									>
										<Input
											type='select'
											name='collaborator'
											id='collaborator'
											placeholder='Colaborador'
											value={formik.values.collaborator}
											onChange={formik.handleChange}
										>
											{expediente?.Collaborator ? (
												<>
													<option value={expediente?.Collaborator.id}>
														{`${expediente?.Collaborator.name} ${expediente?.Collaborator.lastName}`}
													</option>
													<option value={expediente.userId}>{`${userdata?.name} ${userdata?.lastName}`}</option>
													{collaborators
														?.filter(({ User }) => User.id !== expediente.Collaborator.id)
														?.map(({ User }) => {
															return (
																<option
																	key={User.id}
																	value={User.id}
																>
																	{User.name + ' ' + User.lastName}
																</option>
															);
														})}
												</>
											) : (
												<>
													<option value={userdata?.id}>{`${userdata?.name} ${userdata?.lastName}`}</option>
													{collaborators?.map(({ User }) => {
														return (
															<option
																key={User.id}
																value={User.id}
															>
																{User.name + ' ' + User.lastName}
															</option>
														);
													})}
												</>
											)}
										</Input>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col xs={4}>
								<FormGroup>
									<Label
										for='legalAddress'
										className='text-body-large-bold text-secondary-default'
									>
										Domicilio legal *
									</Label>
									<InputGroup
										className='input-group-alternative'
										size='sm'
									>
										<Input
											type='select'
											name='legalAddress'
											id='legalAddress'
											placeholder='Domicilio legal'
											value={formik.values.legalAddress}
											onChange={formik.handleChange}
										>
											<option value={expediente.Office.id}>{expediente.Office.name}</option>
											{offices?.offices
												?.filter((office) => {
													return office.id !== expediente.Office.id;
												})
												?.map((office) => {
													return (
														<option
															key={office.id}
															value={office.id}
														>
															{office.name}
														</option>
													);
												})}
										</Input>
									</InputGroup>
								</FormGroup>
							</Col>
						</Row>
						<Row className='align-items-center py-2'>
							<Col xs={12}>
								<span className='text-heading-3'>Representación *</span>
							</Col>
							<Col xs={12}>
								<Row className='align-items-center m-0 py-3'>
									<span className='text-body-large-bold text-secondary-default'>Parte</span>
									<Col>
										<FormGroup
											className='m-0 ml-3 align-items-center '
											row
											tag='fieldset'
											onChange={(e) => {
                        formik.handleChange(e);
                        setEnableOtherInput(['Actora', 'Demandada', 'Derecho_Propio'].includes(e.target.value) ? false : true);
                      }}
										>
											<FormGroup
												className='custom-radio d-flex align-items-center mx-3'
												check
											>
												<Input
													name='representationType'
													className='custom-control-input'
													value='Actora'
													defaultChecked={formik.values.representationType === 'Actora'}
													id='Actora'
													type='radio'
												/>
												<Label
													check
													for='Actora'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Actora</span>
												</Label>
											</FormGroup>
											<FormGroup
												className='custom-radio d-flex align-items-center mx-3'
												check
											>
												<Input
													name='representationType'
													className='custom-control-input'
													value='Demandada'
													defaultChecked={formik.values.representationType === 'Demandada'}
													id='Demandada'
													type='radio'
												/>
												<Label
													check
													for='Demandada'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Demandada</span>
												</Label>
											</FormGroup>
											<FormGroup
												className='custom-radio d-flex align-items-center mx-3'
												check
											>
												<Input
													name='representationType'
													className='custom-control-input'
													value='Derecho_Propio'
													id='Derecho_Propio'
													defaultChecked={formik.values.representationType === 'Derecho_Propio'}
													type='radio'
												/>
												<Label
													check
													for='Derecho_Propio'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Derecho Propio</span>
												</Label>
											</FormGroup>
											<FormGroup
												className='custom-radio d-flex align-items-center mx-3 w-50'
												check
												style={{
													maxWidth: '400px',
												}}
											>
												<Input
													name='representationType'
													className='custom-control-input'
													value='other'
													id='other'
													defaultChecked={enableOtherInput}
													type='radio'
												/>
												<Label
													check
													for='other'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Otra</span>
												</Label>
												<Input
													type='text'
													name='otherInput'
													id='otherInput'
													placeholder='Ingresá la parte que representas'
													disabled={!enableOtherInput}
													value={formik.values.otherInput}
													onChange={formik.handleChange}
													size='sm'
													className='ml-3 w-75 input-group-alternative'
												/>
											</FormGroup>
										</FormGroup>
									</Col>
								</Row>
							</Col>
							<Col xs={12}></Col>
						</Row>
						<Row className='align-items-center py-2'>
							<Col xs={12}>
								<span className='text-heading-3'>Fecha de caducidad *</span>
							</Col>
							<Col>
								<Row
									className='py-2'
									style={{
										maxWidth: '1000px',
									}}
								>
									<Col
										style={{
											maxWidth: '250px',
										}}
									>
										<FormGroup
											row
											className='m-0 align-items-center'
										>
											<Label
												for='caducatePeriod'
												className='text-body-large-bold text-secondary-default m-0'
											>
												Plazo
											</Label>
											<InputGroup
												className='input-group-alternative mx-3'
												style={{ maxWidth: '80px' }}
												size='sm'
											>
												<Input
													name='caducatePeriod'
													value={formik.values.caducatePeriod}
													onChange={formik.handleChange}
													id='caducatePeriod'
													type='number'
												/>
											</InputGroup>
											<span className='text-body-regular'>días</span>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup
											className='m-0 align-items-center h-100 d-flex justify-content-center'
											row
											tag='fieldset'
											onChange={formik.handleChange}
										>
											<span className='text-body-large-bold text-secondary-default mr-3'>Cómputo</span>
											<FormGroup
												className='custom-radio d-flex align-items-center mx-3'
												check
											>
												<Input
													name='calculationType'
													className='custom-control-input'
													value='WORKING_DAYS'
													checked={formik.values.calculationType === 'WORKING_DAYS'}
													onChange={formik.handleChange}
													id='WORKING_DAYS'
													type='radio'
												/>
												<Label
													check
													for='WORKING_DAYS'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Hábiles</span>
												</Label>
											</FormGroup>
											<FormGroup
												className='custom-radio d-flex align-items-center'
												check
											>
												<Input
													name='calculationType'
													className='custom-control-input'
													value='NON_WORKING_DAYS'
													checked={formik.values.calculationType === 'NON_WORKING_DAYS'}
													onChange={formik.handleChange}
													id='NON_WORKING_DAYS'
													type='radio'
												/>
												<Label
													check
													for='NON_WORKING_DAYS'
													className='custom-control-label'
												>
													<span className='text-body-regular'>Inhábiles</span>
												</Label>
											</FormGroup>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup
											row
											className='m-0 align-items-center'
										>
											<Label
												for='alertPeriod'
												className='text-body-large-bold text-secondary-default m-0'
											>
												Alerta previa
											</Label>
											<InputGroup
												className='input-group-alternative mx-3'
												style={{ maxWidth: '80px' }}
												size='sm'
											>
												<Input
													name='alertPeriod'
													value={formik.values.alertPeriod}
													onChange={formik.handleChange}
													id='alertPeriod'
													type='number'
												/>
											</InputGroup>
											<span className='text-body-regular'>días</span>
										</FormGroup>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<Button
									className='btn btn-outline-noti-secondary py-1 px-5'
									onClick={() => onReject('closed')}
								>
									<span className=''>Cancelar</span>
								</Button>
								<Button
									className='btn btn-noti-primary py-1 px-5'
									type='submit'
									disabled={formik.isSubmitting}
								>
									<Row className='m-0 align-items-center'>
										<span className={`${formik.isSubmitting ? 'spinner-border spinner-border-sm' : ''} mr-2`} />
										<span className='text-button'>Guardar</span>
									</Row>
								</Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</CustomModal>
		</>
	);
};

UpdateCaseConfigurationModal.propTypes = {
	expediente: PropTypes.object,
	isOpen: PropTypes.bool,
	onReject: PropTypes.func,
	onResolve: PropTypes.func,
};

export const updateCaseConfigurationModal = create(UpdateCaseConfigurationModal);
