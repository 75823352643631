import React from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { paymentsService } from '../../services/paymentsService';
import moment from 'moment';
import DynamicTable from '../../components/Commons/DynamicTable';
import { receiptTableColumns } from './assets/receiptTableColumns';

const ReceiptListView = () => {
	const {
		data: receiptPayments,
		mutate,
		isError,
		isLoading,
	} = paymentsService.getUserPayments(
		moment().subtract(1, 'year').format('YYYY-MM-DD'),
		moment().format('YYYY-MM-DD')
	);
	return (
		<>
			<HomeHeader
				headerText='Mis comprobantes'
				canGoBack
			/>
			<Container className='bg-gradient-white' fluid={true}>
				<Row>
					<Col>
						<Card className='shadow-card'>
							<CardBody>
								<DynamicTable
									data={receiptPayments}
									columns={receiptTableColumns}
									isLoading={isLoading}
									isError={isError}
									mutate={mutate}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

ReceiptListView.propTypes = {};

export default ReceiptListView;
