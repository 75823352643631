import React, { useContext } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import { collaboratorService } from '../../../../services/collaboratorService';
import { EXPIRATION_STATUS } from '../../../../helpers/consts';
import { ReactComponent as SearchIcon } from 'assets/img/icons/notiexpress/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import { parseObjectToQS } from '../../../../helpers/utils';
import user from 'context/user';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const SearchForCaducidad = ({ resetSearch, layout }) => {
	const {
		state: { currentUser },
	} = useContext(user);

	const { data: collaborators } = collaboratorService.useCollaboratorsTable();
	const history = useHistory();
	const initialValues = {
		expYear: '',
		expNumber: '',
		coverPage: '',
		folder: '',
		collaborator: '',
		status: '',
	};

	const getExpirationStatus = (status) => {
		switch (status) {
			case EXPIRATION_STATUS.CONTROLLED:
				return 'Controlado';
			case EXPIRATION_STATUS.CRITIC:
				return 'Por vencer';
			case EXPIRATION_STATUS.EXPIRED:
				return 'Caducado';
			default:
				return '';
		}
	};
	const handleSubmit = (values) => {
		const queryObject = {
			...(values.expYear && { anio: values.expYear }),
			...(values.expNumber && { numero: values.expNumber }),
			...(values.coverPage && { caratula: values.coverPage }),
			...(values.folder && { folder: values.folder }),
			...(values.collaborator && { collaborator: values.collaborator }),
			...(values.status && { expirationStatus: values.status }),
		};
		const newQueryString = parseObjectToQS({ ...queryObject, page: 1 });
		history.push(`${location.pathname}?${newQueryString}`);
		formik.setSubmitting(false);
	};
	const handleReset = () => {
		resetSearch();
		history.push(`${location.pathname}`);
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		onReset: handleReset,
	});

	return (
		<Form
			onSubmit={formik.handleSubmit}
			className='py-3'
		>
			<Row>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-number'
						>
							Expediente
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='w-25 text-right'
								name='expNumber'
								id='input-search-number'
								placeholder='Número'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
								}}
								value={formik.values.expNumber}
							/>
							<InputGroupAddon addonType='append'>
								<InputGroupText>/</InputGroupText>
							</InputGroupAddon>
							<Input
								className='w-25'
								name='expYear'
								id='input-search-year'
								placeholder='Año'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
								}}
								value={formik.values.expYear}
							/>
						</InputGroup>
						{formik.errors.expNumber && formik.touched.expNumber && (
							<span className='text-error text-body-small'>{formik.errors.expNumber}</span>
						)}
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-cover-page'
						>
							Carátula
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='form-control-alternative'
								name='coverPage'
								id='input-search-cover-page'
								placeholder='Carátula'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
								}}
								value={formik.values.coverPage}
							/>
						</InputGroup>
						{formik.errors.coverPage && formik.touched.coverPage && (
							<span className='text-error text-body-small'>{formik.errors.coverPage}</span>
						)}
					</FormGroup>
				</Col>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<FormGroup>
						<Label
							className='text-body-large-bold text-secondary-default'
							htmlFor='input-search-folder'
						>
							Carpeta
						</Label>
						<InputGroup
							className='input-group-alternative'
							size='sm'
						>
							<Input
								className='form-control-alternative'
								name='folder'
								id='input-search-folder'
								placeholder='Carpeta'
								type='text'
								onChange={(e) => {
									formik.handleChange(e);
								}}
								value={formik.values.folder}
							/>
						</InputGroup>
						{formik.errors.folder && formik.touched.folder && <span className='text-error text-body-small'>{formik.errors.folder}</span>}
					</FormGroup>
				</Col>
				{layout === 'admin' && (
					<Col
						xs='12'
						style={{ maxWidth: 260, width: '100%' }}
					>
						<FormGroup>
							<Label
								className='text-body-large-bold text-secondary-default'
								htmlFor='input-search-collaborator'
							>
								Responsable
							</Label>
							<InputGroup
								className='input-group-alternative'
								size='sm'
							>
								<Input
									className='form-control-alternative'
									name='collaborator'
									id='input-search-collaborator'
									placeholder='Colaborador'
									type='select'
									onChange={(e) => {
										formik.handleChange(e);
									}}
									value={formik.values.collaborator}
								>
									<option value=''>Todos los responsables</option>
									<option value={currentUser.id}>{`${currentUser.name} ${currentUser.lastName}`}</option>
									{collaborators?.map(({ User: collaborator }) => (
										<option
											key={collaborator.id}
											value={collaborator.id}
										>
											{collaborator.name + ' ' + collaborator.lastName}
										</option>
									))}
								</Input>
							</InputGroup>
							{formik.errors.collaborator && formik.touched.collaborator && (
								<span className='text-error text-body-small'>{formik.errors.collaborator}</span>
							)}
						</FormGroup>
					</Col>
				)}
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				></Col>
			</Row>
			<Row className='align-items-center'>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
					className='align-items-center'
				>
					<Button
						className='btn btn-noti-primary py-2 px-5'
						type='submit'
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<Spinner
								size='sm'
								className='mr-2'
							/>
						) : (
							<SearchIcon
								width={24}
								height={24}
								className='stroke-neutro-1'
							/>
						)}
						<span className='text-button'>Buscar</span>
					</Button>
				</Col>
				<Col
					xs='12'
					style={{ maxWidth: 260, width: '100%' }}
				>
					<Button
						onClick={() => {
							formik.resetForm();
						}}
						color='link'
						className='pl-0'
					>
						<Row className='align-items-center m-0'>
							<CloseIcon
								width={24}
								height={24}
								className='stroke-info'
							/>
							<span className='text-link-regular'>Limpiar campos</span>
						</Row>
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

SearchForCaducidad.propTypes = {
	resetSearch: PropTypes.func.isRequired,
	layout: PropTypes.string.isRequired,
};

export default SearchForCaducidad;
