/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTable, useExpanded } from 'react-table';
import { Col, Row, Spinner, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as FileTextIcon } from 'assets/img/icons/notiexpress/file-text-icon.svg';
import { ReactComponent as UserCheckIcon } from 'assets/img/icons/notiexpress/user-check-icon.svg';
import print from '../../../context/print';
import { useHistory } from 'react-router-dom';
import user from '../../../context/user';
import { newsService } from '../../../services/newsService';

/**
 * Table for Novedades Page that shows cases & providences.
 * @param {Array<any>} columns
 * @param {Array<any>} data
 * @param {boolean} isLoading
 * @param {boolean} isError
 * @param {string} noDataMessage
 * @returns {JSX.Element}
 * @constructor
 */
const NewsTable = ({ columns, data, isLoading, isError, noDataMessage, mutate }) => {
	const {
		actions: { imprimirExpedientes },
	} = useContext(print);

	const {
		state: { currentUser },
	} = useContext(user);

	const { push } = useHistory();

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
		{
			columns,
			data,
		},
		useExpanded
		// We can useExpanded to track the expanded state
		// for sub components too!
	);

	function handlePrint(extract, filename) {
		const pdfSettings = {
			document: extract,
			title: 'Detalle de expediente',
			fileName: filename,
		};
		imprimirExpedientes(pdfSettings);
	}

	function downloadPdf(url, filename) {
		return new Promise((resolve) => {
			const link = document.createElement('a');
			link.href = url;
			link.download = filename;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			resolve();
		});
	}

	const SubComponent = ({ row }) => {
		const jurisdiction = row.original.Jurisdiction.name;
		const providences = row.original.Providences;
		const expId = row.original.id;

		const handleClick = (provFiles, provId) => {
			provFiles.length
				? provFiles.filter(({ contentDetail }) => !contentDetail).length
					? Promise.all(
							provFiles.map(({ url }) => {
								if (!url) push('/error');
								downloadPdf(url, url.split('/').pop().length > 30 ? 'providencia.pdf' : url.split('/').pop());
							})
					  )
					: handlePrint(provFiles[0].contentDetail, provFiles[0].name)
				: jurisdiction === 'Chaco'
				? currentUser.rol === 'Usuario'
					? push(`/admin/providencia/${provId}`)
					: push(`/collaborator/providencia/${provId}`)
				: null;
			newsService.maskReadNovelties(expId);
			mutate();
		};

		return (
			<>
				<Col>
					{providences?.map(({ id, content, isProvidence, ProvidenceFiles }, i) => (
						<Row
							key={id}
							className={`p-3 providence-row${ProvidenceFiles ? '' : 'disabled'}`}
							onClick={() => handleClick(ProvidenceFiles, id)}
						>
							<div className='mx-3'>
								<span className='text-body-regular-bold text-secondary-default'>Orden: </span>
								<span className='text-body-regular'>{i + 1}</span>
							</div>
							<div className='mx-3'>
								<span className='text-body-regular-bold text-secondary-default'>Tipo: </span>
								<span className='text-body-regular'>
									{isProvidence ? (
										<FileTextIcon
											width={18}
											height={18}
											className='stroke-primary-default'
										/>
									) : (
										<UserCheckIcon
											width={18}
											height={18}
											className='stroke-primary-default'
										/>
									)}
								</span>
							</div>
							<div className='mx-3'>
								<span className='text-truncate text-body-regular-bold text-secondary-default text'>Extracto: </span>
								<span className='text-body-regular'>{content ?? '-'}</span>
							</div>
						</Row>
					))}
				</Col>
			</>
		);
	};

	SubComponent.propTypes = {
		row: PropTypes.object.isRequired,
	};

	return (
		<>
			<Table
				className='align-items-center table table-flush'
				responsive
				size='lg'
				hover
				{...getTableProps()}
			>
				<thead className='thead-light'>
					{headerGroups.map((headerGroup, index) => (
						<tr
							key={index}
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map((column, index2) => (
								<th
									key={index2}
									{...column.getHeaderProps()}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.length === 0 && !isLoading && !isError ? (
						<tr
							className=''
							style={{ cursor: 'auto' }}
						>
							<td colSpan={visibleColumns.length}>
								<span className='text-body-large-bold text-secondary-default'>{noDataMessage}</span>
							</td>
						</tr>
					) : (
						rows.map((row, i) => {
							prepareRow(row);
							return (
								// Use a React.Fragment here so the table markup is still valid
								<React.Fragment key={i}>
									<tr
										className='text-center-tr'
										key={i}
										{...row.getRowProps()}
										onClick={() => row.toggleRowExpanded()}
									>
										{row.cells.map((cell, index) => {
											return (
												<td
													key={index}
													{...cell.getCellProps()}
													style={{ maxWidth: '412px' }}
												>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
									{/*
                If the row is in an expanded state, render a row with a
                column that fills the entire length of the table.
                */}
									{row.isExpanded ? (
										<tr key={-i}>
											<td
												colSpan={visibleColumns.length}
												style={{ padding: 0 }}
											>
												<SubComponent row={row} />
											</td>
										</tr>
									) : null}
								</React.Fragment>
							);
						})
					)}
					{isLoading ? (
						<tr>
							<td colSpan={visibleColumns.length}>
								<Row className='justify-content-center'>
									<Spinner color='default' />
								</Row>
							</td>
						</tr>
					) : null}
				</tbody>
			</Table>
		</>
	);
};

NewsTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	noDataMessage: PropTypes.string.isRequired,
	mutate: PropTypes.func.isRequired,
};

export default NewsTable;
