import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import { Row, Spinner, Table } from 'reactstrap';
import './DynamicTable.css';
import PropTypes from 'prop-types';

/**
 * Table that can show data and columns in a dynamic form using the data and columns passed by props
 * @Component
 * @param {Array} data
 * @param {Array} columns Array of objects that contains the information of the columns.
 * @param {String} linkTo If it included redirects to that url + propertyId when clicking in a row.
 * @param {String} propertyId Indicates the property of the data that is going to be used as an id. By default is 'id'.
 * @param {Number} maxColumnWidth
 * @param {Number} minColumnWidth
 * @param {Boolean} isLoading
 * @param {Boolean} isError
 * @param {String} noDataMessage
 * @returns {JSX.Element}
 */

function DynamicTable({
	data = [],
	columns,
	linkTo,
	propertyId = 'id',
	maxColumnWidth = '412px',
	minColumnWidth,
	isLoading,
	isError,
	noDataMessage = 'No se encontró ningún expediente.',
}) {
	const history = useHistory();
	const tableInstance = useTable({
		columns,
		data,
	});

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = tableInstance;

	const goTo = (id) => {
		if (!linkTo) return;
		history.push(linkTo + id);
	};

	return (
		<div className='position-relative'>
			<>
				{/* apply the table props */}
				<Table
					className='align-items-center table table-flush'
					size='lg'
					responsive
					hover
					{...getTableProps()}
				>
					<thead className='thead-light'>
						{
							// Loop over the header rows
							headerGroups.map((headerGroup, index) => (
								// Apply the header row props
								<tr
									key={index}
									{...headerGroup.getHeaderGroupProps()}
								>
									{
										// Loop over the headers in each row
										headerGroup.headers.map((column, index) => (
											// Apply the header cell props
											<th
												key={index}
												{...column.getHeaderProps()}
											>
												{
													// Render the header
													column.render('Header')
												}
											</th>
										))
									}
								</tr>
							))
						}
					</thead>

					{isLoading ? (
						<tbody>
							<tr>
								<td colSpan={visibleColumns.length}>
									<Row className='justify-content-center'>
										<Spinner color='default' />
									</Row>
								</td>
							</tr>
						</tbody>
					) : (
						/* Apply the table body props */
						<tbody {...getTableBodyProps()}>
							{rows.length === 0 && !isLoading && !isError ? (
								<tr
									className=''
									style={{ cursor: 'auto' }}
								>
									<td colSpan={visibleColumns.length}>
										<span className='text-body-large-bold text-secondary-default'>{noDataMessage}</span>
									</td>
								</tr>
							) : (
								rows.map((row, index) => {
									// Prepare the row for display
									prepareRow(row);
									return (
										// Apply the row props
										<tr
											className={'text-center-tr'}
											onClick={() => goTo(row.original.Expediente ? row.original.Expediente?.id : row.original[propertyId])}
											key={index}
											{...row.getRowProps()}
										>
											{
												// Loop over the rows cells
												row.cells.map((cell, index) => {
													// Apply the cell props
													return (
														<td
															className=''
															style={{
																maxWidth: maxColumnWidth,
																minWidth: minColumnWidth,
																width: cell.column.width,
															}}
															key={index}
															{...cell.getCellProps()}
														>
															{
																// Render the cell contents
																cell.render('Cell')
															}
														</td>
													);
												})
											}
										</tr>
									);
								})
							)}
						</tbody>
					)}
				</Table>
			</>
		</div>
	);
}

export default DynamicTable;

DynamicTable.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	linkTo: PropTypes.string,
	propertyId: PropTypes.string,
	maxColumnWidth: PropTypes.string,
	minColumnWidth: PropTypes.string,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	noDataMessage: PropTypes.string,
};
