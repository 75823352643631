import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Modal, ModalHeader } from 'reactstrap';

const CustomModal = ({ title, children, isOpen, toggle, className, isMobile = false }) => {
	return (
		<Modal
			className={`modal-dialog-centered ${className}`}
			isOpen={isOpen}
			toggle={toggle}
			backdrop='static'
			autoFocus={false}
		>
			<CardHeader className='bg-transparent p-2'>
				<ModalHeader
					className={isMobile ? 'p-0 py-2' : ''}
					toggle={toggle}
				>
					<span className='text-heading-2'>{title}</span>
				</ModalHeader>
			</CardHeader>
			{children}
		</Modal>
	);
};

CustomModal.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default CustomModal;
