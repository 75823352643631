import moment from 'moment';
import React from 'react';
import { ReactComponent as FileTextIcon } from 'assets/img/icons/notiexpress/file-text-icon.svg';
import { ReactComponent as UserCheckIcon } from 'assets/img/icons/notiexpress/user-check-icon.svg';
import CopyToClipboardElement from '../../../components/Commons/CopyToClipboardElement';

export const historicTableColumns = [
	{
		Header: 'Fecha',
		accessor: ({ actuationDate }) => <span className='text-body-small pr-3'>{moment.utc(actuationDate).format('DD/MM/YYYY HH:mm')}</span>,
		width: 50,
	},
	{
		Header: 'Foja',
		accessor: ({ foja }) => <span className='text-body-small'>{foja ?? '-'}</span>,
		width: 50,
	},
	{
		Header: 'Tipo',
		accessor: ({ isProvidence }) => (
			<span className='text-body-small'>
				{isProvidence ? (
					<FileTextIcon
						width={20}
						height={20}
						className='stroke-primary-default'
					/>
				) : (
					<UserCheckIcon
						width={20}
						height={20}
						className='stroke-primary-default'
					/>
				)}
			</span>
		),
		width: 50,
	},
	{
		Header: 'Extracto',
		accessor: ({ content, id }) => (
			<div className='d-flex justify-content-between'>
				<span className='text-body-small text-truncate'>{content}</span>
			</div>
		),
		width: '100%',
	},
];
