import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Container, Col, Card, Row, Alert, Spinner } from 'reactstrap';
import HomeHeader from '../../components/Headers/HomeHeader';
import ProvidenceDetails from './components/ProvidenceDetails';
import { casesService } from '../../services/casesService';
import 'moment/locale/es';
import ProvidenceCard from './components/ProvidenceCard';
import { LAYOUTS } from '../../helpers/consts';

const Providence = (props) => {
	let { id } = useParams();

	const {
		data: providence,
		isLoading,
		isError,
		mutate,
	} = casesService.useProvidenceDetail(id);

	return (
		<>
			<HomeHeader
				headerText='Detalle de Despacho'
				canGoBack
			/>
			<Container className='mb-3' fluid={true}>
				<Row className='mb-3'>
					<Col xl='12'>
						{isLoading && <Spinner color='default'></Spinner>}
						{providence && (
							<ProvidenceCard
								providence={providence}
								mutate={mutate}
							/>
						)}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xl='12'>
						{isLoading && <Spinner color='default'></Spinner>}
						{isError && (
							<Card className='shadow-card mb-3'>
								<Alert color='warning'>La providencia deseada no existe.</Alert>
							</Card>
						)}
						{providence && (
							<ProvidenceDetails
								providence={providence}
								layout={LAYOUTS.ADMIN}
								{...props}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Providence;

Providence.propTypes = {
	mutate: PropTypes.func,
	history: PropTypes.object,
};
