import React from 'react';
import { create } from 'react-modal-promise';
import CustomModal from '../../../../../components/Modals/CustomModal/CustomModal';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { officesService } from '../../../../../services/officesService';
import PropTypes from 'prop-types';

const DeleteOfficeModal = ({ office, mutate, isOpen, onResolve, onReject }) => {
	const handleDeleteOffice = async () => {
		try {
			await officesService.deleteOffice({ id: office?.userOfficeId });
      onResolve();
			mutate();
		} catch (e) {
			alert(e?.message === 'You can not delete the principal office' ? 'No se puede eliminar la oficina principal' : 'No se pudo eliminar la oficina');
		}
	};
  
	return (
		<CustomModal
			title={'Eliminar Oficina'}
			isOpen={isOpen}
			toggle={() => onReject()}
			className='w-50'
		>
			<ModalBody>
				<p className='text-body-large text-neutro-2'>
					¿Estás seguro que querés eliminar esta oficina?
				</p>
			</ModalBody>
			<ModalFooter className='justify-content-start'>
				<Button
					className='btn btn-outline-noti-secondary py-1 px-5'
					onClick={() => onReject()}
				>
					Cancelar
				</Button>
				<Button
					className='btn btn-noti-primary ml-3 py-1 px-5'
					onClick={handleDeleteOffice}
				>
					<span className='text-button'>Aceptar</span>
				</Button>
			</ModalFooter>
		</CustomModal>
	);
};

DeleteOfficeModal.propTypes = {
  office: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onResolve: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export const deleteOfficeModal = create(DeleteOfficeModal);
