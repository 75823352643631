import React from 'react';
import { ReactComponent as SmileIcon } from '../../assets/img/icons/notiexpress/smile.svg';
import { ReactComponent as ZapIcon } from '../../assets/img/icons/notiexpress/zap.svg';
import { ReactComponent as FolderIcon } from '../../assets/img/icons/notiexpress/folder-feature.svg';
import { ReactComponent as UserIcon } from '../../assets/img/icons/notiexpress/users.svg';
import { ReactComponent as ShieldIcon } from '../../assets/img/icons/notiexpress/shield.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/icons/notiexpress/clock.svg';
import { ReactComponent as DUXHero } from './components/InforVerticalTabs/svg/design.svg';
import { ReactComponent as NEWSHero } from './components/InforVerticalTabs/svg/novelty.svg';
import { ReactComponent as EXPHero } from './components/InforVerticalTabs/svg/expedients.svg';
import { ReactComponent as CADHero } from './components/InforVerticalTabs/svg/caducity.svg';
import { ReactComponent as RESHero } from './components/InforVerticalTabs/svg/collaborators.svg';
import { ReactComponent as SECHero } from './components/InforVerticalTabs/svg/security.svg';
import { ReactComponent as DUXImage } from './components/InforVerticalTabs/svg/design-f.svg';
import { ReactComponent as NEWSImage } from './components/InforVerticalTabs/svg/novelty-f.svg';
import { ReactComponent as EXPImage } from './components/InforVerticalTabs/svg/exp-f.svg';
import { ReactComponent as CADImage } from './components/InforVerticalTabs/svg/cad-f.svg';
import { ReactComponent as RESImage } from './components/InforVerticalTabs/svg/col-f.svg';
import { ReactComponent as SECImage } from './components/InforVerticalTabs/svg/sec-f.svg';

let resumeData = [
	{
		expData: {
			icon: SmileIcon,
			position: 'Diseño UX',
			period: 'Enfocado en tu comodidad y agilidad',
			details: (
				<div className='mt-3'>
					<p className='text-heading-3 m-0 mb-3'>
						Pensamos una interfaz amigable e intuitiva que te permite navegar a
						través de tus expedientes sin esfuerzo.
					</p>
					<p className='text-heading-3 m-0'>
						Está pensada para ahorrarte tiempo, por lo que encontrarás todas las
						funciones a pocos clics.
					</p>
				</div>
			),
			footer: <DUXImage height={'13.98vh'} />,
			hero: <DUXHero height={'52vh'} />,
		},
	},
	{
		expData: {
			icon: ZapIcon,
			position: 'Novedades del día',
			period: 'Te mantenemos informado',
			details: (
				<div className='mt-2'>
					<p className='text-heading-3 m-0 mb-2'>
						Queremos que tengas toda la información disponible para que tomes
						siempre la mejor decisión.
					</p>
					<p className='text-heading-3 m-0'>
						También te ofrecemos la posibilidad de recibir notificaciones sobre
						cualquier actualización de tus expedientes.
					</p>
				</div>
			),
			footer: <NEWSImage height={'15vh'} />,
			hero: <NEWSHero height={'52vh'} />,
		},
	},
	{
		expData: {
			icon: FolderIcon,
			position: 'Expedientes',
			period: 'Organizarte es muy fácil',
			details: (
				<div className='mt-2'>
					<p className='text-heading-3 m-0 mb-2'>
						Te damos la capacidad de gestionar tus expedientes judiciales desde
						cualquier lugar y en cualquier momento junto con las mejores
						funcionalidades para optimizar tu trabajo.
					</p>
					<p className='text-heading-3 m-0'>
						Buscá, agregá, consultá o configurá tus expedientes de la manera
						más eficiente.
					</p>
				</div>
			),
			footer: <EXPImage height={'15vh'} />,
			hero: <EXPHero height={'52vh'} />,
		},
	},
	{
		expData: {
			icon: ClockIcon,
			position: 'Caducidad',
			period: 'Concentrate en lo importante',
			details: (
				<div className='mt-3 mb-5'>
					<p className='text-heading-3 m-0 mb-3'>
						Nosotros nos encargamos de controlar los plazos de tus expedientes.
					</p>
					<p className='text-heading-3 m-0'>
						Te ofrecemos las mejores configuraciones para que puedas trabajar
						más tranquilo.
					</p>
				</div>
			),
			footer: <CADImage height={'13.21vh'} />,
			hero: <CADHero height={'52vh'} />,
		},
	},
	{
		expData: {
			icon: UserIcon,
			position: 'Colaboradores',
			period: 'Funcionalidades a tu medida',
			details: (
				<div className='mt-3 mb-5'>
					<p className='text-heading-3 m-0 mb-3'>
						Te brindamos herramientas de administración poderosas y
						personalizables.
					</p>
					<p className='text-heading-3 m-0'>
						Podrás organizar tus expedientes, asignar tareas y plazos, realizar
						seguimientos detallados y compartir información relevante con tu
						equipo de trabajo de manera instantánea.
					</p>
				</div>
			),
			footer: <RESImage height={'5.5vh'} />,
			hero: <RESHero height={'52vh'} />,
		},
	},
	{
		expData: {
			icon: ShieldIcon,
			position: 'Seguridad',
			period: 'Trabajá siempre protegido',
			details: (
				<div className='mt-3 mb-5'>
					<p className='text-heading-3 m-0 mb-3'>
						Implementamos medidas de seguridad internacionales para que tanto
						tus datos personales cómo la información de tus expedientes estén
						seguros.{' '}
					</p>
					<p className='text-heading-3 m-0'>
						Nuestra plataforma cuenta con la certificación de seguridad
						necesaria, brindada por la empresa Resguarda.
					</p>
				</div>
			),
			footer: <SECImage height={'5.5vh'} />,
			hero: <SECHero height={'52vh'} />,
		},
	},
];

export default resumeData;
