import React from 'react';
import PropTypes from 'prop-types';

const CreditCardIcon = ({
	className,
	size,
	fill = 'transparent',
	color = '',
}) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M1 10H23'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

CreditCardIcon.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	className: PropTypes.string,
	fill: PropTypes.string,
};

export default CreditCardIcon;
