import React from 'react';
import propTypes from 'prop-types';

const FeatherIcon = ({ size, fill = 'transparent' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21.9983 7.99504C21.9983 9.58722 21.3658 11.1142 20.24 12.24L13.5 19H5V10.5L11.75 3.75004C12.8758 2.62419 14.4028 1.9917 15.995 1.9917C17.5872 1.9917 19.1142 2.62419 20.24 3.75004C21.3658 4.87588 21.9983 6.40285 21.9983 7.99504Z'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16 8L2 22'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M17.5 15H9'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

FeatherIcon.propTypes = {
  size: propTypes.number.isRequired,
  fill: propTypes.string,
};

export default FeatherIcon;
