import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import { CardBody, Col, Input, Row, Container, Card } from 'reactstrap';
import TableOptionsButtons from '../../../components/Commons/TableOptionsButtons/TableOptionsButtons';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/notiexpress/download-icon.svg';

const ProvidenceDetails = ({ providence, layout }) => {
	return (
		<>
			{providence?.ProvidenceFiles.length ? (
				<>
					<div className='mt-2 mb-3'>
						<TableOptionsButtons
							canAdd={false}
							canSearch={false}
							canPrint={!providence?.ProvidenceFiles?.some((fileData) => fileData.url)}
							data={[providence]}
							tableType='providenceDetails'
							layout={layout}
						/>
					</div>
					<Card className='bg-white shadow-card mb-3'>
						{providence?.ProvidenceFiles?.map((fileData, index) =>
							fileData.url ? (
								<CardBody key={index}>
									<Col
										className='py-3'
										key={index}
									>
										<Row className='align-items-center'>
											<DownloadIcon
												width={24}
												height={24}
												className='stroke-primary-default'
											/>
											<span className='text-body-large ml-2'>Descargar archivo</span>
											<a
												className='ml-2'
												key={fileData}
												href={fileData.url}
												target='_blank'
												rel='noopener noreferrer'
											>
												<span className='text-link-large'>
													{
														//fileData.url.split('/').pop()
														fileData.url.split('/').pop().length > 30 ? 'providencia.pdf' : fileData.url.split('/').pop()
													}
												</span>
											</a>
										</Row>
									</Col>
								</CardBody>
							) : (
								<CardBody key={index}>
									<Row>
										<Col>
											<Input
												className='bg-white border-0'
												type='textarea'
												name='text'
												id='exampleText'
												rows='30'
												defaultValue={fileData.contentDetail}
												readOnly
											/>
										</Col>
									</Row>
								</CardBody>
							)
						)}
					</Card>
				</>
			) : null}
		</>
	);
};

export default ProvidenceDetails;

ProvidenceDetails.propTypes = {
	providence: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
	layout: PropTypes.string,
};
