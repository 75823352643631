import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

// reactstrap components
import { Container, Row, Col, Card } from 'reactstrap';
import useBreakpoints from '../../hooks/useBreakpoints';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ReactComponent as NoiexpressTitle } from '../../assets/img/icons/logo/notiexpress-landing.svg';
import { ReactComponent as RayoIcon } from '../../assets/img/icons/notiexpress/rayo.svg';
import { ReactComponent as RayoWhiteIcon } from '../../assets/img/icons/notiexpress/rayo-white.svg';
import { ReactComponent as VerifyICon } from '../../assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as Recommendations } from '../../assets/img/icons/notiexpress/recommendations.svg';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import VerticalTab from './components/InforVerticalTabs/VerticalTab';
import features from './features-info';
import { ReactComponent as LandingImage } from '../../assets/img/icons/notiexpress/landing.svg';

const Landing = () => {
	const { isDesktop } = useBreakpoints();

	return (
		<>
			{!isDesktop && <Redirect to='/auth/login' />}
			<div className='main-content'>
				<div className='landing-bg-image' />
				<div className='landing-bg-filter' />
				<section className='landing-container first-content'>
					<Navbar />
					<NoiexpressTitle className='title-image' />
					<div className='d-flex align-items-center flex-column'>
						<LandingImage width={'63vw'} />
						<Row className='align-items-center'>
							<RayoWhiteIcon />
							{/* <span className='slogan-text-dark text-center px-2'>CHACO</span>
							<span className='slogan-text-dark text-center px-2'>|</span> */}
							<span className='slogan-text-dark text-center px-2'>CAPITAL FEDERAL</span>
							<span className='slogan-text-dark text-center px-2'>|</span>
							<span className='slogan-text-dark px-2'>JUSTICIA FEDERAL</span>
							<RayoWhiteIcon />
						</Row>
					</div>
				</section>
				<section className='landing-container second-content'>
					<Row className='section mb-4 d-flex justify-content-center align-items-center'>
						<span className='text-heading-landing-3 text-center'>Creamos soluciones</span>
					</Row>
					<Row className='w-100 my-5 d-flex justify-content-center align-items-center'>
						<Col className='d-flex justify-content-center align-items-center'>
							<p className='text-center message'>
								Nuestra plataforma está especialmente diseñada para gestionar tu Estudio Jurídico de la manera más práctica.{' '}
								<span>Te brindamos todas las soluciones y herramientas en un solo lugar</span>
							</p>
						</Col>
					</Row>
					<Card className='landing-card-features'>
						<VerticalTab data={features} />
					</Card>
				</section>
				<section className='landing-container mt-6'>
					<Row className='section mb-4 d-flex justify-content-center align-items-center'>
						<span className='text-heading-landing-3 text-center'>Formá parte de Notiexpress</span>
					</Row>
					<Container className='container-content p-0'>
						<Row className='w-100 my-5 d-flex justify-content-center align-items-center'>
							<Col className='d-flex justify-content-center align-items-center'>
								<p className='text-center message'>
									Simplificarás tu trabajo, ahorrarás tiempo y recursos, y podrás dedicarte a lo que realmente importa:{' '}
									<span>brindar un servicio legal excepcional a tus clientes</span>
								</p>
							</Col>
						</Row>
						<Card className='w-100 section-card mb-3 justify-content-center'>
							<Row className='d-flex justify-content-center align-items-center h-100'>
								<span
									className='text-heading-3-blue-sm mr-5'
									style={{
										fontSize: '2.8vh',
									}}
								>
									DISPONIBLE PARA
								</span>
								<RayoIcon />
								{/* <span className='text-heading-3 text-center mx-3'>CHACO</span>
								<span className='text-heading-2 text-center'>|</span> */}
								<span className='text-heading-3 text-center mx-3'>CAPITAL FEDERAL</span>
								<span className='text-heading-2 text-center'>|</span>
								<span className='text-heading-3 mx-3'>JUSTICIA FEDERAL</span>
								<RayoIcon />
							</Row>
						</Card>
						<Card className='section-card-offer mb-3'>
							<Row className='d-flex justify-content-center align-items-center h-100 w-100'>
								<Col className='d-flex ml-4 justify-content-center align-items-center h-100'>
									<Row>
										<Col>
											<Row>
												<Card className='landing-card-text justify-content-center'>
													<span className='text-heading-landing-1 text-center'>30 DÍAS</span>
												</Card>
											</Row>
											<Row className='justify-content-center'>
												<span
													className='text-center text-heading-3-blue-sm'
													style={{
														fontSize: '2.8vh',
													}}
												>
													PRUEBA GRATIS
												</span>
											</Row>
										</Col>
									</Row>
								</Col>
								<Col
									className='d-flex flex-column h-100 justify-content-center align-items-center'
									sm={6}
								>
									<div>
										<Row>
											<VerifyICon
												className='stroke-success'
												width='5vh'
												height='5vh'
											/>
											<span className='text-heading-3-secondary mx-2'>100% ONLINE</span>
										</Row>
										<Row>
											<VerifyICon
												className='stroke-success'
												width='5vh'
												height='5vh'
											/>
											<span className='text-heading-3-secondary mx-2'>Todas las funciones disponibles</span>
										</Row>
										<Row>
											<VerifyICon
												className='stroke-success'
												width='5vh'
												height='5vh'
											/>
											<span className='text-heading-3-secondary mx-2'>Adaptado a todos tus dispositivos</span>
										</Row>
									</div>
								</Col>
								<Col className='d-flex justify-content-center align-items-center h-100'>
									<Recommendations />
								</Col>
							</Row>
						</Card>
						<Row className='w-100 d-flex justify-content-center align-items-center my-3'>
							<Col className='d-flex justify-content-center align-items-center'>
								<Button
									tag={Link}
									to='/auth/register'
									className='suscribe-button'
								>
									<span
										className='text-heading-3-blue-sm text-center'
										style={{
											fontSize: '2.8vh',
										}}
									>
										¡Suscribite!
									</span>
								</Button>
							</Col>
						</Row>
						<Row className='w-100 mb-4 d-flex justify-content-center align-items-center'>
							<Col className='d-flex justify-content-center align-items-center'>
								<span className='slogan-text'>La justicia nunca ha sido tan accesible</span>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Landing;

Landing.propTypes = {
	location: PropTypes.object,
};
