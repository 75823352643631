import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row, CardFooter } from 'reactstrap';
import { casesService } from '../../services/casesService';
import HomeHeader from '../../components/Headers/HomeHeader';
import { useLocation } from 'react-router-dom';
import TableOptionsButtons from '../../components/Commons/TableOptionsButtons/TableOptionsButtons';
import DynamicTable from '../../components/Commons/DynamicTable';
import queryString from 'query-string';
import user from '../../context/user';
import { useHistory, useParams } from 'react-router-dom';
import { CHART_BAR_COLORS, LAYOUTS, ROLES } from '../../helpers/consts';
import { createNewObjectWithoutProperties, formatToChartData, parseObjectToQS } from '../../helpers/utils';
import Pagination from '../../components/Commons/Pagination';
import useCasesListTableColumns from './hooks/useCasesListTableColumns';
import admin from '../../context/admin';
import { ReactComponent as UsersIcon } from '../../assets/img/icons/notiexpress/users.svg';
import { ReactComponent as ConnectionsIcon } from '../../assets/img/icons/notiexpress/sync-icon.svg';
import { ReactComponent as ZapIcon } from '../../assets/img/icons/notiexpress/zap-icon.svg';
import CardResumeBar from '../../components/Charts/CardResumeBar';
import CardResume from '../../components/Charts/CardResume';
import CardResumeFilter from '../../components/Charts/CardResumeFilter';

const Expedientes = () => {
	const {
		state: { currentUser },
	} = useContext(user);
	const history = useHistory();
	const rowsPerPage = 10;
	const location = useLocation();
	const initialParams = useParams();
	const [params, setParams] = useState(initialParams);

	const {
		data: casesList,
		isError: isErrorCasesList,
		isLoading: isLoadingCasesList,
	} = casesService.usePaginatedCases({ role: currentUser.rol, params: params });

	const { data: casesCount, isLoading: isLoadingCasesCount } = casesService.useCaseCount();

	const {
		state: { activeJurisdictions, activeServices },
	} = useContext(admin);

	const cantAdd =
		activeJurisdictions?.find((jurisdiction) => jurisdiction.name === 'Chaco') &&
		activeServices?.find(({ service }) => service.idJuris[0].id === 'ckao0m7jb0002qr2d01dfqdsg' || service.idJuris[0] === 'All')
			? true
			: false;

	const { currentColumns } = useCasesListTableColumns();

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		setParams({
			take: rowsPerPage,
			page: 1,
			...params,
			...currentParams,
			...(currentUser.rol === ROLES.COLLABORATOR ? { collaborator: currentUser.id } : {}),
		});
	}, [location.search]);

	useEffect(() => {
		history.push(`/admin/expedientes?${parseObjectToQS(params)}`);
	}, [params]);

	function handleSetPage(page) {
		setParams({ ...params, page: page });
	}

	const handleResetSearch = (resetParams) => {
		const newParams = createNewObjectWithoutProperties({
			keys: resetParams,
			object: params,
		});
		setParams(newParams ?? {});
	};

	const jurisdictionsBarData = formatToChartData({ data: casesCount?.totalByJurisdiction, colors: CHART_BAR_COLORS });

	const collaboratorsBarData = formatToChartData({ data: casesCount?.totalByCollaborator, colors: CHART_BAR_COLORS });

	return (
		<>
			<HomeHeader headerText='Administración de Expedientes' />
			<Container
				className='bg-gradient-white mb-3'
				fluid={true}
        style={{
          minHeight: '120px',
        }}
			>
				<Row
					className='px-3'
					style={{
						gap: '1rem',
					}}
				>
					<Col
						style={{
							maxWidth: '250px',
              minWidth: '220px',
						}}
						className='px-0'
					>
						<CardResume
							isLoading={isLoadingCasesCount}
							layout={LAYOUTS.ADMIN}
							totalExp={casesCount?.total}
						/>
					</Col>
					<Col className='px-0'>
						<CardResumeFilter
							cardTitle='Total con'
							subtitle='Novedades'
							totalExp={casesCount?.todayNewslettersExp ?? 0}
							date={new Date().toISOString().split('T')[0]}
							isLoading={isLoadingCasesCount}
              handleFilter={() => setParams({ ...params, fechaActuacion: new Date().toISOString().split('T')[0] })}
						>
							<ZapIcon
								width={20}
								height={20}
								className='stroke-primary-default'
							/>
						</CardResumeFilter>
					</Col>
					<Col className='px-0'>
						<CardResumeBar
							data={jurisdictionsBarData}
							cardTitle='Total por'
							subtitle='Jurisdicción'
							isLoading={isLoadingCasesCount}
						>
							<ConnectionsIcon
								width={20}
								height={20}
								className='fill-primary-default'
							/>
						</CardResumeBar>
					</Col>
					<Col className='px-0'>
						<CardResumeBar
							data={collaboratorsBarData}
							subtitle='Responsable'
							cardTitle='Total por'
							isLoading={isLoadingCasesCount}
						>
							<UsersIcon
								width={20}
								height={20}
								className='stroke-primary-default'
							/>
						</CardResumeBar>
					</Col>
				</Row>
			</Container>
			<Container
				className='bg-white'
				fluid={true}
			>
				<Row
					xs='3'
					className='mb-3'
				>
					<Col>
						<Card className='shadow-card mb-3'>
							<CardBody className='pt-0 '>
								<Row className='m-0 justify-content-between py-5'>
									<Pagination
										page={casesList?.currentPage ?? 0}
										handleSetPage={handleSetPage}
										count={casesList?.totalCount ?? 0}
										pageCount={casesList?.totalPage ?? 0}
										rowsPerPage={rowsPerPage ?? 15}
										disabled={isErrorCasesList || isLoadingCasesList}
									/>
									<Col
										xs={12}
										className='p-0'
									>
										<TableOptionsButtons
											tableType='cases'
											data={casesList?.paginateData ?? []}
											resetSearch={handleResetSearch}
											canAdd={cantAdd}
											canSearch
											layout={LAYOUTS.ADMIN}
										/>
									</Col>
								</Row>
								<DynamicTable
									data={casesList?.paginateData ?? []}
									columns={currentColumns}
									linkTo='/admin/expedientes/'
									propertyId='id'
									isLoading={isLoadingCasesList}
									isError={isErrorCasesList}
								/>
							</CardBody>
							<CardFooter>
								<Row className='m-0 justify-content-end'>
									<span className='text-link-bold'>Expedientes ({casesList?.totalCount ?? '-'})</span>
								</Row>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Expedientes;

Expedientes.propTypes = {
	location: PropTypes.object,
	tableTitle: PropTypes.string,
};
