import React from 'react';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { ReactComponent as UserCheckIcon } from '../../../../../assets/img/icons/notiexpress/user-check-icon.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/img/icons/notiexpress/user-icon.svg';
import { ReactComponent as ZapIcon } from '../../../../../assets/img/icons/notiexpress/zap-icon.svg';
import { Link } from 'react-router-dom';

const SuccessPaymentPanel = () => {
	return (
		<>
			<Card className='border-0'>
				<CardHeader>
					<h3 className='text-heading-1 text-center text-primary-default py-2'>
						¡Suscripción Registrada con Éxito!
					</h3>
					<p className='text-center py-2'>
						<UserCheckIcon
							width={48}
							height={48}
							className='stroke-success'
						/>
					</p>
				</CardHeader>
				<CardBody>
					<p className='text-heading-2 text-center py-2'>
						Podés acceder a tus comprobantes dentro de tu perfil en la sección
						“Mis comprobantes”
					</p>
					<Row className='justify-content-around'>
						<Button
							className='btn btn-outline-noti-secondary py-1 px-5'
							tag={Link}
							to='/admin/user-profile'
						>
							<Row className='align-items-center m-0'>
								<UserIcon
									width={24}
									height={24}
									className='stroke-secondary-default'
								/>
								<span className=''>Mi perfil</span>
							</Row>
						</Button>
						<Button
							className='btn btn-outline-noti-secondary py-1 px-5'
							tag={Link}
							to='/admin/novedades'
						>
							<Row className='align-items-center m-0'>
								<ZapIcon
									width={24}
									height={24}
									className='stroke-secondary-default'
								/>
								<span className=''>Novedades</span>
							</Row>
						</Button>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};

SuccessPaymentPanel.propTypes = {};

export default SuccessPaymentPanel;
