import React from 'react';
import { Link } from 'react-router-dom';
import {
	UncontrolledCollapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
class LandingNavbar extends React.Component {
	render() {
		return (
			<>
				<Navbar
					className='navbar-landing'
					expand='md'
				>
					<Container
						className='px-4'
						fluid={true}
					>
						<button
							className='navbar-toggler navbar-brand'
							id='navbar-collapse-main'
						></button>
						<UncontrolledCollapse
							navbar
							toggler='#navbar-collapse-main'
						>
							<Nav
								className='ml-auto'
								navbar
							>
								<NavItem>
									<NavLink
										className='nav-link-icon'
										to='/auth/login'
										tag={Link}
									>
										<Button className='nav-landing-button text-button'>
											Iniciar sesión
										</Button>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className='nav-link-icon'
										to='/auth/register'
										tag={Link}
									>
										<Button className='nav-landing-button text-button'>Crear cuenta</Button>
									</NavLink>
								</NavItem>
							</Nav>
						</UncontrolledCollapse>
					</Container>
				</Navbar>
			</>
		);
	}
}

export default LandingNavbar;
