import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	ModalBody,
	ModalFooter,
	Row,
} from 'reactstrap';
import { collaboratorService } from 'services/collaboratorService';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/Modals/CustomModal/CustomModal';
import { ReactComponent as EyeIcon } from '../../../assets/img/icons/notiexpress/eye-icon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/img/icons/notiexpress/eye-off-icon.svg';
import password from '../../../components/Forms/Password';

function ModifyCollaboratorModal({
	collaborator,
	updateModalIsOpen,
	setUpdateModalIsOpen,
	mutate,
	setCollaboratorErrorMessage,
	setCollaboratorSuccessMessage,
}) {
	const [showPassword, setShowPassword] = useState(false);
	const formik = useFormik({
		initialValues: {
			name: collaborator?.name,
			lastName: collaborator?.lastName,
			matricula: collaborator?.licensePlate ?? '',
			email: collaborator?.email,
			password: '',
			changePassword: false,
			id: collaborator?.id,
		},
		enableReinitialize: true,
		validate: () => {}, // Add validator
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true);
			collaboratorService
				.update({
					id: values.id,
					name: values.name,
					lastName: values.lastName,
					...(values.matricula && { licensePlate: values.matricula }),
					email: values.email,
					...(password && { password: values.password }),
				})
				.then(() => {
					setSubmitting(false);
					mutate();
					setCollaboratorSuccessMessage('¡Datos actualizados!');
					setUpdateModalIsOpen(false);
					resetForm();
				})
				.catch(() => {
					setCollaboratorErrorMessage('No se pudo modificar el colaborador');
					setSubmitting(false);
				});
		},
	});

	return (
		<CustomModal
			title='Modificar colaborador'
			isOpen={updateModalIsOpen}
			toggle={() => setUpdateModalIsOpen(false)}
			className='w-50'
		>
			<Form
				role='form'
				onSubmit={formik.handleSubmit}
			>
				<ModalBody>
					<Row className='align-items-center  mt-2'>
						<Col md='4'>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='collaborator-name-input'
								>
									Nombre *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='collaborator-name-input'
										name='name'
										onChange={formik.handleChange}
										size='sm'
										value={formik.values.name}
									/>
								</InputGroup>
								{formik.errors.name && formik.touched.name ? (
									<span className='text-link-regular text-error'>{formik.values.name}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col md='4'>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='collaborator-lastname-input'
								>
									Apellido *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='collaborator-lastname-input'
										name='lastName'
										onChange={formik.handleChange}
										placeholder='Ingresá el apellido'
										size='sm'
										value={formik.values.lastName}
									/>
								</InputGroup>
								{formik.errors.lastName && formik.touched.lastName ? (
									<span className='text-link-regular text-error'>{formik.values.lastName}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col md='4'>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='collaborator-lastname-input'
								>
									Matrícula
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='collaborator-lastname-input'
										name='matricula'
										onChange={formik.handleChange}
										size='sm'
										value={formik.values.matricula}
									/>
								</InputGroup>
								{formik.errors.matricula && formik.touched.matricula ? (
									<span className='text-link-regular text-error'>{formik.values.matricula}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col md='6'>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='collaborator-email-input'
								>
									Email *
								</Label>
								<InputGroup className='input-group-alternative'>
									<Input
										className='form-control'
										type='text'
										id='collaborator-email-input'
										name='email'
										onChange={formik.handleChange}
										value={formik.values.email}
										size='sm'
									/>
								</InputGroup>
								{formik.errors.email && formik.touched.email ? (
									<span className='text-link-regular text-error'>{formik.values.email}</span>
								) : null}
							</FormGroup>
						</Col>
						<Col md='6'>
							<FormGroup>
								<Label
									className='text-body-large-bold text-secondary-default'
									htmlFor='collaborator-password-input'
								>
									Contraseña *
								</Label>
								<InputGroup className='input-group-alternative' size='sm'>
									<Input
										className='form-control'
										type={showPassword ? 'text' : 'password'}
										id='collaborator-password-input'
										name='password'
										onChange={formik.handleChange}
										value={formik.values.password}
									/>
									<InputGroupAddon addonType='append'>
										<InputGroupText>
											{showPassword ? (
												<EyeOffIcon
													className='stroke-primary-default'
													onClick={() => setShowPassword(!showPassword)}
												/>
											) : (
												<EyeIcon
													className='stroke-primary-default'
													onClick={() => setShowPassword(!showPassword)}
												/>
											)}
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
								{formik.errors.password && formik.touched.password ? (
									<span className='text-link-regular text-error'>{formik.values.password}</span>
								) : null}
							</FormGroup>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter className='justify-content-start'>
					<Button
						data-dismiss='modal'
						type='button'
						onClick={() => setUpdateModalIsOpen(false)}
						className='btn btn-outline-noti-secondary py-1 px-5'
					>
						Cancelar
					</Button>
					<Button
						className='btn btn-noti-primary py-1 px-5 ml-3'
						type='submit'
						disabled={formik.isSubmitting || !formik.dirty}
					>
						<span
							className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
							role='status'
							aria-hidden='true'
						></span>
						<span className='text-button'>Actualizar</span>
					</Button>
				</ModalFooter>
			</Form>
		</CustomModal>
	);
}

export default ModifyCollaboratorModal;

ModifyCollaboratorModal.propTypes = {
	collaborator: PropTypes.object,
	updateModalIsOpen: PropTypes.bool,
	setUpdateModalIsOpen: PropTypes.func,
	mutate: PropTypes.func,
	setCollaboratorErrorMessage: PropTypes.func,
	setCollaboratorSuccessMessage: PropTypes.func,
};
