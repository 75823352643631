import useBreakpoint from '../../../hooks/useBreakpoint';
import { casesExpirationTableColumns } from '../../../variables/casesListTables';

export default function useExpirationTableColumns() {
	const { breakpoint } = useBreakpoint();

	switch (breakpoint) {
		case '2xs':
		case 'xs':
		case 'sm': // return {currentColumns: casesExpirationTableColumnsMobile}
		case 'md':
		case 'lg': // return {currentColumns: casesExpirationTableColumnsTablet}
		case 'xl': // return {currentColumns: casesExpirationTableColumnsDesktop}
		case '2xl': // return {currentColumns: casesExpirationTableColumns}
		default:
			return { currentColumns: casesExpirationTableColumns };
	}
}
