import React from 'react';
import PropTypes from 'prop-types';

const ChevronDown = ({ className, size, fill = 'transparent', color = '' }) => {
	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6 9L12 15L18 9'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

ChevronDown.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	className: PropTypes.string,
	fill: PropTypes.string,
};

export default ChevronDown;
