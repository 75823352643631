import React, { useState, useEffect } from 'react';
import DynamicTable from '../../../components/Commons/DynamicTable';
import useBreakpoints from 'hooks/useBreakpoints';
import { Button, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { casesService } from '../../../services/casesService';
import { historicTableColumns } from '../assets/historicTableColumns';
import PropTypes from 'prop-types';
import CardAccordion from '../../../components/CardAccordion/CardAccordion';
import TableOptionsButtons from '../../../components/Commons/TableOptionsButtons/TableOptionsButtons';
import { collaboratorRoleService } from '../../../services/collaboratorRoleService';
import { LAYOUTS } from '../../../helpers/consts';
import { createNewObjectWithoutProperties } from '../../../helpers/utils';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import HiddenSynchronization from '../../../components/HiddenSynchronization/HiddenSynchronization';

function CaseFileHistoric({ caseInfo, preview, providencesPreview, layout, mutate: mutateCaseInfo }) {
	const [params, setParams] = useState({});
	const location = useLocation();

	const { isMobile } = useBreakpoints();
	const rowsPerPage = isMobile ? 10 : 10;

	const {
		data,
		isLoading,
		isError,
		size: page,
		setSize: setPage,
		mutate,
	} = layout === LAYOUTS.ADMIN
		? casesService.useProvidences(caseInfo?.Expediente?.id, params)
		: collaboratorRoleService.getProvidences({
				expId: caseInfo?.Expediente?.id,
				...params,
		  });

	const providences = data ? data[0] : [];

	const disabledGetMore = data?.[page - 1]?.paginateData?.length < rowsPerPage;

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		setParams({
			...currentParams,
			...params,
		});
	}, [location.search]);

	const handleResetSearch = (resetParams) => {
		const newParams = createNewObjectWithoutProperties({
			keys: resetParams,
			object: params,
		});
		setParams(newParams ?? {});
	};

	let hasData = true;
	if (!Object.keys(params).length) {
		hasData = !isLoading && providences?.paginateData?.length > 0;
	}

	return (
		<>
			<CardAccordion
				title='Despacho histórico'
				defaultValue={false}
			>
				<CardBody>
					<Row>
						<Col className='pt-3 pb-4'>
							{!preview && (
								<TableOptionsButtons
									tableType='caseDetail'
									data={data?.map((pages) => pages?.paginateData ?? []).flat() ?? []}
									layout={layout}
									details={caseInfo}
									canAdd={false}
									canSend={false}
									canPrint={false}
									resetSearch={handleResetSearch}
									mutate={mutateCaseInfo}
								>
									{!isLoading && !preview && (
										<div>
											<HiddenSynchronization
												jurisdiction={caseInfo?.Expediente.Jurisdiction?.name}
												hasData={hasData}
												expId={caseInfo?.Expediente.id}
												mutate={mutate}
											/>
										</div>
									)}
								</TableOptionsButtons>
							)}
						</Col>
					</Row>
					{preview ? (
						<DynamicTable
							data={providencesPreview}
							columns={historicTableColumns}
							isLoading={isLoading}
							isError={isError}
							noDataMessage='No se encontró ningún despacho.'
						/>
					) : (
						<DynamicTable
							data={data?.map((pages) => pages?.paginateData ?? []).flat() ?? []}
							columns={historicTableColumns}
							linkTo={`/${layout}/providencia/`}
							isLoading={isLoading}
							isError={isError}
							noDataMessage='No se encontró ningún despacho.'
						/>
					)}
				</CardBody>
				{!preview && (
					<CardFooter>
						<Row className='align-items-center'>
							<Col className=''>
								<Button
									type='button'
									className='btn btn-outline-noti-secondary py-1 px-5'
									onClick={() => setPage(page + 1)}
									disabled={isLoading || disabledGetMore || isError}
								>
									<span
										className={`spinner-border spinner-border-sm mr-3 ${isLoading ? '' : 'd-none'}`}
										role='status'
										aria-hidden='true'
									/>
									Ver más
								</Button>
							</Col>
						</Row>
					</CardFooter>
				)}
			</CardAccordion>
		</>
	);
}

export default CaseFileHistoric;

CaseFileHistoric.propTypes = {
	caseInfo: PropTypes.object,
	preview: PropTypes.bool,
	providencesPreview: PropTypes.array,
	layout: PropTypes.string,
};
