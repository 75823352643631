import { API } from '../helpers/fetch';
import { ROLES } from '../helpers/consts';
import useSWR from 'swr';

export const newsService = {
	getNotified,
	getUnnotified,
	getFiltered,
	useNewsTable,
	useNewsTableCount,
	maskReadNovelties,
};

function getNotified() {
	return API.get('/novedades').then((res) => res.id);
}

function getUnnotified() {
	return API.get('/novedades').then((res) => res.id);
}

function getFiltered(params) {
	return API.get('/novedades/filter', {
		params: params,
	}).then((res) => res.id);
}

function fetcher(url, params) {
	if (!url) return;
	return API.get(url, params ? { params: params } : {});
}

/* function useNewsTable(params) {
  params = { ...params, UsuarioId: getCurrentId() };
  const query = parseObjectToQS(params);
  const { data, error, mutate } = useSWR(
    [`/novedades/filter?buttonNum=1000&${query}`],
    fetcher
  );
  const actualData = data?.pageEdges?.map(({ node }) => {return{...node, Usuario: node.Usuario, Organismo: node.Expediente.Organismo}});
  const previousCursor = data?.pageCursors?.previous?.cursor;
  const nextCursor = data?.pageCursors?.next?.cursor;
  const total = data?.totalCount;
  return {
    data: actualData,
    isLoading: !error && !data,
    isError: error,
    mutate,
    previousCursor,
    nextCursor,
    total,
  };
} */

function useNewsTable({ role, params }) {
	const key = role === ROLES.USER ? `/expediente/novelties` : role === ROLES.COLLABORATOR ? 'collaborator/novelties' : '';

	const { data, error, mutate } = useSWR([key, params], fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function useNewsTableCount(params) {
	const key = `/expediente/novedades/count?date=${params.fechaActuacion ?? new Date().toISOString()}`;
	const { data, error, mutate } = useSWR([key], fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function maskReadNovelties(expId) {
	return API.put(`/expediente/read-novelty/${expId}`).then((res) => res.id);
}
