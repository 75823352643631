import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Col, Row } from 'reactstrap';
import DragAndDropInput from '../../../../components/Forms/DragAndDropInput';
import { CgClose } from 'react-icons/cg';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

export default function ProfilePictureLoader({ onAvatarLoaded: setAvatar }) {
	const [imgSrc, setImgSrc] = useState('');
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [showOptions, setShowOptions] = useState(false);
	const [scale] = useState(1);
	const [rotate] = useState(0);
	const aspect = 1;

	function onSelectFile(files) {
		if (files && files?.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.
			const reader = new FileReader();
			const fileLoaded = files[0];
			reader.addEventListener('load', () => {
				setImgSrc(reader.result?.toString() || '');
			});
			if (/^image\/png|image\/jpeg/.test(fileLoaded.type)) {
				reader.readAsDataURL(files[0]);
				setShowOptions(true);
			}
		}
	}

	const blobToBase64 = function (blob, callback) {
		const reader = new FileReader();
		reader.onload = function () {
			const dataUrl = reader.result;
			callback(dataUrl);
		};
		reader.readAsDataURL(blob);
	};

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				await canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate
				);
				previewCanvasRef.current.toBlob(
					(blob) => blobToBase64(blob, setAvatar),
					'image/jpeg',
					0.5
				);
			}
		},
		100,
		[completedCrop, scale, rotate]
	);

	// const ImageOptions = () => {
	//     // Disabled, don't work properly
	//     return <div className="d-flex align-items-center justify-content-around w-100">
	//         <Col xs="6">
	//             <label htmlFor="scale-input">Scale: </label>
	//             <Slider min={1} max={3} step={0.2} value={scale} onChange={(value) => setScale(value)}/>
	//         </Col>
	//         <Col xs="6">
	//             <label htmlFor="rotate-input">Rotate: </label>
	//             <Slider min={-180} max={180} step={10} value={rotate} onChange={(value) => setRotate(value)}/>
	//         </Col>
	//     </div>
	// }

	const ImageFileSelected = () => {
		return (
			<Row className='rounded border d-flex align-items-center p-2'>
				<Col>
					<div className='font-weight-bold'>Imagen seleccionada:</div>
				</Col>
				<Col>
					<Row className='d-flex justify-content-end'>
						<img
							src={imgSrc}
							width='auto'
							height={100}
							alt='preview-file'
						/>
						<Button
							size='sm'
							className='rounded-circle position-absolute'
							type='button'
							color='danger'
							onClick={() => resetLoader()}
						>
							<CgClose />
						</Button>
					</Row>
				</Col>
			</Row>
		);
	};

	const resetLoader = () => {
		setImgSrc('');
		setAvatar('');
		setShowOptions(false);
		setCompletedCrop();
	};

	return (
		<Col className=''>
			<Col className='d-flex flex-column align-items-center'>
				{imgSrc !== '' ? (
					<ImageFileSelected />
				) : (
					<DragAndDropInput
						onChange={(e) => onSelectFile(e.target?.files)}
						accept='image/png, image/jpeg'
						onDrop={(event) => onSelectFile(event.dataTransfer?.files)}
					/>
				)}
			</Col>
			<Row>
				<Col
					lg='9'
					className='d-flex flex-column justify-content-center'
				>
					<Row className='w-100 m-0 justify-content-center'>
						{imgSrc ? (
							<ReactCrop
								crop={crop}
								onChange={(_, percentCrop) => setCrop(percentCrop)}
								onComplete={(c) => {
									setCompletedCrop(c);
								}}
								aspect={aspect}
							>
								<img
									ref={imgRef}
									alt='Crop me'
									src={imgSrc}
									style={{
										transform: `scale(${scale}) rotate(${rotate}deg)`,
										objectFit: 'contain',
										maxHeight: '50vh',
										maxWidth: '100%',
									}}
									onLoad={onImageLoad}
								/>
							</ReactCrop>
						) : null}
					</Row>
				</Col>
				<Col
					lg='3'
					className='align-items-center justify-content-center'
				>
					{completedCrop ? (
						<canvas
							ref={previewCanvasRef}
							style={{
								border: '1px solid black',
								objectFit: 'contain',
								width: 180,
								height: 180,
							}}
						/>
					) : null}
				</Col>
			</Row>
		</Col>
	);
}

ProfilePictureLoader.propTypes = {
	onAvatarLoaded: PropTypes.func,
};
