import React from 'react';
import { Link } from 'react-router-dom';
import {
	UncontrolledCollapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';
import BrandIcon from '../Icons/BrandIcon';
class AdminNavbar extends React.Component {
	render() {
		return (
			<>
				<Navbar
					className='navbar-top border-bottom navbar-light'
					color='white'
					expand='md'
				>
					<Container className='px-4' fluid={true}>
						<NavbarBrand
							to='/'
							tag={Link}
						>
							<BrandIcon />
						</NavbarBrand>
						<button
							className='navbar-toggler navbar-brand '
							id='navbar-collapse-main'
						>
							<span className='navbar-toggler-icon' />
						</button>
						<UncontrolledCollapse
							navbar
							toggler='#navbar-collapse-main'
              className='m-0'
						>
							<div className='navbar-collapse-header d-md-none'>
								<Row>
									<Col
										className='collapse-brand'
										xs='6'
									>
										<NavbarBrand
											to='/'
											tag={Link}
										>
											<BrandIcon size={100} />
										</NavbarBrand>
									</Col>
									<Col
										className='collapse-close'
										xs='6'
									>
										<button
											className='navbar-toggler '
											id='navbar-collapse-main'
										>
											<span />
											<span />
										</button>
									</Col>
								</Row>
							</div>
							<Nav
								className='ml-auto align-items-center d-flex'
								navbar
							>
								<NavItem>
									<NavLink
										className='nav-link-icon'
										to='/auth/login'
										tag={Link}
									>
										<Button
											type='button'
											outline
											className='p-2 btn btn-outline-noti-secondary'
                      style={{
                        width: '200px'
                      }}
										>
											Iniciar sesión
										</Button>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className='nav-link-icon'
										to='/auth/register'
										tag={Link}
									>
										<Button
											type='button'
											className='py-2 btn btn-noti-primary'
                      style={{
                        width: '200px'
                      }}
										>
											Crear cuenta
										</Button>
									</NavLink>
								</NavItem>
							</Nav>
						</UncontrolledCollapse>
					</Container>
				</Navbar>
			</>
		);
	}
}

export default AdminNavbar;
