import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { createNewObjectWithoutProperties, parseObjectToQS } from '../helpers/utils';

export default function useQueryParams(initialParams) {
	const history = useHistory();
	const location = useLocation();
	const [params, setParams] = useState(initialParams);

	useEffect(() => {
		const currentParams = queryString.parse(location.search);
		setParams((prev) => ({ ...prev, ...currentParams }));
	}, [location.search]);

	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: parseObjectToQS(params),
		});
	}, [params]);

	const handleSetFilter = (filters) => {
		setParams({ ...params, ...filters });
	};

	const handleSetPage = (page) => {
		setParams({ ...params, page: page });
	};

	const handleResetSearch = (resetParams) => {
		const newParams = createNewObjectWithoutProperties({
			keys: resetParams,
			object: params,
		});
		setParams(newParams ?? {});
	};

	return { params, handleSetPage, handleSetFilter, handleResetSearch };
}
