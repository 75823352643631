import React, { useContext, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import global from 'context/global';
import { FaPlus } from 'react-icons/fa';
import * as Yup from 'yup';
import { connectionsService } from '../../../services/connectionsService';
import CustomMessage from '../../../components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/notiexpress/close-icon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/img/icons/notiexpress/eye-icon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/img/icons/notiexpress/eye-off-icon.svg';
import { CONNECTION_LIMIT_MESSAGE } from 'helpers/consts';
import PropTypes from 'prop-types';

const connectionFormSchema = Yup.object().shape({
	jurisdiction: Yup.string().required('Campo requerido'),
	matriculaConnection: Yup.string().required('Campo requerido'),
	userConnection: Yup.string().required('Campo requerido'),
	passwordConnection: Yup.string().required('Campo requerido'),
});
const ConnectionsForm = ({
	mutate,
	connectionErrorMessage,
	connectionSuccessMessage,
	setConnectionErrorMessage,
	setConnectionSuccessMessage,
}) => {
	const {
		state: { jurisdictions },
	} = useContext(global);
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = async (values) => {
		formik.setSubmitting(true);
		try {
			await connectionsService.create({
				jurisdictionId: values.jurisdiction,
				userLogin: values.userConnection,
				passwordLogin: values.passwordConnection,
				licensePlate: values.matriculaConnection,
			});
			setConnectionSuccessMessage('¡Conexión creada!');
			formik.resetForm();
			mutate();
		} catch (error) {
			if (error.name === 'BadRequestException' && error.message === CONNECTION_LIMIT_MESSAGE) {
				setConnectionErrorMessage('¡Solo podés tener una conexión por jurisdicción!');
			} else {
				setConnectionErrorMessage('Error al crear la conexión');
			}
		} finally {
			formik.setSubmitting(false);
		}
	};
	const formik = useFormik({
		initialValues: {
			jurisdiction: '',
			matriculaConnection: '',
			userConnection: '',
			passwordConnection: '',
		},
		validationSchema: connectionFormSchema,
		onSubmit: handleSubmit,
	});

	return (
		<form
			onSubmit={formik.handleSubmit}
      autoComplete='off'
		>
			<FormGroup
				tag={Row}
				className='m-0'
			>
				<Col>
					<Label
						className='text-body-large-bold text-secondary-default'
						for='jurisdiction'
					>
						Jurisdicción *
					</Label>
					<InputGroup
						className='input-group-alternative'
						size='sm'
					>
						<Input
							type='select'
							name='jurisdiction'
							id='jurisdiction'
							value={formik.values.jurisdiction}
							onChange={formik.handleChange}
						>
							<>
								<option value=''>Seleccioná una jurisdicción</option>
								{jurisdictions?.map((jurisdiction, index) => (
									<option
										key={index}
										value={jurisdiction.id}
									>
										{jurisdiction.name === 'Federal' ? 'Capital Federal / Justicia Federal' : jurisdiction.name}
									</option>
								))}
							</>
						</Input>
					</InputGroup>
					{formik.errors.jurisdiction && formik.touched.jurisdiction ? (
						<span className='text-dark-link-regular text-error'>{formik.errors.jurisdiction}</span>
					) : null}
				</Col>
				<Col>
					<Label
						className='text-body-large-bold text-secondary-default'
						for='matriculaConnection'
					>
						Matrícula *
					</Label>
					<InputGroup
						className='input-group-alternative'
						size='sm'
					>
						<Input
							type='text'
							name='matriculaConnection'
							id='matriculaConnection'
							value={formik.values.matriculaConnection}
							onChange={formik.handleChange}
							placeholder='Ingresá tu n° de matrícula'
						/>
					</InputGroup>
					{formik.errors.matriculaConnection && formik.touched.matriculaConnection ? (
						<span className='text-dark-link-regular text-error'>{formik.errors.matriculaConnection}</span>
					) : null}
				</Col>
				<Col>
					<Label
						className='text-body-large-bold text-secondary-default'
						for='userConnection'
					>
						Usuario / Id *
					</Label>
					<InputGroup
						className='input-group-alternative'
						size='sm'
					>
						<Input
							type='text'
							name='userConnection'
							id='userConnection'
							value={formik.values.userConnection}
							onChange={formik.handleChange}
							placeholder='Usuario / Id del Poder Judicial'
              autoComplete='ÑÖcompletes'
						/>
					</InputGroup>
					{formik.errors.userConnection && formik.touched.userConnection ? (
						<span className='text-dark-link-regular text-error'>{formik.errors.userConnection}</span>
					) : null}
				</Col>
				<Col>
					<Label
						className='text-body-large-bold text-secondary-default'
						for='passwordConnection'
					>
						Contraseña *
					</Label>
					<InputGroup
						className='input-group-alternative'
						size='sm'
					>
						<Input
							type={showPassword ? 'text' : 'password'}
							name='passwordConnection'
							id='passwordConnection'
							value={formik.values.passwordConnection}
							onChange={formik.handleChange}
							placeholder='Contraseña del Poder Judicial'
						/>
						<InputGroupAddon addonType='append'>
							<InputGroupText>
								{showPassword ? (
									<EyeOffIcon
										className='stroke-primary-default'
										onClick={() => setShowPassword(!showPassword)}
									/>
								) : (
									<EyeIcon
										className='stroke-primary-default'
										onClick={() => setShowPassword(!showPassword)}
									/>
								)}
							</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
					{formik.errors.passwordConnection && formik.touched.passwordConnection ? (
						<span className='text-dark-link-regular text-error'>{formik.errors.passwordConnection}</span>
					) : null}
				</Col>
			</FormGroup>
			<FormGroup
				tag={Row}
				className='m-0 mt-3'
			>
				<Col>
					<Row className='m-0 align-items-center'>
						<Button
							className='btn btn-noti-primary py-1 px-5'
							type='submit'
						>
							<Row className='m-0 align-items-center'>
								{formik.isSubmitting ? (
									<span
										className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<FaPlus
										className='mr-2'
                    size={15}
									/>
								)}
								<span className='text-button'>Agregar</span>
							</Row>
						</Button>
						{connectionSuccessMessage && setTimeout(() => setConnectionSuccessMessage(''), 2000) && (
							<CustomMessage
								className='m-0 align-items-center'
								icon={
									<CheckIcon
										className='stroke stroke-success'
										width={24}
										height={24}
									/>
								}
								message={connectionSuccessMessage}
							/>
						)}
						{connectionErrorMessage && setTimeout(() => setConnectionErrorMessage(''), 2000) && (
							<CustomMessage
								className='m-0'
								icon={
									<CloseIcon
										className='stroke stroke-error'
										width={24}
										height={24}
									/>
								}
								message={connectionErrorMessage}
							/>
						)}
					</Row>
				</Col>
			</FormGroup>
		</form>
	);
};

ConnectionsForm.propTypes = {
	mutate: PropTypes.func,
	connectionErrorMessage: PropTypes.string,
	connectionSuccessMessage: PropTypes.string,
	setConnectionErrorMessage: PropTypes.func,
	setConnectionSuccessMessage: PropTypes.func,
};

export default ConnectionsForm;
