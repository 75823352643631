import { useEffect, useState } from 'react';

export default function useBreakpoints() {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
	const [isTablet, setIsTablet] = useState(window.innerWidth > 600);
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900);

	useEffect(() => {
		let mediaQueryM = window.matchMedia('(max-width: 600)');
		mediaQueryM.addEventListener('change', (e) => {
			if (e.matches) {
				setIsMobile(window.innerWidth < 600);
			}
		});
		let mediaQueryT = window.matchMedia('(min-width: 600)');
		mediaQueryT.addEventListener('change', (e) => {
			if (e.matches) {
				setIsTablet(window.innerWidth > 600);
			}
		});
		let mediaQueryD = window.matchMedia('(min-width: 900)');
		mediaQueryD.addEventListener('change', (e) => {
			if (e.matches) {
				setIsDesktop(window.innerWidth > 900);
			}
		});
		return () => {
			mediaQueryM.removeEventListener('change', setIsMobile);
			mediaQueryT.removeEventListener('change', setIsTablet);
			mediaQueryD.removeEventListener('change', setIsDesktop);
		};
	}, []);

	return { isMobile, isTablet, isDesktop };
}
