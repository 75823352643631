import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ size = 20, fill = 'transparent', color = 'success' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 24 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20 6L9 17L4 12'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

CheckIcon.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  color: PropTypes.string,
};

export default CheckIcon;
