/* eslint-disable react-hooks/rules-of-hooks */
import { API } from 'helpers/fetch';
import { getOwnerId } from 'helpers/user';
import { parseObjectToQS } from 'helpers/utils';
import useSWR, { useSWRInfinite } from 'swr';
import { ROLES } from '../helpers/consts';

export const casesService = {
	deleteCase,
	addDeletedCase,
	deleteExpedient,
	useCaseDetails,
	getCaseDetails,
	useProvidences,
	useProvidenceDetail,
	getCaseFileByKey,
	usePaginatedCases,
	usePaginatedExpirationCases,
	addNonWorkingDays,
	updateGeneralSettings,
	getNonWorkingDays,
	deleteNonWorkingDay,
	updateCaseSettings,
	getAllProvidences,
	useCaseExpirationStatusCount,
	createControlReview,
  useCaseCount,
};

function updateCaseSettings({
	caseId,
	collaborator,
	caducatePeriod,
	alertPeriod,
	folder,
	officeId,
	representationType,
	calculationType,
	deleted,
}) {
	return API.put('/expediente/updateExpedienteOnUserList', {
		expId: caseId,
		...(collaborator && { collaborator: collaborator }),
		...(caducatePeriod && { periodCaducity: caducatePeriod }),
		...(alertPeriod && { periodAlert: alertPeriod }),
		...(folder && { folder: folder }),
		...(officeId && { officeId: officeId }),
		...(representationType && { representationType: representationType }),
		...(calculationType && { calculationType: calculationType }),
		...(deleted && { deleted: deleted }),
	});
}

function addNonWorkingDays({ initDate, endDate, jurisdictions, description, repeat }) {
	const bodyRequest = jurisdictions.map((jurisdiction) => ({
		initDate,
		...(endDate && { endDate }),
		jurisdictionId: jurisdiction.value,
		description,
		repeat: repeat.value,
	}));
	return API.post('/user/profile/nonWorkingDays', bodyRequest);
}

function getNonWorkingDays() {
	const { data, error, mutate } = useSWR('/user/profile/nonWorkingDays', fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function deleteNonWorkingDay({ id }) {
	return API.delete(`/user/profile/nonWorkingDays/${id}`);
}

function deleteExpedient({ userId, expId, expNumber, expYear, captcha }) {
	return API.delete(`expediente/${expId}`, {
		params: {
			userId,
			number: expNumber,
			year: expYear,
			cs: captcha,
		},
	});
}

function getCaseFileByKey({ numero, anio, Localidad }) {
	const query = parseObjectToQS({ numero, anio, Localidad });
	return API.get(`/expediente?${query}`);
}

function deleteCase(id) {
	return API.delete(`/expediente/${id}`);
}

function addDeletedCase(id) {
	return API.put(`/expediente/${id}/deleted`);
}

function usePaginatedCases({ role, params }) {
	const patchedParams =
		role === ROLES.COLLABORATOR
			? {
					lastActuationDate: params?.fechaActuacion,
					page: params?.page,
					limit: params?.take,
					jurisdictionId: params?.jurisdiccion,
					organismId: params?.organismo,
					circumscriptionId: params?.localidad,
					coverPage: params?.caratula,
					folder: params?.folder,
					number: params?.numero,
					year: params?.anio,
			  }
			: params;
	const key =
		role === ROLES.USER ? `/expediente/filter/pagination/${getOwnerId()}` : role === ROLES.COLLABORATOR ? '/collaborator/expedients' : '';
	const { data, error, mutate } = useSWR([key, patchedParams], fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function useCaseCount() {
	const { data, error, mutate } = useSWR('/expediente/count', fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function usePaginatedExpirationCases({ params }) {
	const { data, error, mutate } = useSWR([`/expediente/expiration/${getOwnerId()}`, params], fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function useCaseExpirationStatusCount() {
	const { data, error, mutate } = useSWR('/expediente/expiration/count', fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function fetcher(url, params) {
	return API.get(url, params ? { params: params } : {});
}

function useCaseDetails(expId, userId) {
	const query = parseObjectToQS({ expId, userId });
	const { data, error, mutate } = useSWR([`/expediente/userList?${query}`], fetcher);

	return {
		data,
		error,
		isLoading: !error && !data,
		mutate,
	};
}

function getCaseDetails(id) {
	return API.get(`/expediente/userList?userId=${getOwnerId()}&expId=${id}`);
}

function useProvidences(expId, params) {
	const getKey = (pageIndex, previousPageData) => {
		if (previousPageData && !previousPageData.paginateData.length) return null;
		const patchedParams = {
			...params,
			page: pageIndex + 1,
		};
		const query = parseObjectToQS(patchedParams);
		return `/providence/expediente/${expId}?${query}`;
	};
	const { data, error, mutate, size, setSize } = useSWRInfinite(getKey, fetcher);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
		size,
		setSize,
	};
}

function getAllProvidences(expId) {
	return API.get(`/providence/expediente/${expId}?take=1000&page=1`);
}

function useProvidenceDetail(id) {
	const { data, error, mutate } = useSWR(`/providence/${id}`, fetcher);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
}

function createControlReview({ expId }) {
	return API.put('/providence/addControlReview', {
		expId,
	});
}

function updateGeneralSettings({
	caducityPeriod,
	alertPeriod,
	scheduleNotification,
	sendUserNotification,
	sendCollaboratorNotification,
	enableNotification,
	notificationExpChange,
	notificationCaducity,
	notificationExpAdd,
	calculationCaducity,
}) {
	return API.put('/user/profile', {
		...(caducityPeriod && { caducityPeriod }),
		...(alertPeriod && { alertPeriod }),
		...(scheduleNotification && { scheduleNotification }),
		sendUserNotification,
		sendCollaboratorNotification,
		enableNotification,
		notificationExpChange,
		notificationCaducity,
		notificationExpAdd,
		calculationCaducity,
	});
}
