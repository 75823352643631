import React from 'react';
import HomeHeader from '../../components/Headers/HomeHeader';
import { Card, Container } from 'reactstrap';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const PrivacyPolicyView = () => {
	return (
		<>
			<HomeHeader headerText={'Política de Privacidad'} />
			<Container
				className='bg-white mb-5'
				fluid={true}
			>
				<Card className='shadow-card'>
          <PrivacyPolicyContent />
				</Card>
			</Container>
		</>
	);
};

export default PrivacyPolicyView;
