/* eslint-disable react-hooks/rules-of-hooks */
import { API } from 'helpers/fetch';
import useSWR, { useSWRInfinite } from 'swr';
import { parseObjectToQS } from 'helpers/utils';

function fetcher(url, params) {
	return API.get(url, params ? { params } : {});
}

function getNovelties({ params }) {
	const { data, error, mutate } = useSWR(
		['/collaborator/novelties', params],
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function getNoveltiesCount() {
  const { data, error, mutate } = useSWR(
    ['/collaborator/novelties/count'],
    fetcher
  );
  return {
    data,
    isLoading: !error && !data,
    isError: !!error,
    mutate,
  };
}

function getExpedients({ params }) {
	const { data, error, mutate } = useSWR(
		['/collaborator/expedients', params],
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function getExpedientsCount() {
  const { data, error, mutate } = useSWR(
    ['/collaborator/expedients/count'],
    fetcher
  );
  return {
    data,
    isLoading: !error && !data,
    isError: !!error,
    mutate,
  };
}

function getExpedientsExpiration({ params }) {
	const { data, error, mutate } = useSWR(
		['/collaborator/expedients/expiring', params],
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function getExpedientsExpirationCount({ params }) {
	const { data, error, mutate } = useSWR(
		['/collaborator/expedients/expiring/count', params],
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function getExpedientById(id) {
	const { data, error, mutate } = useSWR(
		`/collaborator/expedients/${id}`,
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function getProvidences({ expId, providenceDate, extract }) {
	const getKey = (pageIndex, previousPageData) => {
		if (previousPageData && !previousPageData.paginateData.length) return null;
		const patchedParams = {
			...{ providenceDate, extract },
			page: pageIndex + 1,
		};
		const query = parseObjectToQS(patchedParams);
		return `/collaborator/expedients/${expId}/providences?${query}`;
	};
	const { data, error, mutate, size, setSize } = useSWRInfinite(
		getKey,
		fetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
		size,
		setSize,
	};
}

function getProvidenceById({ providenceId }) {
	const { data, error, mutate } = useSWR(
		`/collaborator/expedients/providences/${providenceId}`,
		fetcher
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function createControlReview({ expId }) {
  return API.post(`/collaborator/expedients/${expId}/control-review`);
}

export const collaboratorRoleService = {
	getNovelties,
  getNoveltiesCount,
	getExpedients,
  getExpedientsCount,
	getExpedientsExpiration,
	getExpedientById,
	getProvidences,
	getProvidenceById,
	getExpedientsExpirationCount,
  createControlReview,
};