const tableHeading = {
	novedades: [
		'Número',
		'Año',
		'Carátula',
		'Jurisdicción',
		'Localidad',
		'Organismo',
		'Responsable',
	],
	expedientes: [
		'Número',
		'Año',
		'Carátula',
		'Jurisdicción',
		'Localidad',
		'Organismo',
		'Responsable',
	],
	caducidad: [
		'Orden',
		'Expediente',
		'Carátula',
		'Responsable',
		'Últ. providencia',
		'Fecha de Caducidad',
		'Estado',
	],
	expediente: {
		main: ['Fecha', 'Extracto', 'Firma'],
		details: [
			'Expediente',
			'Carátula',
			'Jurisdicción',
			'Organismo',
			'Última novedad',
		],
	},
};

export default tableHeading;
