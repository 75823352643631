import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import user from 'context/user';
import { useFormik } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CustomMessage from '../../../CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';

const SendForCollaborator = ({ handleSend }) => {
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	const {
		state: { currentUser },
	} = useContext(user);

	const emailOptions = [
		{
			label: `${currentUser?.admin?.name}`,
			value: currentUser?.admin?.email,
		},
	];

	const collaboratorsOption = [
		{
			label: `${currentUser?.name} (Yo)`,
			value: currentUser?.email,
		},
	];

	const colorStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: 'white',
			minWidth: '300px',
			maxWidth: '500px',
		}),
		option: (styles, { isDisabled, isSelected }) => {
			return {
				...styles,
				backgroundColor: isDisabled ? undefined : isSelected ? 'lightgrey' : undefined,
				color: isDisabled ? '#ccc' : isSelected ? 'black' : 'black',
				cursor: isDisabled ? 'not-allowed' : 'default',
				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled ? (isSelected ? 'lightgrey' : 'white') : undefined,
				},
			};
		},
		multiValue: (styles, { isDisabled }) => {
			return {
				...styles,
				backgroundColor: isDisabled ? 'grey' : undefined,
				width: '30px',
				maxWidth: '30px',
				height: '30px',
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
			display: 'none',
		}),
		multiValueRemove: (styles, { data, isDisabled }) => ({
			...styles,
			display: isDisabled ? 'none' : 'flex',
			color: 'black',
			backgroundColor: data.color,
			':hover': {
				backgroundColor: 'red',
				color: 'white',
			},
		}),
	};

	const initialState = {
		selectedEmails: [],
		sendOption: 'all',
	};

	const handleSendEmails = (values) => {
		formik.setSubmitting(true);
		const emailProps = values.selectedEmails.map(({ value }) => {
			const sendToAdmin = currentUser?.admin?.email === value ? currentUser?.admin : null;
			return sendToAdmin
				? {
						userName: sendToAdmin?.name,
						userLastName: sendToAdmin?.lastName,
						userEmail: sendToAdmin?.email,
				  }
				: {
						userName: currentUser.name,
						userLastName: currentUser.lastName,
						userEmail: currentUser.email,
				  };
		});
		handleSend(emailProps)
			.then(() => {
				setShowSuccess(true);
				formik.resetForm();
				setShowError(false);
			})
			.catch(() => {
				setShowError(true);
				setShowSuccess(false);
			})
			.finally(() => formik.setSubmitting(false));
	};

	const formik = useFormik({
		initialValues: initialState,
		onSubmit: handleSendEmails,
	});

	useEffect(() => {
		const adminEmail = {
			value: currentUser?.admin?.email,
		};
		const currentUserEmail = { value: currentUser?.email };
		if (formik.values.sendOption === 'all') {
			formik.setFieldValue('selectedEmails', [adminEmail, currentUserEmail]);
		} else {
			formik.setFieldValue('selectedEmails', []);
		}
	}, [currentUser, formik.values.sendOption]);

	const animatedComponents = makeAnimated();
	const CustomOption = (props) => {
		return (
			<animatedComponents.Option {...props}>
				<Row className='m-0 align-items-center justify-content-between'>
					<div className='m-0'>
						<Row className='m-0 align-items-center'>
							<span>{props.data.label}</span>
						</Row>
					</div>
					<div className='custom-control custom-control-alternative custom-checkbox'>
						<Row className='m-0 align-items-center'>
							<input
								type='checkbox'
								id={props.data.value}
								defaultChecked={props.isSelected}
								className='custom-control-input'
							/>
							<label
								className='custom-control-label '
								htmlFor={props.data.value}
							/>
						</Row>
					</div>
				</Row>
			</animatedComponents.Option>
		);
	};

	CustomOption.propTypes = {
		data: PropTypes.object.isRequired,
		isSelected: PropTypes.bool.isRequired,
	};

	return (
		<>
			<Col className='py-2'>
				<Form onSubmit={formik.handleSubmit}>
					<Row className='align-items-center'>
						<span className='text-body-large-bold text-secondary-default mr-3'>Enviar a</span>
						<FormGroup
							className='m-0'
							row
							tag='fieldset'
							onChange={formik.handleChange}
						>
							<FormGroup
								className='custom-radio'
								tag={Col}
								check
							>
								<Input
									name='sendOption'
									className='custom-control-input'
									value='all'
									onChange={formik.handleChange}
									checked={formik.values.sendOption === 'all'}
									id='all'
									type='radio'
									size='sm'
								/>
								<Label
									check
									for='all'
									className='custom-control-label'
								>
									<span className='text-body-regular'>Todos</span>
								</Label>
							</FormGroup>
							<FormGroup
								className='custom-radio'
								tag={Col}
								check
							>
								<Input
									name='sendOption'
									id='responsible'
									value='responsible'
									checked={formik.values.sendOption === 'responsible'}
									onChange={formik.handleChange}
									className='custom-control-input'
									type='radio'
									size='sm'
								/>
								<Label
									check
									for='responsible'
									className='custom-control-label'
								>
									<span className='text-body-regular'>Responsable</span>
								</Label>
							</FormGroup>
						</FormGroup>
						<FormGroup
							row
							className='m-0 '
						>
							<Select
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								options={[...collaboratorsOption, ...(emailOptions ?? [])]}
								onChange={(newValue) => {
									formik.setFieldValue('selectedEmails', newValue);
								}}
								value={formik.values.selectedEmails}
								components={{ ...animatedComponents, Option: CustomOption }}
								isMulti
								styles={colorStyles}
								isDisabled={formik.values.sendOption !== 'responsible'}
								name='selectedEmails'
								placeholder='Seleccioná el destinatario'
							/>
						</FormGroup>
						<FormGroup
							row
							className='m-0 px-1'
						>
							<Row className='m-0 align-items-center'>
								<Button
									type='submit'
									className='btn btn-noti-primary py-1 px-5'
								>
									<Row className='align-items-center m-0'>
										<span
											className={`spinner-border spinner-border-sm mr-3 ${formik.isSubmitting ? '' : 'd-none'}`}
											role='status'
											aria-hidden='true'
										></span>
										<span className='text-button'>Enviar</span>
									</Row>
								</Button>
								{showSuccess ? (
									<CustomMessage
										className='m-0'
										icon={
											<CheckIcon
												className='stroke stroke-success'
												width={24}
												height={24}
											/>
										}
										message='¡Enviado!'
									/>
								) : null}
								{showError ? (
									<CustomMessage
										className='m-0'
										icon={
											<CloseIcon
												className='stroke stroke-error'
												width={24}
												height={24}
											/>
										}
										message='Error al enviar el email'
									/>
								) : null}
							</Row>
						</FormGroup>
					</Row>
				</Form>
			</Col>
		</>
	);
};

export default SendForCollaborator;

SendForCollaborator.propTypes = {
	handleSend: PropTypes.func,
	disabled: PropTypes.bool,
};
