import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToggle, Col } from 'reactstrap';

function ButtonAction({
	active,
	iconClass,
	text,
	handleClick,
	id,
	className,
	isMobile = false,
}) {
	return (
		<Col xs={isMobile && 12} className={isMobile ? 'justify-content-center d-flex' : ''}>
			<ButtonToggle
				onClick={() => handleClick()}
				className={className}
				id={id}
				outline
				active={active}
			>
				<i className={`${iconClass}`} />
				<span className=''>{text}</span>
			</ButtonToggle>
		</Col>
	);
}

export default ButtonAction;

ButtonAction.propTypes = {
	active: PropTypes.bool,
	iconClass: PropTypes.string,
	text: PropTypes.string,
	handleClick: PropTypes.func,
	id: PropTypes.string,
	className: PropTypes.string,
	isMobile: PropTypes.bool,
};
