import React, { useContext } from 'react';
import { Card, Col, Row } from 'reactstrap';
import toArs from '../../../../../helpers/toArs';
import cartContext from '../../../../../context/cart';

const CartStatusCard = () => {
	const { cart, totalAmount, discountAmount } = useContext(cartContext);
	return (
		<Card className='shadow-card mb-5'>
			<Row className='m-0 p-5'>
				<Col>
					{cart?.map((service, index) => (
						<Row
							className='justify-content-between m-0 py-2'
							key={index}
						>
							<span className='text-body-regular'>{service?.nombre}</span>
							<span className='text-body-regular'>
								<strong>{toArs(service?.precio)}</strong>/mes
							</span>
						</Row>
					))}
					{discountAmount > 0 ? (
						<Row className='justify-content-between m-0 py-2'>
							<span className='text-link-regular text-info'>
								<strong>Descuento</strong>
							</span>
							<span className='text-link-regular text-info'>
								<strong>{toArs(-discountAmount)}</strong>
							</span>
						</Row>
					) : null}
				</Col>
				<Col className=''>
					<Col className='py-2'>
						<p className='text-body-regular text-primary-default m-0 text-right'>
							<strong>TOTAL</strong>
						</p>
						<p className='text-body-regular text-primary-default m-0 text-right'>
							<strong>{toArs(totalAmount)}</strong>
						</p>
					</Col>
				</Col>
			</Row>
		</Card>
	);
};

export default CartStatusCard;
