/* eslint-disable react-hooks/rules-of-hooks */
import user from 'context/user';
import { LAYOUTS, ROLES } from 'helpers/consts';
import {
	getCasesPdfSettings,
	getNewsPdfSettings,
	getProvidencePdfSettings,
	getExpirationPdfSettings,
	getExtractPdfSettings,
} from 'helpers/pdfSettings';
import usePDF from '../../../hooks/usePDF';
import React, { useContext, useState } from 'react';
import { Button, Col, Collapse, Row, ButtonToolbar } from 'reactstrap';
import SendToCollaborator from './components/SendToCollaborator';
import Search from './components/Search';
import Print from './components/Print';
import ButtonMainTable from '../ButtonMainTable';
import PropTypes from 'prop-types';
import print from 'context/print';
import AddCaseFlow from './components/AddCaseFlow/AddCaseFlow';
import { emailService } from '../../../services/emailService';
import { ReactComponent as SendIcon } from 'assets/img/icons/notiexpress/send-icon.svg';
import { ReactComponent as SettingIcon } from 'assets/img/icons/notiexpress/setting-icon.svg';
import { ReactComponent as CopyIcon } from 'assets/img/icons/notiexpress/copy-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { Link, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SearchForCaducidad from './components/SearchForCaducidad';
import SearchForProvidence from './components/SearchForProvidence';
import CustomMessage from '../../CustomMessage/CustomMessage';
import SendForCollaborator from './components/SendForCollaborator';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { casesService } from '../../../services/casesService';
import { collaboratorRoleService } from '../../../services/collaboratorRoleService';
import TooltipElement from '../TooltipElement';

function TableOptionsButtons({
	data,
	tableType,
	canAdd = true,
	canSearch = true,
	canSend = true,
	canPrint = true,
	details,
	resetSearch,
	layout,
	children,
	mutate,
}) {
	const [activeTab, setActiveTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [controlled, setControlled] = useState(false);
	const [copied, setCopied] = useState(false);

	const { isMobile } = useBreakpoints();

	const params = useParams();

	const {
		state: { currentUser },
	} = useContext(user);

	const {
		actions: { imprimirExpedientes },
	} = useContext(print);

	function toggleTab(n) {
		activeTab === n ? setActiveTab(0) : setActiveTab(n);
	}

	function getPdfSettings(data) {
		switch (tableType) {
			case 'cases':
				return getCasesPdfSettings(data);
			case 'caseDetail':
				return getProvidencePdfSettings(data, details);
			case 'expiration':
				return getExpirationPdfSettings(data);
			case 'news':
				return getNewsPdfSettings(data);
			case 'providenceDetails':
				return getExtractPdfSettings(data[0]?.ProvidenceFiles[0]?.contentDetail ?? '', data[0]?.extract);
			default:
				break;
		}
	}

	function handlePrint() {
		const pdfSettings = getPdfSettings(data);
		imprimirExpedientes(pdfSettings);
	}

	function handleDownload() {
		const pdfSettings = getPdfSettings(data);
		// rename to downloadTable
		const [, , downloadPDF] = usePDF(pdfSettings);
		downloadPDF(`${pdfSettings.fileName || 'notiexpress'}.pdf`);
	}

	function handleSendEmail(emailsData) {
		const pdfSettings = getPdfSettings(data);
		const [, doc] = usePDF(pdfSettings);
		const base64PDF = doc.output('dataurlstring', {
			filename: `${pdfSettings.fileName || 'notiexpress'}.pdf`,
		});
		const base64 = base64PDF.slice(base64PDF.indexOf(',') + 1);
		const handleSendMethod = () => {
			switch (tableType) {
				case 'news':
					return emailService.sendNovedadesEmailService;
				case 'cases':
					return emailService.sendExpedientesEmailService;
				case 'expiration':
					return emailService.sendCaducidadEmailService;
				case 'caseDetail':
					return emailService.sendCaseDetailsEmailService;
				case 'providenceDetails':
					return emailService.sendProvidenceFileEmailService;
			}
		};
		const sendMethod = handleSendMethod();
		return Promise.all(
			emailsData.map((emailData) =>
				sendMethod({
					...emailData,
					date: params.fechaActuacion ?? new Date().toISOString().slice(0, 10),
					documentDetails: tableType === 'caseDetail' ? [details] : data,
					attachments: [
						{
							type: 'application/pdf',
							content: base64,
							filename: `${pdfSettings.fileName || 'notiexpress'}.pdf`,
						},
					],
				})
			)
		);
	}

	async function handleCreateControlReview() {
		setIsLoading(true);
		layout === LAYOUTS.ADMIN
			? await casesService.createControlReview({
					expId: params.id,
			  })
			: await collaboratorRoleService.createControlReview({
					expId: params.id,
			  });
		setIsLoading(false);
		setControlled(true);
		await mutate();
		return;
	}

	return (
		<div>
			{isMobile ? (
				<ButtonToolbar className='align-items-center'>
					{(tableType === 'providenceDetails' && data[0]?.ProvidenceFiles?.some((fileData) => fileData.url) && (
						<span className='text-heading-2 mr-3 my-0'>Archivos adjuntos</span>
					)) ||
						(tableType === 'providenceDetails' && <span className='text-heading-2 mx-3 my-0'>Archivos adjuntos</span>)}
					{currentUser.rol === ROLES.USER && canAdd && (
						<ButtonMainTable
							handleClick={() => toggleTab(1)}
							active={activeTab === 1}
							tab='1'
							id='addCaseFile'
							iconClass='fas fa-plus'
							text='Agregar'
							className='btn btn-noti-primary px-5'
						/>
					)}
					{canSearch && (
						<ButtonMainTable
							handleClick={() => {
								toggleTab(2);
							}}
							active={activeTab === 2}
							tab='2'
							id='busquedaAvanzada'
							iconClass='fas fa-search'
							text='Buscar'
							className='btn btn-outline-noti-secondary px-5'
							isMobile={isMobile}
						/>
					)}
					{tableType === 'providenceDetails' && data[0]?.ProvidenceFiles?.some((element) => !element.url) && (
						<Col>
							<CopyToClipboard
								text={data[0]?.contentDetail}
								onCopy={() => setCopied(true)}
							>
								<Button
									className='btn btn-noti-icon-primary px-2'
									id='copyExtract'
									size='sm'
								>
									<CopyIcon
										width={18}
										height={18}
										className='stroke-primary-default'
									/>
								</Button>
							</CopyToClipboard>
						</Col>
					)}
					{tableType === 'cases' && currentUser.rol === ROLES.USER && (
						<Col>
							<Button
								className='btn btn-noti-icon-primary px-2'
								id='goToConfig'
								tag={Link}
								to='expedientes/configuration'
							>
								<SettingIcon
									width={24}
									height={24}
									className='stroke-primary-default'
								/>
							</Button>
						</Col>
					)}
					{copied && setTimeout(() => setCopied(!copied), 2000) && (
						<Col className='ml-5'>
							<CustomMessage
								message='¡Copiado!'
								icon={
									<CheckIcon
										width={24}
										height={24}
										className='stroke-success'
									/>
								}
							/>
						</Col>
					)}
					{tableType === 'caseDetail' && currentUser.rol === ROLES.USER && children}
				</ButtonToolbar>
			) : (
				<Row>
					<ButtonToolbar className='align-items-center'>
						{(tableType === 'providenceDetails' && data[0]?.ProvidenceFiles?.some((fileData) => fileData.url) && (
							<span className='text-heading-2 mx-3 my-0'>Archivos adjuntos</span>
						)) ||
							(tableType === 'providenceDetails' && <span className='text-heading-2 mx-3 my-0'>Texto de despacho</span>)}
						{currentUser.rol === ROLES.USER && canAdd && (
							<ButtonMainTable
								handleClick={() => toggleTab(1)}
								active={activeTab === 1}
								tab='1'
								id='addCaseFile'
								iconClass='fas fa-plus'
								text='Agregar'
								className='btn btn-noti-primary px-5'
							/>
						)}
						{canSearch && (
							<ButtonMainTable
								handleClick={() => {
									toggleTab(2);
								}}
								active={activeTab === 2}
								tab='2'
								id='busquedaAvanzada'
								iconClass='fas fa-search'
								text='Buscar'
								className='btn btn-outline-noti-secondary px-5'
								isMobile={isMobile}
							/>
						)}
						{tableType === 'caseDetail' && (
							<ButtonMainTable
								handleClick={() => handleCreateControlReview()}
								id='controlReview'
								iconClass={isLoading ? 'fas fa-spinner fa-spin' : 'fas fa-check'}
								text='Controlado'
								className='btn btn-outline-noti-secondary px-4'
								isMobile={isMobile}
							/>
						)}
						{canPrint && (
							<Print
								disabled={!data?.length}
								handlePrint={handlePrint}
								handleDownload={handleDownload}
							/>
						)}
						{tableType === 'providenceDetails' && data[0]?.ProvidenceFiles?.some((element) => !element.url) && (
							<Col>
								<CopyToClipboard
									text={data[0]?.ProvidenceFiles[0]?.contentDetail ?? ''}
									onCopy={() => setCopied(true)}
								>
									<Button
										className='btn btn-noti-icon-primary px-2'
										id='copyExtract'
									>
										<CopyIcon
											width={24}
											height={24}
											className='stroke-primary-default'
										/>
									</Button>
								</CopyToClipboard>
								<TooltipElement
									children={'Copiar texto de despacho'}
									id={'copyExtract'}
								/>
							</Col>
						)}
						{canSend && (
							<Col>
								<Button
									className='btn btn-noti-icon-primary px-2'
									id='sendEmail'
									onClick={() => toggleTab(3)}
									active={activeTab === 3}
									disabled={!data?.length}
								>
									<SendIcon
										width={24}
										height={24}
										className='stroke-primary-default'
									/>
								</Button>
                <TooltipElement
                  children={'Enviar por email'}
                  id={'sendEmail'}
                />
							</Col>
						)}
						{copied && setTimeout(() => setCopied(!copied), 2000) && (
							<Col className='ml-3'>
								<CustomMessage
									message='¡Copiado!'
									icon={
										<CheckIcon
											width={24}
											height={24}
											className='stroke-success'
										/>
									}
									className='flex-nowrap align-items-center'
								/>
							</Col>
						)}
						{controlled && setTimeout(() => setControlled(!controlled), 2000) && (
							<Col>
								<CustomMessage
									message='¡Expediente controlado!'
									icon={
										<CheckIcon
											width={24}
											height={24}
											className='stroke-success'
										/>
									}
								/>
							</Col>
						)}
						{tableType === 'caseDetail' && currentUser.rol === ROLES.USER && children}
					</ButtonToolbar>
				</Row>
			)}
			<Col className='px-0'>
				<Collapse isOpen={activeTab === 1}>
					{currentUser.rol === ROLES.USER && canAdd && <AddCaseFlow closeTab={() => setActiveTab(0)} />}
				</Collapse>
				<Collapse isOpen={activeTab === 2}>
					{tableType === 'caseDetail' ? (
						<SearchForProvidence resetSearch={resetSearch} />
					) : tableType === 'expiration' ? (
						<SearchForCaducidad
							resetSearch={resetSearch}
							layout={layout}
						/>
					) : (
						<Search
							tableType={tableType}
							resetSearch={resetSearch}
							isMobile={isMobile}
						/>
					)}
				</Collapse>
				<Collapse isOpen={activeTab === 3}>
					{layout === LAYOUTS.ADMIN ? (
						<SendToCollaborator
							closeTab={() => setActiveTab(0)}
							handleSend={handleSendEmail}
							isMobile={isMobile}
						/>
					) : (
						<SendForCollaborator
							closeTab={() => setActiveTab(0)}
							handleSend={handleSendEmail}
						/>
					)}
				</Collapse>
			</Col>
		</div>
	);
}

export default TableOptionsButtons;

TableOptionsButtons.propTypes = {
	data: PropTypes.array.isRequired,
	tableType: PropTypes.string.isRequired,
	canAdd: PropTypes.bool,
	canSearch: PropTypes.bool,
	resetSearch: PropTypes.func,
	details: PropTypes.object,
	canPrint: PropTypes.bool,
	layout: PropTypes.string,
	canSend: PropTypes.bool,
	children: PropTypes.node,
};
