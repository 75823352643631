import React from 'react';
import PropTypes from 'prop-types';

const LightningIcon = ({ size, fill = 'transparent' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 17 24'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16.0909 9.55864C14.0847 9.54503 12.0767 9.55864 10.0705 9.55864H9.65527C9.70291 9.38848 9.73524 9.27617 9.77098 9.16046C10.6785 6.30735 11.5861 3.45368 12.4936 0.599434C12.5634 0.379921 12.5855 0.174022 12.332 0.0498008C12.0784 -0.0744199 11.8759 0.0498011 11.7109 0.245491C7.85378 4.74466 3.99954 9.24497 0.148125 13.7464C0.0392195 13.874 -0.0424618 14.1225 0.0239027 14.2416C0.0902672 14.3607 0.364233 14.4475 0.534399 14.4492C2.52874 14.4628 4.52478 14.4492 6.51912 14.4492H6.96665C6.93772 14.5683 6.92411 14.6432 6.90199 14.7164C5.9865 17.6024 5.06874 20.4884 4.14871 23.3744C4.07554 23.6041 4.02449 23.8236 4.30356 23.9495C4.58263 24.0755 4.7528 23.9495 4.92466 23.7504C8.7704 19.2603 12.6173 14.7697 16.4653 10.2784C16.5272 10.2074 16.5715 10.1228 16.5949 10.0315C16.6182 9.94018 16.6199 9.84469 16.5997 9.75263C16.5623 9.64202 16.273 9.55864 16.0909 9.55864Z'
				fill='#0592E0'
			/>
		</svg>
	);
};

LightningIcon.propTypes = {
  size: PropTypes.number.isRequired,
  fill: PropTypes.string,
};

export default LightningIcon;
