import React, { useState } from 'react';
import { userService } from 'services/userService';
//messenger
import {
	Button,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Input,
	Label,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MailIcon from '../../components/Icons/MailIcon';
import DivideCircleIcon from '../../components/Icons/DivideCircleIcon';
import CustomMessage from '../../components/CustomMessage/CustomMessage';
import CrossIcon from '../../components/Icons/CrossIcon';
import { STATUS_COLORS } from '../../helpers/consts';

const NewPasswordView = () => {
	const [isCollaborator, setIsCollaborator] = useState(false);

	const [showCheckEmail, setShowCheckEmail] = useState(false);
	const [showErrorEmail, setShowErrorEmail] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('Email inválido')
				.required('Campo obligatorio.'),
		}),
		onSubmit: (values) =>
			userService
				.forgotPassword({ email: values.email })
				.then(() => setShowCheckEmail(true))
				.catch(() => setShowErrorEmail(true)),
	});

	return (
		<>
			<Col className='bg-white h-100 pt-6'>
				<Col className='mt-3 px-5'>
					<h3 className='text-heading-2 text-lg py-3'>Cambiar contraseña</h3>
					<p className='text-heading-2 text-sm py-3'>
						Por razones de seguridad, ingresá tu e-mail para comenzar con el
						proceso de recuperación de contraseña.
					</p>
					<Form
						role='form'
						onSubmit={formik.handleSubmit}
					>
						{/* IsCollaborator checkbox */}
						<FormGroup className='mb-3 py-3'>
							<div className='custom-control custom-control-alternative custom-checkbox'>
								<Input
									className='custom-control-input'
									type='checkbox'
									id='isCollaborator'
									checked={isCollaborator}
									onChange={() => setIsCollaborator(!isCollaborator)}
								/>
								<Label
									className='custom-control-label text-body-regular'
									htmlFor='isCollaborator'
								>
									Tengo una cuenta de{' '}
									<span className='text-heading-2 text-caps-regular'>
										COLABORADOR
									</span>
								</Label>
							</div>
						</FormGroup>
						{/* Email */}
						<FormGroup className=''>
							<InputGroup className='input-group-alternative'>
								<InputGroupAddon addonType='prepend'>
									<InputGroupText>
										<MailIcon size={18} />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									name='email'
									onChange={formik.handleChange}
									value={formik.values.email}
									id='email'
									placeholder='Correo electrónico'
									type='email'
									disabled={isCollaborator || formik.isSubmitting}
								/>
							</InputGroup>
						</FormGroup>
						{formik.errors?.email && (
							<span className='text-dark-link-regular text-error'>
								{formik.errors.email}
							</span>
						)}
						{isCollaborator && (
							<Col className='mt-3'>
								<CustomMessage
									icon={<DivideCircleIcon size={18} />}
									message='Tenés que dar aviso al Administrador de la cuenta principal para que reestablezca tu contraseña.'
								/>
							</Col>
						)}
						<div className='text-center'>
							<Button
								className='my-4 p-1 px-5 btn btn-noti-primary'
								color='primary'
								type='submit'
								disabled={formik.isSubmitting || isCollaborator}
							>
								<span
									className={`spinner-border spinner-border-sm mr-3 ${
										formik.isSubmitting ? '' : 'd-none'
									}`}
									role='status'
									aria-hidden='true'
								/>
								Enviar
							</Button>
						</div>
					</Form>
					{showCheckEmail && (
						<Col className='mt-3'>
							<CustomMessage
								icon={<DivideCircleIcon size={18} />}
								message='Revisá tu e-mail para actualizar la contraseña.'
							/>
						</Col>
					)}
					{showErrorEmail && (
						<Col className='mt-3'>
							<CustomMessage
								icon={
									<CrossIcon
										size={18}
										color={STATUS_COLORS.error}
									/>
								}
								message='Ocurrió un error. Intente nuevamente más tarde.'
							/>
						</Col>
					)}
				</Col>
			</Col>
		</>
	);
};

export default NewPasswordView;
