import React, { useContext } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import routes from '../../routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthFooter from '../../../components/Footers/Footer/Footer';
import { Container as ModalContainer } from 'react-modal-promise';
import user from '../../../context/user';
import CollaboratorNavbar from '../../../components/Navbars/CollaboratorNavbar';

const Collaborator = () => {
	const {
		state: { currentUser },
	} = useContext(user);

	const collaboratorRoutes = routes.filter(
		(route) => route.layout === '/collaborator'
	);

	const getRoutes = (collaboratorRoutes) => {
		return collaboratorRoutes.map((route, key) => {
			return (
				<Route
					exact
					path={route.layout + route.path}
					component={route.component}
					key={key}
				/>
			);
		});
	};

	return (
		<>
			<Sidebar
				routes={collaboratorRoutes}
				logo={{
					innerLink: '/collaborator/',
					imgAlt: 'NotiExpress',
				}}
				role={currentUser.rol}
			/>
			<div className='main-content'>
				<CollaboratorNavbar />
				<div className='container-admin'>
					<Switch>
						{getRoutes(collaboratorRoutes)}
						<Redirect
							from='*'
							to={'/collaborator/novedades'}
						/>
					</Switch>
				</div>
				<AuthFooter layout='collaborator' />
				<ModalContainer />
			</div>
		</>
	);
};

export default Collaborator;
