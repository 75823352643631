import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	getCurrentId,
	getCurrentRole,
	getCurrentEmail,
	getCurrentPhoto,
	getOwnerId,
	getCurrentLastName,
	getCurrentName,
} from '../helpers/user';
import { userService } from '../services/userService';
import { ROLES } from '../helpers/consts';

const initialState = {
	currentUser: {}, // Usuario que inició sesión
	status: 'init',
	userdata: {},
};

// Creamos nuestro context pasandole nuestro estado inicial
const UserContext = createContext(initialState);

// Creamos el reducer para manejar nuestro estado
const reducer = (state, action) => {
	switch (action.type) {
		case 'USER_LOGGEDIN':
			return {
				...state,
				status: 'resolved',
				currentUser: action.payload,
			};
		case 'ADD_USERDATA':
			return {
				...state,
				userdata: action.payload,
			};
		case 'USER_LOGGIGNIN':
			return {
				...state,
				status: 'resolving',
			};
		case 'USER_LOGGEDOUT':
			return {
				...state,
				status: 'ended',
				currentUser: {},
				userdata: {},
			};
		default:
			return state;
	}
};

const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	function setCurrentUser(data) {
		dispatch({ type: 'USER_LOGGEDIN', payload: data });
	}
	function addUserdata(userdata) {
		dispatch({ type: 'ADD_USERDATA', payload: userdata });
	}

	function logout() {
		dispatch({ type: 'USER_LOGGEDOUT' });
	}

	useEffect(() => {
		setCurrentUser({
			rol: getCurrentRole(),
			id: getCurrentId(),
			email: getCurrentEmail(),
			owner: getOwnerId(),
			photo: getCurrentPhoto(),
			name: getCurrentName(),
			lastName: getCurrentLastName(),
		});
	}, []);

	useEffect(() => {
		state.currentUser.rol === ROLES.USER && state.currentUser.id
			? userService
					.getProfile({ userId: state.currentUser.id })
					.then((userdata) => addUserdata(userdata))
			: null;
	}, [state.currentUser.id]);

	const mutateUserdata = () => {
		state.currentUser.rol === ROLES.USER && state.currentUser.id
			? userService
					.getProfile({ userId: state.currentUser.id })
					.then((userdata) => addUserdata(userdata))
			: null;
	};

	return (
		<UserContext.Provider
			value={{
				state,
				actions: { setCurrentUser, addUserdata, mutateUserdata, logout },
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export { UserProvider as Provider, UserContext as default };

UserProvider.propTypes = {
	children: PropTypes.object,
};
