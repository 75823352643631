/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import { Provider as UserProvider } from 'context/user';
import { Provider as PrintProvider } from 'context/print';
import PublicRoute from './routes/PublicRoute';
import Admin from 'routes/layouts/Admin/Admin';
import AuthLayout from './routes/layouts/Auth/Auth.js';
import { useLocation } from 'react-router-dom';
import { AUTH, ROLE, ROLES } from 'helpers/consts';
import { SWRConfig } from 'swr';
import Collaborator from './routes/layouts/Collaborator/Collaborator';
import CollaboratorRoute from './routes/CollaboratorRoute';
import AdminRoute from './routes/AdminRoute';

const Routes = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			{localStorage.getItem(AUTH) || sessionStorage.getItem(AUTH) ? (
				(localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE)) ===
				ROLES.USER ? (
					<AdminRoute component={Admin} />
				) : (localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE)) ===
				  ROLES.COLLABORATOR ? (
					<CollaboratorRoute component={Collaborator} />
				) : (
					<PublicRoute component={AuthLayout} />
				)
			) : (
				<PublicRoute component={AuthLayout} />
			)}
		</>
	);
};

const App = () => (
	<UserProvider>
		<SWRConfig
			value={{
				refreshInterval: 150000,
				errorRetryInterval: 150000,
				errorRetryCount: 1,
				// fetcher: (resource, init) => fetch(resource, init).then(res => res.json())
			}}
		>
			<BrowserRouter>
				<PrintProvider>
					<Switch>
						<Routes />
					</Switch>
				</PrintProvider>
			</BrowserRouter>
		</SWRConfig>
	</UserProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
