import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Row, NavbarBrand } from 'reactstrap';
import NotificationBell from './NotificationBell/NotificationBell';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import PropTypes from 'prop-types';
import BrandIcon from '../Icons/BrandIcon';

/**
 * Navbar for authenticated users
 * @param {string} status Payment status for user.
 * @returns {JSX.Element}
 * @constructor
 */
const AdminNavbar = ({ status }) => {
	return (
		<>
			<Navbar
				className='navbar-top navbar-dark d-md-block bg-primary-default'
				expand='md'
				id='navbar-main'
        style={{
          zIndex: 2
        }}
			>
				<Container
					className='h-100'
					fluid
				>
					<NavbarBrand
						to='/'
						tag={Link}
					>
						<BrandIcon theme='dark' />
					</NavbarBrand>
					<Row>
						{/* Notification Bell */}
						<NotificationBell />
						{/* /Notification Bell */}

						{/* Profile*/}
						<ProfileDropdown
							status={status}
							layout='admin'
						/>
						{/* /Profile */}
					</Row>
				</Container>
			</Navbar>
		</>
	);
};

export default AdminNavbar;

AdminNavbar.propTypes = {
	status: PropTypes.string,
};