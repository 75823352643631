/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import FeatherIcon from '../../Icons/FeatherIcon';
import MessageCircleIcon from '../../Icons/MessageCircleIcon';
import InfoHelpIcon from '../../Icons/InfoHelpIcon';
import FileTextIcon from '../../Icons/FileTextIcon';
import LockIcon from '../../Icons/LockIcon';
import LightningIcon from '../../Icons/LightningIcon';
import useBreakpoint from '../../../hooks/useBreakpoint';
import './Footer.css';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

const Footer = ({ layout }) => {
	const { breakpoint } = useBreakpoint();

	return (
		<>
			<footer id='authFooter'>
				<Container className='h-100 border-top'>
					<Col className='h-100 p-3'>
						<Row className='align-items-center justify-content-xl-between h-100'>
							{breakpoint === 'lg' ||
							breakpoint === 'xl' ||
							breakpoint === '2xl' ||
							breakpoint === '3xl' ? (
								<Col
									md='12'
									lg='9'
									xl='9'
								>
									<Nav className='nav-footer justify-content-around'>
										<NavItem className='p-0 p-md-2'>
											<NavLink
												tag={Link}
												to={`/${layout}/contacto`}
											>
												<Row className='align-items-center footer-link'>
													<MessageCircleIcon size={18} />
													<span className='font-weight-700 text-primary-default ml-1'>
														Contacto
													</span>
												</Row>
											</NavLink>
										</NavItem>
										<NavItem className='p-0 p-md-2'>
											<NavLink
												tag={Link}
												to={`/${layout}/ayuda`}
											>
												<Row className='align-items-center footer-link'>
													<InfoHelpIcon size={18} />
													<span className='font-weight-700 text-primary-default ml-1'>
														Ayuda
													</span>
												</Row>
											</NavLink>
										</NavItem>
										<NavItem className='p-0 p-md-2'>
											<NavLink
												tag={Link}
												to={`/${layout}/aviso-legal`}
											>
												<Row className='align-items-center footer-link'>
													<FeatherIcon size={18} />
													<span className='font-weight-700 text-primary-default ml-1'>
														Aviso legal
													</span>
												</Row>
											</NavLink>
										</NavItem>
										<NavItem className='p-0 p-md-2'>
											<NavLink
												tag={Link}
												to={`/${layout}/terminos-y-condiciones`}
											>
												<Row className='align-items-center footer-link'>
													<FileTextIcon size={18} />
													<span className='font-weight-700 text-primary-default ml-1'>
														Términos y condiciones
													</span>
												</Row>
											</NavLink>
										</NavItem>
										<NavItem className='p-0 p-md-2'>
											<NavLink
												tag={Link}
												to={`/${layout}/politica-de-privacidad`}
											>
												<Row className='align-items-center footer-link'>
													<LockIcon size={18} />
													<span className='font-weight-700 text-primary-default ml-1'>
														Política de privacidad
													</span>
												</Row>
											</NavLink>
										</NavItem>
									</Nav>
								</Col>
							) : null}
							<Col
								md='12'
								lg='3'
								className=''
							>
								<Row className='align-items-center justify-content-center justify-content-lg-end'>
									<span className='font-weight-700 text-secondary-default'>
										Notiexpress © 2021
									</span>
									<LightningIcon size={18} />
								</Row>
							</Col>
						</Row>
					</Col>
				</Container>
			</footer>
		</>
	);
};

Footer.propTypes = {
	layout: propTypes.string.isRequired,
};

export default Footer;
