import React from 'react';
import { create } from 'react-modal-promise';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import { Button, Col, ModalBody, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const NoResultCaseModal = ({ isOpen, onResolve, onReject }) => {
	return (
		<CustomModal
			title={'No se encontró el expediente'}
			isOpen={isOpen}
			toggle={() => onReject()}
			className='w-50'
		>
			<ModalBody>
				<Row className=''>
					<Col xs={12}>
						<h3 className='text-body-large'>
							No se encontró el expediente. Las razones pueden ser:
						</h3>
						<p className='text-body-large'>
							1 . El expediente aún no tiene novedades.
						</p>
            <p className='text-body-large'>
            2 . El expediente es muy antiguo o ya no existe.
						</p>
					</Col>
				</Row>
				<Row className='pt-2'>
					<Col>
						<Row className='m-0 align-items-center'>
							<Button
								className='btn btn-outline-noti-secondary py-1 px-5'
								onClick={() => onResolve()}
							>
								<span className=''>Aceptar</span>
							</Button>
						</Row>
					</Col>
				</Row>
			</ModalBody>
		</CustomModal>
	);
};

NoResultCaseModal.propTypes = {
	isOpen: PropTypes.bool,
	onResolve: PropTypes.func,
	onReject: PropTypes.func,
};

export const noResultCaseModal = create(NoResultCaseModal);
