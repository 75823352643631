import { API_NOTIF, API_EMAIL } from 'helpers/fetch';

function sendCaseDetailsEmailService(data) {
	return API_EMAIL.post('/sendDespachoHistorico', data);
}

function sendProvidenceFileEmailService(data) {
	return API_EMAIL.post('/sendProvidenceFiles', data);
}

export function sendExpedientesEmailService(data) {
	return API_EMAIL.post('/sendExpedientes', data);
}

export function sendCaducidadEmailService(data) {
	return API_EMAIL.post('/sendCaducidad', data);
}

export function sendNovedadesEmailService(data) {
	return API_EMAIL.post('/sendNovedades', data);
}

export function sendHelpEmailService(data) {
  return API_EMAIL.post('/sendHelpEmail', data);
}

function sendDaily(recipients = [], data) {
	const newsArray = data.map((item) => ({
		id: item.Expediente.id,
		casefileStatus: '',
		newsDate: item.Expediente.fechaUltimaActuacion,
		casefile: {
			number: item.Expediente.numero,
			label: item.Expediente.caratula,
			organism: item.Expediente.Organismo[0].nombre,
			jurisdiction: item.Expediente.Organismo[0].Jurisdiccion.nombre,
		},
	}));
	const payload = recipients.map((colab) => ({
		...colab,
		processNews: newsArray,
	}));

	return API_NOTIF.post('/notification/immediately/DAILY_NEWS', payload);
}

export function sendNewUserConfirmation(nombre, apellido, id, email) {
	return API_EMAIL.get(
		`/sendNewUser?userName=${nombre}&userLastName=${apellido}&userId=${id}&userEmail=${email}`
	);
}

export function sendRecoverPasswordEmail(email) {
	return API_EMAIL.get(`/sendNewPassword?email=${email}`);
}


export const emailService = {
	sendDaily,
	sendProvidenceFileEmailService,
	sendExpedientesEmailService,
	sendCaducidadEmailService,
	sendNovedadesEmailService,
	sendCaseDetailsEmailService,
  sendHelpEmailService,
};
