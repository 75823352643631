import { API_PAY } from '../helpers/fetch';
import useSWR from 'swr';
import moment from 'moment';
import { getOwnerId } from 'helpers/user';
import { PAYMENT_STATUS, SERVICES_STATUS } from 'helpers/consts';
import { subscriptionStatus } from '../helpers/services';

export const paymentsService = {
	useServicesTable,
	useUserServicesTable,
	subscribeToUser,
	useExpired,
	usePaymentStatus,
	createPayment,
	getFinalAmount,
	getUserPayments,
};

function fetcher(url) {
	return API_PAY.get(url);
}

function useUserServicesTable() {
	const { data, error, mutate } = useSWR(
		`/userservice/${getOwnerId()}`,
		fetcher,
		{
			errorRetryCount: 5,
		}
	);
	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

/**
 *
 * @param {Array<any>} services
 * @param {string?} voucher
 * @returns {Promise<AxiosResponse<any>>}
 */
function getFinalAmount(services, voucher) {
	let servicesId = '';
	services.map((service) => (servicesId += `${service._id},`));
	servicesId = servicesId.slice(0, servicesId.length - 1);
	let params = {
		userId: getOwnerId(),
		servicesId,
	};
	if (voucher) {
		params = { ...params, voucherId: voucher };
	}
	return API_PAY.get('/payment/finalAmount', {
		params: params,
	});
}

/**
 * Get all user payments between startDate and endDate.
 * @param {string} startDate Date in format YYYY-MM-DD
 * @param {string} endDate Date in format YYYY-MM-DD
 * @returns {Object}
 */
function getUserPayments(startDate, endDate) {
	const userId = getOwnerId();
	const { data, error, mutate } = useSWR(
		`/payment/report/${userId}/${startDate}/${endDate}`,
		fetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
	};
}

function useServicesTable() {
	const { data, error, mutate } = useSWR('/service', fetcher);
	const filteredData = data?.filter(({ deletedAt }) => !deletedAt);
	return {
		data: filteredData,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
}

function subscribeToUser(serviceId) {
	return API_PAY.post('/userservice', { serviceId, userId: getOwnerId() });
}

function createPayment(body) {
	return API_PAY.post('/payment', body);
}

function useExpired() {
	const { data, error } = useUserServicesTable();

	if (error) {
		return new Error();
	}
	if (data?.services?.length === 0) {
		return null;
	}
	const expired = data?.services
		?.filter((item) => !moment().isBefore(item.fechaVencimiento))
		.map((item) => item.service.nombre);

	return expired?.length > 0 ? expired : null;
}

function usePaymentStatus() {
	const { data, isLoading, isError } = useUserServicesTable();

	if (isLoading) {
		return PAYMENT_STATUS.ACTIVE;
	}
	if (isError) {
		return new Error();
	}
	if (data?.services?.length === 0) {
		return PAYMENT_STATUS.NEW_USER;
	}
	const expired = data?.services?.every(
		(userService) => subscriptionStatus(userService) === SERVICES_STATUS.EXPIRED
	);
	if (expired) {
		return PAYMENT_STATUS.EXPIRED;
	}
	return PAYMENT_STATUS.ACTIVE;
}
