import React, { useContext, useEffect } from 'react';
import { SynchronizeContext } from '../../context/synchronize';
import IndeterminateStatusBar from '../IndeterminateStatusBar/IndeterminateStatusBar';
import { SYNC_STATUS } from '../../helpers/consts';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as SyncIcon } from '../../assets/img/icons/notiexpress/sync-icon.svg';
import admin from 'context/admin';
import CustomMessage from 'components/CustomMessage/CustomMessage';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';

const HiddenSynchronization = ({ jurisdiction, expId, mutate, hasData, providenceDate }) => {
	const {
		state: { status, message },
		actions: { syncExpedient, syncProvidence },
	} = useContext(SynchronizeContext);

	const handleOnClick = async () => {
		if (providenceDate) {
			await syncProvidence({ jurisdiction, expId, providenceDate });
		} else {
			await syncExpedient({ jurisdiction, expId });
		}
		mutate();
	};

	const {
		state: { activeJurisdictions },
	} = useContext(admin);

	useEffect(() => {
		const synchronize = async () => {
			if (hasData) return;
			if (status !== SYNC_STATUS.START) return;
			if (!jurisdiction || !expId) return;
			if (providenceDate) {
				await syncProvidence({ jurisdiction, expId, providenceDate });
			} else {
				await syncExpedient({ jurisdiction, expId });
			}
		};
		synchronize().then(() => {
			mutate();
		});
	}, []);

	return status === SYNC_STATUS.SYNCING ? (
		<Col className='m-0'>
			<span className='text-body-large'>{message}</span>
			<IndeterminateStatusBar />
		</Col>
	) : status === SYNC_STATUS.ERROR && !hasData ? (
		<Col className='align-items-center d-flex'>
			<Button
				className='btn btn-noti-sync py-2 px-4'
				disabled={!activeJurisdictions?.length}
				onClick={handleOnClick}
			>
				<SyncIcon className='fill-secondary-default stroke' />
				<span className='ml-3'>Sincronizar</span>
			</Button>
			<CustomMessage
				message={message}
				icon={
					<CloseIcon
						width={18}
						height={18}
						className='stroke-error'
					/>
				}
				className='m-0'
			/>
		</Col>
	) : status === SYNC_STATUS.SUCCESS ? (
		<CustomMessage
			message={message}
			icon={
				<CheckIcon
					width={18}
					height={18}
					className='stroke-success'
				/>
			}
			className='m-0'
		/>
	) : status === SYNC_STATUS.CAPTCHA ? (
		<Col className='m-0'>
			<span className='text-body-large'>{message}</span>
			<IndeterminateStatusBar />
		</Col>
	) : null;
};

HiddenSynchronization.propTypes = {
	jurisdiction: PropTypes.string,
	expId: PropTypes.string,
	mutate: PropTypes.func,
	hasData: PropTypes.bool,
	providenceDate: PropTypes.string,
};

export default HiddenSynchronization;
