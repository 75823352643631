import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCurrentId } from 'helpers/user';
import { paymentsService } from '../services/paymentsService';

const initialState = {
	payment: {
		user: getCurrentId(),
		voucher: '',
		services: [],
		card: {
			token: '',
			email: '',
			description: '',
			installments: null,
			payment_method_id: '',
			transaction_amount: null,
		},
	},
};

const CartContext = createContext(initialState);

const CartProvider = ({ children }) => {
	const [cart, setCart] = useState(initialState.payment.services);
	const [validVoucher, setValidVoucher] = useState(null);
	const [allServicesAmount, setAllServicesAmount] = useState(0);
	const [hasPayed, setHasPayed] = useState(false);
	const [totalAmount, setTotalAmount] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);

	function resetCart() {
		setCart(initialState.payment.services);
		setValidVoucher(null);
		setTotalAmount(0);
		setHasPayed(false);
	}

	const addToCart = (service) => {
		if (cart.some((item) => item._id === service._id)) {
			return;
		}
		setCart([...cart, service]);
	};

	useEffect(() => {
		const serviceAmountResponse = async () => {
			if (cart?.length) {
				const { serviceAmount, voucherDiscount, finalAmount } =
					await paymentsService.getFinalAmount(cart, validVoucher?.publicId);
				setAllServicesAmount(serviceAmount);
				setTotalAmount(finalAmount);
				setDiscountAmount(voucherDiscount);
			} else {
				setAllServicesAmount(0);
				setTotalAmount(0);
				setDiscountAmount(0);
			}
		};
		serviceAmountResponse();
	}, [cart, validVoucher]);

	return (
		<CartContext.Provider
			value={{
				cart,
				allServicesAmount,
				setCart,
				validVoucher,
				setValidVoucher,
				totalAmount,
				setTotalAmount,
				discountAmount,
				userId: initialState.payment.user,
				hasPayed,
				setHasPayed,
				resetCart,
				addToCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export { CartProvider as Provider, CartContext as default };

CartProvider.propTypes = {
	children: PropTypes.object,
};
