/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// reactstrap components
import { NavItem, Nav, Container, Row, Col, Button } from 'reactstrap';
import FeatherIcon from '../../../../components/Icons/FeatherIcon';
import FileTextIcon from '../../../../components/Icons/FileTextIcon';
import LockIcon from '../../../../components/Icons/LockIcon';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import './Footer.css';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import CustomModal from '../../../../components/Modals/CustomModal/CustomModal';
import LegalDisclaimerContent from '../../../LegalDisclaimer/LegalDisclaimerContent';
import TermsAndConditionsContent from '../../../Terms&Conditions/TermsAndConditionsContent';
import PrivacyPolicyContent from '../../../PrivacyPolicy/PrivacyPolicyContent';
import LightningIcon from '../../../../components/Icons/LightningIcon';

const Footer = () => {
	const { breakpoint } = useBreakpoint();

	//Modals
	const [showLegalDisclaimerModal, setShowLegalDisclaimerModal] =
		useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);

	//Modals handlers
	const handleToggleLegalDisclaimerModal = () =>
		setShowLegalDisclaimerModal(!showLegalDisclaimerModal);
	const handleToggleTermsModal = () => setShowTermsModal(!showTermsModal);
	const handleTogglePrivacyModal = () => setShowPrivacyModal(!showPrivacyModal);

	return (
		<>
			<footer id='authFooter'>
				<Container
					className='h-100 border-top'
					fluid
				>
					<Row
						className='align-items-center justify-content-xl-between h-100'
						sm={9}
					>
						<Col sm={8}>
							<Nav className='nav-footer justify-content-center'>
								<NavItem className='p-0 p-md-2'>
									<Button
										tag={Link}
										onClick={(e) => {
											e.preventDefault();
											handleToggleLegalDisclaimerModal();
										}}
										className='mx-3 landing-button'
									>
										<Row className='align-items-center footer-link'>
											<FeatherIcon size={18} />
											<span className='font-weight-700 text-primary-default ml-1'>
												Aviso legal
											</span>
										</Row>
									</Button>
								</NavItem>
								<NavItem className='p-0 p-md-2'>
									<Button
										tag={Link}
										onClick={(e) => {
											e.preventDefault();
											handleToggleTermsModal();
										}}
										className='mx-3 landing-button'
									>
										<Row className='align-items-center footer-link'>
											<FileTextIcon size={18} />
											<span className='font-weight-700 text-primary-default ml-1'>
												Términos y condiciones
											</span>
										</Row>
									</Button>
								</NavItem>
								<NavItem className='p-0 p-md-2'>
									<Button
										tag={Link}
										onClick={(e) => {
											e.preventDefault();
											handleTogglePrivacyModal();
										}}
										className='mx-3 landing-button'
									>
										<Row className='align-items-center footer-link'>
											<LockIcon size={18} />
											<span className='font-weight-700 text-primary-default ml-1'>
												Política de privacidad
											</span>
										</Row>
									</Button>
								</NavItem>
							</Nav>
							<CustomModal
								title={'Términos y Condiciones'}
								isOpen={showTermsModal}
								toggle={handleToggleTermsModal}
							>
								<div className='p-2 mx-5 mb-5 '>
									<TermsAndConditionsContent />
								</div>
							</CustomModal>
							<CustomModal
								title={'Políticas de Privacidad'}
								isOpen={showPrivacyModal}
								toggle={handleTogglePrivacyModal}
							>
								<div className='p-2 mx-5 mb-5 '>
									<PrivacyPolicyContent />
								</div>
							</CustomModal>
							<CustomModal
								title={'Aviso Legal'}
								isOpen={showLegalDisclaimerModal}
								toggle={handleToggleLegalDisclaimerModal}
							>
								<div className='p-2 mx-5 mb-5 '>
									<LegalDisclaimerContent />
								</div>
							</CustomModal>
						</Col>
						<Col
							sm={4}
							className='justify-content-center d-flex flex-columns'
						>
							<span className='font-weight-700 text-secondary-default'>
								Notiexpress © 2021
							</span>
							<LightningIcon size='18' />
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

Footer.propTypes = {
	layout: propTypes.string,
};

export default Footer;
