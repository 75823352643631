import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Input, Row } from 'reactstrap';
import './InfoMessage.scss';
import CrossIcon from '../Icons/CrossIcon';
import { Link } from 'react-router-dom';

const InfoMessage = ({
	title,
	message,
	messageKey,
	to,
	icon,
	textButton,
	highlight,
	iconButton,
  isHomePage = false,
}) => {
	const [show, setShow] = useState(
		window.localStorage.getItem(messageKey) !== 'false' && window.sessionStorage.getItem(messageKey) !== 'false'
	);
	const [dontShowAgain, setDontShowAgain] = useState(false);

	const close = () => {
    window.sessionStorage.setItem(messageKey, 'false');
		setShow(false);
	};
	const closeAndDontShowAgain = () => {
		window.localStorage.setItem(messageKey, 'false');
		setShow(false);
	};

	const handleOnClose = () => {
		if (dontShowAgain) {
			closeAndDontShowAgain();
		} else {
			close();
		}
	};
	return show ? (
		<Card className={isHomePage ? 'mt-3 shadow-card border-info' : 'mb-3 shadow-card border-info'}>
			<Row className='justify-content-between p-5 px-3 px-lg-7 d-flex align-items-center'>
				<Col>
					<Row className='m-0 d-flex align-items-center'>
						<Col>
							<h3 className='text-body-large-bold text-secondary-default'>
								<strong className='text-caps-regular text-info'>
									{highlight}
								</strong>
								{title}
							</h3>
							{message ? (
								<h5 className='text-body-regular text-primary-default'>
									{message}
								</h5>
							) : null}
							<div className='custom-control custom-control-alternative custom-checkbox'>
								<Input
									className='custom-control-input'
									id='customCheckRegister'
									type='checkbox'
									checked={dontShowAgain}
									onChange={() => setDontShowAgain(!dontShowAgain)}
								/>
								<label
									className='custom-control-label'
									htmlFor='customCheckRegister'
								>
									<span className='text-body-regular text-primary-default m-0'>
										No mostrar más este mensaje.
									</span>
								</label>
							</div>
						</Col>
						{to ? (
							<Col
								className='p-1'
								lg={iconButton ? 3 : 0}
							>
								<Row className='justify-content-center'>
									<Button
										className={
											iconButton
												? 'btn btn-noti-icon-primary px-2'
												: 'btn btn-outline-noti-secondary py-2 px-5'
										}
										tag={Link}
										to={to}
									>
										<Row className='align-items-center m-0'>
											{icon ? icon : null}
											{textButton ? (
												<span className=''>{textButton}</span>
											) : null}
										</Row>
									</Button>
								</Row>
							</Col>
						) : null}
					</Row>
				</Col>
				<span
					onClick={handleOnClose}
					style={{ cursor: 'pointer' }}
				>
					<CrossIcon
						size={24}
						className='stroke stroke-primary-default'
					/>
				</span>
			</Row>
		</Card>
	) : null;
};

InfoMessage.propTypes = {
	title: PropTypes.string.isRequired,
	messageKey: PropTypes.string.isRequired,
	message: PropTypes.string,
	to: PropTypes.string,
	icon: PropTypes.element,
	textButton: PropTypes.string,
	highlight: PropTypes.string,
	iconButton: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

export default InfoMessage;
