import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import usePDF from 'hooks/usePDF';
import tableHeading from 'components/Tables/TablesHeadings';
import moment from 'moment';
import 'moment/locale/es';
import { casesService } from 'services/casesService';

const initialState = {
	pdfSettings: {},
	caseId: null,
	modalIsOpen: false,
	download: false,
};

const PrintContext = createContext(initialState);

const reducer = (state, action) => {
	switch (action.type) {
		case 'ABRIR_MODAL':
			return {
				...state,
				modalIsOpen: true,
				download: false,
			};
		case 'CERRAR_MODAL':
			return {
				...state,
				modalIsOpen: false,
				download: false,
			};
		case 'IMPRIMIR_EXPEDIENTES':
			return {
				...state,
				pdfSettings: action.payload.pdfSettings,
				download: false,
			};
		case 'IMPRIMIR_RECIBO':
			return {
				...state,
				pdfSettings: action.payload.pdfSettings,
				download: false,
			};
		case 'PREPARAR_EXPEDIENTE_INDIVIDUAL':
			return {
				...state,
				caseId: action.payload.caseId,
				download: false,
			};
		case 'DESCARGAR_TABLA':
			return {
				...state,
				pdfSettings: action.payload.pdfSettings,
				download: true,
			};
		case 'FIN_DESCARGA':
			return {
				...state,
				download: false,
			};

		default:
			return state;
	}
};

const PrintProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	function openModal() {
		dispatch({ type: 'ABRIR_MODAL' });
	}

	function closeModal() {
		dispatch({ type: 'CERRAR_MODAL' });
	}

	function imprimirExpedientes(pdfSettings) {
		dispatch({ type: 'IMPRIMIR_EXPEDIENTES', payload: { pdfSettings } });
		openModal();
	}

	function imprimirRecibo(pdfSettings) {
		dispatch({ type: 'IMPRIMIR_RECIBO', payload: { pdfSettings } });
		openModal();
	}

	async function downloadTableExpedientes(pdfSettings) {
		dispatch({ type: 'DESCARGAR_TABLA', payload: { pdfSettings } });
	}

	async function imprimirExpediente(caseId) {
		const response = await casesService.getCaseDetails(caseId);
		const caseDetails = response?.Expediente;
		const providences = response?.Expediente?.Providences;

		imprimirExpedientes({
			head: [tableHeading.expediente.main],
			details: {
				head: [tableHeading.expediente.details],
				body: caseDetails?.id
					? [
							[
								`${caseDetails?.number}/${caseDetails?.year}`,
								caseDetails?.coverPage,
								caseDetails?.OrganismList[0]?.Organism?.Circumscription.name ?? 'Sin Datos',
								caseDetails?.OrganismList[0]?.Organism?.name ?? 'Sin Datos',
								moment.utc(caseDetails?.lastActuationDate).format('DD/MM/YYYY'),
							],
					  ]
					: [],
			},
			body: [
				providences?.map((item) => [
					moment(item?.actuationDate).format('DD/MM/YYYY'),
					item?.extract,
					caseDetails?.OrganismList[0]?.Organism?.name,
				]),
			],
			title: 'Detalle de expediente',
			fileName: `expediente-${caseDetails?.number}-${caseDetails?.year}`,
		});
	}

	const [previewUri, downloadPDF] = usePDF(state.pdfSettings);

	useEffect(() => {
		if (state.download) {
			downloadPDF(`${state.pdfSettings.fileName || 'notiexpress'}.pdf`);
			dispatch({ type: 'FIN_DESCARGA' });
		}
	}, [state.download]);

	return (
		<PrintContext.Provider
			value={{
				state,
				actions: {
					openModal,
					closeModal,
					imprimirExpedientes,
					imprimirRecibo,
					imprimirExpediente,
					downloadTableExpedientes,
				},
			}}
		>
			{children}
			<Modal
				className='modal-dialog-centered'
				isOpen={state.modalIsOpen}
				toggle={closeModal}
				size='lg'
			>
				<div className='modal-header'>
					<button
						aria-label='Close'
						className='close'
						data-dismiss='modal'
						type='button'
						onClick={closeModal}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className='modal-body'>
					<embed
						width='100%'
						height='500px'
						src={previewUri}
					/>
				</div>
				<div className='modal-footer'>
					<Button
						color='secondary'
						data-dismiss='modal'
						type='button'
						onClick={closeModal}
					>
						Cerrar
					</Button>
				</div>
			</Modal>
		</PrintContext.Provider>
	);
};

export { PrintProvider as Provider, PrintContext as default };

PrintProvider.propTypes = {
	children: PropTypes.object,
};
