import { SERVICES_STATUS } from './consts';
import moment from 'moment';
import toArs from './toArs';

export const subscriptionStatus = (userService) => {
	// First check if subscription is expired.
	return moment().isBefore(userService.fechaVencimiento)
		? // Check if service is going to expire soon
		  moment(userService.fechaVencimiento)
				.subtract(5, 'days')
				.isBefore(moment())
			? SERVICES_STATUS.EXPIRED_SOON
			: SERVICES_STATUS.ACTIVE
		: SERVICES_STATUS.EXPIRED;
};

export const amountToPay = (userService) => {
	// Then check if is a test period or active.
	return userService.isTestPeriod
		? 'Periodo de prueba'
		: toArs(userService.service.precio) + ' ARS';
};
