import React from 'react';
import { CardBody } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import global from 'context/global';

function PrivacyPolicyContent() {
/*   const {
		state: { jurisdictions },
	} = React.useContext(global) */;

	return (
		<CardBody>
			<h1 className='text-heading-3 text-secondary-default'>
				ÚLTIMA ACTUALIZACIÓN: 23/06/2023
			</h1>
			<p className='text-body-regular'>
				En Notiexpress tratamos a la protección de la información personal como
				una oportunidad para generar valor para nuestros usuarios. Haciendo un
				uso responsable de esta, no sólo protegemos la privacidad de quienes nos
				confiaron sus datos, sino que les permitimos operar con seguridad,
				integridad y confianza en nuestra plataforma y página web oficial:{' '}
				<Link to='https://notiexpress.ar'>https://notiexpress.ar</Link>.
			</p>
			<p className='text-body-regular'>
				Por eso, tu privacidad es muy importante para nosotros y nos esforzamos
				para protegerla diariamente.
			</p>
			<p className='text-body-regular'>
				Esta Política de Privacidad forma parte de los{' '}
				<Link to='terminos-y-condiciones'>Términos y Condiciones</Link> y del{' '}
				<Link to='aviso-legal'>Aviso legal</Link> de Notiexpress. Prestar tu
				consentimiento voluntario, expreso e informado es un requisito esencial
				para poder contratar y/o tener cualquier tipo de relación con
				Notiexpress en la Argentina.
			</p>
			<ol className='text-heading-3 text-primary-default'>
				<li>
					Tratamiento de la información personal de los usuarios o potenciales
				</li>
				<p className='text-body-regular'>
					Al momento de registrarte o al utilizar alguno de los servicios que
					ofrece Notiexpress, estás prestando tu consentimiento para que se
					tenga acceso limitado a la información contenida en las bases de datos
					donde tenés matrícula profesional en Derecho vigente y sea almacenada
					provisoriamente en la plataforma.
				</p>
				<p className='text-body-regular'>
					Esta información será usada únicamente para facilitarte el
					seguimiento, administración y procuración de tus expedientes
					judiciales y brindarte una serie de herramientas que permitan generar
					un mayor control sobre los mismos, ahorrándote en el proceso, tu
					valioso tiempo.
				</p>
				<p className='text-body-regular'>
					Es importante mencionar que la plataforma no modifica y/o altera el
					contenido de la información ingresada a su base de datos ni tampoco la
					que se encuentra en las bases de datos de donde se obtiene la misma,
					siendo estas últimas, plataformas estatales oficiales.
				</p>
				<p className='text-body-regular'>
					Los datos que nos proporciones son requisitos mínimos, necesarios y
					esenciales para poder contratar y/o tener cualquier tipo de relación
					con Notiexpress y acceso a los servicios que ofrece. Si no nos
					proporcionás esta información, no podrás disfrutar de nuestros
					servicios o nuestra capacidad para hacerlo puede verse
					significativamente obstaculizada y/o reducida.
				</p>
				<p className='text-body-regular'>
					La inexactitud o falsedad de los datos personales que proporcionés
					podría causar la suspensión o extinción de los servicios que te ofrece
					Notiexpress. Asimismo, la plataforma podrá suspender o inhabilitar
					definitivamente a aquellos usuarios que violen esta cláusula de
					Privacidad.
				</p>
				<p className='text-heading-3 text-primary-default'>
					1.1. ¿Qué información tuya recolectamos y en qué momento?
				</p>
				<p className='text-body-regular'>
					La recolección y tratamiento de tu información personal nos permite
					prestarte un excelente servicio de forma rápida, segura y ofrecerte
					funcionalidades que se adaptan mejor a tus necesidades. Esta es la
					información personal que podríamos recolectar:
				</p>
				<ol
					type='A'
					className='text-body-regular-bold text-secondary-default'
				>
					<li>Al registrarte o utilizar nuestros servicios:</li>
					<ol className='text-body-regular'>
						<li>Nombre y apellido completo.</li>
						<li>Imagen personal (foto personal o foto del documento).</li>
						<li>
							Información de contacto (como dirección de e-mail, número de
							teléfono. domicilio, provincia, ciudad, código postal, nombre y
							dirección de tu estudio u oficina).
						</li>
						<li>
							Nombre de usuario y contraseña del sistema del Poder Judicial al
							cual el profesional de derecho se encuentra registrado y donde se
							encuentran los expedientes judiciales a su cargo y/o requiera
							observar.
						</li>
						<li>
							Listado de expedientes judiciales que el usuario tiene a su cargo
							y/o requiera observar.
						</li>
						<li>
							Listado de las novedades de los expedientes judiciales del usuario
							posteriores a la sincronización inicial.
						</li>
						<li>
							Matrícula profesional en Derecho vigente en la Justicia Ordinaria y/o Justicia Nacional.{/* {jurisdictions.filter(jurisdiction => jurisdiction.name === 'Federal').length > 0 ? ' y/o Justicia Nacional.' : '.'} */}
							
						</li>
					</ol>
					<li>
						Al incluir información en el formulario de contacto de nuestra
						página web:
					</li>
					<ol
						start='9'
						className='text-body-regular'
					>
						<li>Nombre y apellido.</li>
						<li>E-mail.</li>
					</ol>
				</ol>
				<br />
				<p className='text-body-regular'>
					El usuario garantiza que los datos personales facilitados a
					Notiexpress son veraces y se hace responsable de comunicar a aquél
					cualquier modificación de estos.
					<br />
					La aceptación del usuario de que sus datos sean tratados con los fines
					referidos en esta Política de Privacidad tiene siempre carácter
					revocable, sin efectos retroactivos; por lo tanto, tiene derecho a
					retirar su consentimiento en cualquier momento, sin que ello afecte la
					licitud del tratamiento basado en su consentimiento previo.
				</p>
				<p className='text-heading-3 text-primary-default'>
					Mayoría de edad y capacidad legal
				</p>
				<p className='text-body-regular'>
					Para ser usuario, se te requerirá cumplir con lo dispuesto en esta
					Política de Privacidad. Es decir:
				</p>
				<ol
					type='A'
					className='text-body-regular'
				>
					<li>Ser mayor de edad conforme la normativa vigente, y;</li>
					<li>
						Poseer matrícula en Derecho vigente de acuerdo a lo dispuesto por el
						Aviso legal y los{' '}
						<Link to='terminos-y-condiciones'>Términos y Condiciones</Link> de
						la plataforma y/o estar autorizado expresamente por esta persona
						para hacer uso de la plataforma.
					</li>
				</ol>
				<li>
					¿Para que utilizará Notiexpress la información?
					<p className='text-body-regular'>
						Salvo en aquellos casos en los que la normativa aplicable lo
						prohíba, Notiexpress podrá utilizar tu información personal para las
						siguientes finalidades:
					</p>
					<ol
						type='A'
						className='text-body-regular-bold text-secondary-default'
					>
						<li>
							Brindar los productos, servicios y/o beneficios que ofrece la
							plataforma de Notiexpress y/o variantes.
							<ol className='text-body-regular'>
								<li>Identificarte y contactarte.</li>
								<li>Registrarte en nuestros sistemas.</li>
								<li>
									Verificar tu identidad en cumplimiento de exigencias legales.
								</li>
								<li>Validar, actualizar y corregir tu información.</li>
								<li>
									Brindarte los productos, servicios y/o beneficios que
									solicitás o contratás con nosotros.
								</li>
								<li>
									Atender tus comentarios, quejas y sugerencias, así como
									brindarte soporte.
								</li>
								<li>
									Brindarte publicidad personalizada de todos nuestros
									productos.
								</li>
							</ol>
						</li>
						<li>
							Detectar y prevenir fraudes, abusos y delitos relacionados para
							proteger la seguridad de nuestros usuarios y la sustentabilidad de
							las plataformas.
							<ol className='text-body-regular'>
								<li>
									Mejorar la seguridad de la plataforma para la de detección y
									prevención de delitos de fraude, suplantación de identidad,
									informáticos y relacionados.
								</li>
							</ol>
						</li>
						<li>
							Proteger derechos de usuarios, de terceros o propios de
							Notiexpress.
							<ol className='text-body-regular'>
								<li>
									Defender los derechos, activos tangibles e intangibles de
									Notiexpress.
								</li>
								<li>
									Hacer valer los derechos de Notiexpress ante incumplimientos
									de sus{' '}
									<Link to='terminos-y-condiciones'>
										Términos y Condiciones
									</Link>
									.
								</li>
								<li>
									Permitir que otros usuarios o terceros puedan hacer valer sus
									derechos.
								</li>
								<li>
									Contar con todos los permisos, contratos y licencias
									necesarias para el correcto funcionamiento de Notiexpress.
								</li>
							</ol>
						</li>
						<li>
							Colaborar con los titulares de propiedad intelectual y/o
							industrial en el ejercicio de sus derechos.
							<ol className='text-body-regular'>
								<li>
									Colaborar con la protección de los derechos de propiedad
									intelectual y/o industrial, propios o de terceros,
									investigaciones de violación de marcas y patentes.
								</li>
								<li>
									Evitar violaciones en materia de propiedad intelectual y/o
									industrial que puedan ocurrir en la plataforma de Notiexpress.
								</li>
								<li>
									Colaborar con las entidades que intervengan en la resolución
									de disputas.
								</li>
								<li>
									Colaborar con entidades privadas que representen intereses
									colectivos con acuerdo de colaboración, para facilitar la
									resolución de las disputas.
								</li>
							</ol>
						</li>
						<li>
							Efectuar anuncios publicitarios y promocionales.
							<ol className='text-body-regular'>
								<li>
									Contactarte a través de los canales informados oportunamente
									al momento de registrarte para finalidades publicitarias y/o
									promocionales de productos y servicios de Notiexpress, como de
									sus colaboradores.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					¿Compartimos información con terceros?
					<p className='text-body-regular'>
						El resguardo de tu privacidad es muy importante para Notiexpress.
						Por ello, no vendemos ni comercializamos información. Tampoco
						compartimos o transferimos de ningún otro modo tu información
						personal a terceros, salvo de la manera indicada a continuación:
					</p>
					<p className='text-body-regular'>
						Notiexpress podrá ceder, transmitir y/o transferir tu información
						personal a:
					</p>
					<ol className='text-body-regular'>
						<li>
							Otros usuarios de la plataforma y/o los servicios, entidades o
							terceros cuando haya motivos suficientes para considerar que tu
							actividad sea sospechosa de intentar o cometer un delito o
							intentar perjudicar a otras personas, siempre que haya un
							requerimiento administrativo y/o judicial previo.
						</li>
						<li>
							Terceros que suministren soporte o de alguna manera sean parte de
							los servicios que ofrece Notiexpress y cuente con un contrato
							legalmente operativo.
						</li>
						<li>
							Autoridades judiciales, sin requerir tu consentimiento, cuando sea
							requerido por una orden judicial y/o administrativa emitida por el
							organismo competente.
						</li>
					</ol>
					<br />
					<p className='text-body-regular'>
						Notiexpress no será responsable por el uso indebido de tu
						información personal que hagan terceros cuando sean estos quienes
						recolecten y/o traten directamente tu información personal dentro de
						nuestra plataforma.
					</p>
				</li>
				<li>
					Seguridad. Almacenamiento provisoria de la información
					<p className='text-body-regular'>
						Notiexpress cumple con la normativa aplicable y ha adoptado las
						medidas de seguridad recomendadas según los estándares
						internacionales de la industria para proteger tu información
						personal y la que nos brindes de manera provisoria.
					</p>
					<p className='text-body-regular'>
						En la medida en que Notiexpress haya cumplido con las normas
						respectivas y adoptado las medidas mencionadas en{' '}
						<Link to='terminos-y-condiciones'>Términos y Condiciones</Link>, no
						se hace responsable por interceptaciones ilegales o violaciones de
						sus sistemas o bases de datos, ni por la modificación y/o alteración
						de la información inicialmente captada de su fuente original ni por
						su utilización por parte de personas no autorizadas expresamente.
					</p>
				</li>
				<li>
					Derecho a la información personal
					<p className='text-body-regular'>
						Sin perjuicio de lo indicado en esta Política de Privacidad, en
						cumplimiento de lo dispuesto por las disposiciones de la Ley N°
						25.326, el Decreto Reglamentario N° 1558/2001 y las disposiciones
						y/o resoluciones vinculantes emitidas por la Agencia de Acceso a la
						Información Pública se comunica que: &ldquo;el titular de los datos
						personales tiene la facultad de ejercer el derecho de acceso a los
						mismos en forma gratuita a intervalos no inferiores a seis meses,
						salvo que se acredite un interés legítimo al efecto conforme lo
						establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. LA
						AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano
						de Control de la Ley N° 25.326, tiene la atribución de atender las
						denuncias y reclamos que interpongan quienes resulten afectados en
						sus derechos por incumplimiento de las normas vigentes en materia de
						protección de datos personales”. En este aspecto, Notiexpress,
						indica que el responsable de los datos, el usuario, podrá en
						cualquier momento solicitar el retiro o bloqueo de su nombre de los
						bancos de datos que tiene Notiexpress a los que se refiere el
						presente artículo a través del e-mail{' '}
						<a href='mailto:contacto@notiexpress.net'>
							contacto@notiexpress.net
						</a>
						. <br />
						En toda comunicación con fines de publicidad que se realice por
						correo, teléfono, e-mail, Internet u otro medio a distancia a
						conocer por parte de Notiexpress, el usuario deberá indicar, en
						forma expresa y destacada, si desea solicitar el retiro o bloqueo,
						total o parcial, de su nombre y datos de la base de datos. A pedido
						del interesado, se deberá informar el nombre del responsable o
						usuario del banco de datos que proveyó la información&rdquo;.
					</p>
					<p className='text-body-regular'>
						Podrás hacer consultas y/o peticiones relativas a tu información
						personal desde la sección de “Contacto” disponible en la plataforma
						o bien a través del e-mail contacto@notiexpress.net indicando tu
						nombre y apellido completo, adjuntando un documento que acredite tu
						identidad, en el asunto si se trata de una petición o consulta y
						escribiendo en el cuerpo del mensaje tu requerimiento específico.
					</p>
					<p className='text-body-regular'>
						Asimismo, como usuario tenés derecho a presentar un reclamo ante la
						Autoridad de Control competente, la Agencia de Acceso a la
						Información Pública (
						<a
							href='http://www.argentina.gob.ar'
							target='_blank'
							rel='noreferrer noopener'
						>
							http://www.argentina.gob.ar
						</a>
						) considerás que el tratamiento de datos personales que te concierne
						infringe lo dispuesto en por la legislación y normativa de
						protección de datos vigente; todo ello, sin perjuicio de cualquier
						otro recurso administrativo o acción judicial.
					</p>
				</li>
				<li>
					Cambios en la Política de Privacidad
					<p className='text-body-regular'>
						Notiexpress realizará actualizaciones periódicas de las cláusulas
						contenidas en esta Política de Privacidad y de todos los textos
						legales de Notiexpress para reflejar los cambios constantes en los
						servicios que se ofrecen. Estas actualizaciones reflejarán de manera
						transparente, fácil y accesible la forma en que la información
						personal es tratada.
					</p>
					<p className='text-body-regular'>
						Te notificaremos de estos cambios al e-mail que nos has suministrado
						al momento de tu registro, siempre cuando dichas modificaciones
						afecten directamente los servicios que has contratado con nosotros.
						En los casos que corresponda, te solicitaremos nuevamente tu
						consentimiento y lo podrás revocar de manera fácil como lo
						explicamos en el <Link to='aviso-legal'>Aviso legal</Link>.
					</p>
				</li>
				<li>
					Ley Aplicable y Jurisdicción
					<p className='text-body-regular'>
						La presente Política de Privacidad se rige por la ley argentina.
						Toda controversia derivada de su aplicación, interpretación,
						ejecución o validez será resuelta por los tribunales ordinarios
						competentes, con asiento en la Ciudad de Resistencia, Provincia del
						Chaco, salvo disposición específica de normas de Orden Público, como
						por ejemplo, legislación relativa al Consumidor. Para todos los
						efectos relacionados con estos Términos y Condiciones y con el uso
						de la plataforma, Notiexpress establece como domicilio Hipólito
						Irigoyen 352, Ciudad de Resistencia, Provincia del Chaco, Argentina.
					</p>
				</li>
				<li>Relación con los demás textos legales</li>
				<p className='text-body-regular'>
					Todos los textos legales de Notiexpress se encuentran integrados en la
					misma plataforma. Podés visualizarlos en. cualquier momento ingresando
					en <Link to='aviso-legal'>Aviso legal</Link> y{' '}
					<Link to='terminos-y-condiciones'>Términos y Condiciones</Link>.
				</p>
			</ol>
		</CardBody>
	);
}

export default PrivacyPolicyContent;
