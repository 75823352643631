const ID = 'id';
const OWNER = 'ownerId';
const ROLE = 'rol';
const EMAIL = 'email';
const PHOTO = 'photo';
const AUTH = 'authdata';
const NAME = 'name';
const LAST_NAME = 'lastName';
const COLLABORATOR_LIMIT_MESSAGE = 'Group does not accept any more collaborators';

const CONNECTION_LIMIT_MESSAGE = 'The connection already exists';

const ROLES = {
	COLLABORATOR: 'Colaborador',
	USER: 'Usuario',
};

const LAYOUTS = Object.freeze({
	ADMIN: 'admin',
	COLLABORATOR: 'collaborator',
	AUTH: 'auth',
});

const PAYMENT_STATUS = {
	ACTIVE: 'ACTIVE',
	NEW_USER: 'NEW_USER',
	EXPIRED: 'EXPIRED',
};

const SERVICES_STATUS = {
	ACTIVE: 'Activo',
	EXPIRED_SOON: 'Por vencer',
	EXPIRED: 'Vencido',
	TEST: 'Prueba',
};

const MP_PAYMENT_STATUS = {
	approved: 'approved',
	in_process: 'in_process',
	rejected: 'rejected',
};

const EXPIRATION_STATUS = {
	CRITIC: 'alert',
	EXPIRED: 'expired',
	CONTROLLED: 'controlled',
};

const NOTIFICATION_TYPES = {
	NEW_USER: 'NEW_USER',
	DAILY_NEWS: 'DAILY_NEWS',
	LAWYER_DOCUMENT_EXPIRED: 'LAWYER_DOCUMENT_EXPIRED',
	NEW_LAWYER_DOCUMENT: 'NEW_LAWYER_DOCUMENT',
	SERVICE_APROXIMATED_EXPIRED: 'SERVICE_APROXIMATED_EXPIRED',
	UPDATE_LAWYER_DOCUMENT: 'UPDATE_LAWYER_DOCUMENT',
};

const STATUS_COLORS = {
	success: '#92CB38',
	info: '#5FC5FF',
	warning: '#F1DD73',
	error: '#FF5F49',
};

const NOTIEXPRESS_COLORS = {
	neutro1: '#FFFFFF',
	neutro2: '#393939',
	neutro3: '#CCCCD0',
	primaryDefault: '#0592E0',
	primaryVariant1: '#7BBDE1',
	primaryVariant2: '#A3CBE0',
	primaryVariant3: '#D1E7F3',
	primaryVariant4: '#F7FCFF',
	secondaryDefault: '#335C81',
	secondaryDark1: '#274060',
	secondaryDark2: '#183151',
};

// ********** SYNC STATUS **********
const SYNC_STATUS = Object.freeze({
	START: 'START',
	CAPTCHA: 'CAPTCHA',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	SYNCING: 'SYNCING',
});

const CHART_BAR_COLORS = ['#D7F4FB', '#6AD9F1', '#0592E0', '#54677E', '#183151'];

const CHART_BAR_EXPIRATION_COLORS = ['#FBD7D7', '#F16A6A', '#E00505', '#E83636', '#9E0505'];

export {
	ID,
	OWNER,
	ROLE,
	EMAIL,
	PHOTO,
	AUTH,
	NAME,
	LAST_NAME,
	ROLES,
	PAYMENT_STATUS,
	SERVICES_STATUS,
	MP_PAYMENT_STATUS,
	EXPIRATION_STATUS,
	NOTIFICATION_TYPES,
	STATUS_COLORS,
	NOTIEXPRESS_COLORS,
	LAYOUTS,
	SYNC_STATUS,
	COLLABORATOR_LIMIT_MESSAGE,
	CONNECTION_LIMIT_MESSAGE,
  CHART_BAR_COLORS,
  CHART_BAR_EXPIRATION_COLORS
};
