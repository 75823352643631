import React from 'react';
import moment from 'moment';
import { Row } from 'reactstrap';
import { EXPIRATION_STATUS } from '../helpers/consts';
import { ReactComponent as CheckIcon } from 'assets/img/icons/notiexpress/check-icon.svg';
import { ReactComponent as PendingIcon } from 'assets/img/icons/notiexpress/divide-circle-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/notiexpress/close-icon.svg';
import LightningIcon from 'components/Icons/LightningIcon';
import FolderIcon from '../components/Icons/FolderIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TooltipElement from '../components/Commons/TooltipElement';

const orderColumn = {
	Header: 'Orden',
	accessor: ({ orden }) => <p className='text-center text-body-small m-0'>{orden}</p>,
	width: '10px',
};

const orderColumnNews = {
	Header: 'Orden',
	accessor: ({ orden, noveltyUnread }) => (
		<div className='d-flex align-items-center '>
			<span className='text-body-small pr-4'>{orden}</span>
			{!noveltyUnread && <LightningIcon size={12} />}
		</div>
	),
};

const actDateColumn = {
	Header: 'Fecha actuación',
	accessor: ({ lastActuationDate }) => (
		<p
			className='text-center text-body-small m-0'
			style={{ width: '80px' }}
		>
			{lastActuationDate && moment.utc(lastActuationDate).format('DD/MM/YYYY')}
		</p>
	),
	width: 50,
};

const actDateExpirationColumn = {
	Header: 'último despacho',
	accessor: ({ Expediente: { lastActuationDate } }) => (
		<p
			className='text-center text-body-small m-0'
			style={{ width: '80px' }}
		>
			{lastActuationDate && moment.utc(lastActuationDate).format('DD/MM/YYYY')}
		</p>
	),
	width: 50,
};

const expedientExpirationColumn = {
	Header: 'Expediente',
	accessor: ({ Expediente: { number, year } }) => (
		<div className='d-flex justify-content-between'>
			<span className='text-body-small mb-0'>{number + '/' + year}</span>
		</div>
	),
	width: 80,
};

const folderColumn = {
	Header: 'Carpeta',
	accessor: ({ folder }) => (
		<p
			className='text-truncate text-body-small m-0'
			style={{ width: '100%' }}
		>
			{folder ? folder : '-'}
		</p>
	),
	width: 80,
};

const coverExpirationColumn = {
	Header: 'Carátula',
	accessor: ({ Expediente: { coverPage } }) => (
		<p
			className='text-truncate text-body-small m-0'
			style={{ width: '100%' }}
		>
			{coverPage}
		</p>
	),
	width: 200,
};

const expedientColumn = {
	Header: 'Expediente',
	accessor: ({ number, year }) => (
		<div className='d-flex justify-content-between'>
			<span className='text-body-small mb-0'>{number + '/' + year}</span>
		</div>
	),
	width: 80,
};

const newsExpedientColumn = {
	Header: 'Expediente',
	accessor: ({ number, year, id }) => (
		<div className='d-flex justify-content-between'>
			<span className='text-body-small mb-0'>{number + '/' + year}</span>
			<Link
				to={`/admin/expedientes/${id}`}
				className='text-body-small mb-0 ml-2'
			>
				<div id={`tooltip-${id}`}>
					<FolderIcon
						size={20}
						className='stroke-primary-default'
					/>
				</div>
			</Link>
			<TooltipElement id={`tooltip-${id}`}>Ver expediente</TooltipElement>
		</div>
	),
	width: 80,
};

const coverColumn = {
	Header: 'Carátula',
	accessor: ({ coverPage }) => (
		<p
			className='text-truncate text-body-small m-0'
			style={{ width: '100%' }}
		>
			{coverPage}
		</p>
	),
	width: 200,
};

const jurisdictionColumn = {
	Header: 'Jurisdicción',
	accessor: ({ Jurisdiction }) => (
		<p className='text-truncate text-body-small m-0'>
			{Jurisdiction.name === 'Federal' ? 'Capital Federal / Justicia Federal' : Jurisdiction.name}
		</p>
	),
};

const localityColumn = {
	Header: 'Circunscripción',
	accessor: ({ OrganismList }) => (
		<p
			className='text-truncate text-body-small m-0'
			style={{ MaxWidth: '100%' }}
		>
			{OrganismList.length ? OrganismList[0]?.Organism.Circumscription?.name : ''}
		</p>
	),
};

const organismColumn = {
	Header: 'Organismo',
	accessor: ({ OrganismList }) => {
		return (
			<p
				className='text-truncate text-body-small m-0'
				style={{ width: '100%' }}
			>
				{OrganismList[0] ? OrganismList[0]?.Organism.name : ''}
			</p>
		);
	},
	width: 150,
};

const responsibleColumn = {
	Header: 'Responsable',
	accessor: ({ colaborador, User }) => {
		return (
			<p className='text-truncate text-body-small m-0'>
				{colaborador ? colaborador?.name + ' ' + colaborador?.lastName : User?.name + ' ' + User?.lastName}
			</p>
		);
	},
	width: 150,
};

const responsibleExpirationColumn = {
	Header: 'Responsable',
	accessor: ({ Collaborator, User }) => {
		return (
			<p className='text-truncate text-body-small m-0'>
				{Collaborator ? Collaborator?.name + ' ' + Collaborator?.lastName : User?.name + ' ' + User?.lastName}
			</p>
		);
	},
	width: 150,
};

/* const optionsColumn = {
	Header: () => <i className='ni ni-bullet-list-67'></i>,
	accessor: 'id',
	Cell: ({ value }) => <DropdownOptCaseFile id={value} />,
	width: 20,
}; */

const expirationStatusColumn = {
	Header: 'Estado',
	accessor: ({ expirationStatus }) => {
		return (
			<Row className='m-0 justify-content-center'>
				{expirationStatus === EXPIRATION_STATUS.EXPIRED && (
					<CloseIcon
						width={20}
						height={20}
						className='stroke-error'
					/>
				)}
				{expirationStatus === EXPIRATION_STATUS.CRITIC && (
					<PendingIcon
						width={20}
						height={20}
						className='stroke-warning'
					/>
				)}
				{expirationStatus === EXPIRATION_STATUS.CONTROLLED && (
					<CheckIcon
						width={20}
						height={20}
						className='stroke-success'
					/>
				)}
			</Row>
		);
	},
	width: 80,
};

const expirationDateColumn = {
	Header: 'Fecha de caducidad',
	accessor: ({ caducityDate }) => <p className='text-body-small m-0'>{caducityDate && moment.utc(caducityDate).format('DD/MM/YYYY')}</p>,
	width: 50,
};

const expConsistencyColumn = {
	Header: 'Estado Sync',
	accessor: ({ expConsistency }) => (
		<p className='text-center m-0'>
			{expConsistency ? (
				<CheckIcon
					width={20}
					height={20}
					className='stroke-success'
				/>
			) : (
				<CloseIcon
					width={20}
					height={20}
					className='stroke-error'
				/>
			)}
		</p>
	),
	width: 100,
};

export const newsCasesTableColumns = [
	orderColumnNews,
	jurisdictionColumn,
	localityColumn,
	organismColumn,
	newsExpedientColumn,
	coverColumn,
	folderColumn,
	responsibleColumn,
];

export const newsCasesTableColumnsDesktop = [
	orderColumn,
	actDateColumn,
	expedientColumn,
	coverColumn,
	jurisdictionColumn,
	responsibleColumn,
];
export const newsCasesTableColumnsMobile = [orderColumn, expedientColumn, responsibleColumn];

export const newsCasesTableColumnsTablet = [orderColumn, expedientColumn, coverColumn, responsibleColumn];

export const casesTableColumns = [
	orderColumn,
	jurisdictionColumn,
	localityColumn,
	organismColumn,
	expedientColumn,
	coverColumn,
	folderColumn,
	responsibleColumn,
];

export const casesTableColumnsDesktop = [orderColumn, actDateColumn, expedientColumn, coverColumn, responsibleColumn, expConsistencyColumn];

export const casesTableColumnsTablet = [orderColumn, expedientColumn, coverColumn, responsibleColumn, expConsistencyColumn];

export const casesTableColumnsMobile = [orderColumn, expedientColumn, responsibleColumn];

export const casesExpirationTableColumns = [
	orderColumn,
	expedientExpirationColumn,
	coverExpirationColumn,
	folderColumn,
	responsibleExpirationColumn,
	actDateExpirationColumn,
	expirationDateColumn,
];

export const casesExpirationTableColumnsDesktop = [
	orderColumn,
	actDateExpirationColumn,
	expedientExpirationColumn,
	coverExpirationColumn,
	responsibleExpirationColumn,
	expirationStatusColumn,
];

export const casesExpirationTableColumnsMobile = [
	orderColumn,
	expedientExpirationColumn,
	responsibleExpirationColumn,
	expirationStatusColumn,
];

export const casesExpirationTableColumnsTablet = [
	orderColumn,
	expedientExpirationColumn,
	coverExpirationColumn,
	responsibleExpirationColumn,
	expirationStatusColumn,
];
