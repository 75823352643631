import {
	ID,
	ROLE,
	OWNER,
	ROLES,
	EMAIL,
	PHOTO,
	NAME,
	LAST_NAME,
} from './consts';

const getCurrentId = () =>
	localStorage.getItem(ID) || sessionStorage.getItem(ID) || '';

const getCurrentName = () =>
	localStorage.getItem(NAME) || sessionStorage.getItem(NAME) || '';

const getCurrentLastName = () =>
	localStorage.getItem(LAST_NAME) || sessionStorage.getItem(LAST_NAME) || '';

const getCurrentRole = () =>
	localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE) || '';

const getCurrentEmail = () =>
	localStorage.getItem(EMAIL) || sessionStorage.getItem(EMAIL) || '';

const getCurrentPhoto = () =>
	localStorage.getItem(PHOTO) || sessionStorage.getItem(PHOTO) || '';

const getOwnerId = () =>
	getCurrentRole() === ROLES.COLLABORATOR
		? localStorage.getItem(OWNER) || sessionStorage.getItem(OWNER) || ''
		: getCurrentId();

export {
	getCurrentId,
	getCurrentRole,
	getOwnerId,
	getCurrentEmail,
	getCurrentPhoto,
	getCurrentName,
	getCurrentLastName,
};
