import { API_NOTIF } from '../helpers/fetch';
import useSWR, { useSWRInfinite } from 'swr';
import { getOwnerId } from '../helpers/user';

export const notifService = {
	getNotificationsByUserId,
	deleteNotificationById,
	markAsReadNotificationById,
	useNotifications,
	markAllNotificationsAsReadByUserId,
};
const fetcher = (url, params) => {
	return API_NOTIF.get(url, params ? { params: params } : {});
};

function useNotifications({ userId }) {
	const getKey = (pageIndex, previousPageData) => {
		if (previousPageData && !previousPageData.notifications?.length)
			return null;
		return `/notification/${userId}?page=${pageIndex + 1}&limit=10`;
	};
	const { data, error, mutate, size, setSize } = useSWRInfinite(
		getKey,
		fetcher
	);

	return {
		data,
		isLoading: !error && !data,
		isError: !!error,
		mutate,
		size,
		setSize,
	};
}

function getNotificationsByUserId({ userId, params }) {
	const { data, error, mutate } = useSWR(
		[`/notification/${userId ?? getOwnerId()}`, params],
		fetcher
	);
	return {
		data,
		isError: !!error,
		isLoading: !data && !error,
		mutate,
	};
}

function deleteNotificationById({ userId, notificationId }) {
	return API_NOTIF.delete(`/notification/${userId}/${notificationId}`);
}

function markAsReadNotificationById({ userId, notificationId }) {
	return API_NOTIF.put(`/notification/${userId}/${notificationId}`);
}

function markAllNotificationsAsReadByUserId({ userId }) {
	return API_NOTIF.put(`/notification/${userId}`);
}
