import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Col, Collapse, Row } from 'reactstrap';
import ChevronUp from '../Icons/ChevronUp';
import ChevronDown from '../Icons/ChevronDown';
import './CardAccordion.scss';

const CardAccordion = ({ title, children, defaultValue = true }) => {
	const [collapse, setCollapse] = useState(defaultValue);
	const toggle = () => setCollapse(!collapse);

	return (
		<Card className='shadow-card mb-3'>
			<CardHeader>
				<Col>
					<Row
						className='justify-content-between accordion-header'
						onClick={toggle}
					>
						<span className='text-heading-2'>{title}</span>
						{collapse ? (
							<ChevronUp
								size={24}
								className='stroke stroke-secondary-dark-2'
							/>
						) : (
							<ChevronDown
								size={24}
								className='stroke stroke-secondary-dark-2'
							/>
						)}
					</Row>
				</Col>
			</CardHeader>
			<Collapse isOpen={collapse}>{children}</Collapse>
		</Card>
	);
};

CardAccordion.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	defaultValue: PropTypes.bool,
};

export default CardAccordion;
