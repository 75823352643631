import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { subscriptionStatus, amountToPay } from '../../../../helpers/services';
import moment from 'moment/moment';
import CustomProgress from '../../../../components/CustomProgress/CustomProgress';

const UserServiceCard = ({ userService }) => {
	const {
		service: { nombre, periodoVigencia },
		fechaVencimiento,
	} = userService;
	const getCountDaysLeft = () => {
		const today = moment();
		const expirationDate = moment(fechaVencimiento);
		return expirationDate.diff(today, 'days') > 0
			? expirationDate.diff(today, 'days')
			: 0;
	};
	return (
		<Card className='shadow-card mb-3'>
			<CardHeader>
				<div>
					<Row className='justify-content-between m-0'>
						<span className='text-heading-2'>{nombre}</span>
						<Col
							xs='12'
							lg='5'
						>
							{getCountDaysLeft() === 0 ? (
								<Row className='align-items-center'>
									<Col>
										<span className='text-heading-2 text-danger'>
											Tu periodo de prueba finalizó.
										</span>
									</Col>
								</Row>
							) : (
								<Row className='align-items-center'>
									<Col>
										<CustomProgress
											value={getCountDaysLeft()}
											maxValue={periodoVigencia}
										/>
									</Col>
									<Col>
										<span className='text-heading-2 text-neutro-2'>
											Quedan {getCountDaysLeft()} días
										</span>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</div>
			</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<p className='text-body-large-bold text-secondary-default'>
							Estado
						</p>
						<p className='text-body-large'>{subscriptionStatus(userService)}</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default'>
							Vencimiento
						</p>
						<p className='text-body-large'>
							{moment.utc(fechaVencimiento).format('DD/MM/YYYY')}
						</p>
					</Col>
					<Col>
						<p className='text-body-large-bold text-secondary-default'>Costo</p>
						<p className='text-body-large'>{amountToPay(userService)}</p>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

UserServiceCard.propTypes = {
	userService: PropTypes.object,
};

export default UserServiceCard;
