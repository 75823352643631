import React from 'react';
import { Button, Col, DropdownItem, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '../../../../helpers/consts';
import { notifService } from '../../../../services/notificationsService';
import { getCurrentId } from '../../../../helpers/user';
import PropTypes from 'prop-types';
import './NotificationItem.scss';
import EyeIcon from '../../../Icons/EyeIcon';

const NotificationItem = ({ notification, mutate }) => {
	const setNotificationPath = (notification) => {
		switch (notification?.type) {
			case NOTIFICATION_TYPES.NEW_LAWYER_DOCUMENT:
			case NOTIFICATION_TYPES.LAWYER_DOCUMENT_EXPIRED:
			case NOTIFICATION_TYPES.UPDATE_LAWYER_DOCUMENT:
				return `/admin/expedientes/${notification.expId ?? ''}`;
			case NOTIFICATION_TYPES.SERVICE_APROXIMATED_EXPIRED:
				return '/admin/user-profile/subscriptions';
			default:
				return '#';
		}
	};

	const handleMarkAsReadNotification = (notificationId) =>
		notifService
			.markAsReadNotificationById({
				userId: getCurrentId(),
				notificationId: notificationId,
			})
			.then(() => mutate());

	const setNotificationMsg = (notification) => {
		switch (notification?.type) {
			case NOTIFICATION_TYPES.NEW_LAWYER_DOCUMENT:
				return `Se agregó el expediente N° ${notification?.numero}/${notification?.anio}.`;
			case NOTIFICATION_TYPES.LAWYER_DOCUMENT_EXPIRED:
				return `Verifique la caducidad del expediente N° ${notification?.numero}/${notification?.anio}.`;
			case NOTIFICATION_TYPES.UPDATE_LAWYER_DOCUMENT:
				return `El expediente N° ${notification?.numero}/${notification?.anio} tiene cambios.`;
			case NOTIFICATION_TYPES.SERVICE_APROXIMATED_EXPIRED:
				return 'Suscripción pronto a vencer.';
			case NOTIFICATION_TYPES.DAILY_NEWS:
				return 'Nuevas actuaciones en expedientes.';
			default:
				return 'Una nueva notificación.';
		}
	};

	return (
		<DropdownItem
			className='p-0 d-flex justify-content-between align-items-center'
			tag='div'
		>
			<Col
				className='p-2'
				to={setNotificationPath(notification)}
				tag={Link}
				onClick={() => handleMarkAsReadNotification(notification?._id)}
			>
				<span className='text-body-small'>{setNotificationMsg(notification)}</span>
				<div className=''>
					{
						<span className='text-body-small font-italic'>
							{new Date(notification?.createdAt).toLocaleString('es-AR', {
								timeStyle: 'short',
								dateStyle: 'long',
							})}
						</span>
					}
				</div>
			</Col>
			<Col
				className='d-flex justify-content-end'
				style={{
					maxWidth: '50px',
				}}
			>
				<Button
					size='sm'
					onClick={(e) => {
						e.preventDefault();
						handleMarkAsReadNotification(notification?._id);
					}}
					className='border-0 bg-transparent shadow-none'
				>
					<EyeIcon
						size={18}
						className='stroke stroke-primary-default'
					/>
				</Button>
			</Col>
		</DropdownItem>
	);
};

NotificationItem.propTypes = {
	notification: PropTypes.object.bind({
		type: PropTypes.string,
		expId: PropTypes.string,
		numero: PropTypes.number,
		anio: PropTypes.number,
		isRead: PropTypes.bool,
		_id: PropTypes.string,
	}),
	mutate: PropTypes.func,
};
export default NotificationItem;
