import React from 'react';

const ToggleButton = ({ checked, setChecked }) => {
	return (
		<>
			<label className='custom-toggle mb--1'>
				<input
					type='checkbox'
					checked={checked}
					onChange={() => setChecked(!checked)}
				/>
				<span
					className={`custom-toggle-slider rounded-circle ${
						checked ? 'bg-success' : ''
					}`}
				></span>
			</label>
		</>
	);
};

export default ToggleButton;
