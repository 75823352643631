import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ReactComponent as PrinterIcon } from 'assets/img/icons/notiexpress/printer-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/notiexpress/download-icon.svg';
import { Col } from 'reactstrap/lib';
import TooltipElement from '../../TooltipElement.jsx';

const Print = (props) => {
	const _handleDownload = (e) => {
		e.preventDefault();
		if (typeof props.handleDownload === 'function') return props.handleDownload();
	};

	const _handlePrint = (e) => {
		e.preventDefault();
		if (typeof props.handlePrint === 'function') return props.handlePrint();
	};

	if (props.isMobile) {
		// En mobile no se puede imprimir
		return (
			<Col
				xs={6}
				className='justify-content-end d-flex pr-2 pl-0'
			>
				<Button
					id='downloadFile'
					onClick={_handleDownload}
					className='btn btn-noti-icon-primary'
					outline
					disabled={props.disabled}
					bgSize='sm'
				>
					<DownloadIcon
						width={18}
						height={18}
						className='stroke stroke-primary-default'
					/>
				</Button>
			</Col>
		);
	}
	return (
		<>
			<Col>
				<Button
					id='printFile'
					onClick={_handlePrint}
					className='btn btn-noti-icon-primary px-2'
					outline
					disabled={props.disabled}
				>
					<PrinterIcon
						width={24}
						height={24}
						className='stroke stroke-primary-default'
					/>
				</Button>
        <TooltipElement
          id={'printFile'}
          children={'Imprimir listado'}
        />
			</Col>
			<Col>
				<Button
					id='downloadFile'
					onClick={_handleDownload}
					className='btn btn-noti-icon-primary px-2'
					outline
					disabled={props.disabled}
				>
					<DownloadIcon
						width={24}
						height={24}
						className='stroke stroke-primary-default'
					/>
				</Button>
        <TooltipElement
          id={'downloadFile'}
          children={'Descargar listado'}
        />
			</Col>
		</>
	);
};

export default Print;

Print.propTypes = {
	disabled: PropTypes.bool,
	handlePrint: PropTypes.func,
	handleDownload: PropTypes.func,
	isMobile: PropTypes.bool,
};
