import React from 'react';
import propTypes from 'prop-types';

const MessageCircleIcon = ({ size, fill = 'transparent' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 20 20'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18.1 13.3C18.6951 12.1219 19.0034 10.8199 19 9.50003V9.00003C18.885 6.91568 18.0052 4.94699 16.5291 3.47089C15.053 1.99479 13.0843 1.11502 11 1.00003H10.5C9.18013 0.996587 7.87812 1.30496 6.7 1.90003C5.28825 2.6056 4.10083 3.69028 3.27072 5.03258C2.44061 6.37488 2.00061 7.92179 2 9.50003C1.99656 10.8199 2.30493 12.1219 2.9 13.3L1 19L6.7 17.1C7.87812 17.6951 9.18013 18.0035 10.5 18C12.0782 17.9994 13.6251 17.5594 14.9674 16.7293C16.3098 15.8992 17.3944 14.7118 18.1 13.3Z'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

MessageCircleIcon.propTypes = {
  size: propTypes.number.isRequired,
  fill: propTypes.string,
};

export default MessageCircleIcon;
