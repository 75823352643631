import React, { useState } from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { connectionsService } from 'services/connectionsService';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import CustomModal from '../../../components/Modals/CustomModal/CustomModal';

function DeleteConnectionModal({
	deleteModalIsOpen,
	setDeleteModalIsOpen,
	connection,
	mutate,
  setConnectionErrorMessage,
  setConnectionSuccessMessage,
}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	function handleDeleteConnection(event) {
		setIsSubmitting(true);
		event.preventDefault();
		connectionsService
			.remove(connection?.id)
			.then(() => {
				mutate();
				setDeleteModalIsOpen(false);
        setConnectionSuccessMessage('¡Conexión eliminada!');
			})
			.catch(() => {
				setConnectionErrorMessage('No se pudo eliminar la conexión');
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	}

	return (
		<CustomModal
			isOpen={deleteModalIsOpen}
			toggle={() => setDeleteModalIsOpen(false)}
			title='Eliminar conexión'
			className='w-50'
		>
			<Form
				role='form'
				onSubmit={handleDeleteConnection}
			>
				<ModalBody className='px-5'>
					<h4
						className='text-heading-2'
						id='exampleModalLabel'
					>
						¿Estás seguro que querés eliminar la conexión?
					</h4>
					<p className='text-body-large'>
						Si eliminás la conexión, perderás su configuración y no podrás
						acceder a los expedientes de la jurisdicción.
					</p>
				</ModalBody>
				<ModalFooter className='justify-content-start pb-5 pl-5'>
					<Button
						className='btn btn-outline-noti-secondary py-1 px-5'
						data-dismiss='modal'
						type='button'
						onClick={() => setDeleteModalIsOpen(false)}
					>
						<span>Cancelar</span>
					</Button>
					<Button
						className='btn btn-noti-primary py-1 px-5 ml-3'
						type='submit'
						disabled={isSubmitting}
					>
						<span
							className={`spinner-border spinner-border-sm mr-3 ${
								isSubmitting ? '' : 'd-none'
							}`}
							role='status'
							aria-hidden='true'
						></span>
						<span className='text-button'>Aceptar</span>
					</Button>
				</ModalFooter>
			</Form>
		</CustomModal>
	);
}

export default DeleteConnectionModal;

DeleteConnectionModal.propTypes = {
	deleteModalIsOpen: PropTypes.bool,
	setDeleteModalIsOpen: PropTypes.func,
	connection: PropTypes.object,
	jurisdiccion: PropTypes.object,
	mutate: PropTypes.func,
  setConnectionErrorMessage: PropTypes.func,
  setConnectionSuccessMessage: PropTypes.func,
};
