import React, { useContext } from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Media,
	Nav,
	UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROLES } from '../../../helpers/consts';
import user from 'context/user';
import { userService } from '../../../services/userService';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProfilePhotoIcon from '../../Icons/ProfilePhotoIcon';
import UserIcon from '../../Icons/UserIcon';
import CreditCardIcon from '../../Icons/CreditCardIcon';
import { ReactComponent as InfoHelpIcon } from '../../../assets/img/icons/notiexpress/info.svg';
import LogoutIcon from '../../Icons/LogoutIcon';

const ProfileDropdown = ({ layout }) => {
	const {
		state: { userdata, currentUser },
		actions: { logout },
	} = useContext(user);

	const history = useHistory();

	function handleLogOut() {
		logout();
		userService.logout();
		history.push('/auth/login');
	}

	return (
		<Nav
			className='align-items-center d-none d-md-flex'
			navbar
		>
			<UncontrolledDropdown
				active
			>
				<DropdownToggle
					nav
				>
					<Media className='align-items-center'>
						{userdata?.Profile?.profilePicture ? (
							<img
								src={userdata?.Profile?.profilePicture}
								width={36}
								height={36}
								alt='profile'
								className='rounded-circle'
							/>
						) : (
							<ProfilePhotoIcon size={36} />
						)}
					</Media>
				</DropdownToggle>
				<DropdownMenu
					right
					style={{ minWidth: '350px' }}
				>
					<div className='p-2 px-5'>
						<p className='text-heading-2 text-center'>{`${
							currentUser.name ?? userdata.name
						} ${currentUser.lastName ?? userdata.lastName}`}</p>
						<p className='text-body-large-bold text-center m-0'>
							{currentUser.email}
						</p>
					</div>
					<DropdownItem divider />
					{currentUser.rol === ROLES.USER && (
						<>
							<DropdownItem
								className='pl-4'
								to='/admin/user-profile?tab=1'
								tag={Link}
							>
								<UserIcon
									size={20}
									className='stroke stroke-primary-default'
								/>
								<span className='nav-link-text p-1 pt-2'>
									Perfil
								</span>
							</DropdownItem>
						</>
					)}
					<DropdownItem divider />
					{currentUser.rol === ROLES.USER && (
						<DropdownItem
							className='pl-4'
							to='/admin/user-profile/subscriptions'
							tag={Link}
						>
							<CreditCardIcon
								className='stroke stroke-primary-default'
								size={20}
							/>
							<span className='nav-link-text p-1 pt-2'>Pagos</span>
						</DropdownItem>
					)}
					<DropdownItem divider />
					<DropdownItem
						className='pl-4'
						to={`/${layout}/ayuda`}
						tag={Link}
					>
						<InfoHelpIcon
							className='stroke stroke-primary-default'
              width={20}
						/>
						<span className='nav-link-text p-1 pt-2'>Ayuda</span>
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem
						className='pl-4'
						href='/auth/login'
						onClick={(e) => {
							e.preventDefault();
							handleLogOut();
						}}
					>
						<LogoutIcon
							className='stroke stroke-primary-default'
							size={20}
						/>
						<span className='nav-link-text p-1 pt-2'>
							Cerrar sesión
						</span>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		</Nav>
	);
};

export default ProfileDropdown;

ProfileDropdown.propTypes = {
	status: PropTypes.string,
	layout: PropTypes.string,
};
